import Big from 'big.js';
import debounce from 'lodash/debounce';
import { mapState, mapActions } from 'vuex';
import { required, requiredIf, minValue, maxValue } from 'vuelidate/lib/validators';
import { formatarValor, formatarValorSemPrefixo } from '@/utils/format';

export default {
  validations: {
    dados: {
      descricao: { required },
      tipo: { required },
      lote: {
        required: requiredIf(function validarUnidade() {
          return this.cachedlots?.length > 0 && this.dados.tipo !== 'LOT';
        }),
      },
      unidade: {
        required: requiredIf(function validarUnidade() {
          return this.dados.tipo !== 'LOT';
        }),
      },
      quantidade: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(999999999.9999),
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    this.limparPesquisa();
    return next();
  },
  computed: {
    ...mapState('itens', {
      pesquisa: 'itens',
    }),
    valorReducaoValido() {
      const valorReducao = new Big(Number(this.dados.reducaoLances));

      return valorReducao.gt(0);
    },
    totalGeral() {
      const totalGeral = this.itens.reduce(
        (total, item) => total + item.quantidade * item.referencia,
        0,
      );
      return formatarValor(totalGeral);
    },
    exibeOpcoes() {
      return this.situacao < 4 || this.exibirBotaoRevogarItem;
    },
  },
  methods: {
    ...mapActions('itens', ['pesquisarItens', 'limparPesquisa']),
    atualizarReferencial(dados, referencial) {
      if (referencial === 'unitario') {
        this.dados.referencia = dados.valor;

        this.calcularTotalItem();
      }

      if (referencial === 'total') {
        this.dados.total = dados.valor;

        this.calcularUnitarioItem();
      }
    },
    selecionarItem(option) {
      this.dados.itemSelected = option;
      if (option) this.dados.tipo = option.type;
    },
    exibirTagRevogado(estado) {
      return estado === 'REVOGADO';
    },
    calcularTotalItem() {
      if (this.dados && this.dados.quantidade) {
        const quantidade = new Big(this.dados.quantidade);
        const referencia = new Big(this.dados.referencia);

        const total = Number(quantidade.times(referencia));
        this.dados.total = total;
        this.$refs.referencialTotal.dados.valor = total;
        this.$refs.referencialTotal.valoresMascara.valor = formatarValorSemPrefixo(total);
      }
    },
    calcularUnitarioItem() {
      if (this.dados.quantidade) {
        const total = new Big(this.dados.total);
        const quantidade = new Big(this.dados.quantidade);

        const ref = Number(total.div(quantidade));
        this.dados.referencia = ref;
        this.$refs.referencialUnitario.dados.valor = ref;
        this.$refs.referencialUnitario.valoresMascara.valor = formatarValorSemPrefixo(ref);
      }
    },
    verificarTipoNegociacao() {
      if (this.dados.referencia) this.calcularTotalItem();
      if (this.dados.total) this.calcularUnitarioItem();
    },
    pesquisarDadosItens: debounce(async function obterDadosItens() {
      if (this.dados.descricao && !this.dados.itemSelected) {
        this.loading.descricao = true;
        try {
          await this.pesquisarItens({
            description: this.dados.descricao,
            type: 'all',
            perPage: 20,
          });
        } catch (error) {
          this.$alerta('Erro ao pesquisar descrição detalhada', 'is-danger');
        }
        this.loading.descricao = false;
      }
    }, 500),
    async adicionar() {
      const valido = await this.validarItem();
      if (valido) this.salvarItem();
    },
    validarItem() {
      const valoresInvalidos = [];

      Object.keys(this.$refs)
        .filter(key => key !== 'justificationModal')
        .forEach(key => {
          if (this.$refs[key] && this.$refs[key].$v.dados.$invalid) {
            this.$refs[key].$v.dados.$touch();
            valoresInvalidos.push(this.$refs[key]);
          }
        });

      if (this.$v.dados.$invalid || valoresInvalidos.length) {
        this.$v.dados.$touch();
        this.$alerta('Verifique todos os dados');
        return false;
      }

      if (this.dados.referencia && this.dados.total) {
        const porcentagem = new Big(
          (this.tipoNegociacao === 1 ? this.dados.referencia : this.dados.total) * 0.1,
        );
        const reducaoLances = new Big(this.dados.reducaoLances);

        if (reducaoLances.gt(porcentagem)) {
          const proceed = this.$confirmacao({
            message: `O valor de redução de lance é maior que 10% do valor do seu referencial.
              Deseja continuar?`,

            cancelText: 'Cancelar',
            confirmText: 'Continuar',
            type: 'is-danger',
          });

          if (!proceed) {
            return false;
          }
        }
      }

      return true;
    },
    editarItem(itemId) {
      this.modo = 'editar';
      const filtroItem = this.itens.filter(elemento => elemento.id === itemId);
      const item = filtroItem[0];
      this.montarDadosItem(item);
    },
    editarLoteItem(item) {
      this.modo = 'editar';
      this.montarDadosItem(item, true);
    },
    abrirModal(id, field) {
      this.dados[field] = id;
      this.textoFalha = 'Revogar';
      this.exibirSucesso = false;
      this.exibirFalha = true;
      this.justificativa = true;
    },
    limparCampos(field) {
      this.item = '';
      this.dados.descricao = '';
      this.dados.unidade = null;
      this.dados.itemSelected = null;
      this.dados.quantidade = 1;
      this.dados.referencia = 0;
      this.dados.reducaoLances = 0;
      this.dados.total = 0;
      this.valoresMascara.quantidade = '1';
      this.$refs.referencialUnitario?.limparCampos();

      if (field === 'dispensa' || (field === 'ofertaCompra' && !this.maiorDesconto)) {
        this.$refs.reducaoLances?.limparCampos();
      }

      if (field === 'ofertaCompra') {
        this.dados.exclusivo = false;
        this.dados.reducaoLancesPorcentagem = 0;

        if (this.maiorDesconto) {
          this.$refs.reducaoLancesPercentual?.limparCampos();
        }
      }

      this.$refs.referencialTotal?.limparCampos();
      this.$v.dados.$reset();
    },
  },
};
