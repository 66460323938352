<template>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Tempo</p>
      <p class="title has-text-warning">{{ timer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTimer',
  data() {
    return {
      timer: '00:00',
    };
  },
  sockets: {
    relogio(timer) {
      const minutos = Math.floor(timer / 60);
      const segundos = timer % 60;
      this.timer = `${minutos < 10 ? `0${minutos}` : minutos}:${
        segundos < 10 ? `0${segundos}` : segundos
      }`;
      document.title = `${this.timer} SelCorp - Sistema Eletrônico de Licitações`;
      if (timer === 0) {
        document.title = 'SelCorp - Sistema Eletrônico de Licitações';
        this.$emitBus('onTerminar:itensEmLance');
      }
    },
  },
};
</script>
