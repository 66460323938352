import {
  fetchRequiredDocuments,
  addRequiredDocument,
  removeRequiredDocument,
} from '@/resources/electronicAuctions/requiredDocuments';

const types = {
  REQUIRED_DOCUMENTS: 'REQUIRED_DOCUMENTS',
  REQUIRED_DOCUMENT_ADD: 'REQUIRED_DOCUMENT_ADD',
  REQUIRED_DOCUMENT_REMOVE: 'REQUIRED_DOCUMENT_REMOVE',
};

const mutations = {
  [types.REQUIRED_DOCUMENTS](state, data) {
    if (data && Array.isArray(data)) {
      state.requiredDocuments = data;
    }
  },
  [types.REQUIRED_DOCUMENT_ADD](state, data) {
    if (data && typeof data === 'object') {
      state.requiredDocuments = [...state.requiredDocuments, data].sort((a, b) =>
        a.type.localeCompare(b.type),
      );
    }
  },
  [types.REQUIRED_DOCUMENT_REMOVE](state, data) {
    const index = state.requiredDocuments.findIndex(element => element.id === data.id);
    if (index > -1) state.requiredDocuments.splice(index, 1);
  },
};

const actions = {
  async fetchRequiredDocuments({ commit }, auctionId) {
    const response = await fetchRequiredDocuments(auctionId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.REQUIRED_DOCUMENTS, response.data);
  },
  async addRequiredDocument({ commit }, data) {
    const { auctionId } = data;
    const response = await addRequiredDocument(auctionId, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.REQUIRED_DOCUMENT_ADD, response.data);
  },
  async removeRequiredDocument({ commit }, { auctionId, requiredDocumentId }) {
    const response = await removeRequiredDocument(auctionId, requiredDocumentId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.REQUIRED_DOCUMENT_REMOVE, response.data);
  },
};

const getters = {};

const state = {
  requiredDocuments: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
