import {
  getCompanyKeys,
  insertCompanyKey,
  removeCompanyKey,
} from '@/resources/companies/companiesKeys';

const types = {
  COMPANY_KEYS: 'COMPANY_KEYS',
  COMPANY_KEYS_INSERT: 'COMPANY_KEYS_INSERT',
  COMPANY_KEYS_REMOVE: 'COMPANY_KEYS_REMOVE',
};

const mutations = {
  [types.COMPANY_KEYS](state, data) {
    if (data && Array.isArray(data)) {
      state.keys = data;
    }
  },
  [types.COMPANY_KEYS_INSERT](state, data) {
    if (data && typeof data === 'object') {
      state.keys.unshift(data);
    }
  },
  [types.COMPANY_KEYS_REMOVE](state, keyId) {
    const index = state.keys.findIndex(key => key.id === Number(keyId));
    if (index > -1) state.keys.splice(index, 1);
  },
};

const actions = {
  async getCompanyKeys({ commit }, data) {
    const response = await getCompanyKeys(data.companyId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY_KEYS, response.data);
  },
  async insertCompanyKey({ commit }, data) {
    const response = await insertCompanyKey(data.companyId, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_KEYS_INSERT, response.data);
  },
  async removeCompanyKey({ commit }, data) {
    const { companyId, keyId } = data;
    const response = await removeCompanyKey(companyId, keyId);
    if (response.status !== 204) return Promise.reject(response.data);
    return commit(types.COMPANY_KEYS_REMOVE, keyId);
  },
};

const state = {
  keys: [],
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

export { types };
