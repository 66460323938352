import { api } from '../http';

export const fetchUserNotifications = (filters, userId) => {
  let params = '?';
  if (filters) {
    if (filters.status) params += `status=${filters.status}&`;
    if (filters.page) params += `page=${filters.page}&`;
    if (filters.perPage) params += `per_page=${filters.perPage}&`;
  }
  return api.get(`/users/${userId}/notifications${params}`);
};

export const fetchUserNotificationById = (notificationId, userId) =>
  api.get(`/users/${userId}/notifications/${notificationId}`);
export const readUserNotification = (notificationId, userId) =>
  api.patch(`/users/${userId}/notifications/${notificationId}`);
export const setUserNotificationsAsRead = userId =>
  api.patch(`/users/${userId}/notifications/mark-as-read`);
export const updateUserInvitation = (notificationId, userId, data) =>
  api.patch(`/users/${userId}/notifications/${notificationId}/invitation`, data);
