<template>
  <div class="box is-paddingless chat" :class="{ 'increase-chat-size': viewParticipants }">
    <div v-if="viewParticipants && isActive" class="panel participants-panel">
      <div class="participants-content" :class="{ 'split-columns': mobile }">
        <div
          class="participant-content"
          v-for="participant in participants"
          :key="participant.connection"
        >
          <b-icon
            class="participant-icon"
            icon="circle-medium"
            :type="{ 'is-danger': !participant.online, 'is-success': participant.online }"
          ></b-icon>
          <span v-for="alias in participant.alias" :key="alias">{{
            participant.type.includes('seller') && participant.socialDenomination
              ? reduceSocialDenomination(participant.socialDenomination)
              : alias
          }}</span>
        </div>
      </div>
    </div>

    <div class="panel chat-panel">
      <div
        class="panel-heading chat-header is-relative"
        :class="{ 'remove-border-chat-header': viewParticipants }"
        @click.stop="toggleChat"
      >
        <b-tag v-if="!isActive && quantity" class="chat-counter" type="is-danger">{{
          quantity
        }}</b-tag>

        <b-button
          v-if="displayHeaderElements && isActive"
          class="participants-button"
          size="is-small"
          type="is-primary"
          :icon-left="mobile ? 'arrow-expand-vertical' : 'arrow-expand-horizontal'"
          @click.stop.prevent="viewParticipants = !viewParticipants"
        >
          Participantes
        </b-button>

        <span>
          Chat
        </span>

        <b-tooltip
          v-if="displayHeaderElements"
          class="chat-status"
          :type="connected ? 'is-success' : 'is-danger'"
          :label="connected ? 'Conectado' : 'Desconectado'"
        >
          <b-icon
            icon="circle"
            size="is-small"
            :type="{ 'is-danger': !connected, 'is-success': connected }"
          ></b-icon>
        </b-tooltip>
      </div>

      <div class="chat-content" ref="chat">
        <div ref="messages" class="messages" @scroll="event => handleScroll(event.target)">
          <div class="panel-block notificacao">
            <div class="media-content">
              <a
                class="content has-text-centered load-messages"
                @click.stop.prevent="loadMoreMessages()"
              >
                <small class="tag">Carregar mensagens antigas</small>
              </a>
            </div>
          </div>
          <div
            :class="['panel-block', message.tipo === 1 ? 'notificacao' : 'conteudo']"
            v-for="message in messagesToShow"
            :key="message.id"
          >
            <div class="media-content">
              <div class="content" :class="{ 'has-text-centered': message.tipo === 1 }">
                <div v-if="message.tipo === 1">
                  <small class="tag">{{ message.mensagem }}</small>
                </div>

                <div v-else>
                  <p>
                    <strong :class="{ 'has-text-danger': message.tipo === 'SISTEMA' }">
                      {{ message.usuario }}
                    </strong>

                    <small class="is-pulled-right">{{
                      message.enviado | formatarDataSegundo
                    }}</small>
                  </p>
                </div>

                <div v-if="message.tipo !== 1">
                  <span>{{ message.mensagem }}</span>
                </div>
              </div>
            </div>
          </div>

          <a
            v-if="isActive && !scrollBottom && quantity"
            class="button is-small down-button"
            size="is-small"
            :class="{ 'down-button-without-message': !displayFieldButton }"
            @click.stop.prevent="goDownChat()"
          >
            <strong>Novas Mensagens</strong>
            <b-icon class="down-button-icon" icon="chevron-double-down" size="is-medium"></b-icon>
          </a>
        </div>
        <div v-if="displayFieldButton" class="insert-message">
          <form>
            <b-field @keyup.enter="sendMessage" expanded>
              <b-input
                expanded
                class="custom-chat-input"
                ref="inputMessage"
                placeholder="Digite uma mensagem"
                v-model="message"
                @input.native="event => firstLetterUpperCase(event.target.value)"
              ></b-input>

              <p class="control">
                <button
                  class="button is-primary custom-chat-button"
                  :class="{ 'is-loading': loading }"
                  @click.prevent="sendMessage"
                >
                  Enviar
                </button>
              </p>
            </b-field>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { funcoes } from '@/utils/enumerados';

import { formatarData, formatarDataSegundo } from '@/utils/format';

import SocketResource from '@/resources/socket';

const MESSAGES_PER_LOAD = 20;

export default {
  name: 'AuctionChat',
  data() {
    return {
      message: '',
      quantity: 0,
      total: MESSAGES_PER_LOAD,
      isActive: false,
      loading: false,
      socketResource: new SocketResource(this.$socket),
      scrollBottom: false,
      viewParticipants: false,
    };
  },
  watch: {
    situation() {
      if (this.situation > 11) {
        this.viewParticipants = false;
      }
    },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('auctionsBidders', ['userLoggedBidder']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      function: 'getFunction',
    }),
    ...mapState('chat', ['messages', 'participants']),
    messagesToShow() {
      return this.messages.slice(this.total * -1);
    },
    connected() {
      return this.$socket.connected;
    },
    chat() {
      return this.$refs.messages;
    },
    situation() {
      return this.auction.situacao.id;
    },
    authority() {
      return this.function === funcoes.AUTORIDADE;
    },
    crier() {
      return this.function === funcoes.PREGOEIRO;
    },
    visitor() {
      return this.function === funcoes.VISITANTE;
    },
    bidder() {
      return this.function === funcoes.LICITANTE;
    },
    displayFieldButton() {
      return (this.crier || this.bidder) && this.situation >= 5 && this.situation <= 11;
    },
    displayHeaderElements() {
      return this.situation >= 4 && this.situation <= 11;
    },
    mobile() {
      return this.windowWidth <= 800;
    },
  },
  filters: {
    formatarData,
    formatarDataSegundo,
  },
  sockets: {
    'chat:notification': function chatNotification(message) {
      const data = {
        id: `N${this.messages.length + 1}`,
        tipo: 1,
        enviado: new Date(),
        mensagem: message,
      };
      this.addNewMessage(data);
    },
    'chat:connected': function chatConnected(message) {
      this.updateParticipantStatus(message);
    },
    'chat:disconnected': function chatDisconnected(message) {
      this.updateParticipantStatus(message);
    },
    'chat:message': function chatMessage(data) {
      this.addNewMessage(data);
    },
    'chat:update': function chatUpdate(data) {
      this.updateMessage(data);
    },
  },
  methods: {
    ...mapActions('chat', ['addMessage', 'updateMessage', 'updateParticipantStatus']),
    addNewMessage(data) {
      this.addMessage(data);
      const scrollToBottom = this.handleScroll(this.chat);
      this.notification(scrollToBottom);
    },
    loadMoreMessages() {
      if (this.total > this.messages.length) {
        this.total = this.messages.length;
      } else {
        this.total += MESSAGES_PER_LOAD;
      }
    },
    goDownChat() {
      this.$nextTick(function scrollChat() {
        const chat = this.$refs.messages;
        chat.scrollTop = chat.scrollHeight + 1500;
      });

      this.quantity = 0;
    },
    async sendMessage() {
      if (this.message !== '') {
        this.loading = true;
        const dados = {
          id: `M${this.messages.length + 1}`,
          tipo: 2,
          usuario: this.crier ? 'Pregoeiro(a)' : this.userLoggedBidder.alias,
          mensagem: this.message,
        };
        await this.addMessage(dados);
        this.socketResource.sendMessage({
          id: dados.id,
          message: this.message,
        });
        this.goDownChat();
      }
      this.message = '';
      this.loading = false;
    },
    firstLetterUpperCase(message) {
      if (message && message.length === 1) {
        setTimeout(() => {
          this.message = message.charAt(0).toUpperCase();
        }, 1);
      }
    },
    handleScroll(chat) {
      if (chat.scrollHeight - chat.scrollTop === chat.clientHeight) {
        this.scrollBottom = true;
        this.quantity = 0;
        return true;
      }

      this.scrollBottom = false;
      return false;
    },
    notification(scrollToBottom) {
      if (!this.isActive) {
        this.quantity += 1;
        return;
      }

      if (this.isActive) {
        if (scrollToBottom) {
          this.goDownChat();
        } else {
          this.quantity += 1;
        }
      }
    },
    reduceSocialDenomination(socialDenomination) {
      return socialDenomination
        .split(' ')
        .slice(0, 2)
        .join(' ');
    },
    toggleChat() {
      this.quantity = 0;
      this.$refs.chat.classList.toggle('chat-ativo');
      this.viewParticipants = false;
      this.isActive = !this.isActive;

      this.goDownChat();
      if (this.isActive && this.displayFieldButton) this.$refs.inputMessage.focus();
    },
  },
};
</script>

<style lang="scss">
.chat {
  bottom: 0;
  right: 1.2rem;
  display: flex !important;
  position: fixed;
  width: 45%;
  z-index: 5;
}

.chat-header {
  cursor: pointer;
  text-align: center;
}

.chat-content {
  font-size: 0.96rem;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.chat-content.chat-ativo {
  height: 340px;
}

.chat-counter {
  left: 15px;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 0;
  width: 35px;
}

.chat-panel {
  width: 100%;
}

.chat-status {
  position: absolute !important;
  right: 30px;
}

.custom-chat-button {
  border-bottom-right-radius: 0 !important;
}

.custom-chat-input > input {
  border-top-left-radius: 0 !important;
}

.down-button {
  position: absolute !important;
  right: 38%;
  bottom: 55px;
  border-color: transparent;
  background-color: #feecf0 !important;
  color: #cc0f35 !important;
}

.down-button:hover {
  border-color: #ffb5c5 !important;
  background-color: #ffd5de !important;
  color: #cc0f35 !important;
}

.down-button-without-message {
  bottom: 25px;
}

.down-button-icon {
  margin-left: 0.4rem !important;
}

.insert-message {
  height: 36px;
}

.media-content {
  word-break: break-word;
}

.messages {
  max-height: 340px;
  height: 100%;
  overflow-y: auto;
}

.participants-panel {
  margin-bottom: unset !important;
  background-color: #ededed;
  overflow: auto;
  height: 395px;
}

.participant-content {
  margin-bottom: 0.2rem;
}

.participant-icon {
  vertical-align: middle;
}

.participants-content {
  margin-top: 0.5rem;
  font-size: 0.88rem;
}

.participants-button {
  position: absolute !important;
  left: 1%;
}

.split-columns {
  column-count: 2;
}

.load-messages {
  display: block;
}

@media (max-width: 1023px) {
  .chat {
    left: 5%;
    right: 5%;
    width: 90%;
  }

  .chat-content {
    font-size: 0.88rem;
  }

  .chat-panel {
    height: 50%;
    position: relative;
  }

  .participants-panel {
    position: absolute;
    width: 100%;
    height: 36%;
    z-index: 1;
    top: 14%;
    border-radius: 0 0 6px 6px !important;
  }
}

@media (min-width: 1024px) {
  .increase-chat-size {
    width: 56.25% !important;
  }

  .participants-panel {
    width: 25%;
    border-radius: 6px 0 0 0 !important;
  }

  .remove-border-chat-header {
    border-radius: 0 6px 0 0 !important;
  }
}

@media (min-width: 1500px) {
  .chat {
    width: 40%;
  }

  .chat-content.chat-ativo {
    height: 400px;
  }

  .increase-chat-size {
    width: 50% !important;
  }

  .messages {
    max-height: 400px;
  }

  .panel-content {
    height: 455px;
  }
}
</style>
