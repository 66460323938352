import Vue from 'vue';
import Buefy from 'buefy';

Vue.use(Buefy, {
  defaultDateParser(data) {
    const dataQuebrada = data.split('/');
    return new Date(dataQuebrada[2], dataQuebrada[1] - 1, dataQuebrada[0]);
  },
  defaultDateFormatter(data) {
    return data.toLocaleDateString('pt-BR');
  },
  defaultDayNames: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  defaultMonthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  defaultDialogConfirmText: 'Confirmar',
  defaultDialogCancelText: 'Cancelar',
});
