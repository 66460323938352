<template>
  <div class="panel">
    <div class="panel-heading">
      Documentos Requeridos
    </div>

    <div class="panel-content">
      <div v-if="!requiredDocuments.length" class="panel-block empty-panel">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhum documento requerido.</p>
          </div>
        </section>
      </div>

      <div v-if="requiredDocuments.length">
        <ul
          class="required-documents-list"
          v-for="requiredDocument in requiredDocuments"
          :key="requiredDocument.id"
        >
          <li class="required-documents-item">
            <b-icon
              v-if="auctionDocuments.some(document => document.type === requiredDocument.type)"
              class="custom-icon"
              icon="check"
              type="is-success"
            ></b-icon>
            <b-icon v-else class="custom-icon" icon="close" type="is-danger"></b-icon>
            <span>{{ requiredDocument.type }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ListRequiredDocuments',
  computed: {
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapState('auctionsDocuments', ['auctionDocuments']),
  },
};
</script>

<style lang="scss" scoped>
.custom-icon {
  vertical-align: middle;
}

.empty-panel {
  justify-content: center !important;
}

.panel-content {
  font-size: 0.92rem;
  padding: 0.5rem;
  height: unset !important;
}

.required-documents-item {
  margin: 0.2rem 0 0.2rem;
}

@media (max-width: 1500px) {
  .panel-content {
    font-size: 0.88rem;
  }
}
</style>
