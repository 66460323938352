<template>
  <div class="component-container">
    <div class="columns without-margin">
      <div class="column is-half buyer-container">
        <div class="title-container">
          <h1 class="custom-title">Órgãos Públicos</h1>
        </div>

        <div class="buyer-banner-title-container">
          <h1 class="banner-title">
            transparência, ética
            <strong>& imparcialidade</strong>
          </h1>
        </div>

        <div class="buyer-text-container">
          <div class="content banner-text-call">
            <p class="title-list">Sistema SelCorp é ideal para:</p>

            <ul class="custom-list">
              <li>Pregão Eletrônico</li>
              <li>Dispensa Eletrônica</li>
            </ul>
          </div>

          <p class="banner-text-call">
            Atendendo à legislação vigente é a parceira ideal para seu time de licitação.
          </p>
        </div>

        <div class="action-container">
          <p class="action-text">
            O cadastro de compradores é feito pela SelCorp para maior comodidade, rapidez e
            segurança.
          </p>

          <div class="buttons-container">
            <b-button
              tag="router-link"
              class="custom-button"
              type="is-primary"
              :to="{ name: 'InterestedBuyerRegistration' }"
            >
              Entre em Contato
            </b-button>
          </div>
        </div>
      </div>

      <div class="column is-half seller-container">
        <div class="title-container">
          <h1 class="title custom-title">Fornecedores</h1>
        </div>

        <div class="seller-banner-title-container">
          <h1 class="banner-title">
            Simples, Ágil, confiável
            <strong>& intuitivo</strong>
          </h1>
        </div>

        <div class="seller-text-container">
          <div class="content banner-text-call">
            <p class="title-list">
              Sistema SelCorp, uma Nova era para os fornecedores:
            </p>

            <ul class="custom-list">
              <li>
                Sistema intuitivo, criado para o conforto, agilidade, velocidade e confiança para os
                fornecedores
              </li>
            </ul>
          </div>

          <p class="banner-text-call">
            Sistema visualmente desenvolvido para facilitar os lances e evitar erros.
          </p>
        </div>

        <div class="action-container">
          <p class="action-text">
            Acesse o manual de cadastro para dúvidas e
            <span class="register-word">cadastre-se</span> agora mesmo.
          </p>

          <div class="buttons-container">
            <b-button tag="router-link" to="/manuais" class="custom-button" type="is-light">
              Manuais
            </b-button>

            <b-button tag="router-link" to="/cadastrar" class="custom-button" type="is-primary">
              Cadastre-se
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreRegistration',
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Gotham Light';
  src: url('~@/assets/fonts/gotham/gotham-htf-light.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-light.woff') format('woff');
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('~@/assets/fonts/gotham/gotham-htf-medium.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

.action-container {
  margin: 2.5rem 0.5rem;
}

.action-text {
  color: white;
  font-family: 'Gotham Light';
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
}

.banner-text-call {
  color: white;
  font-family: 'Gotham Medium';
  font-size: 1.3rem;
  margin: 1rem 2rem;
  text-align: left;
}

.banner-title {
  color: white;
  font-family: 'Gotham Medium';
  font-size: 1.8rem;
  font-weight: 800;
  margin: 2rem;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.82px;

  strong {
    color: white;
    display: block;
    font-family: 'Gotham Light';
    font-weight: 300;
    font-size: 1.8rem;
  }
}

.buyer-banner-title-container {
  display: flex;
  height: 20%;
  justify-content: left;
}

.buyer-container {
  background-color: #177caf;
  height: 100vh;
}

.buyer-text-container {
  height: 30%;
  margin: 1rem 0;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.component-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
}

.container-buyer {
  align-items: center;
  justify-content: center;
}

.custom-button {
  border-radius: 6px;
  font-size: 1.4rem;
  margin: 0 0.5rem;
}

.custom-list {
  margin-top: 0.5rem;
}

.custom-title {
  color: white;
  font-family: 'Gotham Medium';
  font-size: 2rem;
  font-weight: 800;
}

.register-word {
  white-space: nowrap;
}

.seller-container {
  background-color: #0e6e9e;
  height: 100vh;
}

.seller-banner-title-container {
  display: flex;
  height: 20%;
  justify-content: left;
}

.seller-text-container {
  height: 30%;
  margin: 1rem 0;
}

.title-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 10%;
  justify-content: center;
  margin: 0.5rem;
}

.title-list {
  margin-bottom: 0 !important;
}

.without-margin {
  margin: 0 !important;
}

@media (min-width: 300px) and (max-width: 768px) {
  .action-container {
    margin: 1.5rem 0.5rem;
  }

  .action-text {
    font-size: 1rem;
  }

  .banner-title {
    font-size: 1.2rem;
  }

  .banner-text-call {
    font-size: 1rem;
  }

  .buyer-banner-title-container {
    height: 23%;
  }

  .custom-button {
    font-size: 1rem;
  }

  .seller-text-container {
    height: 40%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .action-text {
    font-size: 1.1rem;
  }

  .banner-title {
    font-size: 1.6rem;
  }

  .banner-text-call {
    font-size: 1.2rem;
  }

  .custom-button {
    font-size: 1.3rem;
  }

  .title-container {
    margin: 1rem;
  }
}

@media (min-width: 1407px) {
  .action-text {
    font-size: 1.3rem;
  }

  .banner-text-call {
    font-size: 1.4rem;
  }
}

@media (min-width: 1598px) {
  .action-text {
    font-size: 1.4rem;
  }

  .banner-text-call {
    font-size: 1.5rem;
  }

  .banner-title {
    font-size: 1.8rem;
  }

  .custom-button {
    font-size: 1.5rem;
  }
}

@media (min-width: 1789px) {
  .banner-text-call {
    font-size: 1.5rem;
  }

  .banner-title {
    font-size: 2rem;
  }
}
</style>
