<template>
  <div class="panel-block" @keyup.enter.prevent="validarFiltros">
    <b-field grouped class="column-direction-touch">
      <b-field label="Situação">
        <b-select expanded placeholder="Situação" v-model="filters.situation">
          <option v-for="situation in situations" :key="situation.id" :value="situation.id">
            {{ situation.name }}
          </option>
        </b-select>
      </b-field>

      <b-field class="break-attachment-field" label="Abertura">
        <b-field>
          <b-datepicker
            editable
            placeholder="Início"
            icon="calendar-today"
            v-mascara:data
            :max-date="maiorDataInicio"
            v-model="filters.period.start"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button
              class="button is-primary br-4 mr-1"
              @click.prevent.stop="insertTodayFilter('start')"
            >
              <b-icon icon="calendar-today" />
              <span>Hoje</span>
            </button>

            <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('start')">
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>

          <b-datepicker
            editable
            placeholder="Fim"
            icon="calendar-today"
            v-mascara:data
            :disabled="desabilitarPeriodo"
            :min-date="dataMinimaFim"
            v-model="filters.period.end"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button
              class="button is-primary br-4 mr-1"
              @click.prevent.stop="insertTodayFilter('end')"
            >
              <b-icon icon="calendar-today" />
              <span>Hoje</span>
            </button>

            <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('end')">
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>
        </b-field>
      </b-field>

      <b-field label="Número">
        <b-input
          custom-class="uppercase"
          placeholder="Número"
          v-model="filters.number"
          @blur="event => converterMaiusculo('number', event.target.value, 'filters')"
        />
      </b-field>

      <b-field grouped class="column-direction-touch filters-buttons-container">
        <button class="button is-primary" @click.stop.prevent="validarFiltros()">
          Filtrar
        </button>

        <button class="button is-primary" @click.stop.prevent="limparFiltros()">
          Limpar
        </button>
      </b-field>
    </b-field>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import campo from '@/mixins/formulario';

export default {
  name: 'PregaoListaFiltrosComprador',
  mixins: [campo],
  data() {
    const filters = {
      situation: 'todas',
      city: '',
      state: '',
      period: {
        start: null,
        end: null,
      },
      number: '',
    };
    const situations = [
      { id: 'todas', name: 'Todos' },
      { id: 'cadastradas', name: 'Cadastrados' },
      { id: 'abertas', name: 'Abertos' },
      { id: 'andamento', name: 'Em andamento' },
      { id: 'concluidas', name: 'Concluídos' },
    ];

    return {
      filters,
      situations,
    };
  },
  computed: {
    dataMinimaFim() {
      const start = new Date(this.filters.period.start);
      if (start) {
        start.setDate(start.getDate() + 1);
        return start;
      }
      return null;
    },
    desabilitarPeriodo() {
      return this.filters.period.start === null;
    },
    maiorDataInicio() {
      if (this.filters.period.end) {
        const data = new Date(this.filters.period.end);
        data.setDate(data.getDate() - 1);
        return data;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(['filtrarPregoes', 'limparFiltrosPregoes']),
    limparPeriodo() {
      this.filters.period.start = null;
      this.filters.period.end = null;
    },
    validarFiltros() {
      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.start > this.maiorDataInicio
      ) {
        this.limparPeriodo();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }

      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.end < this.dataMinimaFim
      ) {
        this.limparPeriodo();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }

      return this.filtrarPregoes(this.filters);
    },
    limparFiltros() {
      this.filters.situation = 'todas';
      this.filters.number = '';
      this.filters.period.start = null;
      this.filters.period.end = null;

      this.limparFiltrosPregoes();
      this.filtrarPregoes();
    },
  },
};
</script>
