<template>
  <footer class="footer">
    <div class="container custom-container">
      <div class="columns">
        <div class="column is-one-fifth">
          <figure class="image-logo">
            <img :src="logo" alt="SelCorp - Sistema Eletrônico de Licitações" />
          </figure>
        </div>
        <div class="column is-one-fifth">
          <h1 class="custom-title">Pesquisa</h1>
          <div class="item-list">
            <router-link :to="{ name: 'ItemPesquisa' }" class="item-link">
              Itens
            </router-link>
          </div>
        </div>

        <div class="column is-one-fifth">
          <h1 class="custom-title">Licitações</h1>

          <div class="item-list">
            <router-link :to="{ name: 'Pregoes' }" class="item-link"
              >Pregões Eletrônicos</router-link
            >
          </div>

          <div class="item-list">
            <router-link :to="{ name: 'Dispensas' }" class="item-link">
              Dispensas
            </router-link>
          </div>
        </div>

        <div class="column is-one-fifth">
          <h1 class="custom-title">Recursos</h1>

          <div class="item-list">
            <router-link :to="{ name: 'Manuals' }" tag="a" class="item-link">Manuais</router-link>
          </div>

          <div class="item-list">
            <router-link :to="{ name: 'MainContractPoints' }" tag="a" class="item-link"
              >Contrato de Serviço</router-link
            >
          </div>
        </div>

        <div class="column is-one-fifth">
          <h1 class="custom-title">Sobre</h1>
          <div class="item-list">
            <router-link :to="{ name: 'SobreNos' }" class="item-link">
              Quem Somos
            </router-link>
          </div>
          <div class="item-list">
            <div class="item-content">
              <b-icon class="custom-icon" icon="whatsapp" type="is-success" />
              <strong>(14) 9 9164-9190</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="separator" />
    <div class="content has-text-centered custom-content">
      <p>
        <a href="http://www.selcorp.com.br">SelCorp</a> - Sistema Eletrônico de Licitações.
        Copyright (C) {{ new Date().getFullYear() }}. Versão: {{ version }}.
      </p>
    </div>
  </footer>
</template>

<script>
import logo from '@/assets/logosel.png';

export default {
  name: 'TheFooting',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-container {
  border: none !important;
  padding: unset !important;
  width: 80%;
}

.custom-content {
  font-size: 0.96rem;
  margin: 0 10px;
}

.custom-icon {
  margin-right: 0.2rem;
  vertical-align: middle;
}

.custom-title {
  font-size: 0.96rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.footer {
  background-color: #ececec !important;
  padding: 2rem 0 2rem !important;
}

.image-logo {
  width: 80%;
}

.item-content {
  color: rgb(77, 77, 77);
}

.item-link {
  color: rgb(77, 77, 77);
}

.item-link:hover {
  text-decoration: underline;
}

.item-list {
  font-size: 0.92rem;
  margin-bottom: 0.8rem;
}

.separator {
  background-color: rgb(216, 216, 216);
  height: 1px;
}

@media (max-width: 800px) {
  .custom-content {
    font-size: 0.78rem;
  }

  .image-logo {
    width: 40%;
    margin-bottom: 0.8rem;
  }
}

@media (min-width: 1500px) {
  .custom-container {
    width: 70%;
  }

  .image-logo {
    width: 70%;
  }
}
</style>
