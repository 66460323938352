<template>
  <div>
    <div v-if="ofertaCompra.id" class="comprador">
      <ul>
        <li><strong>Nome:</strong> {{ ofertaCompra.comprador.nome }}</li>
        <li><strong>CNPJ:</strong> {{ ofertaCompra.comprador.cnpj }}</li>
        <li><strong>Endereço:</strong> {{ ofertaCompra.comprador | enderecoComprador }}</li>
        <li><strong>Cidade:</strong> {{ ofertaCompra.comprador.municipio }}</li>
        <li><strong>Telefone:</strong> {{ ofertaCompra.comprador.telefone }}</li>
        <li><strong>Endereço de Entrega:</strong> {{ ofertaCompra | enderecoEntrega }}</li>
      </ul>
    </div>
    <br />
    <br />
    <div class="responsaveis">
      <h1 class="title is-4">Responsáveis</h1>

      <div v-if="!responsaveis.length" class="panel">
        <div class="panel-block is-justify-content-center">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Nenhum responsável.</p>
            </div>
          </section>
        </div>
      </div>

      <b-table hoverable v-if="responsaveis.length" :data="responsaveis">
        <b-table-column label="Nome" v-slot="props">
          {{ props.row.nome }}
        </b-table-column>

        <b-table-column label="Função" v-slot="props">
          {{ props.row.funcao | formatFuncao }}
        </b-table-column>

        <b-table-column label="E-mail" v-slot="props">
          {{ props.row.email }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PregaoComprador',
  filters: {
    enderecoEntrega(endereco) {
      if (endereco.endereco_entrega_complemento) {
        return `${endereco.endereco_entrega}, ${endereco.endereco_entrega_numero} - ${endereco.endereco_entrega_complemento} - ${endereco.endereco_entrega_bairro} - ${endereco.endereco_entrega_municipio}-${endereco.endereco_entrega_uf}`;
      }
      return `${endereco.endereco_entrega}, ${endereco.endereco_entrega_numero} - ${endereco.endereco_entrega_bairro} - ${endereco.endereco_entrega_municipio}-${endereco.endereco_entrega_uf}`;
    },
    enderecoComprador(endereco) {
      if (endereco.complemento) {
        return `${endereco.endereco}, ${endereco.numero} - ${endereco.complemento} - ${endereco.bairro} - ${endereco.municipio}-${endereco.uf}`;
      }
      return `${endereco.endereco}, ${endereco.numero} - ${endereco.bairro} - ${endereco.municipio}-${endereco.uf}`;
    },
    formatFuncao(userFunction) {
      return {
        AUTORIDADE: 'Autoridade Competente',
        APOIO: 'Equipe de Apoio',
        ESCRIVAO: 'Subscritor(a) de Edital',
        PREGOEIRO: 'Pregoeiro(a)',
        'PREGOEIRO-SUBSTITUTO': 'Pregoeiro(a) Substituto(a)',
      }[userFunction];
    },
  },
  computed: {
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
      responsaveis: 'obterOfertaCompraResponsaveis',
    }),
  },
};
</script>
