<template>
  <div class="notification-container">
    <b-tag v-if="unreadNotifications.length" class="notification-counter" type="is-danger">{{
      unreadNotifications.length
    }}</b-tag>

    <b-dropdown
      :scrollable="false"
      ref="dropdown"
      id="notification-dropdown"
      position="is-bottom-left"
      aria-role="list"
    >
      <b-icon class="notification-dropdown-icon" icon="bell" slot="trigger"></b-icon>

      <div class="columns">
        <div class="column dropdown-expanded">
          <b-dropdown-item
            custom
            paddingless
            aria-role="listitem"
            class="item-dropdown"
            :focusable="false"
          >
            <article
              class="panel notification-panel"
              :class="{
                'notification-panel-empty': !unreadNotifications.length,
              }"
            >
              <div class="panel-heading">
                <p class="heading-title">Notificações</p>
              </div>

              <section v-if="!unreadNotifications.length" class="section">
                <div class="content has-text-centered">
                  <p>Nenhuma notificação não lida.</p>
                </div>
              </section>

              <div
                class="notification-unread notification-separator"
                :class="{
                  'notification-read notification-separator': notification.status === 'LIDA',
                }"
                v-for="notification in unreadNotifications"
                :key="notification.id"
                @click.stop.prevent="updateStatus(notification)"
              >
                <a class="panel-block">
                  <b-icon
                    v-if="notification.status === 'NAO-LIDA'"
                    icon="circle-small"
                    type="is-danger"
                  ></b-icon>

                  <p v-if="notification.status === 'NAO-LIDA'" class="message-unread">
                    {{ notification.message }}
                  </p>

                  <p v-else class="message-read">
                    {{ notification.message }}
                  </p>
                </a>
              </div>

              <div
                class="panel-block column is-flex notification-footer"
                :class="{ 'notification-footer-justify-end': !unreadNotifications.length }"
              >
                <a v-if="unreadNotifications.length" @click.stop.prevent="setNotificationsAsRead()"
                  >Marcar todas como lidas</a
                >
                <a @click.stop.prevent="goToNotifications()">Ver todas</a>
              </div>
            </article>
          </b-dropdown-item>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'HeaderNotification',
  computed: {
    ...mapState('companies', ['company']),
    ...mapState('notifications', ['unreadNotifications']),
    ...mapGetters({
      user: 'getUser',
    }),
  },
  methods: {
    ...mapActions(['chooseCompanyUser', 'login']),
    ...mapActions('notifications', [
      'cleanFiltersNotifications',
      'filterNotifications',
      'readNotification',
      'setNotificationsAsRead',
    ]),
    goToNotifications() {
      if (this.$route.name !== 'NotificationsList') {
        this.$router.push({ path: '/notificacoes' });
      }
      this.$refs.dropdown.isActive = false;
    },
    async updateStatus(notification) {
      if (notification.action && this.$route.fullPath !== notification.action) {
        if (notification.companyId !== this.company.id) {
          this.chooseCompanyUser(true);
          await this.login({ chooseCompany: true, companyId: notification.companyId });
        }

        this.$router.push({ path: `${notification.action}` }).catch(() => {});
        this.chooseCompanyUser(false);
      } else {
        window.location.reload();
      }

      await this.readNotification(notification.id);
      this.filterNotifications({ status: 'NAO-LIDA', perPage: 5 });
      if (this.$refs.dropdown) this.$refs.dropdown.isActive = false;
    },
  },
  async created() {
    if (this.user.id) {
      await this.filterNotifications({ status: 'NAO-LIDA', perPage: 5 });
      await this.cleanFiltersNotifications();
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown-expanded {
  padding-top: unset;
  padding-bottom: unset;
}

.heading-title {
  font-weight: 500;
}

.item-dropdown {
  min-width: 360px;
}

.notification-container {
  margin-left: 1.4rem;
}

.notification-counter {
  margin: -5px 0 0 -10px;
  position: absolute;
  width: 10px;
  height: 15px !important;
}

.notification-dropdown-icon {
  cursor: pointer;
}

.notification-footer {
  font-weight: 600;
  justify-content: space-between;
  padding: 1rem;
}
.notification-footer-justify-end {
  justify-content: flex-end;
}

.notification-panel {
  font-size: 0.86rem;
  background-color: #ececec;
}

.notification-panel-empty {
  background-color: #ffffff;
}

.notification-unread {
  background-color: #e6e6e6;
}

.notification-read {
  background-color: #ffffff;
}

.notification-separator {
  width: 100%;
  border-bottom: 1px solid rgba(165, 165, 165, 0.7);
}

.message-unread {
  margin-left: 0.2rem;
}

.message-read {
  margin-left: 1.6rem;
}

.panel-heading {
  border-bottom: 1px solid rgba(165, 165, 165, 0.7);
}

@media (max-width: 768px) {
  .item-dropdown {
    min-width: 320px;
  }

  .notification-container {
    margin-left: 0.5rem;
  }
}
</style>

<style lang="scss">
#notification-dropdown > .dropdown-menu {
  overflow-y: unset;

  .dropdown-content {
    padding-bottom: unset;
  }
}
</style>
