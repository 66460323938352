import {
  acceptCompanyServiceContract,
  createCompany,
  createInterestedCompany,
  getCompanyById,
  getCompanyHistory,
  getCompanyInvitation,
  updateCompany,
  sendCompanyLogo,
} from '@/resources/companies';

const types = {
  COMPANY: 'COMPANY',
  COMPANY_CLEAN: 'COMPANY_CLEAN',
  COMPANY_CREATE: 'COMPANY_CREATE',
  COMPANY_HISTORY: 'COMPANY_HISTORY',
  COMPANY_INVITATION: 'COMPANY_INVITATION',
  COMPANY_INVITATION_CLEAN: 'COMPANY_INVITATION_CLEAN',
  COMPANY_SELECT: 'COMPANY_SELECT',
  COMPANY_STATUS_UPDATE: 'COMPANY_STATUS_UPDATE',
  COMPANY_UPDATE: 'COMPANY_UPDATE',
};

const mutations = {
  [types.COMPANY](state, data) {
    if (data && typeof data === 'object') {
      state.company = data;
      state.company.shareCapitalDate = data.shareCapitalDate
        ? new Date(data.shareCapitalDate)
        : null;
      state.company.registryDate = data.registryDate ? new Date(data.registryDate) : null;
      state.company.simpleNationalDate = data.simpleNationalDate
        ? new Date(data.simpleNationalDate)
        : null;
    }
  },
  [types.COMPANY_CLEAN](state) {
    state.company = {};
  },
  [types.COMPANY_CREATE](state, data) {
    if (data && typeof data === 'object') {
      state.company = data;
    }
  },
  [types.COMPANY_HISTORY](state, data) {
    if (data && typeof data === 'object') {
      state.companyHistory = data;
      state.companyHistory.shareCapitalDate = data.shareCapitalDate
        ? new Date(data.shareCapitalDate)
        : new Date();
      state.companyHistory.registryDate = data.registryDate
        ? new Date(data.registryDate)
        : new Date();
      state.companyHistory.simpleNationalDate = data.simpleNationalDate
        ? new Date(data.simpleNationalDate)
        : new Date();
    }
  },
  [types.COMPANY_INVITATION](state, data) {
    if (data && typeof data === 'object') {
      state.invitation = data;
    }
  },
  [types.COMPANY_INVITATION_CLEAN](state) {
    state.invitation = null;
  },
  [types.COMPANY_SELECT](state, data) {
    state.company = data;
  },
  [types.COMPANY_STATUS_UPDATE](state, data) {
    state.company.status = data;
  },
  [types.COMPANY_UPDATE](state, data) {
    if (data && typeof data === 'object') {
      state.company = Object.assign(state.company, data);
      state.company.shareCapitalDate = data.shareCapitalDate
        ? new Date(data.shareCapitalDate)
        : new Date();
      state.company.registryDate = data.registryDate ? new Date(data.registryDate) : new Date();
      state.company.simpleNationalDate = data.simpleNationalDate
        ? new Date(data.simpleNationalDate)
        : new Date();
    }
  },
};

const actions = {
  async acceptCompanyServiceContract({ commit }, { companyId }) {
    const response = await acceptCompanyServiceContract(companyId);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY, response.data);
  },
  async createCompany({ commit }, dados) {
    const response = await createCompany(dados);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.COMPANY_CREATE, response.data);
  },
  async createInterestedCompany(_, data) {
    const response = await createInterestedCompany(data);
    if (response.status !== 201) return Promise.reject(response.data);
    return response.data;
  },
  async fetchCompany({ commit, dispatch }, companyId) {
    const response = await getCompanyById(companyId);
    await dispatch('loginCompany', response.data, { root: true });
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY, response.data);
  },
  async fetchCompanyHistory({ commit }, companyHistoryId) {
    const response = await getCompanyHistory(companyHistoryId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY_HISTORY, response.data);
  },
  async getCompanyInvitation({ commit }, convite) {
    const response = await getCompanyInvitation(convite);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.COMPANY_INVITATION, response.data);
  },
  async saveCompany({ commit, state }, data) {
    const companyId = state.company.id;
    const response = await updateCompany(companyId, data);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY, response.data);
  },
  async sendCompanyLogo({ commit }, data) {
    const { companyId, dataLogo } = data;
    const response = await sendCompanyLogo(companyId, dataLogo);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY, response.data);
  },
  async updateCompany(_, data) {
    const response = await updateCompany(data.companyId, data);
    if (response.status !== 200) return Promise.reject(response.data);
    return Promise.resolve(response.data);
  },
  cleanCompanyInvitation({ commit }) {
    commit(types.COMPANY_INVITATION);
  },
  selectCompany({ commit }, data) {
    commit(types.COMPANY_SELECT, data);
  },
  updateCompanyData({ commit }, data) {
    commit(types.COMPANY_UPDATE, data);
  },
};

const state = {
  company: {
    id: 0,
  },
  companyHistory: {
    id: 0,
  },
  invitation: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

export { types };
