<template>
  <div>
    <b-loading is-full-page :active="isLoading" can-cancel></b-loading>

    <section class="bm-2">
      <ItemInformation :highest-discount="highestDiscount" :item="item" />
    </section>

    <section>
      <div class="container-tabs">
        <b-tabs type="is-boxed">
          <b-tab-item label="Ofertas">
            <OffersList
              :display-social-denomination="displaySocialDenomination"
              :highest-discount="highestDiscount"
              :item="item"
              :format-company-size="formatCompanySize"
            />
          </b-tab-item>

          <b-tab-item label="Propostas">
            <ProposalsList
              :display-social-denomination="displaySocialDenomination"
              :highest-discount="highestDiscount"
              :item="item"
              :format-company-size="formatCompanySize"
            />
          </b-tab-item>

          <b-tab-item label="Declarações">
            <DeclarationsList :format-company-size="formatCompanySize" />
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import DeclarationsList from './DeclarationsList';
import ItemInformation from './ItemInformation';
import OffersList from './OffersList';
import ProposalsList from './ProposalsList';

export default {
  name: 'Offers',
  components: {
    DeclarationsList,
    ItemInformation,
    OffersList,
    ProposalsList,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isLoading: false,
      loaded: false,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      deadlines: 'obterOfertaCompraPrazos',
      itemOffers: 'obterOfertaCompraItemOfertas',
    }),
    displaySocialDenomination() {
      return this.situation >= 9 && this.qualificationDeadline;
    },
    highestDiscount() {
      return this.auction.tipo_negociacao.id === 3;
    },
    qualificationDeadline() {
      return this.deadlines.some(deadline => deadline.prazo_id === 11);
    },
    situation() {
      return this.auction.situacao.id;
    },
  },
  sockets: {
    'ofertacompra:lances': function atualizarLances(lance) {
      if (lance.ofertaCompraItemId === this.item.id) this.adicionarLance(lance);
    },
  },
  methods: {
    ...mapActions(['buscarOfertaCompraItemOfertas', 'buscarOfertaCompraItem', 'adicionarLance']),
    ...mapActions('auctionsDeclarations', ['searchAuctionDeclarations']),
    async loadData() {
      await Promise.all([
        this.buscarOfertaCompraItemOfertas({
          ofertaCompraId: this.auction.id,
          itemId: this.item.id,
        }),
        this.searchAuctionDeclarations({ auctionId: this.auction.id }),
      ]);
    },
    formatCompanySize(porte) {
      if (porte === 'COOPERATIVA_PREFERENCIA') return 'COOPERATIVA PREFERÊNCIA';
      return porte;
    },
  },
  async created() {
    this.isLoading = true;
    await this.loadData();
    this.loaded = true;
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.bm-2 {
  margin-bottom: 2rem;
}

.container-tabs {
  margin-top: 0.5rem;
}
</style>
