import { api, sendTimeout } from '../http';

export const createProposal = (auctionId, auctionItemId, data) =>
  api.post(`/ofertascompra/${auctionId}/itens/${auctionItemId}/propostas`, data);

export const deleteProposal = (auctionId, auctionItemId, proposalId) =>
  api.delete(`ofertascompra/${auctionId}/itens/${auctionItemId}/propostas/${proposalId}`);

export const fetchProposal = (auctionId, proposalId) =>
  api.get(`/ofertascompra/${auctionId}/proposta/${proposalId}/`);

export const fetchProposals = (auctionId, tipo) =>
  api.get(`/ofertascompra/${auctionId}/propostas/?filtro=${tipo}`);

export const fetchItemProposals = (auctionId, auctionItemId) =>
  api.get(`/ofertascompra/${auctionId}/itens/${auctionItemId}/propostas`);

export const fetchItemsLastProposal = auctionId =>
  api.get(`/ofertascompra/${auctionId}/itens/ultima-proposta`);

export const importAuctionProposals = (
  auctionId,
  data,
  { onUploadProgress } = { onUploadProgress: () => {} },
) =>
  api.post(`/auctions/${auctionId}/proposals/import`, data, {
    onUploadProgress,
    timeout: sendTimeout,
  });

export const updateProposal = (auctionId, auctionItemId, proposalId, proposta) =>
  api.patch(`/ofertascompra/${auctionId}/itens/${auctionItemId}/propostas/${proposalId}`, proposta);

export const undoProposalClassification = (auctionId, auctionItemId, proposalId) =>
  api.patch(
    `/ofertascompra/${auctionId}/itens/${auctionItemId}/propostas/${proposalId}/desfazer-classificacao`,
  );

export const untieProposal = (auctionId, proposalId) =>
  api.get(`/ofertascompra/${auctionId}/propostas/${proposalId}/desempate`);

export const classifyItems = (auctionId, classification) =>
  api.post(`/ofertascompra/${auctionId}/propostas/classificar`, classification);
