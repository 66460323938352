<template>
  <div class="box">
    <div class="level item-information-column-direction">
      <div class="level-item has-text-centered">
        <div class="margin-2">
          <p class="heading">Item</p>
          <p>
            <strong>{{ item.identificador }}</strong>
          </p>
        </div>
      </div>

      <div v-if="item.item.exclusivo" class="level-item has-text-centered">
        <div class="margin-2">
          <p class="heading">Exclusivo</p>
          <b-icon icon="check" size="is-small" type="is-success" v-show="item.item.exclusivo" />
        </div>
      </div>

      <div
        class="level-item has-text-centered"
        :class="{ 'description-level-item': !descriptionText }"
      >
        <div class="margin-2 elipsis-description" ref="description-link">
          <p class="heading">Descrição</p>
          <a
            :class="{ 'justify-align': justify }"
            @click.stop.prevent="openDescription = !openDescription"
            >{{ item.item.descricao }}</a
          >
        </div>

        <div v-show="descriptionText">
          <p class="heading">Descrição</p>
          <p class="description-text" ref="description-text">
            <strong>{{ item.item.descricao }}</strong>
          </p>
        </div>
      </div>

      <div class="level-item has-text-centered">
        <div class="margin-2">
          <p class="heading">Unidade</p>
          <p>
            <strong>{{ item.unidade.nome }}</strong>
          </p>
        </div>
      </div>

      <div class="level-item has-text-centered">
        <div class="margin-2 elipsis">
          <p class="heading">Quantidade</p>
          <p>
            <strong>{{ item.quantidade }}</strong>
          </p>
        </div>
      </div>

      <div v-if="highestDiscount || discloseReferential" class="level-item has-text-centered">
        <div class="margin-2">
          <p class="heading">Referência</p>

          <b-tag v-if="!item.referencia && !item.total" type="is-dark" size="is-small"
            >VIDE EDITAL</b-tag
          >

          <p v-else>
            <strong>{{ formatValue(item.total) }}</strong>
          </p>
        </div>
      </div>

      <div class="level-item has-text-centered">
        <button
          class="button is-primary item-information-back-button"
          @click.stop.prevent="backToItems()"
        >
          Voltar
        </button>
      </div>
    </div>

    <div v-if="openDescription" class="complete-description">
      <p>{{ item.item.descricao }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { formatarValor as formatValue } from '@/utils/format';

export default {
  name: 'ItemInformation',
  props: {
    highestDiscount: { type: Boolean, default: false },
    item: { type: Object, default: () => {} },
  },
  data() {
    return {
      descriptionText: false,
      openDescription: false,
      formatValue,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      deadlines: 'obterOfertaCompraPrazos',
    }),
    discloseReferential() {
      return this.situation > 7 && this.negotiationDeadline && this.auction.divulgar_referencial;
    },
    justify() {
      return this.windowWidth > 960;
    },
    negotiationDeadline() {
      return this.deadlines.some(deadline => deadline.prazo_id === 10);
    },
    situation() {
      return this.auction.situacao.id;
    },
  },
  methods: {
    backToItems() {
      this.$emitBus('voltar');
    },
    verifyOverflowed() {
      const descriptionLink = this.$refs['description-link'];
      const descriptionText = this.$refs['description-text'];
      const descriptionOverflowed = descriptionLink.offsetWidth < descriptionLink.scrollWidth;
      if (descriptionOverflowed) {
        descriptionText.hidden = true;
      } else {
        this.descriptionText = true;
        descriptionLink.hidden = true;
      }
    },
  },
  mounted() {
    this.verifyOverflowed();
  },
};
</script>

<style lang="scss" scoped>
.complete-description {
  font-size: 0.88rem;
  margin-top: 2rem;
  text-align: justify;
}

.description-level-item {
  flex-basis: 25%;
  min-width: 1px;
}

.description-text {
  white-space: nowrap;
}

.elipsis-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.justify-align {
  text-align: justify;
}

.margin-2 {
  margin: 0 1.8rem;
}

@media (min-width: 769px) and (max-width: 1120px) {
  .item-information-column-direction {
    flex-direction: column !important;
  }

  .item-information-back-button {
    margin-top: 0.25rem;
  }

  .margin-2 {
    margin: 0.5rem 0;
  }
}

@media (max-width: 768px) {
  .margin-2 {
    margin: unset;
  }
}
</style>
