export default {
  computed: {
    maiorDesconto() {
      return this.tipoNegociacao === 3;
    },
    tipoNegociacao() {
      return this.ofertaCompra.tipo_negociacao.id;
    },
    situacao() {
      return this.ofertaCompra.situacao.id;
    },
    etapaLances() {
      return this.ofertaCompra.situacao.id === 6;
    },
    modoFechado() {
      return ['LANCE-FECHADO', 'PRIMEIRA-CHAMADA', 'NOVA-CHAMADA'].indexOf(this.item.estado) > -1;
    },
    exibirCampoLance() {
      return (
        ['LANCE', 'LANCE-RANDOMICO', 'PREFERENCIA-INICIADA', 'NEGOCIACAO'].indexOf(
          this.item.estado,
        ) > -1
      );
    },
    lanceFechadoEnviado() {
      return this.lances.find(
        lance =>
          lance.ofertaCompraItemId === this.item.id &&
          lance.lance_id !== this.item.menor_lance.lance_id &&
          lance.tipo === 'FECHADO',
      );
    },
    exibirCampoLanceFechado() {
      if (this.situacao === 6) {
        const lanceEmEnvio = this.lancesFechadosEmEnvio.some(
          lance => lance.ofertaCompraItemId === this.item.id,
        );

        return this.item.estado === 'LANCE-FECHADO' && !this.lanceFechadoEnviado && !lanceEmEnvio;
      }
      return false;
    },
    exibirTagEnviado() {
      return this.etapaLances && this.licitante && this.modoFechado && this.lanceFechadoEnviado;
    },
  },
  methods: {
    atualizarValores(dados, input) {
      this.verificaValor(!this.maiorDesconto ? dados.valor : dados.percentual, input);
      this.lance.valor = dados.valorMascara !== '' ? dados.valor : 0;
      this.lance.percentual = dados.percentual;
      this.lance.valores = dados;
      this.lance.invalido =
        input === 'inputPercentual' ? !this.percentualValido : !this.valorValido;
      this.lance.ofertaCompraItemId = this.item.id;
      this.lance.identificador = this.item.identificador;
      this.lance.descricao = this.item.item.descricao;
      this.lance.unidadeNome = this.item.unidade.nome;
      this.lance.referencia = this.item.referencia ? this.item.referencia : null;
      this.lance.total = this.item.total ? this.item.total : null;

      this.emitirLance();
    },
    verificaValor(valor, campo) {
      const valorPorcentagem = !this.maiorDesconto
        ? Number(this.item.menor_lance.valor * 0.8)
        : Number(this.item.menor_lance.percentual * 1.2);
      const comparativo = !this.maiorDesconto ? valor < valorPorcentagem : valor > valorPorcentagem;
      if (valor > 0 && comparativo) {
        if (this.valoresMascara[campo] !== '') {
          this.$emit('aviso', true);
        } else {
          this.$emit('aviso', false);
        }
      } else {
        this.$emit('aviso', false);
      }
    },
    limpaMensagem() {
      this.$emit('aviso', false);
    },
    emitirLance() {
      this.$emit('lance', {
        ...this.lance,
        referencia: this.item.referencia,
        total: this.item.total,
      });
    },
    enviarLance() {
      this.$emit('enviar');
    },
  },
};
