import { adminNotAllowed, companyPage, protectedPage } from '@/utils/session';

export default [
  {
    path: '/empresa/:id',
    redirect: { name: 'CompanyData' },
    name: 'Company',
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      companyPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "company" */ '@/views/Company/Company'),
    children: [
      {
        path: 'dados',
        name: 'CompanyData',
        beforeEnter: protectedPage,
        component: () =>
          import(/* webpackChunkName: "company-data" */ '@/views/Company/CompanyData'),
      },
      {
        path: 'registro',
        name: 'SellerRegistry',
        beforeEnter: protectedPage,
        component: () =>
          import(
            /* webpackChunkName: "company-registry" */ '@/views/Company/Seller/SellerRegistry'
          ),
      },
      {
        path: 'enquadramento',
        name: 'SellerCompanySize',
        beforeEnter: protectedPage,
        component: () =>
          import(/* webpackChunkName: "company-size" */ '@/views/Company/Seller/SellerCompanySize'),
      },
      {
        path: 'documentacao',
        name: 'SellerDocumentation',
        beforeEnter: protectedPage,
        component: () =>
          import(
            /* webpackChunkName: "company-documentation" */ '@/views/Company/Seller/SellerDocumentation'
          ),
      },
      {
        path: 'usuarios',
        name: 'CompanyUsers',
        beforeEnter: protectedPage,
        component: () =>
          import(/* webpackChunkName: "company-users" */ '@/views/Company/CompanyUsers'),
      },
      {
        path: 'configuracoes',
        name: 'CompanyConfigurations',
        beforeEnter: protectedPage,
        component: () =>
          import(
            /* webpackChunkName: "company-configurations" */ '@/views/Company/CompanyConfigurations'
          ),
      },
      {
        path: 'chaves',
        name: 'CompanyKeys',
        beforeEnter: protectedPage,
        component: () =>
          import(/* webpackChunkName: "company-keys" */ '@/views/Company/CompanyKeys'),
      },
      {
        path: 'convidar-fornecedores',
        name: 'CompanyInviteSuppliers',
        beforeEnter: protectedPage,
        component: () =>
          import(
            /* webpackChunkName: "company-invite-suppliers" */ '@/views/Company/CompanyInviteSuppliers'
          ),
      },
    ],
  },
];
