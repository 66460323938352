<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <form @keyup.enter="salvarDados">
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Número do Processo"
          :type="campoInvalido('numeroProcesso') ? 'is-danger' : ''"
          :message="
            campoInvalido('numeroProcesso') ? 'Número do Processo é um campo obrigatório' : ''
          "
        >
          <b-input
            custom-class="uppercase"
            placeholder="Número do Processo"
            v-model="$v.dados.numeroProcesso.$model"
            @blur="evento => converterMaiusculo('numeroProcesso', evento.target.value)"
          />
        </b-field>

        <b-field
          expanded
          label="Número da Dispensa"
          :type="campoInvalido('numeroDispensa') ? 'is-danger' : ''"
          :message="
            campoInvalido('numeroDispensa') ? 'Número da Dispensa é um campo obrigatório' : ''
          "
        >
          <b-input
            custom-class="uppercase"
            placeholder="Número da Dispensa"
            v-model="$v.dados.numeroDispensa.$model"
            @blur="evento => converterMaiusculo('numeroDispensa', evento.target.value)"
          />
        </b-field>

        <b-field
          expanded
          label="Tipo de Negociação"
          :type="campoInvalido('tipoNegociacaoId') ? 'is-danger' : ''"
          :message="campoInvalido('tipoNegociacaoId') ? 'Selecione um tipo de Negociação' : ''"
        >
          <b-select
            expanded
            placeholder="Tipo de Negociação"
            v-model="$v.dados.tipoNegociacaoId.$model"
            @click.native="$v.dados.tipoNegociacaoId.$touch()"
          >
            <option
              v-for="tipoNegociacao in tiposNegociacao"
              :key="tipoNegociacao.id"
              :value="tipoNegociacao.id"
            >
              {{ tipoNegociacao.nome }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          label="Tipo de Participação"
          :type="campoInvalido('tipoParticipacao') ? 'is-danger' : ''"
          :message="campoInvalido('tipoParticipacao') ? 'Selecione um tipo de participação' : ''"
        >
          <b-select
            placeholder="Tipo de Participação"
            v-model="$v.dados.tipoParticipacao.$model"
            @click.native="$v.dados.tipoParticipacao.$touch()"
          >
            <option
              v-for="tipoParticipacao in tiposParticipacao"
              :key="tipoParticipacao.id"
              :value="tipoParticipacao.enum"
            >
              {{ tipoParticipacao.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field
          expanded
          label="Objeto"
          :type="campoInvalido('objeto') ? 'is-danger' : ''"
          :message="campoInvalido('objeto') ? 'Objeto é um campo obrigatório' : ''"
        >
          <b-input
            custom-class="uppercase"
            placeholder="Objeto"
            v-model="$v.dados.objeto.$model"
            @blur="evento => converterMaiusculo('objeto', evento.target.value)"
          />
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Data de Publicação"
          :type="campoInvalido('dataPublicacao') ? 'is-danger' : ''"
          :message="
            campoInvalido('dataPublicacao') ? 'Selecione uma data válida para publicação' : ''
          "
        >
          <b-datepicker
            editable
            placeholder="Data de Publicação"
            icon="calendar-today"
            v-mascara:data
            :unselectable-days-of-week="diasNaoSelecionaveis"
            :unselectable-dates="feriadosNacionais"
            v-model="valoresMascara.dataPublicacao"
            @input="valor => atualizarDataPersonalizado('dataPublicacao', valor)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button
              class="button is-primary br-4 mr-1"
              @click.prevent.stop="
                inserirHoje('dataPublicacao'), atualizarDataPersonalizado('dataPublicacao')
              "
            >
              <b-icon icon="calendar-today" />
              <span>Hoje</span>
            </button>

            <button
              class="button is-danger br-4"
              @click.prevent.stop="limparData('dataPublicacao')"
            >
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-field
          expanded
          label="Data de Abertura"
          :type="campoInvalido('dataAbertura') ? 'is-danger' : ''"
          :message="campoInvalido('dataAbertura') ? 'Selecione uma data válida para abertura' : ''"
        >
          <b-datepicker
            editable
            placeholder="Data de Abertura"
            icon="calendar-today"
            v-mascara:data
            :unselectable-days-of-week="diasNaoSelecionaveis"
            :unselectable-dates="feriadosNacionais"
            v-model="valoresMascara.dataAbertura"
            @input="valor => atualizarDataPersonalizado('dataAbertura', valor)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          >
            <button class="button is-danger" @click.prevent.stop="limparData('dataAbertura')">
              <b-icon icon="close" />
              <span>Limpar</span>
            </button>
          </b-datepicker>
        </b-field>

        <b-field
          expanded
          label="Horário"
          :type="campoInvalido('horaAbertura') ? 'is-danger' : ''"
          :message="campoInvalido('horaAbertura') ? 'Selecione uma hora válida para abertura' : ''"
        >
          <b-timepicker
            editable
            ref="hora"
            maxlength="5"
            placeholder="00:00"
            icon="clock"
            v-mascara:hora
            v-model="valoresMascara.horaAbertura"
            @input="valor => atualizarHora('horaAbertura', valor)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
        </b-field>
      </b-field>

      <hr />

      <b-field grouped class="column-direction-touch">
        <b-field
          label="CEP"
          :type="campoInvalido('enderecoEntregaCEP') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaCEP') ? 'É necessario preencher o CEP' : ''"
        >
          <b-input
            placeholder="CEP"
            maxlength="9"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            :loading="pesquisando"
            :has-counter="false"
            @blur="buscarCEP"
            @keyup.native.enter.prevent.stop="buscarCEP"
            v-mascara:cep
            v-model="$v.dados.enderecoEntregaCEP.$model"
          />
        </b-field>

        <b-field
          expanded
          label="Endereço de Entrega"
          :type="campoInvalido('enderecoEntrega') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntrega') ? 'Endereço é um campo obrigatório' : ''"
        >
          <b-input placeholder="Endereço" v-model="$v.dados.enderecoEntrega.$model" />
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          label="Número"
          :type="campoInvalido('enderecoEntregaNumero') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaNumero') ? 'Número é um campo obrigatório' : ''"
        >
          <b-input
            placeholder="Número"
            v-model="$v.dados.enderecoEntregaNumero.$model"
            @input="$v.dados.enderecoEntregaNumero.$touch()"
          />
        </b-field>

        <b-field label="Complemento">
          <b-input
            custom-class="uppercase"
            placeholder="Complemento"
            v-model="$v.dados.enderecoEntregaComplemento.$model"
            @blur="evento => converterMaiusculo('enderecoEntregaComplemento', evento.target.value)"
          />
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Bairro"
          :type="campoInvalido('enderecoEntregaBairro') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaBairro') ? 'Bairro é um campo obrigatório' : ''"
        >
          <b-input placeholder="Bairro" v-model="$v.dados.enderecoEntregaBairro.$model" />
        </b-field>

        <b-field
          expanded
          label="Município"
          :type="campoInvalido('enderecoEntregaMunicipio') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaMunicipio') ? 'Preencha o munícipio' : ''"
        >
          <b-input placeholder="Município" v-model="$v.dados.enderecoEntregaMunicipio.$model" />
        </b-field>

        <b-field
          expanded
          label="UF"
          :type="campoInvalido('enderecoEntregaUF') ? 'is-danger' : ''"
          :message="campoInvalido('enderecoEntregaUF') ? 'O campo UF deve ser preenchido' : ''"
        >
          <b-select
            expanded
            placeholder="UF"
            v-model="$v.dados.enderecoEntregaUF.$model"
            @click.native="$v.dados.enderecoEntregaUF.$touch()"
          >
            <option v-for="option in ufOptions" :key="option.id" :value="option.value">
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field class="column-direction-touch">
        <button
          class="button is-primary is-fullwidth save-button"
          :class="{ ' is-loading': loading.salvar }"
          @click.prevent.stop="salvarDados"
        >
          Salvar
        </button>
      </b-field>
    </form>
  </div>
</template>

<script>
import Holidays from 'date-holidays';
import { mapActions, mapGetters } from 'vuex';
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';

import campoMixin from '@/mixins/formulario';
import { dadosCEP } from '@/resources/utils';
import { ufOptions } from '@/utils/data';

export default {
  name: 'DispensaEditarDados',
  mixins: [campoMixin],
  data() {
    const hd = new Holidays('BR');
    const diasNaoSelecionaveis = [0, 6];
    const feriadosNacionais = [];
    const tiposNegociacao = [
      { id: 1, nome: 'Valor Unitário' },
      { id: 2, nome: 'Valor Total' },
    ];
    const tiposParticipacao = [
      { id: 1, nome: 'Participação Ampla', enum: 'AMPLA' },
      {
        id: 2,
        nome: 'Participação Exclusiva de ME/EPP/MEI/Cooperativa Preferência',
        enum: 'EXCLUSIVA',
      },
    ];

    const dados = {
      numeroDispensa: '',
      numeroProcesso: null,
      objeto: '',
      tipoNegociacaoId: null,
      dataPublicacao: null,
      dataAbertura: null,
      horaAbertura: null,
      tipoParticipacao: null,
      enderecoEntregaCEP: null,
      enderecoEntrega: null,
      enderecoEntregaNumero: null,
      enderecoEntregaComplemento: null,
      enderecoEntregaBairro: null,
      enderecoEntregaMunicipio: null,
      enderecoEntregaUF: null,
    };

    const valoresMascara = {
      dataPublicacao: null,
      dataAbertura: null,
      horaAbertura: null,
    };

    const loading = {
      general: false,
      salvar: false,
    };

    return {
      diasNaoSelecionaveis,
      tiposNegociacao,
      tiposParticipacao,
      dados,
      valoresMascara,
      ufOptions,
      pesquisando: false,
      feriados: hd
        .getHolidays(new Date().getFullYear())
        .concat(hd.getHolidays(new Date().getFullYear() + 1)),
      feriadosNacionais,
      loading,
      dadosMontados: false,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.logged && this.$v.dados.$anyDirty) {
      const sair = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(sair);
    }
    return next();
  },
  validations: {
    dados: {
      numeroDispensa: { required },
      numeroProcesso: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      objeto: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      tipoNegociacaoId: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
        minValue: minValue(1),
      },
      dataPublicacao: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      dataAbertura: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      horaAbertura: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      tipoParticipacao: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaCEP: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntrega: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaNumero: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaComplemento: { required: false },
      enderecoEntregaBairro: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaMunicipio: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
      enderecoEntregaUF: {
        required: requiredIf(function validarEmPublicacao() {
          return this.emPublicacao;
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      dispensa: 'obterDispensa',
      emPublicacao: 'obterDispensaEmPublicacao',
      logged: 'getLogged',
    }),
    horaAbertura: {
      get() {
        return this.dados.horaAbertura;
      },
      set(hora) {
        this.dados.horaAbertura = hora;
        if (this.dados.dataAbertura) {
          this.setHora(hora);
        }
      },
    },
  },
  watch: {
    emPublicacao() {
      if (
        this.emPublicacao &&
        this.$route.name === 'DispensaEditarDados' &&
        this.dispensa.id &&
        this.dadosMontados
      ) {
        this.exibirRequeridos();
      }
    },
  },
  methods: {
    ...mapActions(['alternarEmPublicacaoDispensa', 'buscarDispensa', 'editDispensation']),
    async salvarDados() {
      const dadosRequisicao = { ...this.dados };

      Object.keys(dadosRequisicao).forEach(key => {
        if (dadosRequisicao[key] === '') {
          dadosRequisicao[key] = null;
        }
      });

      this.loading.salvar = true;
      try {
        await this.editDispensation(dadosRequisicao);
        this.$alerta('Dados salvos', 'is-success');
        this.$v.dados.$reset();
      } catch (error) {
        this.$alerta(error.message, 'is-danger');
      }
      this.alternarEmPublicacaoDispensa(false);
      this.loading.salvar = false;
    },
    exibirRequeridos() {
      if (this.$v.dados.$invalid) {
        Object.keys(this.$v.dados).forEach(key => {
          if (this.$v.dados[key].$invalid) {
            this.$v.dados[key].$touch();
          }
        });
      } else {
        this.$v.dados.$reset();
      }
    },
    montarDispensa() {
      this.dados.numeroDispensa = this.dispensa.numero_dispensa;
      this.dados.numeroProcesso = this.dispensa.numero_processo;
      this.dados.objeto = this.dispensa.objeto;
      this.dados.tipoNegociacaoId = this.dispensa.tipo_negociacao
        ? this.dispensa.tipo_negociacao.id
        : null;
      this.valoresMascara.dataPublicacao = this.dispensa.data_publicacao;
      this.dados.dataPublicacao = this.dispensa.data_publicacao;
      this.valoresMascara.dataAbertura = this.dispensa.data_abertura;
      this.dados.dataAbertura = this.dispensa.data_abertura;
      this.valoresMascara.horaAbertura = this.dispensa.data_abertura;
      this.dados.horaAbertura = this.dispensa.data_abertura;
      this.dados.tipoParticipacao = this.dispensa.tipo_participacao;
      this.dados.enderecoEntregaCEP = this.dispensa.endereco_entrega_cep;
      this.dados.enderecoEntrega = this.dispensa.endereco_entrega;
      this.dados.enderecoEntregaNumero = this.dispensa.endereco_entrega_numero;
      this.dados.enderecoEntregaComplemento = this.dispensa.endereco_entrega_complemento;
      this.dados.enderecoEntregaBairro = this.dispensa.endereco_entrega_bairro;
      this.dados.enderecoEntregaMunicipio = this.dispensa.endereco_entrega_municipio;
      this.dados.enderecoEntregaUF = this.dispensa.endereco_entrega_uf;

      this.dadosMontados = true;
    },
    async buscarCEP() {
      if (!this.dados.enderecoEntregaCEP) return;

      if (this.dados.enderecoEntregaCEP.trim()) {
        this.pesquisando = true;
        try {
          const {
            data: { data: response },
          } = await dadosCEP(this.dados.enderecoEntregaCEP);
          this.dados.enderecoEntrega = response.address;
          this.dados.enderecoEntregaBairro = response.neighborhood;
          this.dados.enderecoEntregaMunicipio = response.city;
          this.dados.enderecoEntregaUF = response.state;
        } catch (error) {
          this.$alerta(error.response.data.error, 'is-danger', 3000);
        }
        this.pesquisando = false;
      }
    },
    verificaFeriado(data) {
      return this.feriadosNacionais.some(
        dataFeriado => dataFeriado.toDateString() === data.toDateString(),
      );
    },
    preencherAbertura() {
      const data = new Date();
      const dataPublicacao = new Date(this.dados.dataPublicacao);
      const dataAbertura = new Date(dataPublicacao.getTime());
      dataAbertura.setHours(data.getHours());
      dataAbertura.setMinutes(data.getMinutes());
      dataAbertura.setSeconds(data.getSeconds());

      let dataValida = false;
      let contador = 0;
      let dia = dataAbertura.getDay();
      let feriado = this.verificaFeriado(dataAbertura);

      while (!dataValida) {
        if (dia === 0 || feriado) {
          dataAbertura.setDate(dataAbertura.getDate() + 1);
        } else if (dia === 6) {
          dataAbertura.setDate(dataAbertura.getDate() + 2);
        } else {
          dataAbertura.setDate(dataAbertura.getDate() + 1);
          contador += 1;
        }
        dia = dataAbertura.getDay();
        feriado = this.verificaFeriado(dataAbertura);
        if ([0, 6].indexOf(dia) === -1 && !feriado && contador >= 2) dataValida = true;
      }

      this.atualizarDadosAbertura(dataAbertura);
      this.atualizarHora('dataAbertura', dataAbertura);
    },
    atualizarDadosAbertura(dataAbertura) {
      this.dados.dataAbertura = dataAbertura;
      this.valoresMascara.dataAbertura = dataAbertura;
      this.valoresMascara.horaAbertura = dataAbertura;
      this.dados.horaAbertura = dataAbertura;
    },
    atualizarHora(campo, valor) {
      if (this.valoresMascara[campo] !== this.dados[campo]) {
        this.atualizarDado(campo, valor);
      }
      if (this.dados.dataAbertura && valor) {
        this.dados.dataPublicacao.setHours(valor.getHours());
        this.dados.dataPublicacao.setMinutes(valor.getMinutes());
        this.dados.dataPublicacao.setSeconds(valor.getSeconds());
        this.dados.dataAbertura.setHours(valor.getHours());
        this.dados.dataAbertura.setMinutes(valor.getMinutes());
        this.dados.dataAbertura.setSeconds(valor.getSeconds());
      }
    },
    atualizarDataPersonalizado(campo, valor) {
      if (this.valoresMascara[campo] !== this.dados[campo]) {
        if (valor) {
          const dia = valor.getDay();

          if ([0, 6].indexOf(dia) > -1) {
            this.dados[campo] = null;
            this.valoresMascara[campo] = null;
            this.$alerta('Finais de semana não são permitidos', 'is-danger', 4000);
            return;
          }

          const feriado = this.feriadosNacionais.some(
            elemento => elemento.toString() === valor.toString(),
          );
          if (feriado) {
            this.dados[campo] = null;
            this.valoresMascara[campo] = null;
            this.$alerta('Feriados não são permitidos', 'is-danger', 4000);
            return;
          }

          this.$v.dados[campo].$model = valor;
        }
        this.$v.dados[campo].$touch();
        if (campo === 'dataPublicacao') this.preencherAbertura();
        if (campo === 'dataAbertura' && this.dados.horaAbertura) {
          this.dados.dataAbertura.setHours(this.dados.horaAbertura.getHours());
          this.dados.dataAbertura.setMinutes(this.dados.horaAbertura.getMinutes());
          this.dados.dataAbertura.setSeconds(this.dados.horaAbertura.getSeconds());
        }
      }
    },
    mapearFeriadosNacionais() {
      this.feriadosNacionais = this.feriados
        .filter(filtro => filtro.type === 'public')
        .map(feriado => new Date(feriado.date));
    },
    verificarPublicacao() {
      if (this.emPublicacao) this.exibirRequeridos();
    },
  },
  mounted() {
    this.mapearFeriadosNacionais();
  },
  async created() {
    this.loading.general = true;
    try {
      await this.buscarDispensa(this.$route.params.id);
      this.montarDispensa();
      this.verificarPublicacao();
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1023px) {
  .column-direction-touch {
    flex-direction: column;
  }
}
</style>
