<template>
  <div class="company-validation-container">
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <App-Titulo titulo="Cadastro" />

    <section v-if="company.status === 'CADASTRADA'" class="section">
      <div class="columns">
        <div
          :class="
            companyRegisterStep === 0
              ? 'column is-8 is-offset-2'
              : 'column is-half is-offset-one-quarter'
          "
        >
          <div class="bm-1">
            <b-steps
              animated
              :mobile-mode="null"
              :size="mobile ? 'is-small' : 'is-medium'"
              v-model="companyRegisterStep"
              :has-navigation="false"
            >
              <b-step-item clickable step="4" label="Dados da Empresa"> </b-step-item>

              <b-step-item clickable step="5" label="Documentos Requeridos"> </b-step-item>
            </b-steps>
          </div>

          <div
            v-if="Object.keys(lastValidation).length"
            class="box is-flex invalid-company-container"
          >
            <b-icon
              class="invalid-company-icon"
              icon="alert-box"
              size="is-large"
              type="is-warning"
            />
            <div class="invalid-company-text">
              <p>
                Infelizmente encontramos inconsistência(s) nos dados de sua empresa e a validação
                está pendente.
              </p>
              <p><strong>Motivo: </strong>{{ lastValidation.justificativa }}</p>
            </div>
          </div>

          <Company-Data-Register
            v-if="companyRegisterStep === 0 && company.status === 'CADASTRADA'"
          />

          <div
            v-if="companyRegisterStep === 1 && company.status === 'CADASTRADA'"
            class="send-documents-container"
          >
            <Company-Required-Documents :required-documents="requiredDocuments" />

            <Company-Send-Document :required-documents="requiredDocuments" />

            <b-field expanded>
              <button
                class="button is-primary is-fullwidth"
                :class="{ ' is-loading': loading.button }"
                :disabled="disableRequestValidation"
                @click.prevent.stop="requestValidation()"
              >
                Solicitar Validação
              </button>
            </b-field>
          </div>
        </div>
      </div>
    </section>

    <div
      class="message-container"
      v-if="company.status === 'PRIMEIRA_VALIDACAO' && loading.general === false"
    >
      <div class="message-content">
        <AppMessage>
          <div slot="message-icon">
            <b-icon type="is-success" icon="check-circle-outline" size="is-large" />
          </div>

          <p slot="message-title">Solicitação de validação enviada com sucesso</p>

          <div slot="message">
            Você pode continuar a navegação no sistema. A SelCorp irá analisar os dados enviados e
            enviar um e-mail em até 48 horas úteis.
          </div>
        </AppMessage>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppMessage from '@/components/AppMessage';
import AppTitulo from '@/components/AppTitulo';
import CompanyDataRegister from './CompanyDataRegister';
import CompanyRequiredDocuments from './CompanyRequiredDocuments';
import CompanySendDocument from './CompanySendDocument';

export default {
  name: 'CompanyValidation',
  components: {
    AppMessage,
    AppTitulo,
    CompanyDataRegister,
    CompanyRequiredDocuments,
    CompanySendDocument,
  },
  data() {
    const loading = {
      button: false,
      general: false,
    };

    return {
      companyRegisterStep: 0,
      loading,
    };
  },
  watch: {
    company: {
      deep: true,
      handler() {
        if (this.company.status === 'HABILITADA') {
          this.$router.push({ name: 'Company' });
        }
      },
    },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapState('companiesDocuments', ['companyDocuments', 'companyUpload']),
    ...mapState('documentTypes', ['documentTypes']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      lastValidation: 'lastValidation',
      user: 'getUser',
    }),
    disableRequestValidation() {
      if (this.documentToSend) return true;
      if (!this.company.activity) return true;
      if (!this.company.address) return true;
      if (!this.company.city) return true;
      if (!this.company.cnpj) return true;
      if (!this.company.companySize) return true;
      if (!this.company.email) return true;
      if (!this.company.fantasyName) return true;
      if (!this.company.legalNature) return true;
      if (!this.company.IBGECode) return true;
      if (!this.company.municipalRegistration) return true;
      if (!this.company.neighborhood) return true;
      if (!this.company.number) return true;
      if (!this.company.registry) return true;
      if (!this.company.registryDate) return true;
      if (!this.company.registryNumber) return true;
      if (!this.company.registryState) return true;
      if (this.company.registry === 'CARTORIO' && !this.company.registryOffice) {
        return true;
      }
      if (!this.company.shareCapital) return true;
      if (!this.company.shareCapitalDate) return true;
      if (!this.company.telephone) return true;
      if (!this.company.socialDenomination) return true;
      if (!this.company.state) return true;
      if (!this.company.zipCode) return true;

      return false;
    },
    documentToSend() {
      const requiredTypes = this.requiredDocuments.map(element => element.type);
      const companyTypes = this.companyDocuments.map(element => element.type);

      return requiredTypes.some(requiredType => !companyTypes.includes(requiredType));
    },
    requiredDocuments() {
      return this.documentTypes.filter(documentType => documentType.register);
    },
  },
  methods: {
    ...mapActions('companies', ['fetchCompany']),
    ...mapActions('companiesDocuments', ['fetchCompanyDocuments']),
    ...mapActions('documentTypes', ['fetchDocumentTypes']),
    ...mapActions(['fetchUser', 'fetchLastValidation', 'solicitarValidacaoEmpresa']),
    async loadData() {
      this.loading.general = true;
      try {
        await Promise.all([
          this.fetchDocumentTypes(),
          this.fetchCompany(this.company.id),
          this.fetchCompanyDocuments(this.company.id),
          this.fetchLastValidation(this.company.id),
        ]);
      } catch (error) {
        this.$alerta('Erro ao buscar dados', 'is-danger');
      } finally {
        this.loading.general = false;
      }
    },
    async requestValidation() {
      const confirmation = await this.$confirmacao({
        message:
          'Deseja prosseguir? Você não poderá fazer mais alterações e deverá aguardar avaliação da SelCorp.',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-info',
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      this.loading.button = true;
      try {
        await this.solicitarValidacaoEmpresa(this.company.id);
        await Promise.all([this.fetchCompany(this.company.id), this.fetchUser(this.user.id)]);
        this.$alerta('Solicitação enviada', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao solicitar validação da empresa', 'is-danger');
      }
      this.loading.button = false;
    },
  },
  async created() {
    if (this.company.contractAccepted) {
      this.loadData();
    } else {
      this.$router.push({ name: 'Register' });
    }
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 55vh;
}

.message-content {
  margin: 0 10px;
  max-width: 710px;
}

.message-title {
  margin-top: 1rem;
}

.invalid-company-container {
  align-items: center;
  margin-bottom: 3rem;
}

.invalid-company-icon {
  margin-right: 1rem;
}

@media (max-width: 769px) {
  .invalid-company-text {
    font-size: 0.9rem;
  }
}
</style>
