import { ufOptions } from './data';

const getOnlyText = text => text.replace(/[-+.^:,]/g, '');

const isAbbreviation = text => /^([A-Z]\.)+$/.test(text);

const isRomanNumeral = text =>
  /^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.test(text);

export const capitalize = text => {
  const prepos = ['da', 'do', 'das', 'dos', 'a', 'e', 'de'];
  return text
    .split(' ')
    .map(word => {
      if (ufOptions.some(uf => uf.value === getOnlyText(word))) {
        return word;
      }

      if (isAbbreviation(word) || isRomanNumeral(word)) {
        return word;
      }

      const lowerCaseWord = word.toLowerCase();
      if (prepos.includes(lowerCaseWord)) {
        return lowerCaseWord;
      }

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export const fileServer = (nome, container = 'documents') =>
  `${process.env.VUE_APP_BLOB_BASE_PATH}/${container}/${nome}`;
