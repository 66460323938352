<template>
  <div>
    <b-switch
      size="is-medium"
      type="is-primary"
      v-model="lance.importado"
      :disabled="existeLanceValidoItem"
      @input.native="evento => atualizarImportado(evento.target.checked)"
    >
    </b-switch>
  </div>
</template>

<script>
/* eslint-disable no-confusing-arrow */

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DispensaImportado',
  props: {
    item: Object,
  },
  data() {
    const lance = {
      importado: false,
    };

    return {
      lance,
    };
  },
  computed: {
    ...mapGetters({
      lances: 'obterDispensaLances',
      lancesParaEnviar: 'obterLancesParaEnviar',
    }),
    existeLanceValidoItem() {
      return this.lances.some(
        elemento => elemento.dispensaItemId === this.item.id && elemento.status === 'VALIDO',
      );
    },
  },
  watch: {
    lances() {
      if (this.lances.length) {
        const indice = this.lancesParaEnviar.findIndex(
          lance => lance.dispensaItemId === this.item.id,
        );

        if (indice === -1 && !this.lancesEmEnvio && !this.existeLanceValidoItem) {
          this.lance.importado = false;
        } else {
          this.carregarUltimoLance();
        }
      }
    },
  },
  methods: {
    ...mapActions(['adicionarDispensaLance']),
    async atualizarImportado(importado) {
      const dados = this.montarDados(importado);
      await this.adicionarDispensaLance(dados);
    },
    montarDados(importado) {
      const dados = this.lancesParaEnviar.find(lance => lance.dispensaItemId === this.item.id);

      return {
        dispensaItemId: this.item.id,
        identificador: this.item.identificador,
        itemDescricao: this.item.item.descricao,
        itemUnidadeNome: this.item.unidade.nome,
        valor: dados ? dados.valor : 0,
        marca: dados ? dados.marca : '',
        importado,
        primeiroLance: !this.existeLanceValidoItem,
      };
    },
    carregarUltimoLance() {
      const lancesFiltrados = this.lances.filter(
        elemento => elemento.dispensaItemId === this.item.id && elemento.status === 'VALIDO',
      );

      if (lancesFiltrados.length) {
        const ultimoLance = lancesFiltrados.reduce((anterior, atual) =>
          anterior.id > atual.id ? anterior : atual,
        );

        if (ultimoLance.fabricado === 'IMPORTADO') {
          this.lance.importado = true;
        } else {
          this.lance.importado = false;
        }
      } else {
        this.lance.importado = false;
      }
    },
    enviarLance() {
      this.$emit('enter-lance');
    },
  },
  mounted() {
    this.carregarUltimoLance();
  },
};
</script>
