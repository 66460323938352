import { api } from '../http';

export const acceptAuctionDocument = (auctionId, bidderId, documentId) =>
  api.patch(`/auctions/${auctionId}/bidders/${bidderId}/documents/${documentId}/accept`);
export const refuseAuctionDocument = (auctionId, bidderId, documentId) =>
  api.patch(`/auctions/${auctionId}/bidders/${bidderId}/documents/${documentId}/refuse`);

export const getAuctionQualifications = auctionId =>
  api.get(`/ofertascompra/${auctionId}/habilitacoes`);
export const qualifyBidder = (auctionId, itemId, data) =>
  api.post(`/ofertascompra/${auctionId}/itens/${itemId}/documentos/habilitar/`, data);
export const disqualifyBidder = (auctionId, itemId, data) =>
  api.post(`/ofertascompra/${auctionId}/itens/${itemId}/documentos/inabilitar/`, data);
