<template>
  <div>
    <section v-if="!propostasItem.length" class="section">
      <div class="content has-text-grey has-text-centered">
        <p>Nenhuma proposta.</p>
      </div>
    </section>

    <b-table
      hoverable
      default-sort="['ordem_classificacao', 'asc']"
      v-if="propostasItem.length"
      :data="propostasItem"
    >
      <b-table-column label="Posição" v-slot="props">
        {{ props.row.ordem_classificacao }}
      </b-table-column>

      <b-table-column label="Fornecedor" v-slot="props">
        {{ props.row.fornecedor }}
      </b-table-column>

      <b-table-column centered label="Marca/Modelo" v-slot="props">
        {{ props.row.marca }}
      </b-table-column>

      <b-table-column centered label="Importado" v-slot="props">
        {{ converteFabricado(props.row.fabricado) }}
      </b-table-column>

      <b-table-column :visible="maiorDesconto" centered label="Percentual" v-slot="props">
        {{ maiorDesconto ? `${formatarPorcentagem(props.row.percentual)}%` : 0 }}
      </b-table-column>

      <b-table-column centered :visible="!!displayValueColumn" label="Valor" v-slot="props">
        {{
          !maiorDesconto
            ? formatarValor(props.row.valor)
            : formatarValor(calcularValorDePorcentagem(item.total, props.row.percentual))
        }}
      </b-table-column>

      <b-table-column
        label="Proposta Inicial"
        :visible="displayInitialProposalColumn"
        v-slot="props"
      >
        <a v-if="props.row.arquivo" :href="props.row.arquivo | fileServer" download>{{
          props.row.arquivoNome
        }}</a>
        <b-tag v-else type="is-danger">Não Enviada</b-tag>
      </b-table-column>

      <b-table-column v-slot="props" label="Status">
        <b-tag :type="definirCor(props.row.estado)">{{ definirTexto(props.row.estado) }}</b-tag>
      </b-table-column>

      <b-table-column v-slot="props" label="Ações">
        <button
          class="button is-primary is-small"
          v-if="showBotaoClassificar(props.row.estado)"
          @click.prevent.stop="classificar(props.row)"
        >
          Classificar
        </button>
        <button
          class="button is-primary is-small is-outlined"
          v-else-if="showBotaoDesfazer(props.row.estado)"
          @click.prevent.stop="desfazerClassificacao(props.row)"
        >
          Desfazer
        </button>
      </b-table-column>
    </b-table>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificar"
      :onCancel="atualizarLoading"
      @justificate="classifyProposal"
    >
      <AppJustification v-if="justificar" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppJustification from '@/components/AppJustification';
import { calcularValorDePorcentagem } from '@/utils/calculations';
import { fileServer } from '@/utils/functions';
import { formatarValor, formatarPorcentagem } from '@/utils/format';

export default {
  name: 'ProposalAnalysis',
  props: {
    item: Object,
  },
  components: {
    AppJustification,
  },
  filters: {
    fileServer,
  },
  data() {
    return {
      proposta: {},
      justificar: false,
      propostaId: 0,
      listaEmpate: [],
      desabilitarBotaoClassificar: false,
      calcularValorDePorcentagem,
      formatarValor,
      formatarPorcentagem,
    };
  },
  computed: {
    ...mapState('proposals', { propostas: 'proposals' }),
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
    }),
    displayInitialProposalColumn() {
      return this.requiredDocuments.some(
        requiredDocument =>
          requiredDocument.type.toLowerCase() === 'Proposta Inicial'.toLowerCase(),
      );
    },
    displayValueColumn() {
      return !this.maiorDesconto || (this.maiorDesconto && this.referencialExists);
    },
    referencialExists() {
      return this.item.referencia && this.item.total;
    },
    maiorDesconto() {
      return this.tipoNegociacao === 3;
    },
    tipoNegociacao() {
      return this.ofertaCompra.tipo_negociacao.id;
    },
    showBotaoClassificar() {
      return estado => {
        switch (estado) {
          case 'CLASSIFICAR':
            return true;
          case 'EMPATADA':
            return true;
          default:
            return false;
        }
      };
    },
    showBotaoDesfazer() {
      return estado => {
        switch (estado) {
          case 'CLASSIFICADA':
            return true;
          case 'DESCLASSIFICADA':
            return true;
          default:
            return false;
        }
      };
    },
    definirCor() {
      return estado => {
        switch (estado) {
          case 'CLASSIFICAR':
            return 'is-light';
          case 'EMPATADA':
            return 'is-warning';
          case 'CLASSIFICADA':
            return 'is-success';
          case 'DESCLASSIFICADA':
            return 'is-danger';
          default:
            return 'is-dark';
        }
      };
    },
    definirTexto() {
      return estado => {
        switch (estado) {
          case 'CLASSIFICAR':
            return 'Sem classificação';
          case 'EMPATADA':
            return 'Sem classificação';
          case 'CLASSIFICADA':
            return 'Classificada';
          case 'DESCLASSIFICADA':
            return 'Desclassificada';
          default:
            return 'Erro';
        }
      };
    },
    textoStatus() {
      return estado => {
        switch (estado) {
          case 'CLASSIFICADA':
            return 'Classificação';
          case 'DESCLASSIFICADA':
            return 'Desclassificação';
          default:
            return 'Erro';
        }
      };
    },
    desabilitarBotao() {
      return estado => estado === 'CLASSIFICADA' || estado === 'DESCLASSIFICADA';
    },
    propostasItem() {
      return this.propostas.filter(proposta => {
        const propostaItemIdOC = proposta.oferta_compra_item_id;
        const itemIdOC = this.item.id;
        if (propostaItemIdOC === itemIdOC) {
          return proposta;
        }
        return undefined;
      });
    },
  },
  methods: {
    ...mapActions('proposals', ['updateProposal', 'untieProposal', 'undoProposalClassification']),
    ...mapActions({
      setPropostas: 'setOfertaCompraPropostaCollection',
    }),
    converteFabricado(fabricado) {
      if (fabricado === 'BRASIL') return 'Não';
      return 'Sim';
    },
    classificar(proposta) {
      this.loading = true;
      if (proposta.estado === 'EMPATADA') {
        this.desempatar(proposta.id);
      } else {
        this.propostaId = proposta.id;
        this.justificar = true;
      }
    },
    async desfazerClassificacao(proposta) {
      const proceed = await this.$confirmacao({
        message: `Deseja desfazer a ${this.textoStatus(proposta.estado)} do licitante ${
          proposta.fornecedor
        } no ${this.item.item.descricao} ${this.item.id}?`,
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-danger',
        hasIcon: true,
      });
      if (!proceed) {
        return;
      }
      await this.undoProposalClassification({ itemId: this.item.id, propostaId: proposta.id });
    },
    desempatar(propostaId) {
      this.untieProposal(propostaId).finally(() => {
        this.loading = false;
        this.propostaId = 0;
      });
    },
    async classifyProposal({ justification, state }) {
      // eslint-disable-next-line camelcase
      const { numero_oc } = this.ofertaCompra;
      const { propostaId } = this;
      const proposta = {
        propostaId,
        justificativa: justification || 'Classificada.',
        estado: state === 'success' ? 'CLASSIFICADA' : 'DESCLASSIFICADA',
      };
      this.desabilitarBotaoClassificar = true;
      await this.updateProposal({ numero_oc, itemId: this.item.id, propostaId, proposta });
      this.desabilitarBotaoClassificar = false;
      this.$refs.justificationModal.close();
    },
    atualizarLoading() {
      this.loading = false;
    },
  },
};
</script>
