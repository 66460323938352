<template>
  <div>
    <b-table hoverable scrollable class="table is-fullwidth decision-acts-table" :data="items">
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nada aqui.</p>
          </div>
        </section>
      </template>

      <b-table-column label="ID" width="40" numeric v-slot="props">{{
        props.row.identificador
      }}</b-table-column>

      <b-table-column
        cell-class="elipsis-size"
        label="Descrição"
        v-slot="props"
        :key="descriptionKey"
      >
        <div
          class="elipsis-column"
          :class="{
            'cursor-pointer': displayEyeIcon(props.row.id),
            'justify-align': justify,
          }"
          :ref="`description-container-${props.row.id}`"
          @click.stop.prevent="openDescription(props.row.id)"
        >
          <b-icon
            class="custom-eye-icon mr-1"
            icon="eye-outline"
            v-if="displayEyeIcon(props.row.id)"
          ></b-icon>

          <a
            :class="{ 'justify-align': justify }"
            v-if="displayEyeIcon(props.row.id)"
            :ref="`description-link-${props.row.id}`"
            >{{ props.row.item.descricao }}</a
          >
          <p v-else>{{ props.row.item.descricao }}</p>
        </div>
      </b-table-column>

      <b-table-column expanded centered label="Qtd." v-slot="props"
        >{{ props.row.quantidade | formatNumber }}
      </b-table-column>

      <b-table-column expanded centered label="Unidade" v-slot="props"
        >{{ props.row.unidade.nome }}
      </b-table-column>

      <b-table-column
        expanded
        centered
        class="lower-bid-column"
        label="Maior Desconto"
        v-slot="props"
        :visible="highestDiscount"
        >{{ formatBidPercent(props.row) }}
      </b-table-column>

      <b-table-column
        class="lower-offer-column"
        expanded
        centered
        label="Melhor Oferta"
        v-slot="props"
        >{{ formatBidValue(props.row) | formatValue }}
      </b-table-column>

      <b-table-column expanded centered label="Estado" v-slot="props"
        >{{ props.row.estado | formatItemState }}
      </b-table-column>

      <b-table-column label="Adjudicado" centered v-slot="props">
        <div class="decisive-field" :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <div v-if="props.row.justificativa_adjudicacao !== null">
            <p>
              {{ formatCrierText }}
            </p>
            <p>
              {{ adjudicationTextDate(props.row) }}
            </p>
            <p>
              {{ props.row.justificativa_adjudicacao }}
            </p>
          </div>
          <p v-else>
            N/A
          </p>
        </div>
      </b-table-column>

      <b-table-column label="Homologado" centered v-slot="props">
        <div class="decisive-field" :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <div v-if="props.row.justificativa_homologacao !== null">
            <p>
              {{ formatAuthorityText }}
            </p>
            <p>
              {{ homologationTextDate(props.row) }}
            </p>
            <p>
              {{ props.row.justificativa_homologacao }}
            </p>
          </div>
          <p v-else>
            N/A
          </p>
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import {
  formatarData as formatDate,
  tituloEstadoItem as formatItemState,
  formatarNumero as formatNumber,
  formatarPorcentagem as formatPercentage,
  formatarValor as formatValue,
} from '@/utils/format';

export default {
  name: 'DecisionActs',
  filters: {
    formatDate,
    formatItemState,
    formatNumber,
    formatValue,
  },
  data() {
    return {
      formatPercentage,
      descriptionKey: 0,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      items: 'obterTodosItens',
      responsible: 'obterOfertaCompraResponsaveis',
    }),
    authorityUser() {
      return this.responsible.find(responsavel => responsavel.funcao === 'AUTORIDADE');
    },
    crierUser() {
      return this.responsible.find(responsavel => responsavel.funcao === 'PREGOEIRO');
    },
    highestDiscount() {
      return this.auction.tipo_negociacao.id === 3;
    },
    formatAuthorityText() {
      return `Por: ${this.authorityUser.nome}`;
    },
    formatCrierText() {
      return `Por: ${this.crierUser.nome}`;
    },
    justify() {
      return this.windowWidth > 960;
    },
  },
  methods: {
    adjudicationTextDate(item) {
      return `Em: ${formatDate(item.data_adjudicacao)}`;
    },
    displayEyeIcon(id) {
      const container = this.$refs[`description-container-${id}`];
      if (container) {
        return container.scrollWidth >= container.parentElement.scrollWidth;
      }

      return false;
    },
    formatBidPercent(item) {
      return item.menor_lance.percentual
        ? `${formatPercentage(item.menor_lance.percentual)}%`
        : 'Nenhum Percentual';
    },
    formatBidValue(item) {
      return item.menor_lance.valor ? item.menor_lance.valor : 'Nenhum Lance';
    },
    homologationTextDate(item) {
      return `Em: ${formatDate(item.data_homologacao)}`;
    },
    openDescription(id) {
      const tag = this.$refs[`description-container-${id}`];
      if (tag) {
        const { classList } = tag;
        const contains = classList.contains('elipsis-column');
        if (contains) {
          classList.remove('elipsis-column');
          return;
        }
        classList.add('elipsis-column');
      }
    },
    verifyOverflowed() {
      const overflowed = this.items.some(item => this.displayEyeIcon(item.id));

      if (!overflowed) {
        const refs = Object.values(this.$refs);
        const rows = refs.filter(
          ref => ref && ref.parentNode && ref.parentNode.className === 'elipsis-size',
        );
        rows.forEach(row => row.parentNode.classList.remove('elipsis-size'));
      } else {
        this.descriptionKey += 1;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.verifyOverflowed();
    });
  },
};
</script>

<style scoped>
.decisive-field {
  min-width: 300px;
}

.decision-acts-table {
  font-size: 0.96rem;
  table-layout: fixed;
}

.lower-bid-column {
  min-width: 140px;
}
</style>

<style lang="scss">
.custom-eye-icon .mdi-eye-outline::before {
  font-size: 22px;
}

.decision-acts-table .table-wrapper .table {
  max-width: 99.8%;
}

@media (min-width: 769px) {
  .elipsis-size {
    max-width: 1px;
    width: 100%;
  }
}
</style>

<style src="@/styles/items.scss" lang="scss" scoped />
