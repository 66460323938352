<template>
  <div>
    <b-tabs type="is-boxed" :value="internalTabSelected">
      <b-tab-item label="Esclarecimentos">
        <AuctionClarifications />
      </b-tab-item>

      <b-tab-item label="Impugnações">
        <AuctionImpugnations />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import AuctionClarifications from './AuctionClarifications';
import AuctionImpugnations from './AuctionImpugnations';

export default {
  name: 'ClarificationsImpugnations',
  props: {
    internalTabSelected: Number,
  },
  components: {
    AuctionClarifications,
    AuctionImpugnations,
  },
};
</script>

<style></style>
