/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { ToastProgrammatic as Toast } from 'buefy';

function alerta(message, type = 'is-dark', duration = 2000, position = 'is-bottom') {
  Toast.open({
    duration,
    message,
    position,
    type,
    queue: false,
  });
}

const plugin = {
  install(vue) {
    vue.prototype.$alerta = alerta;
  },
};

Vue.use(plugin);
