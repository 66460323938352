const sanitizeFileName = fileName => {
  const illegalRe = /[/?<>\\:*|":]/g;
  // eslint-disable-next-line no-control-regex
  const controlRe = /[\x00-\x1f\x80-\x9f]/g;
  const reservedRe = /^\.+$/;
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;

  const sanitized = fileName
    .replace(illegalRe, '')
    .replace(controlRe, '')
    .replace(reservedRe, '')
    .replace(windowsReservedRe, '');
  return sanitized
    .split('')
    .splice(0, 255)
    .join('')
    .split(' ')
    .join('-');
};

export default sanitizeFileName;
