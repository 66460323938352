import { api } from '../http';

export const searchUser = term => api.get(`/usuarios?q=${term}`);

export const getAllUsers = () => api.get('/usuarios');

export const createUser = user => api.post('/usuarios', user);

export const getUser = user => api.get(`/usuarios/${user}`);

export const updateUser = (user, data) => api.patch(`/usuarios/${user}`, data);

export const requestEmailCode = userId => api.patch(`/users/${userId}/email-code`);

export const requestPasswordReset = data => api.post('/usuarios/solicitar-redefinicao-senha', data);

export const validateEmailCode = (userId, data) =>
  api.patch(`/users/${userId}/validate-email`, data);

export const validatePasswordResetHash = data => api.post('/usuarios/validar-hash', data);

export const resetPassword = data => api.post('/usuarios/redefinir-senha', data);
