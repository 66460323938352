import Vue from 'vue';

import {
  fetchUserNotificationById,
  fetchUserNotifications,
  readUserNotification,
  setUserNotificationsAsRead,
  updateUserInvitation,
} from '@/resources/users/notifications';

const types = {
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
  USER_NOTIFICATIONS_CHANGE_PER_PAGE: 'USER_NOTIFICATIONS_CHANGE_PER_PAGE',
  USER_NOTIFICATIONS_CLEAN_FILTERS: 'USER_NOTIFICATIONS_CLEAN_FILTERS',
  USER_NOTIFICATIONS_FILTER: 'USER_NOTIFICATIONS_FILTER',
  USER_NOTIFICATIONS_LOADING: 'USER_NOTIFICATIONS_LOADING',
  USER_NOTIFICATIONS_TOTAL: 'USER_NOTIFICATIONS_TOTAL',
  USER_NOTIFICATIONS_UNREAD: 'USER_NOTIFICATIONS_UNREAD',
  USER_NOTIFICATIONS_UPDATE: 'USER_NOTIFICATIONS_UPDATE',
  USER_NOTIFICATIONS_UPDATE_READ: 'USER_NOTIFICATIONS_UPDATE_READ',
};

const mutations = {
  [types.USER_NOTIFICATIONS](state, data) {
    if (data && Array.isArray(data)) {
      state.notifications = data;
    } else if (data && typeof data === 'object') {
      state.notifications.push(data);
    }
  },
  [types.USER_NOTIFICATIONS_CHANGE_PER_PAGE](state, perPage) {
    state.dataFilters.perPage = perPage;
    state.dataFilters.page = 1;
  },
  [types.USER_NOTIFICATIONS_CLEAN_FILTERS](state) {
    state.dataFilters = {
      status: '',
      page: 1,
      perPage: 20,
    };
  },
  [types.USER_NOTIFICATIONS_FILTER](state, filters) {
    if (filters.page) {
      Vue.set(state.dataFilters, 'page', filters.page);
    }
    if (filters.perPage) {
      Vue.set(state.dataFilters, 'perPage', filters.perPage);
    }
    if (filters.status) {
      Vue.set(state.dataFilters, 'status', filters.status);
    }
  },
  [types.USER_NOTIFICATIONS_LOADING](state, data) {
    state.notificationsLoading = data;
  },
  [types.USER_NOTIFICATIONS_TOTAL](state, data) {
    state.notificationsTotal = data;
  },
  [types.USER_NOTIFICATIONS_UNREAD](state, data) {
    if (data && Array.isArray(data)) {
      state.unreadNotifications = data;
    } else if (data && typeof data === 'object') {
      state.unreadNotifications.unshift(data);

      if (state.unreadNotifications.length > 5) {
        state.unreadNotifications.pop();
      }
    }
  },
  [types.USER_NOTIFICATIONS_UPDATE](state, data) {
    if (data && Array.isArray(data)) {
      state.notifications = data;
    } else if (data && typeof data === 'object') {
      const index = state.notifications.findIndex(notificacao => notificacao.id === data.id);
      if (index > -1) {
        Vue.set(state.notifications, index, data);
      } else {
        state.notifications.unshift(data);
      }
    }
  },
  [types.USER_NOTIFICATIONS_UPDATE_READ](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const current = data.shift();

        state.unreadNotifications.forEach((unreadNotification, index) => {
          if (unreadNotification.id === current) {
            state.unreadNotifications.splice(index, 1);
          }
        });
        state.notifications.forEach((notification, index) => {
          if (notification.id === current) {
            state.notifications[index] = Object.assign(state.notifications[index], {
              status: 'LIDA',
            });
          }
        });
      }
    }
  },
};

const actions = {
  changePerPageNotifications({ dispatch, commit, state }, perPage) {
    commit(types.USER_NOTIFICATIONS_CHANGE_PER_PAGE, perPage);
    dispatch('fetchUserNotifications', state.dataFilters);
  },
  cleanFiltersNotifications({ commit }) {
    commit(types.USER_NOTIFICATIONS_CLEAN_FILTERS);
  },
  async fetchUserNotificationById({ commit, rootState }, notificationId) {
    const response = await fetchUserNotificationById(notificationId, rootState.users.user.id);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.USER_NOTIFICATIONS, response.data);
  },
  async fetchUserNotifications({ commit, rootState }, filters = {}) {
    commit(types.USER_NOTIFICATIONS_LOADING, true);
    const response = await fetchUserNotifications(filters, rootState.users.user.id);
    if (response.status !== 200) {
      commit(types.USER_NOTIFICATIONS_LOADING, false);
      return Promise.reject(response.data.data);
    }
    commit(types.USER_NOTIFICATIONS_TOTAL, response.data.count);
    commit(types.USER_NOTIFICATIONS, response.data.data);
    return commit(types.USER_NOTIFICATIONS_LOADING, false);
  },
  filterNotifications({ dispatch, commit, state }, filters) {
    const dataFilters = filters ? { ...filters } : state.dataFilters;
    commit(types.USER_NOTIFICATIONS_FILTER, dataFilters);

    if (dataFilters.status && dataFilters.status === 'NAO-LIDA') {
      dispatch('getUnreadNotifications', state.dataFilters);
    } else {
      dispatch('fetchUserNotifications', state.dataFilters);
    }
  },
  async getUnreadNotifications({ commit, rootState }, filters = {}) {
    const response = await fetchUserNotifications(filters, rootState.users.user.id);
    if (response.status !== 200) return Promise.reject(response.data.data);
    return commit(types.USER_NOTIFICATIONS_UNREAD, response.data.data);
  },
  async readNotification({ commit, rootState }, notificacaoId) {
    const response = await readUserNotification(notificacaoId, rootState.users.user.id);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.USER_NOTIFICATIONS_UPDATE, response.data);
  },
  async setNotificationsAsRead({ commit, rootState }) {
    const response = await setUserNotificationsAsRead(rootState.users.user.id);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.USER_NOTIFICATIONS_UPDATE_READ, response.data);
  },
  updateUserNotifications({ commit }, data) {
    commit(types.USER_NOTIFICATIONS_UNREAD, data);
    commit(types.USER_NOTIFICATIONS_UPDATE, data);
  },
  async updateUserInvitation({ commit, rootState }, data) {
    const response = await updateUserInvitation(data.notificationId, rootState.users.user.id, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.USER_NOTIFICATIONS_UPDATE, response.data);
  },
};

const state = {
  dataFilters: {
    status: '',
    page: 1,
    perPage: 20,
  },
  notificationsLoading: false,
  notifications: [],
  notificationsTotal: 0,
  unreadNotifications: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
