<template>
  <form @keyup.enter="saveData">
    <b-field
      label="Denominação Social"
      :type="campoInvalido('socialDenomination', 'data') ? 'is-danger' : ''"
      :message="
        campoInvalido('socialDenomination', 'data')
          ? 'Denominação Social é um campo obrigatório'
          : ''
      "
    >
      <b-input
        custom-class="uppercase"
        placeholder="Denominação Social"
        v-model="$v.data.socialDenomination.$model"
        @blur="event => converterMaiusculo('socialDenomination', event.target.value, 'data')"
      ></b-input>
    </b-field>

    <b-field
      label="Nome Fantasia"
      :type="campoInvalido('fantasyName', 'data') ? 'is-danger' : ''"
      :message="campoInvalido('fantasyName', 'data') ? 'Nome Fantasia é um campo obrigatório' : ''"
    >
      <b-input
        custom-class="uppercase"
        placeholder="Nome Fantasia"
        v-model="$v.data.fantasyName.$model"
        @blur="event => converterMaiusculo('fantasyName', event.target.value, 'data')"
      ></b-input>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Natureza Jurídica"
        :type="campoInvalido('legalNature', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('legalNature', 'data') ? 'Natureza Jurídica é um campo obrigatório' : ''
        "
      >
        <b-select
          expanded
          placeholder="Natureza Jurídica"
          v-model="$v.data.legalNature.$model"
          @click.native="$v.data.legalNature.$touch()"
        >
          <option v-for="option in legalNatureOptions" :key="option.id" :value="option.id">{{
            option.name
          }}</option>
        </b-select>
      </b-field>

      <b-field
        expanded
        label="Atividades"
        :type="campoInvalido('activity', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('activity', 'data') ? 'Atividades é um campo obrigatório' : ''"
      >
        <b-select
          expanded
          placeholder="Atividades"
          v-model="$v.data.activity.$model"
          @click.native="$v.data.activity.$touch()"
        >
          <option v-for="option in activitiesOptions" :key="option.id" :value="option.id">{{
            option.name
          }}</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        :type="campoInvalido('shareCapital', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('shareCapital', 'data') ? 'Adicione um valor válido' : ''"
      >
        <template #label>
          Capital Social
          <b-tooltip
            label="Exemplo: R$ 1.000.000,00 (1 milhão)"
            :position="mobile ? 'is-top' : 'is-right'"
            type="is-primary"
          >
            <b-icon size="is-small" icon="help-circle-outline"></b-icon>
          </b-tooltip>
        </template>
        <b-input
          placeholder="Capital Social"
          v-mascara:valor
          v-model="maskValues.shareCapital"
          @blur="maskValues.shareCapital = formatValue(data.shareCapital, 2)"
          @input.native="event => atualizarValor('shareCapital', event, 'data', 'maskValues')"
          @keypress.native="teclasPermitidasValor($event)"
        >
        </b-input>
      </b-field>

      <b-field
        expanded
        label="Data Capital Social"
        :type="campoInvalido('shareCapitalDate', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('shareCapitalDate', 'data')
            ? 'Data Capital Social é um campo obrigatório'
            : ''
        "
      >
        <b-datepicker
          editable
          placeholder="Data Capital Social"
          icon="calendar-today"
          v-mascara:data
          v-model="maskValues.shareCapitalDate"
          @input="valor => atualizarData('shareCapitalDate', valor, 'data', 'maskValues')"
          @keypress.native="teclasPermitidasNumero($event)"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('shareCapitalDate', 'data', 'maskValues')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button
            class="button is-danger br-4"
            @click.prevent.stop="limparData('shareCapitalDate', 'data', 'maskValues')"
          >
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="CNPJ"
        :type="campoInvalido('cnpj', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('cnpj', 'data')
            ? 'CNPJ é um campo obrigatório. Insira um número válido'
            : ''
        "
      >
        <b-input
          maxlength="18"
          placeholder="CNPJ"
          :has-counter="false"
          v-mascara:cnpj
          v-model="$v.data.cnpj.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field expanded label="Inscrição Estadual">
        <b-input
          maxlength="15"
          placeholder="Inscrição Estadual"
          :has-counter="false"
          v-mascara:inscricaoEstadual
          v-model="data.stateRegistration"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="Inscrição Municipal"
        :type="campoInvalido('municipalRegistration', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('municipalRegistration', 'data')
            ? 'Inscrição Municipal é um campo obrigatório'
            : ''
        "
      >
        <b-input
          placeholder="Inscrição Municipal"
          v-model="$v.data.municipalRegistration.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>
    </b-field>

    <hr />

    <b-field grouped class="column-direction-touch">
      <b-field
        label="CEP"
        :type="campoInvalido('zipCode', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('zipCode', 'data') ? 'CEP é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="CEP"
          maxlength="9"
          @keypress.native="teclasPermitidasNumero($event)"
          :has-counter="false"
          :loading="searching"
          @blur="fetchCEP"
          @keyup.native.enter.prevent.stop="fetchCEP"
          v-mascara:cep
          v-model.trim="$v.data.zipCode.$model"
        />
      </b-field>

      <b-field
        expanded
        label="Endereço"
        :type="campoInvalido('address', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('address', 'data') ? 'Endereço é um campo obrigatório' : ''"
      >
        <b-input
          expanded
          placeholder="Endereço"
          :loading="searching"
          v-model="$v.data.address.$model"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Número"
        :type="campoInvalido('number', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('number', 'data') ? 'Número é um campo obrigatório' : ''"
      >
        <b-input placeholder="Número" v-model="$v.data.number.$model" />
      </b-field>

      <b-field expanded label="Complemento">
        <b-input expanded placeholder="Complemento" v-model="$v.data.complement.$model" />
      </b-field>

      <b-field
        expanded
        label="Bairro"
        :type="campoInvalido('neighborhood', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('neighborhood', 'data') ? 'Bairro é um campo obrigatório' : ''"
      >
        <b-input
          expanded
          placeholder="Bairro"
          :loading="searching"
          v-model="$v.data.neighborhood.$model"
        />
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Município"
        :type="campoInvalido('city', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('city', 'data') ? 'Município é um campo obrigatório' : ''"
      >
        <b-input placeholder="Município" :loading="searching" v-model="$v.data.city.$model" />
      </b-field>

      <b-field
        label="Código IBGE"
        :type="campoInvalido('IBGECode', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('IBGECode', 'data') ? 'Código IBGE é um campo obrigatório' : ''"
      >
        <b-input
          maxlength="7"
          placeholder="Código IBGE"
          :has-counter="false"
          v-model="$v.data.IBGECode.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="UF"
        :type="campoInvalido('state', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('state', 'data') ? 'UF é um campo obrigatório' : ''"
      >
        <b-select
          expanded
          placeholder="UF"
          :loading="searching"
          v-model="$v.data.state.$model"
          @click.native="$v.data.state.$touch()"
        >
          <option v-for="option in ufOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Telefone"
        :type="campoInvalido('telephone', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('telephone', 'data') ? 'Telefone é um campo obrigatório' : ''"
      >
        <b-input
          placeholder="Telefone"
          maxlength="12"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.telephone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field label="Celular">
        <b-input
          placeholder="Celular"
          maxlength="13"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.cellphone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="E-mail"
        :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
      >
        <b-input
          expanded
          placeholder="E-mail"
          v-model="$v.data.email.$model"
          @input="data.email = data.email.toLowerCase()"
        />
      </b-field>
    </b-field>

    <hr />

    <b-field grouped class="column-direction-touch"
      ><b-field
        expanded
        label="Responsável Cobrança"
        :type="campoInvalido('billingResponsible', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('billingResponsible', 'data')
            ? 'Responsável cobrança é um campo obrigatório'
            : ''
        "
      >
        <b-input
          expanded
          placeholder="Responsável Cobrança"
          v-model="$v.data.billingResponsible.$model"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Telefone Cobrança"
        :type="campoInvalido('billingTelephone', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('billingTelephone', 'data')
            ? 'Telefone cobrança é um campo obrigatório'
            : ''
        "
      >
        <b-input
          placeholder="Telefone Cobrança"
          maxlength="12"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.billingTelephone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field label="Celular Cobrança">
        <b-input
          placeholder="Celular Cobrança"
          maxlength="13"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.billingCellphone.$model"
          @keypress.native="teclasPermitidasNumero($event)"
        />
      </b-field>

      <b-field
        expanded
        label="E-mail Cobrança"
        :type="campoInvalido('billingEmail', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('billingEmail', 'data') ? 'E-mail cobrança é um campo obrigatório' : ''
        "
      >
        <b-input
          expanded
          placeholder="E-mail"
          v-model="$v.data.billingEmail.$model"
          @input="data.billingEmail = data.billingEmail.toLowerCase()"
        />
      </b-field>
    </b-field>

    <hr />

    <b-field
      :type="campoInvalido('registry', 'data') ? 'is-danger' : ''"
      :message="campoInvalido('registry', 'data') ? 'Selecione uma opção válida' : ''"
    >
      <b-radio-button
        size="is-medium"
        v-model="data.registry"
        v-for="option in registryOptions"
        :key="option.id"
        :native-value="option.value"
        @input="$v.data.registry.$touch()"
      >
        <span>{{ option.name }}</span>
      </b-radio-button>
    </b-field>

    <b-field
      label="Cartório"
      v-show="registryOffice"
      :type="campoInvalido('registryOffice', 'data') ? 'is-danger' : ''"
      :message="campoInvalido('registryOffice', 'data') ? 'Cartório é um campo obrigatório' : ''"
    >
      <b-input placeholder="Cartório" v-model="$v.data.registryOffice.$model" />
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="UF Registro"
        :type="campoInvalido('registryState', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('registryState', 'data') ? 'UF Registro é um campo obrigatório' : ''
        "
      >
        <b-select
          expanded
          placeholder="UF Registro"
          v-model="$v.data.registryState.$model"
          @click.native="$v.data.registryState.$touch()"
        >
          <option v-for="option in ufOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        :type="campoInvalido('registryNumber', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('registryNumber', 'data') ? 'Nr. Registro é um campo obrigatório' : ''
        "
        label="Nr. Registro"
        expanded
      >
        <b-input placeholder="Nr. Registro" v-model="$v.data.registryNumber.$model" />
      </b-field>

      <b-field
        expanded
        class="registry-date-field"
        :label="`Data do Registro ${registryOffice ? 'no Cartório' : 'na Junta Comercial'}`"
        :type="campoInvalido('registryDate', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('registryDate', 'data') ? 'Selecione uma data válida para o registro' : ''
        "
      >
        <b-datepicker
          editable
          icon="calendar-today"
          v-mascara:data
          v-model="maskValues.registryDate"
          :placeholder="`Data do Registro ${registryOffice ? 'no Cartório' : 'na Junta Comercial'}`"
          @input="valor => atualizarData('registryDate', valor, 'data', 'maskValues')"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('registryDate', 'data', 'maskValues')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button
            class="button is-danger br-4"
            @click.prevent.stop="limparData('registryDate', 'data', 'maskValues')"
          >
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        label="Enquadramento da Empresa"
        :type="campoInvalido('companySize', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('companySize', 'data')
            ? 'Enquadramento da Empresa é um campo obrigatório'
            : ''
        "
      >
        <b-select
          expanded
          placeholder="Enquadramento da Empresa"
          v-model="$v.data.companySize.$model"
          @input="changeCompanySize"
          @click.native="$v.data.companySize.$touch()"
        >
          <option v-for="option in companySizeOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Simples Nacional" v-show="['ME', 'EPP'].indexOf(data.companySize) > -1">
        <b-switch size="is-medium" v-model="$v.data.simpleNational.$model" />
      </b-field>

      <b-field
        label="Data Registro"
        v-show="data.simpleNational"
        :type="campoInvalido('simpleNationalDate', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('simpleNationalDate', 'data') ? 'Data Registro é um campo obrigatório' : ''
        "
      >
        <b-datepicker
          editable
          placeholder="Data Registro"
          icon="calendar-today"
          v-mascara:data
          v-model="maskValues.simpleNationalDate"
          @input="valor => atualizarData('simpleNationalDate', valor, 'data', 'maskValues')"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('simpleNationalDate', 'data', 'masValues')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button
            class="button is-danger br-4"
            @click.prevent.stop="limparData('simpleNationalDate', 'data', 'masValues')"
          >
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>
    </b-field>

    <b-field expanded>
      <button
        class="button is-primary is-fullwidth next-button"
        :class="{ ' is-loading': loading }"
        @click.prevent.stop="saveData"
      >
        Próximo
      </button>
    </b-field>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, maxValue, minValue, required, requiredIf } from 'vuelidate/lib/validators';
import { isValidCnpj } from '@brazilian-utils/validators';

import fieldMixin from '@/mixins/formulario';
import { dadosCEP as fetchDataCEP } from '@/resources/utils';
import { formatarValor as formatValue } from '@/utils/format';
import { ufOptions, legalNatureOptions, activitiesOptions } from '@/utils/data';

export default {
  name: 'CompanyDataRegister',
  mixins: [fieldMixin],
  data() {
    const companySizeOptions = [
      { id: 1, name: 'ME', value: 'ME' },
      { id: 2, name: 'MEI', value: 'MEI' },
      { id: 3, name: 'EPP', value: 'EPP' },
      { id: 4, name: 'Outros', value: 'OUTROS' },
      { id: 5, name: 'Cooperativa', value: 'COOPERATIVA' },
      {
        id: 6,
        name: 'Cooperativa Direito de Preferência',
        value: 'COOPERATIVA_PREFERENCIA',
      },
    ];
    const data = {
      activity: '',
      address: '',
      billingCellphone: '',
      billingEmail: '',
      billingResponsible: '',
      billingTelephone: '',
      cellphone: '',
      city: '',
      cnpj: '',
      companySize: '',
      complement: '',
      email: '',
      fantasyName: '',
      legalNature: '',
      IBGECode: 0,
      municipalRegistration: '',
      neighborhood: '',
      number: '',
      registry: '',
      registryDate: null,
      registryNumber: 0,
      registryOffice: '',
      registryState: '',
      shareCapital: 0,
      shareCapitalDate: null,
      simpleNational: false,
      simpleNationalDate: null,
      socialDenomination: '',
      state: '',
      stateRegistration: '',
      telephone: '',
      zipCode: '',
    };
    const registryOptions = [
      { id: 1, name: 'Junta Comercial', value: 'JUNTA' },
      { id: 2, name: 'Cartório', value: 'CARTORIO' },
    ];
    const maskValues = {
      registryDate: null,
      shareCapital: null,
      shareCapitalDate: null,
      simpleNationalDate: null,
    };

    return {
      activitiesOptions,
      companySizeOptions,
      confirmed: false,
      data,
      formatValue,
      legalNatureOptions,
      loading: false,
      maskValues,
      registryOptions,
      searching: false,
      ufOptions,
    };
  },
  validations: {
    data: {
      activity: { required },
      address: { required },
      billingCellphone: { required: false },
      billingEmail: { required },
      billingResponsible: { required },
      billingTelephone: { required },
      cellphone: { required: false },
      city: { required },
      cnpj: { isValidCnpj },
      companySize: { required },
      complement: { required: false },
      email: { email, required },
      fantasyName: { required },
      legalNature: { required },
      IBGECode: { required },
      municipalRegistration: { required },
      neighborhood: { required },
      number: { required },
      registry: { required },
      registryDate: { required },
      registryNumber: { required },
      registryOffice: {
        required: requiredIf(function verificaCartorio() {
          return this.registryOffice;
        }),
      },
      registryState: { required },
      shareCapital: {
        required,
        minValue: minValue(0.0001),
        maxValue: maxValue(99999999999999.9999),
      },
      shareCapitalDate: { required },
      simpleNational: { required: false },
      simpleNationalDate: {
        required: requiredIf(function validarDataSimplesNacional() {
          return this.data.simpleNational;
        }),
      },
      socialDenomination: { required },
      state: { required },
      telephone: { required },
      zipCode: { required },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({ user: 'getUser' }),
    ...mapGetters('app', { mobile: 'mobile' }),
    registryOffice() {
      return this.data.registry === 'CARTORIO';
    },
  },
  methods: {
    ...mapActions('companies', ['saveCompany']),
    changeCompanySize(value) {
      if (['ME', 'EPP'].indexOf(value) === -1) {
        this.data.simpleNational = false;
      }
    },
    async fetchCEP() {
      if (this.data.zipCode) {
        this.searching = true;
        try {
          const {
            data: { data: response },
          } = await fetchDataCEP(this.data.zipCode);
          this.data.address = response.address;
          this.data.neighborhood = response.neighborhood;
          this.data.city = response.city;
          this.data.IBGECode = response.cityIBGE;
          this.data.state = response.state;
        } catch (error) {
          this.$alerta(error.response.data.error, 'is-danger', 3000);
        }
        this.searching = false;
      }
    },
    mountCompanyData() {
      this.data.activity = this.company.activity;
      this.data.address = this.company.address;
      this.data.billingCellphone = this.company.billingCellphone;
      this.data.billingEmail = this.company.billingEmail;
      this.data.billingResponsible = this.company.billingResponsible;
      this.data.billingTelephone = this.company.billingTelephone;
      this.data.cellphone = this.company.cellphone;
      this.data.city = this.company.city;
      this.data.cnpj = this.company.cnpj;
      this.data.companySize = this.company.companySize;
      this.data.complement = this.company.complement;
      this.data.socialDenomination = this.company.socialDenomination;
      this.data.email = this.company.email;
      this.data.fantasyName = this.company.fantasyName;
      this.data.legalNature = this.company.legalNature;
      this.data.IBGECode = this.company.IBGECode;
      this.data.municipalRegistration = this.company.municipalRegistration;
      this.data.neighborhood = this.company.neighborhood;
      this.data.number = this.company.number;
      this.data.registry = this.company.registry || 'JUNTA';
      this.data.registryDate = this.company.registryDate;
      this.maskValues.registryDate = this.company.registryDate;
      this.data.registryNumber = this.company.registryNumber;
      this.data.registryOffice = this.company.registryOffice;
      this.data.registryState = this.company.registryState;
      this.data.shareCapital = this.company.shareCapital;
      if (this.company.shareCapital) {
        this.maskValues.shareCapital = formatValue(this.company.shareCapital, 2);
      }
      this.data.shareCapitalDate = this.company.shareCapitalDate;
      this.data.simpleNational = this.company.simpleNational;
      this.data.simpleNationalDate = this.company.simpleNationalDate;
      this.maskValues.simpleNationalDate = this.company.simpleNationalDate;
      this.maskValues.shareCapitalDate = this.company.shareCapitalDate;
      this.data.state = this.company.state;
      this.data.stateRegistration = this.company.stateRegistration;
      this.data.telephone = this.company.telephone;
      this.data.zipCode = this.company.zipCode;
    },
    async saveData() {
      if (this.validar('data')) {
        this.loading = true;
        try {
          const data = { ...this.data };
          await this.saveCompany(data);
          this.$alerta('Dados salvos', 'is-success');
          this.confirmed = true;
          this.$v.data.$reset();
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.$parent.companyRegisterStep = 1;
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.company.id) {
      this.mountCompanyData();
    }
  },
};
</script>

<style lang="scss" scoped>
.next-button {
  margin-top: 1rem;
}

.registry-date-field {
  min-width: 350px;
}

@media (min-width: 1024px) {
  .next-button {
    margin-top: 1.5rem;
  }
}
</style>
