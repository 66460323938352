<template>
  <div>
    <div v-if="!prazos.length" class="panel">
      <div class="panel-block is-justify-content-center">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhum prazo.</p>
          </div>
        </section>
      </div>
    </div>

    <b-table v-if="prazos.length" :data="prazos" hoverable :mobile-cards="false">
      <b-table-column label="Descrição" v-slot="props">
        <div v-if="!ofertaCompra.justificativa_suspensao">
          {{ props.row.nome }}
        </div>

        <div v-else>
          {{ props.row.nome }}
          <small class="ml-2"
            ><strong
              ><i>{{ props.row.justificativa_suspensao }}</i></strong
            ></small
          >
        </div>
      </b-table-column>

      <b-table-column centered label="Período" :width="!mobile ? '350' : '50%'" v-slot="props">
        {{ dataPrazo(props.row.data_de, props.row.data_ate) }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatarDataSegundo } from '@/utils/format';

export default {
  name: 'PregaoPrazos',
  computed: {
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
      prazos: 'obterOfertaCompraPrazos',
    }),
  },
  methods: {
    dataPrazo(dataDe, dataAte) {
      const dataDeFormatada = formatarDataSegundo(dataDe);
      const dataAteFormatada = dataAte ? formatarDataSegundo(dataAte) : '';
      if (dataDeFormatada === '') return dataAteFormatada;
      if (dataAteFormatada === '') return dataDeFormatada;
      if (dataAteFormatada === '') {
        return `${dataDeFormatada} a Data Indeterminada`;
      }
      return `${dataDeFormatada} a ${dataAteFormatada}`;
    },
  },
};
</script>
