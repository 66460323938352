/* eslint-disable no-param-reassign */
import Vue from 'vue';

const bus = new Vue();

function emitBus(event, data) {
  bus.$emit(event, data);
}
function onBus(event, callback) {
  bus.$on(event, callback);
}

const plugin = {
  install(vue) {
    vue.prototype.$emitBus = emitBus;
    vue.prototype.$onBus = onBus;
  },
};

Vue.use(plugin);
