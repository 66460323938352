import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';

Vue.use(VueProgressBar, {
  color: '#2153B5',
  failedColor: '#FF3860',
  transition: {
    speed: '0.2s',
    opacity: '0.1s',
  },
});
