<template>
  <div>
    <section class="section" v-if="!itemOffers.length">
      <div class="content has-text-grey has-text-centered">
        <p>Nenhuma oferta.</p>
      </div>
    </section>

    <b-table
      class="table is-fullwidth offers-table"
      default-sort-direction="ASC"
      v-if="itemOffers.length"
      :data="itemOffers"
    >
      <b-table-column centered sortable field="data" label="Data" v-slot="props">
        <div class="date-column">
          <div v-if="openDispute && props.row.prorrogacao && !mobile">
            {{ props.row.data | formatDatetime }}
            <b-tooltip label="Prorrogação de Lances" :position="mobile ? 'is-left' : 'is-top'">
              <b-icon icon="timer" type="is-black" size="is-small"></b-icon>
            </b-tooltip>
          </div>

          <div v-if="openDispute && props.row.prorrogacao && mobile">
            <b-tooltip label="Prorrogação de Lances" :position="mobile ? 'is-left' : 'is-top'">
              <b-icon icon="timer" type="is-black" size="is-small"></b-icon>
            </b-tooltip>
            {{ props.row.data | formatDatetime }}
          </div>

          <div class="date-without-bid-extension" v-if="!openDispute || !props.row.prorrogacao">
            {{ props.row.data | formatDatetime }}
          </div>
        </div>
      </b-table-column>

      <b-table-column centered label="Fornecedor" v-slot="props">
        {{ displaySocialDenomination ? props.row.denominacaoSocial : props.row.fornecedor }}
      </b-table-column>

      <b-table-column centered label="Porte" v-slot="props">
        {{ formatCompanySize(props.row.porte) }}
      </b-table-column>

      <b-table-column centered label="Percentual" v-slot="props" :visible="highestDiscount">
        {{ `${formatPercentage(props.row.percentual || 0)}%` }}
      </b-table-column>

      <b-table-column
        centered
        label="Valor"
        v-slot="props"
        :visible="!highestDiscount || !!(highestDiscount && item.referencia && item.total)"
      >
        {{ props.row.valor ? formatValue(props.row.valor) : 0 }}
      </b-table-column>

      <b-table-column label="Status" v-slot="props">
        <div class="status-column">{{ formatStatusOffer(props.row) }}</div>
      </b-table-column>

      <b-table-column centered label="Origem" v-slot="props">
        {{ formatOfferOrigin(props.row.origem) }}
      </b-table-column>

      <b-table-column centered label="Ações" v-slot="props" :visible="displayActions">
        <b-tooltip label="Excluir">
          <button
            class="button is-danger"
            v-if="displayExcludeOfferButton(props.row.origem)"
            @click.prevent.stop="removeBid(props.row.id)"
          >
            <b-icon icon="delete"></b-icon>
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { funcoes } from '@/utils/enumerados';
import {
  formatarDataSegundo as formatDatetime,
  formatarValor as formatValue,
  formatarPorcentagem as formatPercentage,
} from '@/utils/format';

export default {
  name: 'OffersList',
  props: {
    displaySocialDenomination: { type: Boolean, default: false },
    highestDiscount: { type: Boolean, default: false },
    item: { type: Object, default: () => {} },
    formatCompanySize: { type: Function, default: () => {} },
  },
  data() {
    return {
      formatPercentage,
      formatValue,
    };
  },
  filters: {
    formatDatetime,
  },
  computed: {
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      itemOffers: 'obterOfertaCompraItemOfertas',
      function: 'getFunction',
    }),
    openDispute() {
      return this.auction.modo_disputa === 'ABERTO';
    },
    displayActions() {
      return this.auction.situacao.id === 6 && this.crier();
    },
  },
  methods: {
    ...mapActions(['deleteBid']),
    displayExcludeOfferButton(origin) {
      return origin === 'LANCE';
    },
    bidStarted() {
      return ['LANCE', 'LANCE-RANDOMICO', 'LANCE-FECHADO'].indexOf(this.item.estado) > -1;
    },
    crier() {
      return this.function === funcoes.PREGOEIRO;
    },
    formatOfferOrigin(origin) {
      const disputeMode =
        origin === 'LANCE' && this.auction.modo_disputa === 'FECHADO'
          ? this.auction.modo_disputa
          : '';

      return {
        PROPOSTA: 'Proposta',
        LANCE: 'Lance',
        LANCEFECHADO: 'Lance Aberto',
        FECHADO: 'Lance Fechado',
        PREFERENCIA: 'Preferência',
        NEGOCIACAO: 'Negociação',
      }[origin + disputeMode];
    },
    async removeBid(bidId) {
      const proceed = await this.$confirmacao({
        message: 'Essa ação irá excluir o lance do item no pregão eletrônico. Deseja prosseguir?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-danger',
        hasIcon: true,
      });
      if (!proceed) {
        return;
      }
      try {
        await this.deleteBid({
          ofertaCompraId: this.auction.id,
          itemId: this.item.id,
          lanceId: bidId,
        });
        this.$alerta('O lance foi excluido', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir lance', 'is-danger');
      }
    },
    formatStatusOffer(row) {
      const CLASSIFIED_DEFAULT_MESSAGE = 'Classificada.';

      return {
        CLASSIFICAR: 'Classificar',
        EMPATADA: 'Empatada',
        CLASSIFICADA: `${
          row.justificativa !== CLASSIFIED_DEFAULT_MESSAGE
            ? `Classificada - ${row.justificativa}`
            : 'Classificada.'
        }`,
        DESCLASSIFICADA: `Desclassificada - ${row.justificativa}`,
        VALIDO: 'Válido.',
        INVALIDOSUPERIOR: !this.highestDiscount
          ? 'Inválido - Valor superior a outro já registrado.'
          : 'Inválido - Percentual inferior a outro já registrado',
        INVALIDOIGUAL: !this.highestDiscount
          ? 'Inválido - Valor igual a outro já registrado.'
          : 'Inválido - Percentual igual a outro já registrado.',
        INVALIDOREDUCAO: 'Inválido - Não atingiu a redução mínima entre lances.',
        ENVIADO: 'Em processamento.',
      }[row.status + (row.motivo || '')];
    },
  },
};
</script>

<style lang="scss" scoped>
.date-column {
  min-width: fit-content;
  white-space: nowrap;
}

.date-without-bid-extension {
  width: calc(100% - 18px);
}

.status-column {
  min-width: fit-content;
}

@media (max-width: 1500px) {
  .offers-table {
    font-size: 0.9rem;
  }
}
</style>
