import Big from 'big.js';

const calcularPorcentagemDeValor = (valorTotal, dadosValor) => {
  const total = new Big(valorTotal);
  const valor = new Big(dadosValor);

  return Number(valor.div(total).times(100));
};

const calcularReducaoDePorcentagem = (valorTotal, dadosPercentual) => {
  const total = new Big(valorTotal);
  const percentual = new Big(dadosPercentual);

  return Number(total.times(percentual).div(100));
};

const calcularValorDePorcentagem = (valorTotal, dadosPercentual) => {
  const total = new Big(valorTotal);
  const percentual = new Big(dadosPercentual);

  return Number(total.minus(total.times(percentual).div(100)));
};

export { calcularReducaoDePorcentagem, calcularPorcentagemDeValor, calcularValorDePorcentagem };
