import { api, sendTimeout } from '../http';

export const fetchAuctionBidderDocuments = (auctionId, bidderId, itemId) => {
  let params = '?';
  if (itemId) params += `auction_item_id=${itemId}`;
  return api.get(`/auctions/${auctionId}/bidders/${bidderId}/documents${params}`);
};

export const addAuctionDocuments = (
  auctionId,
  bidderId,
  data,
  { onUploadProgress } = { onUploadProgress: () => {} },
) =>
  api.post(`/auctions/${auctionId}/bidders/${bidderId}/documents`, data, {
    onUploadProgress,
    timeout: sendTimeout,
  });
export const removeAuctionDocument = (auctionId, bidderId, documentId) =>
  api.delete(`/auctions/${auctionId}/bidders/${bidderId}/documents/${documentId}`);

export const fetchAuctionDocumentsList = (auctionId, filters) => {
  let params = '?';
  if (filters) {
    if (filters.page) params += `page=${filters.page}&`;
    if (filters.perPage) params += `per_page=${filters.perPage}`;
  }

  return api.get(`auctions/${auctionId}/documents${params}`);
};
