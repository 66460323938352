<template>
  <div>
    <b-loading is-full-page :active="pageLoading" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="level">
            <div class="level-left">
              <div class="dispensations-title">
                <h1 class="title">Dispensas de Licitação</h1>
              </div>
            </div>
            <div class="level-end has-text-centered">
              <div>
                <a
                  class="button is-primary is-inverted is-outlined botao-criar"
                  :class="{ ' is-loading': loading.criarDispensa }"
                  v-if="logged && company.buyer && permissaoOperarDispensa"
                  @click.prevent.stop="criarDispensa"
                >
                  Criar Dispensa
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <Filtros />

        <div class="panel">
          <div class="panel-block empty-table">
            <section v-if="!exibirTabela" class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhuma Dispensa.</p>
              </div>
            </section>

            <b-table
              detailed
              backend-pagination
              v-if="exibirTabela"
              class="table is-fullwidth dispensations-table"
              default-sort-direction="ASC"
              :data="dispensas"
              :mobile-card="false"
            >
              <b-table-column
                centered
                sortable
                field="ultima_visualizacao"
                label="Última Visualização"
                v-slot="props"
                :visible="logged"
              >
                <div class="last-view-column">
                  <b-icon
                    class="last-view-icon mr-1"
                    v-show="props.row.situacao.id !== 0 && props.row.ultima_visualizacao"
                    :icon="props.row.situacao.id === 1 ? 'eye' : 'email'"
                    size="is-small"
                  />
                  <span>{{ props.row.ultima_visualizacao | formatarData }}</span>
                </div>
              </b-table-column>

              <b-table-column
                centered
                sortable
                field="tipo_edital"
                label="Exclusiva"
                width="60"
                v-slot="props"
                :visible="dispensas.some(element => element.tipo_edital === 'EXCLUSIVA')"
              >
                <b-icon
                  icon="check"
                  size="is-medium"
                  type="is-success"
                  v-show="exclusiva(props.row.tipo_participacao)"
                />
              </b-table-column>

              <b-table-column
                centered
                sortable
                label="Dispensa"
                field="numero_dispensa"
                v-slot="props"
              >
                <router-link
                  v-if="props.row.situacao.id > 1"
                  :to="{ name: 'Dispensa', params: { id: props.row.id } }"
                >
                  {{ props.row.numero_dispensa }}
                </router-link>
                <p v-else>{{ props.row.numero_dispensa }}</p>
              </b-table-column>

              <b-table-column
                centered
                field="comprador.nome"
                label="Comprador"
                v-slot="props"
                :sortable="isNotBuyer"
              >
                <div class="buyer-column">{{ props.row.comprador.nome }}</div>
              </b-table-column>

              <b-table-column
                centered
                sortable
                field="situacao.nome"
                label="Situação"
                v-slot="props"
              >
                <div class="situation-column">{{ nomeSituacao(props.row) }}</div>
              </b-table-column>

              <b-table-column centered sortable field="objeto" label="Objeto" v-slot="props">
                {{ props.row.objeto }}
              </b-table-column>

              <b-table-column
                centered
                sortable
                field="data_abertura"
                label="Previsão de Abertura"
                width="200"
                v-slot="props"
              >
                {{ props.row.data_abertura | formatarData }}
              </b-table-column>

              <b-table-column
                centered
                ref="opcoes"
                label="Opções"
                v-slot="props"
                :visible="exibirOpcoes"
              >
                <b-field
                  id="dispensations-list-options"
                  v-if="company.id === props.row.comprador.id && props.row.situacao.id === 1"
                >
                  <p class="control">
                    <b-tooltip label="Editar">
                      <button
                        class="button is-primary"
                        ref="editar"
                        v-if="company.id === props.row.comprador.id && props.row.situacao.id === 1"
                        @click.prevent.stop="editarDispensa(props.row.id)"
                      >
                        <b-icon icon="pencil" size="is-small" />
                      </button>
                    </b-tooltip>
                  </p>
                  <p class="control">
                    <b-tooltip label="Excluir" type="is-danger">
                      <button
                        class="button is-danger"
                        ref="excluir"
                        loading="loading.delete"
                        v-if="company.id === props.row.comprador.id && props.row.situacao.id === 1"
                        @click.prevent.stop="excluir(props.row.id)"
                      >
                        <b-icon icon="delete" size="is-small" />
                      </button>
                    </b-tooltip>
                  </p>
                </b-field>

                <b-tooltip label="Editar" v-else>
                  <button
                    class="button is-primary"
                    ref="editar"
                    v-if="company.id === props.row.comprador.id && props.row.situacao.id === 1"
                    @click.prevent.stop="editarDispensa(props.row.id)"
                  >
                    <b-icon icon="pencil" size="is-small" />
                  </button>
                </b-tooltip>
              </b-table-column>

              <template v-slot:detail="props">
                <b-tabs expanded type="is-toggle">
                  <b-tab-item label="Informações">
                    <div class="columns">
                      <div class="column">
                        <ul>
                          <li v-if="props.row.numero_processo !== null">
                            <strong>Número de Processo:</strong>
                            {{ props.row.numero_processo }}
                          </li>

                          <li>
                            <strong>Comprador:</strong>
                            {{ props.row.comprador.nome }}
                          </li>

                          <li>
                            <strong>CNPJ:</strong>
                            {{ props.row.comprador.cnpj }}
                          </li>

                          <li v-if="props.row.endereco_entrega_cep">
                            <strong>CEP:</strong>
                            {{ props.row.endereco_entrega_cep }}
                          </li>

                          <li v-if="props.row.endereco_entrega">
                            <strong>Endereço de Entrega:</strong>
                            {{ formataEnderecoEntrega(props.row) }}
                          </li>
                        </ul>
                      </div>

                      <div class="column">
                        <ul>
                          <li v-if="props.row.tipo_negociacao.nome !== null">
                            <strong>Negociação:</strong>
                            {{ props.row.tipo_negociacao.nome }}
                          </li>

                          <li v-if="props.row.tipo_participacao !== null">
                            <strong>Tipo de Participação:</strong>
                            {{ formatarTipoParticipacao(props.row.tipo_participacao) }}
                          </li>

                          <li v-if="props.row.data_publicacao !== null">
                            <strong>Data de Publicação:</strong>
                            {{ props.row.data_publicacao | formatarDataSemHorario }}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <button
                      v-if="props.row.edital !== null"
                      class="button is-primary"
                      :class="{ ' is-loading': loading.notice }"
                      @click="baixarEdital(props.row.id)"
                    >
                      <b-icon icon="download" />
                      <span>Baixar Edital</span>
                    </button>
                  </b-tab-item>

                  <b-tab-item label="Itens">
                    <b-table
                      paginated
                      class="items-table"
                      per-page="5"
                      :data="props.row.itens"
                      :mobile-cards="false"
                    >
                      <b-table-column
                        numeric
                        label="ID"
                        width="40"
                        field="identificador"
                        v-slot="props"
                      >
                        {{ props.row.identificador }}
                      </b-table-column>

                      <b-table-column field="nome" label="Descrição" v-slot="props">
                        {{ props.row.descricao }}
                      </b-table-column>

                      <b-table-column numeric label="Quantidade" width="60" v-slot="props">
                        {{ props.row.quantidade || formatarNumero }}
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                </b-tabs>
              </template>

              <template v-slot:footer>
                <AppPagination
                  :data-filters="dataFilters"
                  :total="total"
                  @change-page="page => filtrarDispensas({ page })"
                  @change-per-page="perPage => alterarPorPaginaDispensas(perPage)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppPagination from '@/components/AppPagination';

import { funcoes } from '@/utils/enumerados';
import { formatarData, formatarDataSemHorario, formatarNumero } from '@/utils/format';
import { fileServer } from '@/utils/functions';

import Filtros from './DispensaFiltros';

export default {
  name: 'ListaDispensas',
  components: {
    AppPagination,
    Filtros,
  },
  data() {
    const loading = {
      notice: false,
      edit: false,
      delete: false,
      criarDispensa: false,
    };

    return {
      loading,
      funcoes,
    };
  },
  computed: {
    ...mapState('prefectures', ['prefecturesCities']),
    ...mapGetters({
      dispensas: 'obterDispensas',
      obterAnexos: 'obterAnexosDispensa',
      carregando: 'obterCarregandoDispensas',
      total: 'obterTotalDispensas',
      usuario: 'getUser',
      funcao: 'getFunction',
      logged: 'getLogged',
      dataFilters: 'obterDispensaDadosFiltros',
    }),
    ...mapState('companies', ['company']),
    isNotBuyer() {
      return ['AUTORIDADE', 'PREGOEIRO', 'COMPRADOR'].indexOf(this.funcao) === -1;
    },
    exibirTabela() {
      return this.dispensas.length;
    },
    permissaoOperarDispensa() {
      return this.usuario.empresas.some(
        empresaUsuario => empresaUsuario.id === this.company.id && empresaUsuario.operar_dispensas,
      );
    },
    exibirOpcoes() {
      return (
        this.logged &&
        this.company.buyer &&
        this.dispensas.some(dispensa => dispensa.situacao.id === 1)
      );
    },
    pageLoading() {
      return this.loading.edit || this.carregando;
    },
  },
  filters: {
    formatarData,
    formatarDataSemHorario,
    formatarNumero,
  },
  methods: {
    ...mapActions([
      'novaDispensa',
      'excluirDispensa',
      'salvarDispensa',
      'revogarDispensa',
      'buscarAnexosDispensa',
      'filtrarDispensas',
      'alterarPorPaginaDispensas',
    ]),
    ...mapActions('prefectures', ['fetchPrefecturesCities']),
    formatarTipoParticipacao(participacao) {
      if (participacao === 'AMPLA') return 'Ampla';
      return 'Exclusiva de ME/EPP/MEI/Cooperativa c/ Preferência';
    },
    nomeSituacao(row) {
      const situacao = row.situacao.nome;
      if (situacao === 'Encerrada com Vencedor') return 'Encerrada';
      return situacao;
    },
    formataEnderecoEntrega(row) {
      if (row.endereco_entrega_complemento) {
        return `${row.endereco_entrega}, ${row.endereco_entrega_numero} - ${row.endereco_entrega_complemento} - ${row.endereco_entrega_bairro} - ${row.endereco_entrega_municipio}-${row.endereco_entrega_uf}`;
      }
      return `${row.endereco_entrega}, ${row.endereco_entrega_numero} - ${row.endereco_entrega_bairro} - ${row.endereco_entrega_municipio}-${row.endereco_entrega_uf}`;
    },
    exclusiva(tipoParticipacao) {
      return tipoParticipacao === 'EXCLUSIVA';
    },
    async baixarEdital(id) {
      this.loading.notice = true;
      try {
        await this.buscarAnexosDispensa(id);
        const edital = this.obterAnexos.find(anexo => anexo.tipo === 'EDITAL');
        if (edital) window.location = fileServer(edital.arquivo);
      } catch (error) {
        this.$alerta('Erro ao buscar edital', 'is-danger');
      } finally {
        this.loading.notice = false;
      }
    },
    async criarDispensa() {
      this.loading.criarDispensa = true;
      const id = await this.novaDispensa();
      if (id) {
        await this.$router.push({ name: 'DispensaEditar', params: { id } });
      } else {
        this.$alerta('Erro ao criar dispensa', 'is-danger');
      }
      this.loading.criarDispensa = false;
    },
    async editarDispensa(id) {
      this.loading.edit = true;
      await this.$router.push({ name: 'DispensaEditar', params: { id } });
      this.loading.edit = false;
    },
    async excluir(id) {
      const confirmacao = await this.$confirmacao({
        message: 'Tem certeza que deseja excluir a dispensa?',
      });
      if (confirmacao) {
        this.loading.delete = true;
        try {
          await this.excluirDispensa(id);
        } catch (error) {
          this.$alerta('Erro ao excluir dispensa', 'is-danger');
        } finally {
          this.loading.delete = false;
        }
      }
    },
  },
  async created() {
    try {
      await Promise.all([
        this.filtrarDispensas(),
        this.isNotBuyer && !this.prefecturesCities.length
          ? this.fetchPrefecturesCities()
          : Promise.resolve(),
      ]);
    } catch (error) {
      this.$alerta('Erro ao buscar dispensas', 'is-danger');
    }
  },
};
</script>

<style lang="scss" scoped>
.situation-column {
  min-width: 150px;
}
</style>

<style lang="scss">
@media (max-width: 800px) {
  .dispensations-title {
    margin-bottom: 1rem;
    text-align: center !important;
  }
}

.buyer-column {
  min-width: 150px;
}

.custom-icon {
  vertical-align: text-bottom;
}

#dispensations-list-options .field-body .field {
  justify-content: center;
}

.dispensations-table {
  font-size: 0.96rem;
}

.empty-table {
  justify-content: center !important;
}

.field.is-grouped .field {
  flex-shrink: 1;
}

.last-view-column {
  min-width: 145px;
}

.last-view-icon {
  vertical-align: text-bottom;
}

.items-table {
  background-color: #fafafa;
}

.modal-card-body .media .media-content .field .control .input {
  text-transform: uppercase;
}
</style>
