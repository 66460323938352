import { getAnexos as dadosAnexos, criarAnexos, deletarAnexo } from '@/resources/ofertasCompra';

const ANEXOS = 'ANEXOS';
const ANEXOS_ADICIONAR = 'ANEXOS_ADICIONAR';
const ANEXOS_LIMPAR = 'ANEXOS_LIMPAR';
const ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD = 'ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD';
const ANEXOS_ZERAR_PROGRESSO_UPLOAD = 'ANEXOS_ZERAR_PROGRESSO_UPLOAD';
const ANEXO_REMOVER = 'ANEXO_REMOVER';

const mutations = {
  [ANEXOS](state, data) {
    if (data && Array.isArray(data)) {
      state.anexos = data;
    }
  },
  [ANEXOS_ADICIONAR](state, data) {
    if (data.tipo === 'EDITAL') {
      const indice = state.anexos.findIndex(anexo => anexo.tipo === 'EDITAL');
      if (indice > -1) {
        state.anexos.splice(indice, 1, data);
      } else {
        state.anexos = [...state.anexos, data];
      }
    } else {
      state.anexos = [...state.anexos, data];
    }
  },
  [ANEXO_REMOVER](state, id) {
    const index = state.anexos.findIndex(anexo => anexo.id === id);
    if (index > -1) {
      state.anexos.splice(index, 1);
    }
  },
  [ANEXOS_LIMPAR](state) {
    state.anexos = [];
  },
  [ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD](state, progresso) {
    state.anexosUpload = Math.round((progresso.loaded / progresso.total) * 100);
  },
  [ANEXOS_ZERAR_PROGRESSO_UPLOAD](state) {
    state.anexosUpload = 0;
  },
};

const actions = {
  async buscarOfertaCompraAnexos({ commit }, ofertaCompraId) {
    const response = await dadosAnexos(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(ANEXOS, response.data.data);
  },
  async adicionarOfertaCompraAnexos({ commit, rootState }, dados) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await criarAnexos(ofertaCompraId, dados, {
      onUploadProgress: progress => commit(ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD, progress),
    });
    if (response.status !== 201) return Promise.reject(response);
    return commit(ANEXOS_ADICIONAR, response.data.data);
  },
  validarOfertaCompraAnexos({ state }) {
    return state.anexos.length && state.anexos.some(anexo => anexo.tipo === 'EDITAL');
  },
  limparAnexosPregao({ commit }) {
    commit(ANEXOS_LIMPAR);
  },
  async removerOfertaCompraAnexo({ commit, rootState }, id) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const anexo = await deletarAnexo(ofertaCompraId, id);
    if (anexo.status === 204) commit(ANEXO_REMOVER, id);
  },
  zerarAnexosUploadOfertaCompra({ commit }) {
    commit(ANEXOS_ZERAR_PROGRESSO_UPLOAD);
  },
};

const getters = {
  obterOfertaCompraAnexos(state) {
    return state.anexos;
  },
  obterAnexosUploadOfertaCompra(state) {
    return state.anexosUpload;
  },
};

const state = {
  anexos: [],
  anexosUpload: 0,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
