<template>
  <div>
    <h1 class="title is-4">Potênciais Fornecedores</h1>
    <div class="box">
      <nav class="level">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">ME</p>
            <p class="title">390</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">EPP</p>
            <p class="title">346</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Outros</p>
            <p class="title">3347</p>
          </div>
        </div>
      </nav>
    </div>

    <hr />

    <h1 class="title is-4">Fornecedores</h1>
    <b-table
      class="table is-fullwidth"
      :data="fornecedores"
      paginated
      detailed
      :mobile-cards="false"
    >
      <b-table-column centered label="Oferta de Compra" v-slot="props">
        <router-link :to="{ name: 'Pregao', params: { id: props.row.fornecedor.nome } }">
          {{ props.row.fornecedor.nome }}
        </router-link>
      </b-table-column>

      <b-table-column centered numeric label="Quantidade" v-slot="props">
        {{ props.row.quantidade }}
      </b-table-column>

      <b-table-column centered label="Cidade" v-slot="props">
        {{ props.row.cidade }}
      </b-table-column>

      <template v-slot:detail="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img src="static/img/placeholder-128x128.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.first_name }} {{ props.row.last_name }}</strong>
                <small>@{{ props.row.first_name }}</small>
                <small>31m</small>
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare magna eros, eu
                pellentesque tortor vestibulum ut. Maecenas non massa sem. Etiam finibus odio quis
                feugiat facilisis.
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ItemFornecedores',
  data() {
    const fornecedores = [
      {
        id: 1,
        fornecedor: { id: 1, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 2,
        fornecedor: { id: 2, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 3,
        fornecedor: { id: 3, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 4,
        fornecedor: { id: 4, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 5,
        fornecedor: { id: 5, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 6,
        fornecedor: { id: 6, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 7,
        fornecedor: { id: 7, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 8,
        fornecedor: { id: 8, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 9,
        fornecedor: { id: 9, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 10,
        fornecedor: { id: 10, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 11,
        fornecedor: { id: 11, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 12,
        fornecedor: { id: 12, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 13,
        fornecedor: { id: 13, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 14,
        fornecedor: { id: 14, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 15,
        fornecedor: { id: 15, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 16,
        fornecedor: { id: 16, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 17,
        fornecedor: { id: 17, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 18,
        fornecedor: { id: 18, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 19,
        fornecedor: { id: 19, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 20,
        fornecedor: { id: 20, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 21,
        fornecedor: { id: 21, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 22,
        fornecedor: { id: 22, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 23,
        fornecedor: { id: 23, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 24,
        fornecedor: { id: 24, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 25,
        fornecedor: { id: 25, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 26,
        fornecedor: { id: 26, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 27,
        fornecedor: { id: 27, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 28,
        fornecedor: { id: 28, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 29,
        fornecedor: { id: 29, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 30,
        fornecedor: { id: 30, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 31,
        fornecedor: { id: 31, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 32,
        fornecedor: { id: 32, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 33,
        fornecedor: { id: 33, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 34,
        fornecedor: { id: 34, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 35,
        fornecedor: { id: 35, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 36,
        fornecedor: { id: 36, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 37,
        fornecedor: { id: 37, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 38,
        fornecedor: { id: 38, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 39,
        fornecedor: { id: 39, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 40,
        fornecedor: { id: 40, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 41,
        fornecedor: { id: 41, nome: 'Assembléia Legislativa' },
        quantidade: 13,
        cidade: 'Edital Publicado',
      },
      {
        id: 42,
        fornecedor: { id: 42, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Edital Publicado',
      },
      {
        id: 43,
        fornecedor: { id: 43, nome: 'Assembléia Legislativa' },
        quantidade: 6,
        cidade: 'Encerrado com Vencedor',
      },
      {
        id: 44,
        fornecedor: { id: 44, nome: 'Assembléia Legislativa' },
        quantidade: 10,
        cidade: 'Edital Publicado',
      },
      {
        id: 45,
        fornecedor: { id: 45, nome: 'Assembléia Legislativa' },
        quantidade: 14,
        cidade: 'Encerrado com Vencedor',
      },
    ];

    return {
      fornecedores,
    };
  },
  methods: {
    formatarData(data) {
      return `${new Date(data).toLocaleDateString()} ${new Date(data).toLocaleTimeString()}`;
    },
  },
};
</script>
