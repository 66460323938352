import {
  getRecursos as dadosRecursos,
  criarRecurso,
  atualizarRecurso,
} from '@/resources/ofertasCompra';

const types = {
  RECURSOS: 'RECURSOS',
  NOVO_RECURSO: 'NOVO_RECURSO',
  RESPOSTA_RECURSO: 'RESPOSTA_RECURSO',
};

const mutations = {
  [types.RECURSOS](state, recursos) {
    if (recursos && Array.isArray(recursos)) {
      state.recursos = recursos;
      state.recursos.forEach(recurso => {
        recurso.criado = new Date(recurso.criado); // eslint-disable-line
        if (recurso.resposta.data) {
          recurso.resposta.data = new Date(recurso.resposta.data); // eslint-disable-line
        }
      });
    }
  },
  [types.NOVO_RECURSO](state, recurso) {
    if (recurso && typeof recurso === 'object') {
      const novoRecurso = recurso;
      novoRecurso.criado = new Date(novoRecurso.criado);
      state.recursos.unshift(recurso);
    }
  },
  [types.RESPOSTA_RECURSO](state, recurso) {
    if (recurso && typeof recurso === 'object') {
      const indice = state.recursos.findIndex(elemento => elemento.id === recurso.id);
      if (indice > -1) {
        state.recursos.splice(indice, 1, recurso);
      }
    }
  },
};

const actions = {
  async buscarOfertaCompraRecursos({ commit }, ofertaCompraId) {
    const recursos = await dadosRecursos(ofertaCompraId);
    commit(types.RECURSOS, recursos.data.data);
  },
  async salvarOfertaCompraRecurso({ commit, rootState }, dados) {
    const empresa = rootState.users.company;
    if (empresa.id) {
      dados.empresaId = empresa.id; // eslint-disable-line
    }
    const response = await criarRecurso(rootState.ofertasCompra.ofertaCompra.id, dados);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.NOVO_RECURSO, response.data.data);
  },
  async responderOfertaCompraRecurso({ commit, rootState }, { id, dados }) {
    const response = await atualizarRecurso(rootState.ofertasCompra.ofertaCompra.id, id, dados);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.RESPOSTA_RECURSO, response.data.data);
  },
  atualizarRecursos({ commit, rootState }, data) {
    if (
      typeof data === 'object' &&
      data.resposta.justificativa === null &&
      data.empresaId !== rootState.companies.company.id
    ) {
      commit(types.NOVO_RECURSO, data);
    } else if (
      typeof data === 'object' &&
      (data.status === 'ADMISSIVEL' || data.status === 'INADMISSIVEL') &&
      (data.empresaId !== rootState.companies.company.id ||
        (data.empresaId === rootState.companies.company.id && data.resposta.justificativa !== null))
    ) {
      commit(types.RESPOSTA_RECURSO, data);
    }
  },
};

const getters = {
  obterOfertaCompraRecursos(state) {
    return state.recursos;
  },
};

const state = {
  recursos: [],
};

export default {
  state,
  mutations,
  getters,
  actions,
};

export { types };
