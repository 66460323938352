<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <form>
      <b-field grouped class="column-direction-touch">
        <b-field
          label="Tipo de Arquivo"
          :type="campoInvalido('tipo') ? 'is-danger' : ''"
          :message="campoInvalido('tipo') ? 'Campo obrigatório' : ''"
        >
          <b-select
            expanded
            placeholder="Documento"
            v-model="dados.tipo"
            @click.native="$v.dados.tipo.$touch()"
          >
            <option v-for="dados in tiposDocumentos" :key="dados.id" :value="dados.id">
              {{ dados.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field expanded>
          <template v-slot:label>
            Observações
            <b-tooltip type="is-primary" label="O conteúdo será exibido com a formatação inserida">
              <b-icon size="is-small" icon="help-circle"></b-icon>
            </b-tooltip>
          </template>
          <b-input v-model="dados.observacao" maxlength="1000" type="textarea"></b-input>
        </b-field>

        <b-field class="auction-attachment-upload-field">
          <div class="field">
            <b-upload
              v-model="dados.arquivo"
              @input="
                {
                  zerarAnexosUploadOfertaCompra(), inserirAnexos();
                }
              "
            >
              <a class="button is-primary is-fullwidth">
                <b-icon icon="upload" />
                <span>Carregar Arquivo</span>
              </a>
            </b-upload>
          </div>
        </b-field>
      </b-field>
    </form>

    <div>
      <b-progress
        show-value
        class="progress-upload"
        format="percent"
        type="is-info"
        size="is-medium"
        :value="progressoUpload"
      ></b-progress>
    </div>

    <hr />

    <b-table hoverable paginated per-page="8" v-if="anexos.length" :data="anexos">
      <b-table-column label="Tipo" v-slot="props">
        <b-tooltip
          :label="props.row.tipo === 'EDITAL' ? 'Edital' : 'Anexo'"
          :position="windowWidth < 769 ? 'is-left' : 'is-right'"
        >
          <b-icon
            type="is-primary"
            :icon="props.row.tipo === 'EDITAL' ? 'alpha-e-box' : 'alpha-a-box'"
          ></b-icon>
        </b-tooltip>
      </b-table-column>

      <b-table-column label="Nome" v-slot="props">
        <a :href="props.row.arquivo | fileServer" download>{{ props.row.nome }}</a>
        <b-tag class="ml-2" v-if="props.row.retificacao" type="is-info">Retificado</b-tag>
      </b-table-column>

      <b-table-column centered label="Tamanho" v-slot="props">{{
        props.row.tamanho | tamanhoArquivo
      }}</b-table-column>

      <b-table-column
        expanded
        label="Observações"
        v-slot="props"
        :visible="anexos.some(anexo => anexo.observacao)"
      >
        <pre v-if="props.row.observacao">{{ props.row.observacao }}</pre>
      </b-table-column>

      <b-table-column centered label="Opções" v-slot="props">
        <b-tooltip label="Excluir" position="is-right">
          <button
            class="button is-danger"
            :disabled="desabilitar.delete"
            @click.prevent.stop="excluirAnexo(props.row.id)"
          >
            <b-icon icon="delete" />
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { required } from 'vuelidate/lib/validators';

import { fileServer } from '@/utils/functions';
import { tamanhoArquivo } from '@/utils/format';
import { uploadLimit } from '@/utils/data';

import campo from '@/mixins/formulario';

export default {
  name: 'PregaoEditarAnexos',
  mixins: [campo],
  data() {
    const dados = {
      tipo: '',
      retificacao: false,
      observacao: null,
      arquivo: [],
    };
    const tiposDocumentos = [
      { id: 'EDITAL', nome: 'Edital' },
      { id: 'ANEXO', nome: 'Anexo' },
    ];

    const loading = {
      general: false,
    };

    const desabilitar = {
      delete: false,
    };

    return {
      dados,
      tiposDocumentos,
      loading,
      desabilitar,
    };
  },
  validations: {
    dados: {
      tipo: { required },
      arquivo: { required },
    },
  },
  filters: {
    tamanhoArquivo,
    fileServer,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters({
      anexos: 'obterOfertaCompraAnexos',
      ofertaCompra: 'obterOfertaCompra',
      progressoUpload: 'obterAnexosUploadOfertaCompra',
    }),
  },
  methods: {
    ...mapActions([
      'adicionarOfertaCompraAnexos',
      'removerOfertaCompraAnexo',
      'buscarOfertaCompra',
      'buscarOfertaCompraAnexos',
      'atualizarOfertaCompraEdital',
      'alternarEmPublicacaoPregao',
      'zerarAnexosUploadOfertaCompra',
    ]),
    async inserirAnexos() {
      if (!this.validar()) {
        this.dados.arquivo = [];
        return false;
      }

      if (this.dados.arquivo.size > uploadLimit) {
        this.$alerta('Insira um arquivo com até 50MB', 'is-danger');
        return false;
      }

      if (this.dados.tipo === 'EDITAL') {
        const existe = this.anexos.find(elemento => elemento.tipo === 'EDITAL');
        if (existe) {
          const retificar = await this.$confirmacao({
            message: 'Tem certeza que deseja retificar o Edital?',
            cancelText: 'Cancelar',
            confirmText: 'Continuar',
            type: 'is-danger',
            hasIcon: true,
          });
          if (!retificar) return false;

          this.dados.retificacao = true;
          return this.enviarAnexo('Edital retificado', 'Erro ao retificar edital');
        }
        return this.enviarAnexo('Edital enviado', 'Erro ao enviar edital');
      }
      return this.enviarAnexo('Anexo enviado', 'Erro ao enviar anexo');
    },
    async enviarAnexo(sucesso, erro) {
      const dados = new FormData();
      dados.append('tipo', this.dados.tipo);
      dados.append('retificacao', this.dados.retificacao);
      if (this.dados.observacao) {
        dados.append('observacao', this.dados.observacao);
      }
      dados.append('file', this.dados.arquivo);

      this.loading.general = true;
      try {
        await this.adicionarOfertaCompraAnexos(dados);
        this.$alerta(sucesso, 'is-success');
      } catch (error) {
        this.$alerta(erro, 'is-danger');
      }
      this.limparCampos();
      this.loading.general = false;
    },
    async excluirAnexo(anexo) {
      this.desabilitar.delete = true;
      try {
        await this.removerOfertaCompraAnexo(anexo);
        this.$alerta('Documento removido', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao remover o documento', 'is-danger');
      }
      this.desabilitar.delete = false;
    },
    limparCampos() {
      this.dados.tipo = '';
      this.dados.retificacao = false;
      this.dados.observacao = '';
      this.dados.arquivo = [];
      this.$v.dados.$reset();
    },
  },
  mounted() {
    this.zerarAnexosUploadOfertaCompra();
  },
  async created() {
    this.loading.general = true;
    try {
      await Promise.all([
        this.buscarOfertaCompraAnexos(this.$route.params.id),
        !this.ofertaCompra.id ? this.buscarOfertaCompra(this.$route.params.id) : Promise.resolve(),
      ]);
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
  beforeDestroy() {
    this.alternarEmPublicacaoPregao(false);
  },
};
</script>

<style lang="scss" scoped>
pre {
  background-color: #ededed;
  font-family: unset;
  word-break: break-all;
  white-space: pre-wrap;
}

.progress-upload {
  margin-top: 0.5rem;
}

@media (min-width: 1024px) {
  .auction-attachment-upload-field {
    margin-top: 31px;
  }
}

@media (max-width: 1023px) {
  .auction-attachment-upload-field {
    margin-top: 0.75rem;

    .field .upload {
      width: 100%;
    }
  }

  .progress-upload {
    margin-top: 1.5rem;
  }
}
</style>
