<template>
  <div class="component-container">
    <article v-if="!displaySuccessMessage" class="panel is-primary custom-panel">
      <div class="panel-heading custom-panel-heading">
        <h1 class="title custom-title">DEMONSTRAÇÃO GRATUITA</h1>

        <h2 class="subtitle custom-subtitle">
          Preencha seus dados e entraremos em contato com você.
        </h2>
      </div>

      <div class="panel-block custom-panel-content">
        <form class="custom-form" @keyup.enter="sendData">
          <b-field
            label="Nome do Órgão"
            :type="campoInvalido('name', 'data') ? 'is-danger' : ''"
            :message="campoInvalido('name', 'data') ? 'Nome do Órgão é um campo obrigatório' : ''"
          >
            <b-input placeholder="Nome do Órgão" v-model="$v.data.name.$model" />
          </b-field>

          <b-field
            label="Departamento"
            :type="campoInvalido('department', 'data') ? 'is-danger' : ''"
            :message="
              campoInvalido('department', 'data') ? 'Departamento é um campo obrigatório' : ''
            "
          >
            <b-input placeholder="Departamento" v-model="$v.data.department.$model" />
          </b-field>

          <b-field
            label="Responsável"
            :type="campoInvalido('responsible', 'data') ? 'is-danger' : ''"
            :message="
              campoInvalido('responsible', 'data') ? 'Responsável é um campo obrigatório' : ''
            "
          >
            <b-input placeholder="Responsável" v-model="$v.data.responsible.$model" />
          </b-field>

          <b-field
            label="E-mail"
            :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
            :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
          >
            <b-input
              placeholder="E-mail"
              v-model="$v.data.email.$model"
              @input="data.email = data.email.toLowerCase()"
            />
          </b-field>

          <b-field
            label="Número de Contato"
            :type="campoInvalido('contactNumber', 'data') ? 'is-danger' : ''"
            :message="
              campoInvalido('contactNumber', 'data')
                ? 'Número de contato é um campo obrigatório'
                : ''
            "
          >
            <b-input
              v-model="$v.data.contactNumber.$model"
              placeholder="Número de Contato"
              maxlength="13"
              v-mascara:telefone
              :has-counter="false"
              @keypress.native="teclasPermitidasNumero($event)"
            />
          </b-field>

          <b-field class="custom-button-field">
            <b-button
              expanded
              class="custom-button"
              type="is-primary"
              :loading="loading"
              @click.prevent.stop="saveData"
              >Enviar</b-button
            >
          </b-field>
        </form>
      </div>
    </article>

    <div v-if="displaySuccessMessage" class="success-content">
      <b-icon type="is-success" icon="check-circle-outline" size="is-large"></b-icon>
      <div class="message-title">
        <h1 class="title is-3 is-spaced">
          Dados enviados com sucesso!
        </h1>

        <h3 class="subtitle">
          Um de nossos consultores entrará em contato em breve.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';

import mixinField from '@/mixins/formulario';

export default {
  name: 'PreRegistration',
  mixins: [mixinField],
  data() {
    const data = {
      name: '',
      contactNumber: '',
      department: '',
      email: '',
      responsible: '',
    };

    return {
      data,
      displaySuccessMessage: false,
      loading: false,
    };
  },
  validations: {
    data: {
      name: { required },
      contactNumber: { required },
      department: { required },
      email: { email, required },
      responsible: { required },
    },
  },
  methods: {
    ...mapActions('companies', ['createInterestedCompany']),
    async saveData() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        return;
      }

      this.loading = true;
      try {
        await this.createInterestedCompany(this.data);
        this.displaySuccessMessage = true;
        this.$v.data.$reset();
      } catch (error) {
        this.$alerta('Erro ao enviar dados', 'is-danger');
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Gotham Bold';
  src: url('~@/assets/fonts/gotham/gotham-htf-bold.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('~@/assets/fonts/gotham/gotham-htf-medium.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

.component-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.custom-button {
  font-size: 1.2rem;
  font-weight: 600;
}

.custom-button-field {
  margin-top: 2rem;
}

.custom-form {
  width: 100%;
}

.custom-panel {
  border: 1px solid #dbdbdb;
  margin: 2rem auto;
  width: 50%;
}

.custom-panel-content {
  padding: 1.5rem;
}

.custom-panel-heading {
  text-align: center;
}

.custom-subtitle {
  color: white;
  font-family: 'Gotham Medium';
}

.custom-title {
  color: white;
  font-family: 'Gotham Bold';
}

.success-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% auto;
  text-align: center;
  width: 50%;
}

@media (max-width: 768px) {
  .custom-panel {
    width: 80%;
  }

  .custom-title {
    font-size: 1.6rem;
  }

  .custom-subtitle {
    font-size: 1.4rem;
  }

  .success-content {
    width: 80%;
  }
}
</style>
