import { api } from './http';

export const stats = () => api.get('/dispensas/stats');

export const todas = filters => {
  let params = '?';
  if (filters) {
    if (filters.buyerId) {
      params += `cidade=${filters.buyerId}&`;
    }
    if (filters.situation) params += `situacao=${filters.situation}&`;
    if (filters.period) {
      if (filters.period.start) {
        params += `inicio=${filters.period.start.toISOString()}&`;
      }
      if (filters.period.end) {
        params += `fim=${filters.period.end.toISOString()}&`;
      }
    }
    if (filters.number) params += `numero=${filters.number}&`;
    if (filters.indexed) {
      params += `cadastradas=${filters.indexed}&`;
    }
    if (filters.page) params += `pagina=${filters.page}&`;
    if (filters.perPage) params += `por_pagina=${filters.perPage}&`;
  }
  return api.get(`/dispensas${params}`);
};
export const get = dispensaId => api.get(`/dispensas/${dispensaId}`);
export const criar = () => api.post('/dispensas');
export const editDispensation = (dispensationId, data) =>
  api.patch(`/dispensas/${dispensationId}`, data);
export const revogarDispensa = (dispensaId, dados) =>
  api.patch(`/dispensas/${dispensaId}/revogar`, dados);
export const deletar = dispensaId => api.delete(`/dispensas/${dispensaId}`);

export const getPrazos = dispensaId => api.get(`/dispensas/${dispensaId}/prazos`);

export const getItens = dispensaId => api.get(`/dispensas/${dispensaId}/itens`);
export const getItem = (dispensaId, dispensaItemId) =>
  api.get(`/dispensas/${dispensaId}/itens/${dispensaItemId}`);
export const criarItem = (dispensaId, dados) => api.post(`/dispensas/${dispensaId}/itens`, dados);
export const atualizarItem = (dispensaId, dispensaItemId, dados) =>
  api.patch(`/dispensas/${dispensaId}/itens/${dispensaItemId}`, dados);
export const revogarItem = (dispensaId, dispensaItemId, dados) =>
  api.patch(`/dispensas/${dispensaId}/itens/${dispensaItemId}/revogar`, dados);
export const deletarItem = (dispensaId, dispensaItemId) =>
  api.delete(`/dispensas/${dispensaId}/itens/${dispensaItemId}`);

export const publishDispensation = dispensationId =>
  api.get(`/dispensas/${dispensationId}/publicar`);
export const openDispensationSession = dispensationId =>
  api.get(`/dispensas/${dispensationId}/abrir-sessao`);

export const getLances = (dispensaId, tipo) =>
  api.get(`/dispensas/${dispensaId}/lances/?filtro=${tipo}`);
export const getLancesItem = (dispensaId, dispensaItemId) =>
  api.get(`/dispensas/${dispensaId}/itens/${dispensaItemId}/lances`);
export const iniciarLance = (dispensaId, lances) =>
  api.post(`/dispensas/${dispensaId}/itens/lances`, lances);
export const enviarLances = (dispensaId, lances) =>
  api.post(`/dispensas/${dispensaId}/lances`, lances);

export const getAnexos = dispensaId => api.get(`/dispensas/${dispensaId}/anexos`);
export const criarAnexos = (
  dispensaId,
  dados,
  { onUploadProgress } = { onUploadProgress: () => {} },
) => api.post(`/dispensas/${dispensaId}/anexos`, dados, { onUploadProgress });
export const deletarAnexo = (dispensaId, id) => api.delete(`/dispensas/${dispensaId}/anexos/${id}`);

export const ratificarVencedorItem = (dispensaId, dispensaItemId, dados) =>
  api.post(`/dispensas/${dispensaId}/itens/${dispensaItemId}/ratificar`, dados);

export const fetchUserLoggedBidder = dispensaId => api.get(`/dispensas/${dispensaId}/bidders/me`);
