import {
  getAuctionQualifications,
  acceptAuctionDocument,
  refuseAuctionDocument,
  qualifyBidder,
  disqualifyBidder,
} from '@/resources/electronicAuctions/auctionsQualifications';

const types = {
  AUCTION_QUALIFICATIONS: 'AUCTION_QUALIFICATIONS',
};

const mutations = {
  [types.AUCTION_QUALIFICATIONS](state, data) {
    if (data && Array.isArray(data)) {
      state.qualifications = data;
    } else {
      state.qualifications.push(data);
    }
  },
};

const actions = {
  async getAuctionQualifications({ commit }, auctionId) {
    const { data: qualifications } = await getAuctionQualifications(auctionId);
    commit(types.AUCTION_QUALIFICATIONS, qualifications.data);
  },
  async acceptAuctionDocument({ commit }, { auctionId, bidderId, documentId }) {
    const response = await acceptAuctionDocument(auctionId, bidderId, documentId);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit('auctionsDocuments/AUCTION_DOCUMENTS_UPDATE', response.data, { root: true });
  },
  async refuseAuctionDocument({ commit }, { auctionId, bidderId, documentId }) {
    const response = await refuseAuctionDocument(auctionId, bidderId, documentId);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit('auctionsDocuments/AUCTION_DOCUMENTS_UPDATE', response.data, { root: true });
  },
  async qualifyBidder({ rootState }, { itemId, bidder, justification }) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await qualifyBidder(auctionId, itemId, {
      fornecedor: bidder,
      justificativa: justification,
    });
    if (response.status !== 200) return Promise.reject(response.data);
    return { status: 200, type: 'QualifiedBidder' };
  },
  async disqualifyBidder({ rootState }, { itemId, bidder, justification }) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await disqualifyBidder(auctionId, itemId, {
      fornecedor: bidder,
      justificativa: justification,
    });
    if (response.status !== 200) return Promise.reject(response.data);
    return { status: 200, type: 'DisqualifiedBidder' };
  },
};

const getters = {};

const state = {
  qualifications: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
