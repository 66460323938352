<template>
  <div class="landing">
    <header class="header">
      <div class="container">
        <div class="header-mob">
          <div
            class="header-hamburger"
            :class="{ '__is-open': menuOpen }"
            @click.prevent.stop="toggleMenu"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div class="header-logo">
          <img :src="logo" alt />
        </div>

        <nav class="header-nav" :class="{ '__is-open': menuOpen }">
          <ul class="header-list">
            <li class="header-item">
              <router-link :to="{ name: 'Start' }" class="header-link">Início</router-link>
            </li>
            <li class="header-item">
              <router-link :to="{ name: 'ItemPesquisa' }" class="header-link">Itens</router-link>
            </li>
            <li class="header-item">
              <router-link :to="{ name: 'Pregoes' }" class="header-link">Pregões</router-link>
            </li>
            <li class="header-item">
              <router-link :to="{ name: 'Dispensas' }" class="header-link">Dispensas</router-link>
            </li>
            <li class="header-item">
              <router-link :to="{ name: 'SobreNos' }" class="header-link">Sobre Nós</router-link>
            </li>
            <li class="header-item" v-if="!logged">
              <router-link :to="{ name: 'PreRegistration' }" class="header-link"
                >Cadastre-se</router-link
              >
            </li>
          </ul>
        </nav>

        <div class="header-account">
          <router-link
            v-if="logged"
            :to="{
              name:
                usuario && usuario.empresas && usuario.empresas.length ? 'Dashboard' : 'Pregoes',
            }"
            class="btn btn-outline btn-profile-outline"
          >
            <div class="profile-button-content">
              <b-icon class="icon" icon="account" custom-size="mdi-18px" size="is-small" />
              Perfil
            </div></router-link
          >

          <button v-else class="btn btn-outline" @click.prevent.stop="logando = true">
            Entrar
          </button>

          <div @click.prevent.stop="minhaConta">
            <b-icon class="mobile-icon" icon="account" both size="is-small" />
          </div>
        </div>
      </div>
    </header>

    <div class="banner">
      <div class="container">
        <div class="banner-content">
          <h1 class="banner-title">
            transparência, ética
            <strong>& imparcialidade</strong>
          </h1>
          <p class="banner-text">
            Reunindo um sistema eletrônico integrado de licitação inédito do Brasil.
          </p>
          <router-link :to="{ name: 'PreRegistration' }" class="btn btn-orange">
            quero conhecer
          </router-link>
        </div>
      </div>
    </div>

    <div class="about">
      <div class="container">
        <div class="about-list">
          <div class="about-item">
            <img :src="licitacao" alt="" class="about-item-image" />
            <h2 class="about-item-title">Licitação</h2>
            <p class="about-item-text">
              Meio administrativo para que empresas e órgãos públicos façam aquisição de bens e
              serviços. Fornecedores oferecem os produtos para o órgão público em uma espécie de
              leilão.
            </p>
          </div>

          <div class="about-item">
            <img :src="licitante" alt="" class="about-item-image" />
            <h2 class="about-item-title">Licitante</h2>
            <p class="about-item-text">
              Quem faz o lance ou oferta de compra pelo preço que indica. Quem quer vender material
              ou serviço para o setor público.
            </p>
          </div>

          <div class="about-item">
            <img :src="pregoeiro" alt="" class="about-item-image" />
            <h2 class="about-item-title">pregoeiro</h2>
            <p class="about-item-text">
              Do lado do órgão público, que é o contratante, está o pregoeiro e equipe de apoio. O
              pregoeiro é responsável pela condução.
            </p>
          </div>
        </div>

        <h3 class="about-title">
          Opere em um sistema supermoderno, prático e confiável para licitações públicas
        </h3>

        <router-link :to="{ name: 'PreRegistration' }" class="btn btn-lightblue">
          quero conhecer
        </router-link>

        <div class="about-arrow"></div>
      </div>
    </div>

    <div class="platform">
      <div class="container">
        <h2 class="platform-title">
          <strong>plataforma segura, intuitiva e eficaz</strong>
          para a execução de onde se dará o leilão
        </h2>

        <div class="platform-content">
          <p class="platform-text">
            Com a SelCorp, o estado ou o município se mostra pioneiro na gestão dos recursos
            públicos, utilizando inteligência tecnológica para otimização de fundos e transparência
            na gestão.
          </p>
          <router-link :to="{ name: 'PreRegistration' }" class="btn btn-white">
            quero conhecer
          </router-link>
        </div>
      </div>
    </div>

    <div ref="contact" class="contact">
      <div class="container">
        <h2 class="contact-title">Fale conosco</h2>
        <p class="contact-text">Teremos o maior prazer em atendê-lo</p>

        <form @keyup.enter="salvarDados" class="contact-form">
          <div class="columns">
            <div class="column">
              <input
                type="text"
                class="contact-form-input"
                :class="{ 'contact-form-input-error': campoInvalido('name') }"
                placeholder="Seu nome"
                v-model="$v.dados.name.$model"
              />
              <span v-if="campoInvalido('name')" class="contact-form-error">Campo obrigatório</span>
            </div>
            <div class="column">
              <input
                type="email"
                class="contact-form-input"
                :class="{ 'contact-form-input-error': campoInvalido('from') }"
                placeholder="Seu e-mail"
                v-model="$v.dados.from.$model"
                @input="login.from = login.from.toLowerCase()"
              />
              <span v-if="campoInvalido('from')" class="contact-form-error">Campo obrigatório</span>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <select
                class="contact-form-select"
                :class="{ 'contact-form-input-error': campoInvalido('subject') }"
                placeholder="Assunto"
                v-model="$v.dados.subject.$model"
              >
                <option v-for="assunto in assuntos" :key="assunto.id" :value="assunto.name">{{
                  assunto.name
                }}</option>
              </select>
              <span v-if="campoInvalido('subject')" class="contact-form-error"
                >Campo obrigatório</span
              >
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <textarea
                class="contact-form-textarea"
                :class="{ 'contact-form-input-error': campoInvalido('message') }"
                placeholder="Digite sua mensagem..."
                v-model="$v.dados.message.$model"
              >
              </textarea>
              <span v-if="campoInvalido('message')" class="contact-form-error"
                >Campo obrigatório</span
              >
            </div>
          </div>

          <button class="btn btn-blue btn-submit" @click.prevent.stop="salvarDados">Enviar</button>
        </form>
      </div>
    </div>

    <footer class="footer">
      <AppFooter class="app-footer" />
    </footer>

    <b-modal has-modal-card :active.sync="logando" @close="handleLogout">
      <login-form v-if="logando" />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';

import campoMixin from '@/mixins/formulario';

import { AppFooter } from '@/components/app';
import LoginForm from '@/views/User/Login';

import { contato } from '@/resources/utils';

import logo from '@/assets/images/logo-sm.svg';
import banner from '@/assets/images/img-banner.png';
import licitacao from '@/assets/images/img-licitacao.svg';
import licitante from '@/assets/images/img-licitante.svg';
import pregoeiro from '@/assets/images/img-pregoeiro.svg';

export default {
  name: 'Inicio',
  mixins: [campoMixin],
  components: {
    AppFooter,
    LoginForm,
  },
  data() {
    const dados = {
      name: '',
      from: '',
      subject: '',
      message: '',
    };
    const assuntos = [
      { id: 1, name: 'Dúvidas' },
      { id: 2, name: 'Críticas' },
      { id: 3, name: 'Parcerias' },
      { id: 4, name: 'Outros' },
    ];

    return {
      logo,
      banner,
      licitacao,
      licitante,
      pregoeiro,
      dados,
      assuntos,
      menuOpen: false,
      logando: false,
    };
  },
  validations: {
    dados: {
      name: { required },
      from: {
        required,
        email,
      },
      subject: { required },
      message: { required },
    },
  },
  beforeRouteLeave(to, from, next) {
    window.scrollTo(0, 0);
    return next();
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      usuario: 'getUser',
      logged: 'getLogged',
    }),
  },
  methods: {
    ...mapActions(['logout']),
    openNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    handleLogout() {
      if (!this.company.id) {
        this.logout();
      }
    },
    minhaConta() {
      if (this.logged) {
        this.$router.push({ name: 'Company', params: { id: this.company.id } });
      } else {
        this.logando = true;
      }
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    async salvarDados() {
      if (this.validar()) {
        try {
          await contato(this.dados);
          this.$alerta('Mensagem de contato enviada', 'is-success');
          this.limparCampos();
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
      }
    },
    scrollTo(section) {
      const element = this.$refs[section === 'contato' ? 'contact' : section];
      const top = element.offsetTop + 70;

      window.scrollTo(0, top);
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.sessao) {
      this.scrollTo(this.$route.query.sessao);
    }
  },
};
</script>

<style lang="scss">
input,
select,
:focus {
  outline: 0;
}
a,
button {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.25s ease-in-out 0s;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('~@/assets/fonts/gotham/gotham-htf-bold.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('~@/assets/fonts/gotham/gotham-htf-book.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Light';
  src: url('~@/assets/fonts/gotham/gotham-htf-light.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Medium';
  src: url('~@/assets/fonts/gotham/gotham-htf-medium.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Thin';
  src: url('~@/assets/fonts/gotham/gotham-htf-thin.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

.btn {
  background-color: transparent;
  display: flex;
  padding: 12px 35px;
  font: 12px 'Gotham Bold';
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;

  @media (min-width: 992px) {
    padding: 18px 44px;
    font-size: 14px;
  }

  &-outline {
    min-width: 119px;
    max-width: 250px;
    padding: 11px 32px;
    font: 12px 'Gotham Medium';
    color: #fff;
    border: 1px solid #fff;
    overflow: hidden;

    &:hover {
      background-color: #fff;
      color: #2798da;
    }
  }

  &-profile-outline {
    padding: 8px 24px 10px;
  }

  &-orange {
    background-color: #ff9400;
    color: #fff;

    &:hover {
      background-color: transparent;
      color: #ff9400;
      border-color: #ff9400;
    }
  }

  &-lightblue {
    background-color: #00bae9;
    color: #fff;

    &:hover {
      background-color: transparent;
      color: #00bae9;
      border-color: #00bae9;
    }
  }

  &-white {
    background-color: #fff;
    color: #000;

    &:hover {
      background-color: transparent;
      color: #fff;
      border-color: #fff;
    }
  }

  &-blue {
    background-color: #2798da;
    color: #fff;

    &:hover {
      background-color: transparent;
      color: #2798da;
      border-color: #2798da;
    }
  }

  &-submit {
    padding: 15px 74px;
    font-size: 15px;
  }
}

.landing {
  font-family: 'Gotham Medium';

  @media (min-width: 992px) {
    background: url('~@/assets/images/firula-header.svg') no-repeat 125% -122px;
  }

  @media (min-width: 1200px) {
    background-position: 111% -82px;
  }

  .container {
    display: flex;
    max-width: 95% !important;
    margin: auto;
    position: relative;

    @media (min-width: 1500px) {
      max-width: 70%;
    }
  }
}

.header {
  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 0;

    @media (min-width: 992px) {
      padding: 45px 0 55px;
    }
  }

  &-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 992px) {
      position: relative;
      padding-right: 50px;
      left: 0;
      transform: translateX(0);
    }

    @media (min-width: 1200px) {
      padding-right: 100px;
    }
  }

  &-hamburger {
    width: 34px;
    height: 34px;
    position: relative;

    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);

    transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;

    cursor: pointer;
    border: 2px solid #000;

    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    z-index: 99;

    @media (min-width: 1200px) {
      width: 44px;
      height: 44px;
    }

    @media (min-width: 992px) {
      display: none;
    }

    span {
      background: #000;
      display: block;
      width: 60%;
      height: 2px;
      position: absolute;
      left: 6px;
      border-radius: 9px;
      opacity: 1;

      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);

      transition: 0.25s ease-in-out;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      -ms-transition: 0.25s ease-in-out;

      @media (min-width: 1200px) {
        left: 8px;
      }

      &:nth-child(1) {
        top: 8px;

        @media (min-width: 1200px) {
          top: 12px;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 14px;

        @media (min-width: 1200px) {
          top: 20px;
        }
      }

      &:nth-child(4) {
        top: 21px;

        @media (min-width: 1200px) {
          top: 28px;
        }
      }
    }

    &.__is-open {
      border-color: #fff;
      position: fixed;
      top: 20px;

      span {
        background-color: #fff;
      }

      span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }

  &-nav {
    display: none;
    flex: 1;

    @media (min-width: 992px) {
      display: block;
    }

    &.__is-open {
      background-color: rgba(0, 0, 0, 0.9);
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 9;

      .header-list {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-top: 70px;
        height: 70%;
      }

      .header-link {
        color: #fff;
        font-size: 18px;
      }

      .header-item {
        padding: 0;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-item {
    @media (min-width: 992px) {
      padding: 0 15px;
    }

    @media (min-width: 1200px) {
      padding: 0 20px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &-link {
    font: 14px 'Gotham Medium';
    color: #000;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      color: #2798da;
    }
  }

  &-account {
    @media (min-width: 992px) {
      padding-left: 80px;
    }

    @media (min-width: 1200px) {
      padding-left: 200px;
    }

    .btn {
      display: none;

      @media (min-width: 992px) {
        display: block;
      }
    }

    .icon {
      margin-right: 2px;
      vertical-align: bottom;
    }

    .mobile-icon {
      display: flex;
      width: 34px;
      height: 34px;
      color: #000;
      border: 1.5px solid #000;
      border-radius: 50%;

      @media (min-width: 992px) {
        display: none;
      }
    }

    .profile-button-content {
      margin-bottom: 1px;
    }
  }
}

.banner {
  background-image: url('~@/assets/images/img-banner.png');
  background-position: 145% 80%;
  background-repeat: no-repeat;
  background-size: 60%;
  padding-bottom: 100px;

  @media (min-width: 768px) {
    padding-bottom: 0;
    background-size: contain;
    background-position: 100% 80%;
  }

  @media (min-width: 992px) {
    background-image: url('~@/assets/images/firula-banner.svg'),
      url('~@/assets/images/img-banner.png');
    background-position: left bottom, 120% 60%;
    background-size: 60%;
    height: 580px;
  }

  @media (min-width: 1200px) {
    background-position: left bottom, 90% top;
    height: 605px;
    background-size: auto;
  }

  .container {
    max-width: 90% !important;

    @media (min-width: 768px) {
      max-width: 95% !important;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-title {
    margin-top: 40px;
    margin-bottom: 15px;
    font: 25px 'Gotham Medium';
    color: #000;
    text-transform: uppercase;
    letter-spacing: 0.82px;

    @media (min-width: 992px) {
      font-size: 41px;
    }

    strong {
      display: block;
      padding-top: 9px;
      font: 32px 'Gotham Light';

      @media (min-width: 992px) {
        font-size: 53px;
      }
    }
  }

  &-text {
    width: 80%;
    margin-bottom: 35px;
    font: 16px/30px 'Gotham Light';
    color: #000;
    letter-spacing: 0.4px;

    @media (min-width: 768px) {
      width: 60%;
    }

    @media (min-width: 992px) {
      width: 80%;
      font-size: 20px;
    }
  }
}

.about {
  padding: 10px 0 0;

  @media (min-width: 768px) {
    padding: 70px 0 0;
  }

  @media (min-width: 992px) {
    padding: 100px 0 0;
  }

  .container {
    flex-direction: column;
    align-items: center;
  }

  &-title {
    width: 80%;
    padding-top: 30px;
    margin: 0 auto 30px;
    font: 18px/1.5 'Gotham Book';
    color: #000;
    text-align: center;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    position: relative;

    @media (min-width: 992px) {
      width: 65%;
      padding-top: 45px;
      margin: 0 auto 45px;
      font-size: 28px;
    }

    &:after {
      content: '';
      background-color: #00bae9;
      display: block;
      width: 120px;
      height: 5px;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-arrow {
    width: 100%;
    margin-top: 65px;
    padding-top: 28px;
    position: relative;

    &:before,
    &:after {
      content: '';
      height: 0;
      width: 0;
      border: solid transparent;
      position: absolute;
      top: 0;
      left: 50%;
      pointer-events: none;
    }

    &:before {
      margin-left: -26px;
      border-color: rgba(0, 0, 0, 0);
      border-top-color: #000;
      border-width: 26px;
    }

    &:after {
      margin-left: -25px;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 25px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 28%;
    margin-bottom: 60px;
    padding: 0 25px;

    @media (min-width: 768px) {
      padding: 0;
    }

    &-image {
      @media (min-width: 768px) {
        width: 60%;
      }
    }

    &-title {
      margin-bottom: 25px;
      font: 35px/1.5 'Gotham Light';
      letter-spacing: 0.53px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: 28px;
      }
    }

    &-text {
      font: 14px/2 'Gotham Book';
      color: #707070;
      text-align: center;

      @media (min-width: 768px) {
      }
    }
  }
}

.platform {
  background: transparent linear-gradient(180deg, #319cda 0%, #00296a 100%) 0% 0% no-repeat;
  padding-top: 100px;
  height: 580px;
  position: relative;
  overflow: hidden;

  @media (min-width: 992px) {
    padding-top: 150px;
    height: 740px;
  }

  &:before {
    content: '';
    background-color: #fff;
    display: block;
    width: 1578px;
    height: 1244px;
    border-radius: 50%;
    position: absolute;
    top: -203%;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 992px) {
      top: -160%;
    }
  }

  .container {
    flex-direction: column;
  }

  &-title {
    margin-bottom: 40px;
    font: 20px 'Gotham Light';
    color: #fff;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 992px) {
      margin-bottom: 70px;
      font-size: 40px;
    }

    strong {
      display: block;
      margin-bottom: 10px;
      font: 21px 'Gotham Medium';
      color: #fff;
      letter-spacing: 0.82px;

      @media (min-width: 992px) {
        font-size: 41px;
      }
    }
  }

  &-text {
    margin-bottom: 40px;
    font: 14px/2 'Gotham Book';
    color: #fff;

    @media (min-width: 768px) {
      margin-top: 40px;
    }

    @media (min-width: 992px) {
      margin-top: 0;
      font-size: 20px;
    }
  }

  &-content {
    background-image: url('~@/assets/images/img-plataforma.png'), url('~@/assets/images/shadow.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    width: 90%;
    height: 309px;
    margin: 0 auto;

    @media (min-width: 768px) {
      background-size: auto;
      padding-left: 56%;
    }

    @media (min-width: 992px) {
      background-position: 77% center;
      padding-left: 44%;
    }

    @media (min-width: 1200px) {
      background-position: center bottom;
      padding-left: 50%;
    }
  }
}

.contact {
  background-color: #f2f2f2;
  padding: 40px 0 30px;

  @media (min-width: 992px) {
    padding: 95px 0 60px;
  }

  .container {
    flex-direction: column;
  }

  &-title {
    margin-bottom: 15px;
    font: 21px/1.5 'Gotham Medium';
    color: #000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.82px;

    @media (min-width: 992px) {
      font-size: 41px;
    }
  }

  &-text {
    margin-bottom: 40px;
    font: 12px/1.5 'Gotham Light';
    color: #000;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.62px;

    @media (min-width: 992px) {
      margin-bottom: 70px;
      font-size: 31px;
    }
  }

  &-form {
    width: 85%;
    margin: 0 auto;

    @media (min-width: 992px) {
      width: 770px;
    }

    &-error {
      padding: 0 10px;
      font: 10px 'Gotham Medium';
      color: #f14668;
    }

    &-input,
    &-select,
    &-textarea {
      background-color: #fff;
      width: 100%;
      padding: 25px 30px;
      font: 14px 'Gotham Book';
      color: rgba(0, 0, 0, 0.5);
      border: 1px solid transparent;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
      border-radius: 5px;
    }

    &-input {
      &-error {
        border-color: #f14668;
      }
    }

    &-select {
      background: #fff url('~@/assets/images/ico-angle-down.svg') no-repeat 95% center;
    }

    &-textarea {
      height: 170px;
    }

    .btn {
      float: right;
    }
  }
}

.footer {
  background-color: #f2f2f2 !important;
  padding: 0 !important;

  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 22px 0 30px;
    border-top: 1px solid rgba(204, 204, 204, 0.5);
  }

  &-logo {
    max-width: 127px;
    margin-bottom: 10px;
  }

  &-text {
    font: 12px 'Gotham Book';
    color: #000;
    text-align: center;
  }
}
</style>

<style lang="scss" scoped>
.app-footer {
  flex-shrink: 0;
}
</style>
