import {
  getAuctionImpugnations,
  createAuctionImpugnation,
  answerAuctionImpugnation,
} from '@/resources/electronicAuctions/auctionsImpugnations';

const types = {
  IMPUGNATION_RESPONSE: 'IMPUGNATION_RESPONSE',
  IMPUGNATION_UPDATE: 'IMPUGNATION_UPDATE',
  IMPUGNATIONS: 'IMPUGNATIONS',
};

const mutations = {
  [types.IMPUGNATIONS](state, impugnations) {
    if (impugnations && Array.isArray(impugnations)) {
      const updatedImpugnations = impugnations.map(impugnation => {
        const impugnationClone = { ...impugnation };

        impugnationClone.createdAt = new Date(impugnation.createdAt);
        if (impugnationClone.response.date) {
          impugnationClone.response.date = new Date(impugnationClone.response.date);
        }

        return impugnationClone;
      });

      state.impugnations = updatedImpugnations;
    }
  },
  [types.IMPUGNATION_RESPONSE](state, impugnation) {
    if (impugnation && typeof impugnation === 'object') {
      const index = state.impugnations.findIndex(element => element.id === impugnation.id);
      if (index > -1) {
        state.impugnations.splice(index, 1, impugnation);
      }
    }
  },
  [types.IMPUGNATION_UPDATE](state, impugnation) {
    if (impugnation && typeof impugnation === 'object') {
      const index = state.impugnations.findIndex(element => element.id === impugnation.id);
      if (index > -1) {
        state.impugnations.splice(index, 1, impugnation);
      } else {
        state.impugnations.unshift(impugnation);
      }
    }
  },
};

const actions = {
  async answerAuctionImpugnation(_, { auctionId, impugnationId, data }) {
    const response = await answerAuctionImpugnation(auctionId, impugnationId, data);
    if (response.status !== 200) return Promise.reject(response.data);
    return response.data;
  },
  async createAuctionImpugnation(_, { auctionId, data }) {
    const response = await createAuctionImpugnation(auctionId, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return response.data;
  },
  async getAuctionImpugnations({ commit }, ofertaCompraId) {
    const response = await getAuctionImpugnations(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.IMPUGNATIONS, response.data);
  },
  updateAuctionImpugnations({ commit }, impugnation) {
    commit(types.IMPUGNATION_UPDATE, impugnation);
  },
};

const state = {
  impugnations: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
