<template>
  <div>
    <AppTitle titulo="Cadastro" />

    <b-loading can-cancel is-full-page :active="loading.general"></b-loading>

    <div v-if="loaded">
      <div
        v-if="!renderInvalidInvitation"
        class="container is-fluid is-flex is-justify-content-center custom-container"
      >
        <div class="custom-column">
          <div>
            <div
              v-if="renderSteps && !invitationHash && !invitation && !getRegisteredUser"
              class="custom-steps"
            >
              <b-steps
                animated
                v-model="registerStep"
                :has-navigation="false"
                :mobile-mode="null"
                :size="mobile ? 'is-small' : 'is-medium'"
              >
                <b-step-item
                  :clickable="(!user && !registeredUser) || !user.emailVerificado"
                  step="1"
                  label="Usuário"
                />

                <b-step-item
                  :clickable="!company && registeredUser && user.emailVerificado"
                  step="2"
                  label="Empresa"
                />

                <b-step-item :clickable="registeredCompany" step="3" label="Contrato de Serviço" />
              </b-steps>
            </div>

            <UserRegister
              v-if="registerStep === 0 && !registeredUser && loaded"
              @hide-steps="renderSteps = false"
              @user-registered="registeredUser = true"
            />

            <UserVerification
              v-if="loaded && registerStep === 0 && registeredUser"
              @check-register-step="checkRegisterStep()"
              @user-registered="
                registeredUser = true;
                registerStep = 1;
              "
            />

            <CompanyRegister
              v-if="loaded && registerStep === 1 && registeredUser"
              @company-registered="
                registeredCompany = true;
                registerStep = 2;
              "
            />

            <ServiceContract
              ref="service"
              v-if="loaded && registerStep === 2 && registeredCompany"
            />
          </div>
        </div>
      </div>

      <div class="invalid-invitation-container" v-else>
        <AppMessage>
          <div slot="message-icon">
            <b-icon icon="alert-circle-outline" size="is-large" type="is-danger" />
          </div>

          <p slot="message-title">Convite inválido ou não encontrado</p>

          <div slot="message">
            Contate o remetente do convite.
          </div>
        </AppMessage>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import formValidation from '@/mixins/formulario';
import AppMessage from '@/components/AppMessage';
import AppTitle from '@/components/AppTitulo';

import CompanyRegister from './CompanyRegister';
import ServiceContract from './ServiceContract';
import UserRegister from './UserRegister';
import UserVerification from './UserRegister/UserVerification';

export default {
  name: 'Register',
  mixins: [formValidation],
  components: {
    AppMessage,
    AppTitle,
    CompanyRegister,
    ServiceContract,
    UserRegister,
    UserVerification,
  },
  data() {
    const loading = {
      button: false,
      general: false,
    };

    return {
      invitationHash: '',
      loaded: false,
      loading,
      registeredCompany: false,
      registeredUser: false,
      registerStep: 0,
      renderInvalidInvitation: false,
      renderSteps: true,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.cleanCompanyInvitation();
    next();
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company', 'invitation']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      confirmedUserEmail: 'getConfirmedUserEmail',
      getRegisteredUser: 'getRegisteredUser',
      logged: 'getLogged',
      user: 'getUser',
    }),
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('companies', ['cleanCompanyInvitation', 'getCompanyInvitation']),
    checkInvitationStep() {
      if (this.invitation.registeredUser) {
        if (!this.logged) {
          this.$router.push({
            name: 'Login',
            query: {
              redirect: this.$route.fullPath,
            },
          });
        }

        if (this.user.id && this.user.id !== this.invitation.userId) {
          this.logout(false);
          this.$router.push({
            name: 'Login',
            query: {
              redirect: this.$route.fullPath,
            },
          });
        }

        if (this.invitation.verifiedEmail) {
          this.$router.push({ name: 'NotificationsList' });
        } else {
          this.registeredUser = true;
          this.registerStep = 0;
        }
      } else {
        this.logout(false);
        this.registerStep = 0;
      }
    },
    checkRegisterStep() {
      if (this.logged && this.user.id) {
        this.registeredUser = true;

        if (this.company.id && this.company.status === 'CADASTRADA') {
          this.registeredCompany = true;

          if (!this.company.contractAccepted) {
            this.registerStep = 2;
          } else {
            this.$router.push({ name: 'CompanyValidation', params: { id: this.company.id } });
          }

          return;
        }

        if (this.confirmedUserEmail) {
          this.registerStep = 1;
          return;
        }
      } else if (this.$route.path === '/confirmar-email') {
        this.$router.push({
          name: 'Login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      }

      this.registerStep = 0;
    },
    async validateInvitation(invitationHash) {
      this.loading.general = true;
      try {
        await this.getCompanyInvitation(invitationHash);
      } catch (error) {
        if (
          error.response.status === 400 &&
          error.response.data.message === 'ValidationError' &&
          error.response.data.details.message === 'Convite não existente'
        ) {
          this.renderInvalidInvitation = true;
        }
      } finally {
        this.loading.general = false;
      }
    },
  },
  async created() {
    this.invitationHash = this.$route.query.convite;
    if (this.invitationHash) {
      await this.validateInvitation(this.invitationHash);
      this.checkInvitationStep();
      this.loaded = true;
    } else {
      this.checkRegisterStep();
    }

    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
.custom-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
  max-width: 60vw;
}

.custom-container {
  margin: 1% 0;
}

.custom-steps {
  margin-bottom: 1rem;
}

.invalid-invitation-container {
  padding: 20vh 0;
}

@media (min-width: 1400px) {
  .custom-column {
    max-width: 50vw;
  }
}

@media (max-width: 1023px) {
  .custom-column {
    max-width: 80vw;
  }
}
</style>

<style lang="scss">
.custom-steps > div > nav .step-items {
  .step-item {
    padding: 0 1rem;
  }

  .step-item:last-child {
    margin-right: 1.5rem;
  }
}
</style>
