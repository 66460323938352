<template>
  <div>
    <div>
      <div v-if="!exibirTabela" class="panel">
        <div class="panel-block div-paragraph">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Nenhum recurso</p>
            </div>
          </section>
        </div>
      </div>

      <b-table v-if="exibirTabela" hoverable striped detailed :data="recursos">
        <b-table-column field="assunto" label="Assunto" v-slot="props">
          {{ props.row.assunto }}
        </b-table-column>

        <b-table-column centered field="status" label="Situação" width="50" v-slot="props">
          {{ formataStatus(props.row.status) }}
        </b-table-column>

        <b-table-column centered field="criado" label="Data" width="185" v-slot="props">
          {{ props.row.criado | formatarData }}
        </b-table-column>

        <template v-slot:detail="props">
          <AppMensagem
            :nome="props.row.recurso.nome"
            :email="props.row.recurso.email"
            :data="new Date(props.row.criado)"
          >
            <div class="content mensagem" v-html="props.row.recurso.mensagem" />
          </AppMensagem>

          <AppMensagem
            :nome="props.row.resposta.nome"
            :email="props.row.resposta.email"
            :data="new Date(props.row.resposta.data)"
            v-if="props.row.resposta.data"
          >
            <div class="content mensagem" v-html="props.row.resposta.justificativa" />
          </AppMensagem>
          <article class="media" v-if="exibirCamposResposta(props.row.status)">
            <div class="media-content">
              <b-field
                label="Situação"
                :type="campoInvalido('status', 'resposta') ? 'is-danger' : ''"
                :message="
                  campoInvalido('status', 'resposta') ? 'Situação é um campo obrigatório' : ''
                "
              >
                <b-select
                  v-model="$v.resposta.status.$model[props.index]"
                  v-if="exibirCamposResposta(props.row.status)"
                  @click.native="$v.resposta.status.$touch()"
                >
                  <option v-for="status in opcoesStatus" :key="status.id" :value="status.id">
                    {{ status.nome }}
                  </option>
                </b-select>
              </b-field>

              <b-field
                label="Justificativa"
                :type="campoInvalido('justificativa', 'resposta') ? 'is-danger' : ''"
                :message="
                  campoInvalido('justificativa', 'resposta')
                    ? 'Justificativa é um campo obrigatório'
                    : ''
                "
              >
                <b-input
                  maxlength="1000"
                  type="textarea"
                  v-model="$v.resposta.justificativa.$model[props.index]"
                />
              </b-field>
              <nav class="level">
                <div class="level-left">
                  <div class="level-item">
                    <b-button
                      type="is-primary send-button"
                      :loading="loading"
                      @click.prevent.stop="salvarJustificativa(props.row, props.index)"
                      >Enviar</b-button
                    >
                  </div>
                </div>
              </nav>
            </div>
          </article>
        </template>
      </b-table>
    </div>

    <div class="send-resource" v-if="!company.buyer && podeRecursar">
      <h1 v-if="licitante" class="title is-4 titulo-form">Enviar Recurso</h1>
      <form>
        <b-field grouped class="column-direction-touch">
          <b-field
            expanded
            label="Nome"
            :type="campoInvalido('nome') ? 'is-danger' : ''"
            :message="campoInvalido('nome') ? 'Nome é um campo obrigatório' : ''"
          >
            <b-input :disabled="logged" v-model="$v.dados.nome.$model" />
          </b-field>
          <b-field
            v-if="logged"
            label="CNPJ"
            :type="campoInvalido('cnpj') ? 'is-danger' : ''"
            :message="
              campoInvalido('cnpj') ? 'CNPJ é um campo obrigatório. Insira um número válido' : ''
            "
          >
            <b-input
              :disabled="logged"
              maxlength="18"
              :has-counter="false"
              v-mascara:cnpj
              v-model="$v.dados.cnpj.$model"
            />
          </b-field>
          <b-field
            expanded
            label="E-mail"
            :type="campoInvalido('email') ? 'is-danger' : ''"
            :message="campoInvalido('email') ? 'Insira um e-mail válido' : ''"
          >
            <b-input
              :disabled="logged"
              v-model="$v.dados.email.$model"
              @input="dados.email = dados.email.toLowerCase()"
            />
          </b-field>
        </b-field>
        <b-field grouped class="column-direction-touch">
          <b-field
            expanded
            label="Assunto"
            :type="campoInvalido('assunto') ? 'is-danger' : ''"
            :message="campoInvalido('assunto') ? 'Assunto é um campo obrigatório' : ''"
          >
            <b-input v-model="$v.dados.assunto.$model" />
          </b-field>
        </b-field>
        <b-field
          label="Recurso"
          :type="campoInvalido('mensagem') ? 'is-danger' : ''"
          :message="campoInvalido('mensagem') ? 'Recurso é um campo obrigatório' : ''"
        >
          <b-input type="textarea" v-model="$v.dados.mensagem.$model" />
        </b-field>
        <button
          class="button is-primary send-button"
          :class="{ ' is-loading': loading }"
          @click.prevent.stop="salvarDados"
        >
          Enviar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, required, requiredIf } from 'vuelidate/lib/validators';

import campoMixin from '@/mixins/formulario';

import AppMensagem from '@/components/AppMensagem';

import { verificarPregoeiro, funcoes } from '@/utils/enumerados';
import { formatarData } from '@/utils/format';

export default {
  name: 'PregaoRecursos',
  mixins: [campoMixin],
  components: {
    AppMensagem,
  },
  filters: {
    formatarData,
  },
  data() {
    const opcoesStatus = [
      { id: 'ADMISSIVEL', nome: 'Admissível' },
      { id: 'INADMISSIVEL', nome: 'Inadmissível' },
    ];
    const dados = {
      nome: '',
      cnpj: null,
      email: '',
      assunto: '',
      mensagem: '',
    };
    const resposta = {
      justificativa: [],
      status: [],
    };

    return {
      dados,
      resposta,
      opcoesStatus,
      loading: false,
    };
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      funcao: 'getFunction',
      recursos: 'obterOfertaCompraRecursos',
      logged: 'getLogged',
      ofertaCompra: 'obterOfertaCompra',
      prazos: 'obterOfertaCompraPrazos',
    }),
    exibirTabela() {
      return this.recursos.length;
    },
    situacao() {
      return this.ofertaCompra.situacao.id;
    },
    pregoeiro() {
      return this.logged && verificarPregoeiro(this.funcao);
    },
    licitante() {
      return this.funcao === funcoes.LICITANTE;
    },
    podeRecursar() {
      const prazo = this.prazos.find(elemento => elemento.id === 1);
      if (prazo) {
        return this.situacao === 10 && this.licitante;
      }
      return false;
    },
  },
  watch: {
    company() {
      this.carregarInfoEmpresa();
    },
  },
  validations: {
    dados: {
      nome: { required },
      cnpj: {
        required: requiredIf(function validarcnpj() {
          return this.company.cnpj;
        }),
      },
      email: { email, required },
      assunto: { required },
      mensagem: { required },
    },
    resposta: {
      justificativa: { required },
      status: { required },
    },
  },
  methods: {
    ...mapActions(['salvarOfertaCompraRecurso', 'responderOfertaCompraRecurso']),
    formataStatus(status) {
      if (status === 'ADMISSIVEL') return 'Admissível';
      if (status === 'INADMISSIVEL') return 'Inadmissível';
      return 'Criado';
    },
    exibirCamposResposta(status) {
      return this.pregoeiro && status === 'CRIADO' && this.situacao === 10;
    },
    carregarInfoEmpresa() {
      if (this.company) {
        this.dados.nome = this.company.socialDenomination;
        this.dados.cnpj = this.company.cnpj;
        this.dados.email = this.company.email;
      }
    },
    async salvarDados() {
      if (this.validar('dados')) {
        this.loading = true;
        try {
          const dados = { ...this.dados };
          await this.salvarOfertaCompraRecurso(dados);
          this.$alerta('Dados salvos', 'is-success');
          this.limparCampos();
          this.$v.dados.$reset();
          if (this.company) this.carregarInfoEmpresa();
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    async salvarJustificativa(recurso, indice) {
      if (this.validar('resposta')) {
        this.loading = true;
        try {
          const dados = {
            status: this.resposta.status[indice],
            justificativa: this.resposta.justificativa[indice],
          };
          dados.justificativaData = new Date();
          await this.responderOfertaCompraRecurso({
            id: recurso.id,
            dados,
          });
          this.$alerta('Respondido', 'is-success');
          this.$v.dados.$reset();
          this.limparCamposPregoeiro(indice);
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    limparCamposPregoeiro(indice) {
      this.resposta.status.splice(indice, 1);
      this.resposta.justificativa.splice(indice, 1);
    },
    limparCampos() {
      this.dados.assunto = '';
      this.dados.mensagem = '';
    },
  },
  created() {
    this.carregarInfoEmpresa();
  },
};
</script>

<style scoped>
.div-paragraph {
  justify-content: center !important;
}

.mensagem {
  white-space: pre-line;
}

.titulo-form {
  margin-top: 2rem;
}

.send-resource {
  margin-top: 1rem;
}

.send-button {
  margin-top: 0.75rem;
  width: 150px;
}

@media (max-width: 1023px) {
  .send-button {
    width: 100%;
  }
}
</style>
