// Funções
const funcoes = {
  VISITANTE: 'VISITANTE',
  COMPRADOR: 'COMPRADOR',
  VENDEDOR: 'VENDEDOR',
  PREGOEIRO: 'PREGOEIRO',
  AUTORIDADE: 'AUTORIDADE',
  FORNECEDOR: 'FORNECEDOR',
  LICITANTE: 'LICITANTE',
  USUARIO: 'USUARIO',
};

// Estados
const situacoes = {
  CADASTRADA: 'CADASTRADA',
};

const verificarPregoeiro = funcao => funcao === funcoes.PREGOEIRO;
const verificarComprador = funcao => funcao === funcoes.COMPRADOR;
const verificarVendedor = funcao => funcao === funcoes.VENDEDOR;
const verificarFornecedor = funcao => funcao === funcoes.FORNECEDOR;
const verificarLicitante = funcao => funcao === funcoes.LICITANTE;
const verificarVisitante = funcao => funcao === funcoes.VISITANTE;

export {
  funcoes,
  situacoes,
  verificarPregoeiro,
  verificarComprador,
  verificarVendedor,
  verificarFornecedor,
  verificarLicitante,
  verificarVisitante,
};
