<template>
  <div>
    <b-loading is-full-page :active="loading.documentos" can-cancel></b-loading>

    <section>
      <div class="columns">
        <div class="column has-text-right">
          <div v-if="comprador">
            <button
              v-if="exibirIniciarLances"
              class="button field is-primary acoes"
              :class="{ ' is-loading': loading.general }"
              :disabled="!itensSelecionados.length || itensEmLance"
              @click="iniciarLance"
            >
              <span>Iniciar Lances</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div v-if="!itens.length" class="panel">
      <div class="panel-block is-justify-content-center">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhum item.</p>
          </div>
        </section>
      </div>
    </div>

    <b-table
      hoverable
      scrollable
      v-if="itens.length"
      detail-key="id"
      class="table is-fullwidth dispensation-items-table"
      :data="itensFiltrados"
      :key="componentKey"
      :checked-rows.sync="itensSelecionados"
      :is-row-checkable="row => itensParaLance(row)"
      :checkable="exibirChecaveis"
      :opened-detailed.sync="listaItensDetalhes"
    >
      <b-table-column numeric label="ID" field="identificador" width="40" v-slot="props">{{
        props.row.identificador
      }}</b-table-column>

      <b-table-column
        cell-class="elipsis-size"
        label="Descrição"
        v-slot="props"
        :key="descriptionKey"
      >
        <div
          class="elipsis-column"
          :class="{
            'cursor-pointer': displayEyeIcon(props.row.id),
            'justify-align': justify,
          }"
          :ref="`description-container-${props.row.id}`"
          @click.stop.prevent="openDescription(props.row.id)"
        >
          <b-icon
            class="custom-eye-icon mr-1"
            icon="eye-outline"
            v-if="displayEyeIcon(props.row.id)"
          ></b-icon>

          <a
            v-if="displayEyeIcon(props.row.id)"
            :class="{ 'justify-align': justify }"
            :ref="`description-link-${props.row.id}`"
            >{{ props.row.item.descricao }}</a
          >
          <p v-else>{{ props.row.item.descricao }}</p>
        </div>
      </b-table-column>

      <b-table-column centered label="Unidade" :visible="situacao < 6" v-slot="props">{{
        props.row.unidade.nome
      }}</b-table-column>

      <b-table-column centered label="Quantidade" :visible="situacao < 6" v-slot="props">{{
        props.row.quantidade | formatarNumero
      }}</b-table-column>

      <b-table-column :visible="exibirReferencia" centered label="Referência" v-slot="props">{{
        verificaReferencia(props.row) | formatarValor
      }}</b-table-column>

      <b-table-column :visible="exibirTotal" centered label="Total" v-slot="props">{{
        total(props.row.total)
      }}</b-table-column>

      <b-table-column :visible="exibirReducao" centered label="Redução" v-slot="props">{{
        props.row.reducaoLances | formatarValor
      }}</b-table-column>

      <b-table-column
        centered
        label="Menor Lance"
        width="160"
        v-slot="props"
        :visible="exibirFornecedorMenorLance"
      >
        <div class="is-flex justify-center has-text-centered" :class="{ 'justify-end': mobile }">
          <span
            class="tag tag-extended"
            v-if="exibirTagMenorLance(props.row.estado)"
            :class="verificarValorLicitante(props.row)"
            >{{ lanceValor(props.row) | formatarValor }}</span
          >
        </div>
      </b-table-column>

      <b-table-column
        centered
        :visible="exibirFornecedorMenorLance"
        label="Fornecedor"
        v-slot="props"
      >
        <span
          v-if="exibirTagFornecedor(props.row.estado)"
          class="tag tag-extended"
          :class="verificarValorLicitante(props.row)"
          >{{ props.row.menor_lance.apelido | formatarPadrao('Nenhum Fornecedor') }}</span
        >

        <span
          v-if="exibirEmpresa(props.row.estado)"
          class="tag tag-extended"
          :class="verificarValorLicitante(props.row)"
          >{{
            props.row.menor_lance.denominacao_social | formatarPadrao('Nenhum Fornecedor')
          }}</span
        >
      </b-table-column>

      <b-table-column centered label="Marca/Modelo" :visible="exibirColunas" v-slot="props">
        <DispensaMarcaModelo
          v-if="props.row.estado === 'LANCE'"
          :item="props.row"
          :width="resolucaoFullHd ? '300' : '200'"
          @enter-lance="informarPIN"
        />
      </b-table-column>

      <b-table-column centered label="Importado" :visible="exibirColunas" width="80" v-slot="props">
        <DispensaImportado
          v-if="props.row.estado === 'LANCE'"
          :item="props.row"
          @enter-lance="informarPIN"
      /></b-table-column>

      <b-table-column
        centered
        :label="exibirTipoNegociacao"
        :visible="exibirColunas"
        v-slot="props"
      >
        <DispensaValor
          v-if="props.row.estado === 'LANCE'"
          :item="props.row"
          :ref="`lance-${props.index}`"
          :width="resolucaoFullHd ? '240' : '200'"
          @enter-lance="informarPIN"
        />
      </b-table-column>

      <b-table-column centered :visible="exibirEstado" label="Estado" v-slot="props">
        <span class="tag is-primary tag-extended" :class="props.row.estado | estadoItemDispensa">{{
          props.row.estado | tituloEstadoItemDispensa
        }}</span>
      </b-table-column>

      <b-table-column centered label="Ações" :visible="exibirAcoes" v-slot="props">
        <div class="is-flex justify-center has-text-centered" :class="{ 'justify-end': mobile }">
          <button
            class="button is-primary acoes"
            v-if="exibirBotaoRatificar(props.row.estado)"
            :class="{ 'blink-button': !justification }"
            :disabled="desabilitar.homologar"
            @click.prevent.stop="abrirModal(props.row.id, 'ratificar')"
          >
            Ratificar
          </button>

          <button
            class="button is-danger acoes"
            v-if="exibirRevogarItem(props.row.estado)"
            :disabled="desabilitar.revogarItem"
            @click.prevent.stop="abrirModal(props.row.id, 'revogar-item')"
          >
            Revogar
          </button>
        </div>
      </b-table-column>

      <b-table-column
        centered
        class="decision-column"
        label="Atos Decisórios"
        v-slot="props"
        :visible="[15, 16].includes(situacao)"
      >
        <div :class="{ 'justify-end': mobile, 'is-flex': mobile }">
          <div v-if="exibirTextoRatificado(props.row)">
            <p>
              {{ textoUsuarioRatificacao(props.row.usuario_ratificacao) }}
            </p>
            <p>
              {{ textoDataRatificacao(props.row) }}
            </p>
            <p>
              {{ props.row.justificativa_ratificacao }}
            </p>
          </div>
          <p v-else>N/A</p>
        </div>
      </b-table-column>

      <b-table-column centered label="Opções" v-slot="props" :visible="exibirOpcoes">
        <button
          tabindex="-1"
          class="button is-small is-primary is-outlined botao-lances"
          v-if="exibirListagemLances(props.row)"
          @click.prevent.stop="visualizarLances(props.row)"
        >
          Lances
        </button>
      </b-table-column>

      <template v-if="exibirRodape" v-slot:footer>
        <div class="footer-container">
          <div class="alternate-table-button-container has-text-left">
            <button
              class="button is-primary"
              style="height: 100%"
              v-if="exibirBotaoAlternarItens"
              @click.stop.prevent="alternarItens"
            >
              {{ textoBotao }}
            </button>
          </div>

          <div class="value-warning-container has-text-centered">
            <transition name="fade">
              <b-tag
                class="value-warning"
                type="is-danger"
                size="is-medium"
                v-if="situacao === 6 && exibirAviso"
                >{{ textoAviso }}</b-tag
              >
            </transition>
          </div>

          <div class="send-button-container has-text-right">
            <button
              ref="botao"
              class="button is-primary"
              :class="{ ' is-loading': loading.general, 'is-small': mobile }"
              style="height: 100%"
              @click.stop.prevent="informarPIN"
            >
              Enviar Lance(s)
            </button>
          </div>
        </div>
      </template>
    </b-table>

    <b-modal
      has-modal-card
      :active.sync="openConfirmationModal"
      :onCancel="resetarLoadings"
      @confirm="enviar()"
    >
      <AppActionConfirmation
        v-if="displayConfirmation"
        :display="confirmation.display"
        :items="confirmation.items"
        :message="confirmation.message"
        :mobile="mobile"
        :warning="confirmation.warning"
      />
    </b-modal>

    <b-modal has-modal-card :active.sync="solicitarPIN" :onCancel="resetarLoadings">
      <PinForm v-if="solicitarPIN" :reason="reasonPIN" @validar="enviar()" />
    </b-modal>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificativa"
      :onCancel="resetarEstadoBotoes"
      @justificate="enviarJustificativa"
    >
      <AppJustification
        v-if="justificativa"
        :displayFailButton="exibirFalha"
        :displaySuccessButton="exibirSucesso"
        :failText="textoFalha"
        :successText="textoSucesso"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppActionConfirmation from '@/components/AppActionConfirmation';
import PinForm from '@/views/User/Pin';

import { funcoes } from '@/utils/enumerados';

import {
  formatarValor,
  formatarData,
  formatarNumero,
  tituloEstadoItemDispensa,
  estadoItemDispensa,
  formatarPadrao,
} from '@/utils/format';

import { enviarLances } from '@/resources/dispensas';

import AppJustification from '@/components/AppJustification';
import DispensaValor from '@/views/Dispensa/DispensaItem/DispensaValor';
import DispensaMarcaModelo from '@/views/Dispensa/DispensaItem/DispensaMarcaModelo';
import DispensaImportado from '@/views/Dispensa/DispensaItem/DispensaImportado';

export default {
  name: 'DispensaItensTodos',
  components: {
    AppActionConfirmation,
    AppJustification,
    DispensaValor,
    DispensaMarcaModelo,
    DispensaImportado,
    PinForm,
  },
  props: {
    loaded: Boolean,
  },
  data() {
    const loading = {
      general: false,
      documentos: false,
    };
    const desabilitar = {
      revogarItem: false,
      ratificar: false,
    };

    return {
      confirmation: {},
      displayConfirmation: false,
      openConfirmationModal: false,
      loading,
      desabilitar,
      acao: '',
      textoSucesso: '',
      textoFalha: '',
      aviso: false,
      reasonPIN: '',
      solicitarPIN: false,
      itensSelecionados: [],
      listaItensDetalhes: [],
      itensEmLance: true,
      justificativa: false,
      item: null,
      fornecedor: null,
      exibirSucesso: false,
      exibirFalha: false,
      exibirAbertos: false,
      lances: [],
      componentKey: 0,
      descriptionKey: 0,
    };
  },
  filters: {
    formatarValor,
    formatarData,
    formatarNumero,
    tituloEstadoItemDispensa,
    estadoItemDispensa,
    formatarPadrao,
  },
  beforeRouteLeave(to, from, next) {
    this.limparLancesParaEnviar();
    this.lances = [];
    next();
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('dispensationsBidders', ['userLoggedBidder']),
    ...mapState('companies', ['company']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      dispensa: 'obterDispensa',
      itens: 'obterTodosItensDispensa',
      funcao: 'getFunction',
      usuario: 'getUser',
      prazos: 'obterDispensaPrazos',
      pinValido: 'getValidPin',
      exibirAviso: 'obterExibirAviso',
      lancesDispensa: 'obterDispensaLances',
      lancesParaEnviar: 'obterLancesParaEnviar',
    }),
    justify() {
      return this.windowWidth > 960;
    },
    resolucaoHd() {
      return this.windowWidth <= 1366;
    },
    resolucaoFullHd() {
      return this.windowWidth >= 1920;
    },
    textoBotao() {
      if (!this.exibirAbertos) return 'Todos os Itens';
      return 'Abertos';
    },
    itensFiltrados() {
      if (!this.exibirAbertos && this.situacao === 6 && this.itemAberto && this.vendedor) {
        return this.itens.filter(item => item.estado === 'LANCE');
      }
      return this.itens;
    },
    fornecedorLicitacao() {
      return this.funcao === funcoes.FORNECEDOR;
    },
    licitante() {
      return this.funcao === funcoes.LICITANTE;
    },
    ampla() {
      return this.dispensa.tipo_participacao === 'AMPLA';
    },
    exclusiva() {
      return this.dispensa.tipo_participacao === 'EXCLUSIVA';
    },
    exibirColunas() {
      if (
        (this.ampla || (this.exclusiva && this.company.preference)) &&
        this.company.status === 'HABILITADA' &&
        this.situacao === 6 &&
        this.vendedor &&
        this.operarDispensas &&
        this.itemAberto
      ) {
        return true;
      }
      return false;
    },
    operarDispensas() {
      return this.usuario.empresas.some(
        empresaUsuario => empresaUsuario.id === this.company.id && empresaUsuario.operar_dispensas,
      );
    },
    lanceIniciado() {
      return this.itens.some(item => item.estado === 'LANCE');
    },
    exibirFornecedorMenorLance() {
      return [6, 15].indexOf(this.situacao) > -1;
    },
    exibirIniciarLances() {
      return this.verificarSituacao(6) && this.comprador;
    },
    itensPara() {
      return this.itensSelecionados.map(item => item.id);
    },
    etapaLances() {
      return this.dispensa.situacao.id === 6;
    },
    comprador() {
      return this.funcao === funcoes.COMPRADOR && this.company.id === this.dispensa.comprador.id;
    },
    vendedor() {
      return this.company.seller;
    },
    situacao() {
      return this.dispensa.situacao.id;
    },
    textoAviso() {
      return '20% menor que o menor lance';
    },
    itemAberto() {
      return this.itens.some(item => item.estado === 'LANCE');
    },
    todosItensAbertos() {
      return this.itens.every(item => item.estado === 'LANCE');
    },
    exibirBotaoAlternarItens() {
      return (
        this.situacao === 6 &&
        this.itemAberto &&
        !this.todosItensAbertos &&
        (this.fornecedorLicitacao || this.licitante)
      );
    },
    exibirTipoNegociacao() {
      if (this.situacao === 6 && this.dispensa.tipo_negociacao.id === 1) {
        return 'Valor Unitário';
      }
      return 'Valor Total';
    },
    exibirRodape() {
      const filtroLance = this.itens.find(item => item.estado === 'LANCE');
      if (this.situacao === 6 && this.vendedor && filtroLance) return true;

      return false;
    },
    verificarValorLicitante() {
      return item => {
        if (!item.menor_lance.apelido || ['FRACASSADA', 'REVOGADO'].indexOf(item.estado) > -1) {
          return '';
        }

        if (this.funcao === funcoes.VISITANTE) return 'is-dark';

        if (
          this.company.buyer &&
          this.dispensa.tipo_negociacao.id === 1 &&
          item.referencia >= item.menor_lance.valor
        ) {
          return 'is-success';
        }
        if (
          this.company.buyer &&
          this.dispensa.tipo_negociacao.id === 2 &&
          item.total >= item.menor_lance.valor
        ) {
          return 'is-success';
        }
        if (item.menor_lance.fornecedor_id === this.userLoggedBidder.id) return 'is-success';
        return 'is-danger';
      };
    },
    exibirOpcoes() {
      return this.situacao > 4 && this.itens.some(item => item.estado !== 'DESERTO');
    },
    exibirChecaveis() {
      return this.situacao === 6 && this.comprador;
    },
    exibirEstado() {
      return this.situacao > 1;
    },
    exibirReferencia() {
      return this.comprador;
    },
    exibirTotal() {
      return this.comprador && [2, 4].indexOf(this.situacao) > -1;
    },
    exibirReducao() {
      return this.situacao >= 6;
    },
    exibirAcoes() {
      if ([2, 4, 15].indexOf(this.situacao) > -1) {
        const existe = this.itens.find(
          item => ['REVOGADO', 'DESERTO', 'FRACASSADO', 'RATIFICADO'].indexOf(item.estado) === -1,
        );
        if (this.comprador && existe) return true;
      }

      return false;
    },
    itemEmLance() {
      return this.itens.some(elemento => elemento.estado === 'LANCE');
    },
  },
  watch: {
    exibirColunas() {
      if (this.exibirColunas) {
        this.componentKey += 1;
        this.focarInput();
      }
    },
    itens(itens) {
      if (Array.isArray(itens)) {
        itens.forEach(item => {
          if (item.referencia !== null) formatarValor(item.referencia);
        });
      } else if (itens.referencia !== null) {
        formatarValor(itens.referencia);
      }
    },
    loaded() {
      if (this.loaded) {
        this.verifyOverflowed();
        this.updateDescriptionColumn();
      }
    },
  },
  sockets: {
    'dispensa:lance-processado': async function atualizarDispensaLancesEmpresa(bid) {
      if ((this.fornecedorLicitacao || this.licitante) && this.situacao >= 6) {
        if (this.lancesDispensa.length) {
          const bidItem = this.lancesDispensa.find(
            element => element.dispensaItemId === bid.dispensaItemId,
          );
          if (!bidItem) {
            await this.buscarDispensaLances({
              dispensaId: this.dispensa.id,
              tipo: 'empresa',
            });
          } else if (this.userLoggedBidder.id === bid.fornecedor_id) {
            this.atualizarLancesItemEmpresaDispensa(bid);
          }
        }

        if (!this.lancesDispensa.length) {
          await this.buscarDispensaLances({
            dispensaId: this.dispensa.id,
            tipo: 'empresa',
          });
        }

        const myBid = this.lancesDispensa.find(element => element.id === bid.id);

        if (myBid && myBid.status === 'INVALIDO') {
          this.$alerta(`Lance Inválido no Item ${bid.identificador}`, 'is-danger', 3000);
        }
      }
    },
  },
  methods: {
    ...mapActions([
      'buscarDispensaLances',
      'permitirLancesDispensa',
      'atualizarLancesItemEmpresaDispensa',
      'revogarItemDispensa',
      'ratificarVencedorItem',
      'limparLancesParaEnviar',
    ]),
    ...mapActions('dispensationsBidders', ['fetchUserLoggedBidder']),
    displayEyeIcon(id) {
      const container = this.$refs[`description-container-${id}`];
      if (container) {
        return container.scrollWidth >= container.parentElement.scrollWidth;
      }

      return false;
    },
    openDescription(id) {
      const tag = this.$refs[`description-container-${id}`];
      if (tag) {
        const { classList } = tag;
        const contains = classList.contains('elipsis-column');
        if (contains) {
          classList.remove('elipsis-column');
          return;
        }
        classList.add('elipsis-column');
      }
    },
    exibirTextoRatificado(item) {
      return item.justificativa_ratificacao !== null;
    },
    textoUsuarioRatificacao(usuarioNome) {
      return `Ratificado por: ${usuarioNome}`;
    },
    textoDataRatificacao(item) {
      return `Em: ${formatarData(item.data_ratificacao)}`;
    },
    alternarItens() {
      if (!this.exibirAbertos) {
        this.exibirAbertos = true;
      } else {
        this.exibirAbertos = false;
      }
    },
    exibirListagemLances(row) {
      return (
        this.situacao > 4 && this.situacao <= 15 && this.situacao !== 13 && row.estado !== 'DESERTO'
      );
    },
    exibirTagFornecedor() {
      return this.situacao === 6;
    },
    exibirTagMenorLance() {
      return [6, 15].indexOf(this.situacao) > -1;
    },
    exibirEmpresa() {
      return this.situacao === 15;
    },
    exibirRevogarItem(estado) {
      return (
        this.comprador &&
        [2, 4, 15].indexOf(this.situacao) > -1 &&
        !['REVOGADO', 'DESERTO', 'FRACASSADO', 'RATIFICADO'].includes(estado)
      );
    },
    exibirBotaoRatificar(estado) {
      return this.situacao === 15 && this.comprador && estado === 'FINALIZADO';
    },
    total(valorTotal) {
      if (valorTotal) return formatarValor(valorTotal);
      return '';
    },
    referencia(valorReferencia) {
      if (valorReferencia) return formatarValor(valorReferencia);
      return '';
    },
    visualizarLances(item) {
      this.$emitBus('detalhe', {
        tipo: 'lance',
        item,
        dispensaId: this.dispensa.id,
      });
    },
    abrirModal(itemId, acao, lanceId = 0, fornecedor = '') {
      if (acao === 'ratificar') {
        this.desabilitar.ratificar = true;
        this.textoSucesso = 'Ratificar';
        this.exibirSucesso = true;
        this.exibirFalha = false;
      } else if (acao === 'revogar-item') {
        this.desabilitar.revogarItem = true;
        this.textoFalha = 'Revogar';
        this.exibirSucesso = false;
        this.exibirFalha = true;
        this.justificativa = true;
      }

      this.item = itemId;
      this.lanceId = lanceId;
      this.fornecedor = fornecedor;
      this.acao = acao;
      this.justificativa = true;
    },
    async enviarJustificativa({ justification }) {
      if (this.item) {
        if (this.acao === 'ratificar') {
          await this.ratificarItem({
            dispensaItemId: this.item,
            dataRatificacao: new Date(),
            justificativa: justification || 'Item ratificado.',
          });
        } else if (this.acao === 'revogar-item') {
          await this.revogarItem({
            dispensaItemId: this.item,
            justification,
          });
        }
        this.item = null;
        this.acao = '';
      }
    },
    lanceValor(item) {
      return item.menor_lance.valor ? item.menor_lance.valor : 'Nenhum Lance';
    },
    lanceFornecedor(item) {
      return item.menor_lance.fornecedor ? item.menor_lance.fornecedor : 'Nenhum Fornecedor';
    },
    itensParaLance(item) {
      return item.estado === 'CADASTRADO' && !this.itemEmLance;
    },
    verificarSituacao(situacao) {
      return this.dispensa.situacao.id === situacao;
    },
    verificaReferencia(item) {
      return (
        (this.situacao < 6 || this.dispensa.tipo_negociacao.id === 1
          ? item.referencia
          : item.total) || 0
      );
    },
    async iniciarLance() {
      this.loading.general = true;
      this.itensEmLance = await this.permitirLancesDispensa(this.itensPara);
      if (this.itensEmLance) {
        if (this.itensSelecionados.length === 1) {
          this.$alerta('Item iniciado para lance', 'is-success');
        } else if (this.itensSelecionados.length > 1) {
          this.$alerta('Itens iniciados para lances', 'is-success');
        }
        this.itensSelecionados = [];
      } else {
        this.$alerta('Erro ao iniciar lances', 'is-danger');
      }
      this.loading.general = false;
    },
    filtrarMarcaImportado() {
      const filtro = this.lances.map(lance => {
        const lanceClone = { ...lance };

        delete lanceClone.primeiroLance;

        const existeLanceValido = this.lancesDispensa.some(
          elemento =>
            elemento.dispensaItemId === lance.dispensaItemId && elemento.status === 'VALIDO',
        );

        if (existeLanceValido) {
          delete lanceClone.marca;
          delete lanceClone.importado;
        }
        return lanceClone;
      });
      this.lances = filtro;
    },
    async enviar() {
      if (!this.pinValido) {
        this.displayConfirmation = false;
        this.solicitarPIN = true;
        this.reasonPIN = 'DISPENSA-LANCE';
        return;
      }

      await this.filtrarMarcaImportado();

      this.loading.general = true;
      try {
        if (this.situacao === 6) {
          const { data: lances } = await enviarLances(this.dispensa.id, this.lances);
          if (lances.length === 1) {
            this.fetchUserLoggedBidder(this.$route.params.id);
            this.$alerta('Lance Enviado', 'is-info', 1000);
          } else if (lances.length > 1) {
            this.$alerta('Lances Enviados', 'is-info', 1000);
          }
        }
      } catch (error) {
        if (error.response && Array.isArray(error.response.data.error)) {
          const outOfTime = error.response.data.error.some(
            element => element.status === 'ForaDoPrazo',
          );

          if (outOfTime) {
            this.$alerta('Tempo finalizado', 'is-danger');
            return;
          }
        }

        this.$alerta('Erro ao enviar lance', 'is-danger');
      } finally {
        this.limparLancesParaEnviar();
        this.lances = [];
        this.limparCampos();
        this.focarInput();
        this.loading.general = false;
      }
    },
    informarPIN() {
      const existeMaiorQueZero = this.lancesParaEnviar.some(lance => lance.valor > 0);

      if (!this.lancesParaEnviar.length || !existeMaiorQueZero) {
        this.$alerta('Nenhum lance inserido', 'is-danger', 3000);
        this.focarInput();
        return;
      }

      if (this.lancesParaEnviar.length) {
        this.lances = this.lancesParaEnviar
          .filter(lance => lance.valor > 0)
          .sort((a, b) => a.identificador - b.identificador);

        const lanceSemMarca = this.lances.find(lance => !lance.marca);

        if (lanceSemMarca) {
          this.$alerta('Existe lance sem marca inserida', 'is-danger', 3000);
          return;
        }

        const lanceAcimaDoLimite = this.lances.find(lance => lance.valor > 999999999.9999);

        if (lanceAcimaDoLimite) {
          this.$alerta('Valor acima do limite: R$ 999.999.999,9999', 'is-danger', 3000);
          this.focarInput();
          return;
        }

        this.resetarEstado();

        this.openConfirmationModal = true;

        this.mountBidConfirmation();
      }
    },
    mountBidConfirmation() {
      this.confirmation.display = {
        identifier: true,
        description: true,
        unity: true,
        brand: true,
        imported: true,
        percent: false,
        value: true,
      };

      const firstBidExists = this.lances.some(bid => bid.primeiroLance);
      this.confirmation.warning = firstBidExists
        ? 'Após o primeiro lance válido do item, marca/modelo e procedência(nacional ou importado) não podem ser modificados.'
        : '';
      this.confirmation.message = 'Confirme o(s) lance(s):';

      this.confirmation.items = this.lances.map(bid => ({
        identifier: bid.identificador,
        description: bid.itemDescricao,
        unity: bid.itemUnidadeNome,
        brand: bid.marca.toUpperCase(),
        imported: bid.importado,
        percent: null,
        value: bid.valor,
      }));

      this.solicitarPIN = false;
      this.displayConfirmation = true;
      this.openConfirmationModal = true;
    },
    verificarItens() {
      this.itensEmLance =
        this.itens.filter(item => item.estado in ['LANCE', 'PREFERENCIA-INICIADA']).length > 0;
    },
    async revogarItem(dados) {
      try {
        await this.revogarItemDispensa(dados);
        this.$alerta('O item foi revogado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao revogar item', 'is-danger');
      }
      this.desabilitar.revogarItem = false;
      this.enviarFecharModal();
    },
    async ratificarItem(dados) {
      try {
        await this.ratificarVencedorItem(dados);
        this.$alerta('O vencedor do item foi ratificado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao ratificar o vencedor do item', 'is-danger');
      }
      this.desabilitar.adjudicar = false;
      this.enviarFecharModal();
    },
    limparCampos() {
      this.$emitBus('limparCampos');
    },
    resetarEstado() {
      this.$emitBus('resetarEstado');
    },
    enviarFecharModal() {
      this.$refs.justificationModal.close();
    },
    resetarLoadings() {
      this.loading.general = false;
    },
    resetarEstadoBotoes() {
      this.loading.general = false;
      this.desabilitar.revogarItem = false;
      this.desabilitar.ratificar = false;
    },
    focarInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          const firstInput = this.$refs['lance-0'];
          if (firstInput) firstInput.focus();
        }, 100);
      });
    },
    updateDescriptionColumn() {
      setTimeout(() => {
        this.descriptionKey += 1;
      }, 1);
    },
    verifyOverflowed() {
      const overflowed = this.itens.some(item => this.displayEyeIcon(item.id));

      if (!overflowed) {
        const refs = Object.values(this.$refs);
        const rows = refs.filter(ref => ref.label === 'Descrição');
        rows.forEach(row => row.$el.classList.remove('elipsis-size'));
      }
    },
  },
  mounted() {
    this.verifyOverflowed();
    this.updateDescriptionColumn();
    if (this.userFunction !== funcoes.VISITANTE) {
      this.fetchUserLoggedBidder(this.$route.params.id);
    }
    if (this.exibirColunas) this.focarInput();
  },
  created() {
    this.$onBus('onTerminar:itensEmLance', () => {
      this.itensEmLance = false;
    });
    this.verificarItens();
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #1a0dab !important;
}

.acoes {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.alternate-table-button-container {
  width: 25%;
}

.blink-button {
  &:not(hover) {
    -webkit-animation: blink 2s infinite both;
    animation: blink 2s infinite both;
  }

  &:hover {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes blink {
  0%,
  50%,
  100% {
    background-color: #2153b5;
  }
  25%,
  75% {
    background-color: #568259;
  }
}
@keyframes blink {
  0%,
  50%,
  100% {
    background-color: #2153b5;
  }
  25%,
  75% {
    background-color: #568259;
  }
}

.botao-lances {
  margin-right: 0.1rem;
  margin-bottom: 0.4rem;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-eye-icon {
  cursor: pointer;
  vertical-align: top;
}

.decision-column {
  min-width: 300px;
}

.elipsis-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.footer-container {
  display: flex;
  margin-top: 0.5rem;
  width: 100%;
}

.dispensation-items-table {
  font-size: 0.96rem;
  table-layout: fixed;
}

.justify-align {
  text-align: justify;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.send-button-container {
  width: 25%;
}

.value-warning-container {
  width: 50%;
}

.tag-extended {
  display: flex;
  position: relative;
}

.tag-ratificado {
  background-color: #35a1d6;
  color: #fff;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 2em;
  -webkit-box-pack: center;
}

@media (max-width: 768px) {
  .value-warning {
    font-size: 0.7rem;
  }
}

@media (min-width: 300px) and (max-width: 422px) {
  .elipsis-column {
    max-width: 20ch;
  }
}

@media (min-width: 423px) and (max-width: 545px) {
  .elipsis-column {
    max-width: 35ch;
  }
}

@media (min-width: 546px) and (max-width: 768px) {
  .elipsis-column {
    max-width: 45ch;
  }
}
</style>

<style lang="scss">
.custom-eye-icon .mdi-eye-outline::before {
  font-size: 22px;
}

.dispensation-items-table .table-wrapper .table {
  max-width: 99.5%;
}

@media (min-width: 769px) {
  .elipsis-size {
    max-width: 1px;
    width: 100%;
  }

  .modal-card.animation-content {
    min-width: 400px;
  }
}
</style>
