<template>
  <form>
    <DetailItem
      v-if="situation >= 9"
      :item="item"
      :qualification-company="qualificationCompany"
      :situation="situation"
    />
    <DocumentsWrapper
      :item="item"
      :qualificationCompany="qualificationCompany"
      :situation="situation"
    />
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

import DetailItem from './DetailItem';
import DocumentsWrapper from './DocumentsWrapper';

export default {
  name: 'SendDocuments',
  components: {
    DetailItem,
    DocumentsWrapper,
  },
  props: {
    items: Array,
    item: Object,
    qualificationCompany: Object,
  },
  computed: {
    ...mapGetters({
      auction: 'obterOfertaCompra',
    }),
    situation() {
      return this.auction.situacao.id;
    },
  },
};
</script>
