<template>
  <div>
    <AppPorcentagem
      ref="inputPercentual"
      v-if="maiorDesconto && (exibirCampoLance || exibirCampoLanceFechado)"
      :calcular-valor="maiorDesconto"
      :item="item"
      :percentual-valido="percentualValido"
      :valores="valores"
      :width="'110'"
      @valor="dados => atualizarValores(dados, 'inputPercentual')"
      @blur="limpaMensagem"
      @keyup.enter.native="enviarLance"
    />

    <div v-if="exibirTagEnviado" class="tag-enviado">
      <h3 style="font-weight: bold">
        {{ `${formatarPorcentagem(lanceFechadoEnviado.percentual)}%` }}
      </h3>
    </div>
  </div>
</template>

<script>
import Big from 'big.js';

import { mapGetters } from 'vuex';
import campoMixin from '@/mixins/formulario';
import auctionValuesMixin from '@/mixins/auctionValues';

import { formatarValor, formatarPorcentagem } from '@/utils/format';

import { funcoes } from '@/utils/enumerados';

import AppPorcentagem from '@/components/AppPorcentagem';

export default {
  name: 'PregaoPercentual',
  props: {
    item: Object,
    lancesFechadosEmEnvio: Array,
    valores: Object,
  },
  components: {
    AppPorcentagem,
  },
  mixins: [campoMixin, auctionValuesMixin],
  data() {
    const lance = {
      ofertaCompraItemId: 0,
      identificador: 0,
      descricao: '',
      valor: 0,
      percentual: 0,
      invalido: false,
      valores: {},
    };

    return {
      lance,
      formatarValor,
      formatarPorcentagem,
    };
  },
  filters: {
    formatarValor,
  },
  computed: {
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
      lances: 'obterOfertaCompraLances',
      funcao: 'getFunction',
    }),
    divulgarReferencial() {
      return this.ofertaCompra.divulgar_referencial;
    },
    licitante() {
      return this.funcao === funcoes.LICITANTE;
    },
    percentualValido() {
      const percentual = new Big(this.lance.percentual);
      const percentualLance = new Big(this.item.menor_lance.percentual);

      if (this.situacao === 8) {
        return percentual.gt(percentualLance) && percentual.lt(100);
      }

      return percentual.gt(0) && percentual.lt(100);
    },
    valoresMascara() {
      return this.$refs.inputPercentual.valoresMascara;
    },
  },
  methods: {
    focus() {
      if ((this.exibirCampoLanceFechado && this.modoFechado) || this.exibirCampoLance) {
        if (this.maiorDesconto) {
          this.$refs.inputPercentual.focus();
        }
      }
    },
  },
};
</script>

<style scoped>
.detail-container {
  font-size: 0.9rem;
  margin: 0%;
}

.tag-enviado {
  background-color: #2153b5;
  color: #fff;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 1.5em;
  -webkit-box-pack: center;
}
</style>
