<template>
  <b-select
    class="select-company-button"
    placeholder="Selecionar Empresa"
    @input="value => emitValue(value)"
  >
    <option v-for="(company, index) in companies" :key="index" :value="company">{{
      company.socialDenomination
    }}</option>
  </b-select>
</template>

<script>
import { mapGetters } from 'vuex';

import { buscarItemAdesaoEmpresas } from '@/resources/itens';

export default {
  name: 'SelecionarEmpresaAdesao',
  props: {
    item: Object,
  },
  data() {
    return {
      companies: [],
    };
  },
  computed: {
    ...mapGetters({
      auction: 'obterOfertaCompra',
    }),
  },
  methods: {
    emitValue(value) {
      this.$emit('selecionar-empresa', value);
    },
  },
  async created() {
    const { data: response } = await buscarItemAdesaoEmpresas(this.auction.id, this.item.id);
    this.companies = response.data;
  },
};
</script>

<style scoped>
.select-company-button {
  margin-right: 0.2rem;
  margin-bottom: 0.8rem;
  text-align: center !important;
}
</style>
