<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <form @keyup.enter="adicionar">
      <div v-if="indexedStep" class="controls-container is-flex">
        <b-field expanded grouped class="column-direction-touch">
          <b-field expanded id="import-items-field">
            <div expanded class="field">
              <b-upload
                expanded
                v-model="dados.file"
                @input="
                  {
                    resetAuctionImportItemsUpload(), importItems();
                  }
                "
              >
                <a class="button is-primary is-fullwidth" :class="{ 'is-loading': loading.import }">
                  <b-icon icon="import"></b-icon>
                  <span>Importar Itens</span>
                </a>
              </b-upload>
            </div>
          </b-field>

          <b-field>
            <b-button
              expanded
              icon-left="file-download"
              type="is-primary"
              @click.prevent.stop="fetchImportItemsModel()"
            >
              Modelo Importação
            </b-button>
          </b-field>
        </b-field>

        <b-progress
          show-value
          class="upload-progress"
          format="percent"
          type="is-info"
          size="is-medium"
          :value="auctionImportItemsUpload"
        ></b-progress>
      </div>

      <b-field
        expanded
        label="Descrição Detalhada"
        :type="campoInvalido('descricao') ? 'is-danger' : ''"
        :message="campoInvalido('descricao') ? 'Descrição Detalhada é um campo obrigatório' : ''"
      >
        <b-autocomplete
          expanded
          open-on-focus
          class="auction-items-autocomplete"
          custom-class="uppercase"
          placeholder="Pesquise por descrição ou código"
          field="description"
          maxlength="2000"
          v-model="dados.descricao"
          :data="pesquisa"
          :loading="loading.descricao"
          @blur="evento => converterMaiusculo('descricao', evento.target.value)"
          @input="pesquisarDadosItens"
          @select="selecionarItem"
        >
          <template v-slot:empty
            >Nenhum resultado para {{ dados.descricao.toUpperCase() }}</template
          >

          <template v-slot:header>
            <div class="media header-complete">
              <div class="media-left code-media">
                <b>Código</b>
              </div>

              <div class="media-content description-media">
                <b>Descrição Detalhada</b>
              </div>

              <div class="media-right type-media">
                <b>Tipo</b>
              </div>
            </div>
          </template>

          <template v-slot="props">
            <div class="media">
              <div class="media-left code-media">{{ props.option.code }}</div>
              <div class="media-content description-media" :class="{ 'justify-align': justify }">
                {{ props.option.description }}
              </div>
              <div class="media-right type-media">
                {{ props.option.type | formatType }}
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>

      <b-field expanded grouped class="column-direction-touch">
        <b-field
          expanded
          label="Tipo"
          :type="campoInvalido('tipo') ? 'is-danger' : ''"
          :message="campoInvalido('tipo') ? 'Selecione um tipo válido' : ''"
        >
          <b-select
            :disabled="avoidTypeChange()"
            expanded
            placeholder="Tipo"
            v-model="$v.dados.tipo.$model"
          >
            <option v-for="type in types" :key="type.value" :value="type.value">
              {{ type.name }}
            </option>
          </b-select>
        </b-field>

        <b-field
          v-if="isNotLotType"
          expanded
          label="Unidade"
          :type="campoInvalido('unidade') ? 'is-danger' : ''"
          :message="campoInvalido('unidade') ? 'Selecione uma unidade válida' : ''"
        >
          <b-select expanded placeholder="Unidade" v-model="$v.dados.unidade.$model">
            <option v-for="unit in units" :key="unit.id" :value="unit.name">{{ unit.name }}</option>
          </b-select>
        </b-field>

        <AppValor
          fieldGrow
          v-if="!maiorDesconto && (!thereIsLots || !isNotLotType)"
          ref="reducaoLances"
          :cancelar-validacao="false"
          :carregarValor="carregarDadosItem"
          :label="'Redução Mínima Lances'"
          :placeholder="'Valor'"
          :valor-monetario="dados.reducaoLances"
          :valor-valido="valorReducaoValido"
          @valor="dados => atualizarReducao(dados, 'valor')"
        />

        <AppPorcentagem
          fieldGrow
          v-if="maiorDesconto && (!thereIsLots || !isNotLotType)"
          ref="reducaoLancesPercentual"
          :cancelar-validacao="false"
          :carregarPercentual="carregarDadosItem"
          :label="'Redução Mínima Lances'"
          :placeholder="'Percentual'"
          :percentual-valido="percentualReducaoValido"
          :valor-percentual="dados.reducaoLancesPorcentagem"
          @valor="
            dados => {
              atualizarReducao(dados, 'percentual');
            }
          "
        />

        <b-field
          v-if="thereIsLots && isNotLotType"
          expanded
          label="Lote"
          :type="campoInvalido('lote') ? 'is-danger' : ''"
          :message="campoInvalido('lote') ? 'Selecione um lote válido' : ''"
        >
          <b-select expanded placeholder="Lote" v-model="$v.dados.lote.$model">
            <option v-for="lot in lots" :key="lot.value" :value="lot.value">{{ lot.name }}</option>
          </b-select>
        </b-field>

        <b-field v-if="displayExclusiveCheck" label="Exclusivo">
          <b-checkbox
            class="exclusive-check"
            size="is-large"
            v-model="dados.exclusivo"
          ></b-checkbox>
        </b-field>

        <b-field v-if="!isNotLotType">
          <button
            class="button is-primary is-fullwidth adicionar save-button"
            :class="{ ' is-loading': loading.botao }"
            @click.stop.prevent="adicionar"
          >
            {{ modo === 'editar' ? 'Salvar' : 'Adicionar' }}
          </button>
        </b-field>
      </b-field>

      <b-field class="values-field is-flex-touch column-direction-touch" grouped>
        <b-field
          v-if="isNotLotType"
          expanded
          label="Quantidade"
          :type="campoInvalido('quantidade') ? 'is-danger' : ''"
          :message="campoInvalido('quantidade') ? 'Selecione uma quantidade válida' : ''"
          @input.native="
            evento => {
              verificarTipoNegociacao();
            }
          "
        >
          <b-input
            placeholder="Quantidade"
            v-mascara:valor.integer
            v-model="valoresMascara.quantidade"
            @input.native="evento => atualizarValor('quantidade', evento)"
          />
        </b-field>

        <AppValor
          v-if="isNotLotType"
          fieldGrow
          :cancelar-validacao="false"
          :carregarValor="carregarDadosItem"
          ref="referencialUnitario"
          :label="'Referencial Unitário'"
          :placeholder="'Unitário'"
          :valor-monetario="dados.referencia"
          @valor="
            dados => {
              atualizarReferencial(dados, 'unitario');
            }
          "
        />

        <AppValor
          v-if="isNotLotType"
          fieldGrow
          ref="referencialTotal"
          :cancelar-validacao="false"
          :carregarValor="carregarDadosItem"
          :label="'Referencial Total'"
          :placeholder="'Total'"
          :valor-monetario="dados.total"
          @valor="
            dados => {
              atualizarReferencial(dados, 'total');
            }
          "
        />

        <b-field v-if="isNotLotType">
          <button
            class="button is-primary is-fullwidth adicionar save-button"
            :disabled="desabilitar.salvar"
            :class="{ ' is-loading': loading.botao }"
            @click.stop.prevent="adicionar"
          >
            {{ modo === 'editar' ? 'Salvar' : 'Adicionar' }}
          </button>
        </b-field>
      </b-field>
    </form>

    <hr />

    <div v-if="indexedStep && itens.length" class="is-flex is-justify-content-right mb-3">
      <b-field class="delete-button-field">
        <b-button
          expanded
          icon-left="delete-sweep"
          type="is-primary"
          :disabled="!selectedItems.length"
          :loading="loading.deleteAuctionItems"
          @click.prevent.stop="deleteItems()"
        >
          Excluir Itens
        </b-button>
      </b-field>
    </div>

    <b-table
      detailed
      hoverable
      custom-detail-row
      class="items-table-edit is-flex-touch column-direction-touch"
      detail-key="id"
      v-if="itens.length"
      :checkable="indexedStep"
      :data="paginatedItems"
      :opened-detailed.sync="detailsList"
      :has-detailed-visible="row => row.item.tipo === 'LOT'"
      @check="(checkedList, row) => check(checkedList, row)"
    >
      <b-table-column centered label="Exclusivo" v-slot="props" :visible="exclusiveItem">
        <b-icon
          custom-size="mdi-24px"
          icon="check"
          type="is-success"
          v-show="props.row.item.exclusivo"
        />
      </b-table-column>

      <b-table-column centered label="ID" width="40" v-slot="props">
        {{ props.row.identificador }}
      </b-table-column>

      <b-table-column cell-class="elipsis-size" label="Descrição" v-slot="props">
        <div
          class="elipsis-column"
          :class="{
            'justify-align': justify,
          }"
        >
          <a
            :class="{ 'elipsis-column': mobile, 'justify-align': justify }"
            @click.stop.prevent="openDetails(props.row)"
            >{{ props.row.item.descricao }}</a
          >
        </div>
      </b-table-column>

      <b-table-column numeric label="Unidade" v-slot="props">{{
        props.row.unidade.nome
      }}</b-table-column>

      <b-table-column numeric label="Qtde." v-slot="props">
        <span v-if="props.row.item.tipo !== 'LOT'">{{
          props.row.quantidade | formatarNumero
        }}</span>
      </b-table-column>

      <b-table-column numeric label="Unitário" v-slot="props">
        <span v-if="props.row.item.tipo !== 'LOT'">{{
          Number(props.row.referencia) | formatarValor
        }}</span>
      </b-table-column>

      <b-table-column numeric label="Valor Total" v-slot="props">
        {{ Number(props.row.total) | formatarValor }}
      </b-table-column>

      <b-table-column numeric label="Redução" v-slot="props">
        <b-tooltip
          v-if="maiorDesconto && props.row.referencia && props.row.total"
          :label="formatarValor(props.row.reducaoLances)"
        >
          <span>{{ `${formatarPorcentagem(props.row.reducaoLancesPorcentagem)}%` }}</span>
        </b-tooltip>

        <span v-else-if="maiorDesconto">{{
          `${formatarPorcentagem(props.row.reducaoLancesPorcentagem)}%`
        }}</span>

        <span v-else>{{ formatarValor(props.row.reducaoLances) }}</span>
      </b-table-column>

      <b-table-column
        centered
        custom-key="opcoes"
        label="Opções"
        v-slot="props"
        :visible="exibeOpcoes"
      >
        <div id="auction-items-options">
          <b-field class="control-buttons">
            <p class="control" v-if="situacao < 4">
              <b-tooltip label="Editar">
                <button class="button is-primary" @click.prevent.stop="editarItem(props.row.id)">
                  <b-icon icon="pencil" size="is-small" />
                </button>
              </b-tooltip>
            </p>

            <p class="control" v-if="situacao === 1">
              <b-tooltip label="Excluir">
                <button
                  class="button is-danger"
                  :disabled="desabilitar.remover"
                  @click.prevent.stop="removerItemOfertaCompra(props.row.id, null)"
                >
                  <b-icon icon="delete" size="is-small" />
                </button>
              </b-tooltip>
            </p>

            <p class="control" v-if="exibirRevogarItem(props.row.estado)">
              <b-tooltip label="Revogar">
                <button
                  class="button is-danger is-outlined"
                  :disabled="desabilitar.revogar"
                  @click.prevent.stop="abrirModal(props.row.id, 'ofertaCompraItemId')"
                >
                  <b-icon icon="cancel" size="is-small" />
                </button>
              </b-tooltip>
            </p>
          </b-field>

          <div v-if="exibirTagRevogado(props.row.estado)" class="tag-revogado">
            <h3 style="font-weight: bold">Revogado</h3>
          </div>
        </div>
      </b-table-column>

      <template v-slot:detail="props">
        <tr v-for="item in props.row.items" :key="item.name" class="item-lote">
          <td class="lote"></td>
          <td v-if="indexedStep">
            <b-checkbox
              @input="
                value => {
                  if (value) {
                    selectedLotItems.push(item.id);
                  } else {
                    const index = selectedLotItems.indexOf(item.id);
                    selectedLotItems.splice(index, 1);
                  }
                }
              "
              :value="selectedLotItems.indexOf(item.id) > -1"
            ></b-checkbox>
          </td>
          <td class="has-text-centered">
            {{ item.identificador }}
          </td>
          <td>
            <div
              class="elipsis-column"
              :class="{
                'justify-align': justify,
              }"
            >
              <a
                :class="{ 'elipsis-column': mobile, 'justify-align': justify }"
                @click.stop.prevent="openDetails(item)"
                >{{ truncate(item.item.descricao, 40) }}</a
              >
            </div>
          </td>
          <td class="has-text-right">
            {{ item.unidade.nome }}
          </td>
          <td class="has-text-right">
            {{ item.quantidade | formatarNumero }}
          </td>
          <td class="has-text-right">
            {{ Number(item.referencia) | formatarValor }}
          </td>
          <td class="has-text-right">
            {{ Number(item.total) | formatarValor }}
          </td>
          <td class="has-text-right"></td>
          <td class="has-text-centered">
            <div id="auction-items-options">
              <b-field class="control-buttons">
                <p class="control" v-if="situacao < 4">
                  <b-tooltip label="Editar">
                    <button class="button is-primary" @click.prevent.stop="editarLoteItem(item)">
                      <b-icon icon="pencil" size="is-small" />
                    </button>
                  </b-tooltip>
                </p>

                <p class="control" v-if="situacao === 1">
                  <b-tooltip label="Excluir">
                    <button
                      class="button is-danger"
                      :disabled="desabilitar.remover"
                      @click.prevent.stop="
                        removerItemOfertaCompra(item.id, item.ofertaCompraItemId)
                      "
                    >
                      <b-icon icon="delete" size="is-small" />
                    </button>
                  </b-tooltip>
                </p>
              </b-field>

              <div v-if="exibirTagRevogado(item.estado)" class="tag-revogado">
                <h3 style="font-weight: bold">Revogado</h3>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:itensLote="props">
        <b-table
          hoverable
          class="description-table is-flex-touch column-direction-touch"
          :data="[...props]"
        >
          <b-table-column :class="{ 'justify-align': justify }" label="Descrição Detalhada"
            ><div :class="{ 'justify-align': justify }">
              {{ props.row.item.descricao }}
            </div></b-table-column
          >

          <b-table-column label="Tipo">{{ props.row.item.tipo | formatType }}</b-table-column>
        </b-table>
      </template>

      <template v-slot:footer>
        <th class="is-hidden-mobile" style="width: 40px">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-numeric">Total:</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-numeric">{{ totalGeral }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
      </template>
    </b-table>

    <AppPagination
      v-if="itens.length"
      :data-filters="{ page: filters.page, perPage: filters.perPage }"
      :number-lines="25"
      :total="itens.length"
      @change-page="page => changePage(page)"
      @change-per-page="perPage => changePerPage(perPage)"
    />

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificativa"
      @justificate="enviar"
    >
      <AppJustification
        v-if="justificativa"
        :displayFailButton="exibirFalha"
        :displaySuccessButton="exibirSucesso"
        :failText="textoFalha"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Big from 'big.js';

import {
  formatarValor,
  formatarNumero,
  formatarPorcentagem,
  formatType,
  truncate,
} from '@/utils/format';

import campo from '@/mixins/formulario';
import itemRegistration from '@/mixins/itemRegistration';

import { fileServer } from '@/utils/functions';
import { funcoes } from '@/utils/enumerados';
import { itemTypes } from '@/utils/data';

import AppJustification from '@/components/AppJustification';
import AppPagination from '@/components/AppPagination';
import AppPorcentagem from '@/components/AppPorcentagem';
import AppValor from '@/components/AppValor';

export default {
  name: 'PregaoEditarItens',
  components: {
    AppJustification,
    AppPagination,
    AppPorcentagem,
    AppValor,
  },
  mixins: [campo, itemRegistration],
  data() {
    const dados = {
      exclusivo: false,
      descricao: '',
      tipo: null,
      lote: null,
      quantidade: 1,
      referencia: 0,
      total: 0,
      reducaoLances: 0,
      reducaoLancesPorcentagem: 0,
      itemSelected: null,
      ofertaCompraItemId: 0,
      unidade: '-',
    };

    const valoresMascara = {
      quantidade: '1',
    };

    const filters = {
      page: 1,
      perPage: 25,
    };

    const loading = {
      botao: false,
      deleteAuctionItems: false,
      descricao: false,
      general: false,
      import: false,
    };

    const desabilitar = {
      remover: false,
      revogar: false,
      salvar: false,
    };

    return {
      cachedlots: [],
      dados,
      filters,
      detailsList: [],
      file: [],
      paginatedItems: [],
      types: [],
      valoresMascara,
      loading,
      desabilitar,
      item: '',
      modo: '',
      textoFalha: '',
      exibirSucesso: false,
      exibirFalha: false,
      justificativa: false,
      exibirBotaoRevogarItem: false,
      carregarDadosItem: false,
      formatarValor,
      truncate,
      formatarPorcentagem,
      uploadLimit: 5242880,
      selectedItems: [],
      selectedLotItems: [],
    };
  },
  filters: {
    formatarValor,
    truncate,
    formatarNumero,
    formatType,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('itens', ['units']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      auctionImportItemsUpload: 'getAuctionImportItemsUpload',
      ofertaCompra: 'obterOfertaCompra',
      funcao: 'getFunction',
      itens: 'obterTodosItens',
      responsaveis: 'obterOfertaCompraResponsaveis',
    }),
    checkedItem() {
      return itemId => this.selectedItems.indexOf(itemId) > -1;
    },
    autoridade() {
      return this.funcao === funcoes.AUTORIDADE;
    },
    displayExclusiveCheck() {
      return this.ofertaCompra.tipo_edital === 'AMPLOCOTA';
    },
    isNotLotType() {
      return this.dados.tipo !== 'LOT';
    },
    isNotLotItem() {
      return !this.dados.lote;
    },
    thereIsLots() {
      return this.cachedlots.length > 0;
    },
    lots() {
      return this.cachedlots || [];
    },
    exclusiveItem() {
      return this.itens.some(item => item.item.exclusivo);
    },
    indexedStep() {
      return this.situacao === 1;
    },
    justify() {
      return this.windowWidth > 960;
    },
    maiorDesconto() {
      return this.tipoNegociacao === 3;
    },
    percentualReducaoValido() {
      const valorReducao = new Big(Number(this.dados.reducaoLancesPorcentagem));

      return valorReducao.gt(0) && valorReducao.lt(100);
    },
    tipoNegociacao() {
      return this.ofertaCompra.tipo_negociacao.id;
    },
    situacao() {
      return this.ofertaCompra.situacao.id;
    },
  },
  watch: {
    itens: {
      immediate: true,
      deep: true,
      handler() {
        this.updatelots();
      },
    },
    'dados.tipo': function tipoWatcher(newTipo) {
      if (newTipo === 'LOT') {
        this.dados.lote = undefined;
      }
    },
  },
  methods: {
    ...mapActions('itens', ['getItemUnits']),
    ...mapActions([
      'buscarItens',
      'buscarOfertaCompra',
      'atualizarItemComprador',
      'removerItem',
      'removerLoteItem',
      'revogarItem',
      'buscarOfertaCompraResponsaveis',
      'importAuctionItems',
      'resetAuctionImportItemsUpload',
      'getAuctionItems',
      'deleteAuctionItems',
      'createAuctionItem',
      'createAuctionBatchItem',
      'updateAuctionBatchItem',
    ]),
    avoidTypeChange() {
      return this.modo === 'editar' && this.dados.tipo === 'LOT';
    },
    updatelots() {
      const lots = this.itens
        .filter(item => item.item.tipo === 'LOT')
        .map(item => ({
          value: item.id,
          name: item.item.descricao,
        }));
      this.cachedlots = lots;
      const itensAvulsos = this.itens.some(item => item.item.tipo !== 'LOT');
      if (itensAvulsos) {
        this.types = itemTypes.filter(registro => !['ALL', 'LOT'].includes(registro.value));
      } else {
        this.types = itemTypes.filter(itemType => itemType.value !== 'ALL');
      }
    },
    checkAll(checkedList) {
      this.selectedItems = [];
      this.selectedLotItems = [];
      if (checkedList.length > 0) {
        this.selectedItems.push(...checkedList.map(elem => elem.id));
        this.paginatedItems.forEach(element => {
          if (element.items.length > 0) {
            this.selectedLotItems.push(...element.items.map(elem => elem.id));
          }
        });
      }
    },
    checkLote(row) {
      const indexLote = this.selectedItems.indexOf(row.id);
      if (indexLote > -1) {
        this.selectedItems.splice(indexLote, 1);
        row.items.forEach(element => {
          const indexItem = this.selectedItems.indexOf(element.id);
          this.selectedLotItems.splice(indexItem, 1);
        });
      } else {
        this.selectedItems.push(...[row].map(elem => elem.id));
        this.selectedLotItems.push(...row.items.map(elem => elem.id));
      }
    },
    check(checkedList, row) {
      if (row === undefined) {
        this.checkAll(checkedList);
      } else if (row.item.tipo === 'LOT') {
        this.checkLote(row);
      } else if (checkedList.length > 0) {
        this.selectedItems.push(...checkedList.map(elem => elem.id));
      } else {
        const index = this.selectedItems.indexOf(row.id);
        this.selectedItems.splice(index, 1);
      }
    },
    atualizarReducao(dados, tipoValor) {
      if (tipoValor === 'valor') {
        this.dados.reducaoLances = dados.valor;
      }

      if (tipoValor === 'percentual') {
        this.dados.reducaoLancesPorcentagem = dados.percentual;
      }
    },
    changePage(page) {
      this.filters.page = Number(page);
      this.paginateItems();
    },
    changePerPage(perPage) {
      this.filters.perPage = Number(perPage);
      this.paginateItems();
    },
    paginateItems() {
      const { page, perPage } = this.filters;
      this.paginatedItems = this.itens.slice((page - 1) * perPage, page * perPage);
    },
    async deleteItems() {
      if (this.situacao > 1) {
        const proceed = await this.$confirmacao({
          message:
            'Ao excluir um ou mais itens, todas as propostas serão excluídas e os participantes serão notificados. Deseja prosseguir?',
          cancelText: 'Cancelar',
          confirmText: 'Continuar',
          type: 'is-danger',
          hasIcon: true,
        });
        if (!proceed) {
          return;
        }
      }

      this.loading.deleteAuctionItems = true;
      try {
        await this.deleteAuctionItems({
          auctionId: this.$route.params.id,
          auctionItemsId: this.selectedItems,
        });
        this.selectedItems = [];
        this.paginateItems();
        this.$alerta('Exclusão com sucesso', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir', 'is-danger');
      }
      this.loading.deleteAuctionItems = false;
    },
    exibirRevogarItem(estado) {
      if (
        this.autoridade &&
        [2, 3, 4, 15, 16, 17].indexOf(this.situacao) > -1 &&
        estado !== 'REVOGADO'
      ) {
        this.exibirBotaoRevogarItem = true;
        return true;
      }
      return false;
    },
    fetchImportItemsModel() {
      const url = fileServer('modelo-importar-itens.xlsx', 'files');
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async salvarItem() {
      this.loading.botao = true;
      this.desabilitar.salvar = true;
      const item = {
        itemId:
          this.dados.itemSelected && this.dados.tipo !== 'LOT'
            ? this.dados.itemSelected.code
            : null,
        descricao: this.dados.descricao,
        tipo: this.dados.tipo,
        exclusivo: this.dados.exclusivo,

        unidade: this.dados.tipo === 'LOT' ? null : this.dados.unidade,
        quantidade: this.dados.tipo === 'LOT' ? 1 : this.dados.quantidade,
        referencia: this.dados.tipo === 'LOT' ? 0 : this.dados.referencia,

        reducaoLances: !this.maiorDesconto ? this.dados.reducaoLances : null,
        reducaoLancesPorcentagem: this.maiorDesconto ? this.dados.reducaoLancesPorcentagem : null,
        total: this.dados.tipo === 'LOT' && this.modo !== 'editar' ? 0 : this.dados.total,
        ofertaCompraItemId: this.dados.ofertaCompraItemId,
        idLote: this.dados.lote,
      };
      if (item.tipo !== 'LOT' && (item.referencia <= 0 || item.total <= 0)) {
        this.$alerta('Referência e Total devem ser maior que zero', 'is-danger');
      } else if (this.modo === 'editar') {
        try {
          if (!item.idLote) {
            await this.atualizarItemComprador(item);
          } else {
            await this.updateAuctionBatchItem({
              auctionId: this.$route.params.id,
              auctionItemId: this.dados.ofertaCompraItemId,
              auctionLoteItemId: this.dados.ofertaCompraLoteItemId,
              item,
            });
          }
          this.$alerta('Item atualizado', 'is-success');
          this.limparCampos('ofertaCompra');
          this.modo = '';
          if (item.idLote !== item.ofertaCompraItemId) {
            await this.buscarItens({
              ofertaCompraId: this.$route.params.id,
              listarItensLote: true,
              v2: this.$route.query.v2,
            });
            this.paginateItems();
          }
        } catch (error) {
          this.$alerta('Erro ao editar item', 'is-danger');
        }
      } else {
        try {
          if (!item.idLote) {
            await this.createAuctionItem({ auctionId: this.$route.params.id, item });
          } else {
            await this.createAuctionBatchItem({
              auctionId: this.$route.params.id,
              auctionItemId: this.dados.lote,
              item,
            });
          }
          this.$alerta('Item adicionado', 'is-success');
          this.limparCampos('ofertaCompra');
          this.modo = '';
        } catch (error) {
          this.$alerta('Erro ao adicionar item', 'is-danger');
        }
      }
      this.paginateItems();
      this.loading.botao = false;
      this.desabilitar.salvar = false;
      this.carregarDadosItem = false;
    },
    async montarDadosItem(item, loteItem = false) {
      const selecionar = {
        code: item.item.id,
        description: item.item.descricao,
        type: item.item.tipo,
      };
      this.dados.itemSelected = selecionar;
      this.dados.ofertaCompraItemId = item.ofertaCompraItemId ? item.ofertaCompraItemId : item.id;
      this.dados.ofertaCompraLoteItemId = item.ofertaCompraItemId ? item.id : 0;
      this.dados.descricao = item.item.descricao;
      this.dados.tipo = item.item.tipo;
      this.dados.unidade = item.unidade.nome;
      this.dados.exclusivo = item.item.exclusivo;
      this.dados.quantidade = item.quantidade;
      this.dados.referencia = Number(item.referencia);
      this.dados.lote = item.ofertaCompraItemId;
      this.dados.reducaoLances = loteItem ? 0 : item.reducaoLances;
      this.dados.reducaoLancesPorcentagem = loteItem ? 0 : item.reducaoLancesPorcentagem;
      this.valoresMascara.quantidade = formatarNumero(this.dados.quantidade);
      if (loteItem || !this.dados.lote) {
        this.calcularTotalItem();
      }

      this.$nextTick(() => {
        this.carregarDadosItem = true;
      });
    },
    openDetails(row) {
      if (this.detailsList.length) {
        const openItem = this.detailsList.findIndex(itemLista => itemLista === row.id);
        if (openItem > -1) {
          this.detailsList.splice(openItem, 1);
          return;
        }
        this.$alerta(row.item.descricao, 'is-success', 3000);
      }
      this.detailsList.push(row.id);
    },
    async removerItemOfertaCompra(ofertaCompraItemId, loteId = null) {
      if (this.situacao > 1) {
        const proceed = await this.$confirmacao({
          message:
            'Ao excluir o item, todas as propostas serão excluídas e os participantes serão notificados. Deseja prosseguir?',
          cancelText: 'Cancelar',
          confirmText: 'Continuar',
          type: 'is-danger',
          hasIcon: true,
        });
        if (!proceed) {
          return;
        }
      }

      try {
        if (loteId === null) {
          const itemsLote = this.itens.find(item => item.id === ofertaCompraItemId);
          if (itemsLote.items?.length > 1) {
            const proceed = await this.$confirmacao({
              message: `Ao excluir este lote, todos os ${itemsLote.items.length} itens que o compõe serão excluídos juntamente. Deseja prosseguir?`,
              cancelText: 'Cancelar',
              confirmText: 'Continuar',
              type: 'is-danger',
              hasIcon: true,
            });
            if (!proceed) {
              return;
            }
          }
          this.desabilitar.remover = true;
          await this.removerItem(ofertaCompraItemId);
        } else {
          this.desabilitar.remover = true;
          await this.removerLoteItem({
            ofertaCompraItemId: loteId,
            ofertaCompraItemLoteId: ofertaCompraItemId,
          });
        }
        this.$alerta('Item removido', 'is-success');
        this.paginateItems();
        this.limparCampos('ofertaCompra');
      } catch (error) {
        this.$alerta('Erro ao remover item', 'is-danger');
      }
      this.desabilitar.remover = false;
    },
    async enviar({ justification }) {
      await this.revogarItemOfertaCompra({
        ofertaCompraItemId: this.dados.ofertaCompraItemId,
        justificativa: justification,
      });
    },
    async revogarItemOfertaCompra(dados) {
      this.desabilitar.revogar = true;
      try {
        await this.revogarItem(dados);
        this.$alerta('O item foi revogado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao revogar item', 'is-danger');
      }
      this.desabilitar.revogar = false;
      this.$refs.justificationModal.close();
    },
    async importItems() {
      if (
        ![
          'text/csv',
          'text/plain',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(this.dados.file.type)
      ) {
        this.$alerta('Insira um arquivo com o formato .xlsx ou .csv', 'is-danger');
        return;
      }

      if (this.dados.file.size > this.uploadLimit) {
        this.$alerta('Insira um arquivo com até 5MB', 'is-danger');
        return;
      }

      const data = new FormData();
      data.append('items-import', this.dados.file);

      this.loading.import = true;
      try {
        await this.importAuctionItems({ auctionId: this.$route.params.id, data });
        this.$alerta('Importação com sucesso', 'is-success');
        this.paginateItems();
      } catch (error) {
        if (error?.response?.data?.message === 'ValidationError') {
          this.$snackbar(error.response.data.details.message, 'is-warning', 'is-top', true);
          return;
        }
        this.$alerta('Erro ao realizar importação', 'is-danger');
      } finally {
        this.loading.import = false;
        this.dados.file = [];
      }
    },
  },
  mounted() {
    this.resetAuctionImportItemsUpload();
  },
  async created() {
    this.loading.general = true;
    this.types = itemTypes.filter(itemType => itemType.value !== 'ALL');
    try {
      await Promise.all([
        this.buscarItens({
          ofertaCompraId: this.$route.params.id,
          listarItensLote: true,
          v2: this.$route.query.v2,
        }),
        this.getItemUnits(),
        !this.responsaveis.length
          ? this.buscarOfertaCompraResponsaveis(this.$route.params.id)
          : Promise.resolve(),
        !this.ofertaCompra.id ? this.buscarOfertaCompra(this.$route.params.id) : Promise.resolve(),
      ]);
      this.paginateItems();
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.code-media {
  width: 15%;
}

.control-buttons {
  justify-content: center;
}

.controls-container {
  margin-bottom: 20px;
}

.delete-button-field {
  max-width: 200px;
}

.description-media {
  width: 65%;
  white-space: normal;
}

.description-table {
  font-size: 0.88rem;
}

.elipsis-size {
  max-width: 1px;
  width: 100%;
}

.elipsis-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.exclusive-check {
  margin-left: 1.3rem;
}

.header-complete {
  background-color: LightGray;
}

.import-auction-items-upload {
  margin: 1.25rem 0;
}

.items-table-edit {
  font-size: 0.92rem;
}

.items-title {
  background-color: LightGray;
  padding-right: 32px;
}

.justify-align {
  text-align: justify;
}

pre {
  background-color: #ededed;
  font-family: unset;
  word-break: break-all;
  white-space: pre-wrap;
}

.save-button {
  height: 100%;
  margin-top: 0.4rem;
  width: 100px;
}

.tag-revogado {
  background-color: #f80338;
  color: #fff;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.3em;
  padding-right: 0.3em;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 2em;
  -webkit-box-pack: center;
}

.type-media {
  width: 20%;
}

.upload-progress {
  margin-left: 40px;
  margin-top: 10px;
  width: 100%;
}

.values-field {
  margin-top: 1rem;
}

@media (min-width: 300px) and (max-width: 422px) {
  .elipsis-column {
    max-width: 20ch;
  }
}

@media (min-width: 423px) and (max-width: 545px) {
  .elipsis-column {
    max-width: 25ch;
  }
}

@media (min-width: 546px) and (max-width: 768px) {
  .elipsis-column {
    max-width: 35ch;
  }
}

@media (max-width: 1023px) {
  .code-media,
  .description-media,
  .type-media {
    font-size: 0.8rem;
    white-space: normal;
  }

  .controls-container {
    flex-direction: column;
  }

  .import-auction-items-upload {
    margin-top: 1rem;
  }

  .upload-progress {
    margin-left: unset;
  }
}
</style>

<style lang="scss">
#auction-items-options .field-body .field {
  justify-content: center;
}

.auction-items-autocomplete .dropdown-menu .dropdown-content a.dropdown-item {
  padding-right: 1rem;
}

@media (max-width: 1023px) {
  #import-items-field .field {
    display: flex;
    flex-direction: column;

    .field .upload {
      width: 100%;
    }
  }
}

.item-lote,
.item-lote:hover {
  background-color: #f2efef !important;
}

.lote {
  background-color: white !important;
}
</style>
