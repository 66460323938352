import { adminNotAllowed, protectedPage } from '@/utils/session';

export default [
  {
    path: '/notificacoes',
    name: 'NotificationsList',
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      protectedPage(to, from, next);
    },
    component: () =>
      import(
        /* webpackChunkName: "listagem-notificações" */ '@/views/Notifications/NotificationsList'
      ),
  },
];
