<template>
  <div class="app-wrapper">
    <AppHeader />
    <main class="app-content">
      <router-view :key="$route.path" />
    </main>
    <AppFooter class="app-footer" />
  </div>
</template>

<script>
import { AppHeader, AppFooter } from '@/components/app';

export default {
  name: 'Default',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style lang="scss">
.app-content {
  flex: 1 0 auto;
}

.app-footer {
  flex-shrink: 0;
}

.app-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
