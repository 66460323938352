<template>
  <div id="app" :key="reloadIndex">
    <vue-progress-bar />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SocketResource from '@/resources/socket';

import { handleSession, mountSharedCredentials } from '@/utils/session';

export default {
  name: 'App',
  data() {
    return {
      socketResource: new SocketResource(this.$socket),
    };
  },
  computed: {
    ...mapGetters({
      reloadIndex: 'getReloadIndex',
      logging: 'getLogging',
      user: 'getUser',
    }),
  },
  methods: {
    ...mapActions('app', ['updateWindowWidth']),
    ...mapActions('companies', ['updateCompanyData']),
    ...mapActions('notifications', ['updateUserNotifications']),
    loadResizeListener() {
      window.addEventListener('resize', () => {
        this.updateWindowWidth(window.innerWidth);
      });
    },
    removeResizeListener() {
      window.removeEventListener('resize', () => {
        this.updateWindowWidth(window.innerWidth);
      });
    },
  },
  sockets: {
    connect() {
      window.console.log('conectado');
    },
    disconnect() {
      window.console.log('desconectado');
    },
    'user:notification': function updateCompanyUserNotification(notification) {
      this.updateUserNotifications(notification);
    },
    'company:notifications': function notificationsUpdate(notifications) {
      const notification = notifications.find(element => element.userId === this.user.id);
      if (notification) this.updateUserNotifications(notification);
    },
    'company:update': function updateCompanyData(data) {
      this.updateCompanyData(data);
    },
  },
  watch: {
    loading(loading) {
      if (loading) {
        this.$Progress.start();
      } else {
        this.$Progress.finish();
      }
    },
  },
  async created() {
    handleSession();
    this.loadResizeListener();

    if (!this.logging) {
      await this.socketResource.closeConnection();
      await this.socketResource.openConnection();
    }
  },
  mounted() {
    mountSharedCredentials();
  },
  beforeDestroy() {
    this.removeResizeListener();
  },
};
</script>

<style lang="scss">
// Import Bulma's core
@import '~bulma/sass/utilities/_all';

// Custom tooltip
$tooltip-multiline-sizes: (
  small: 180px,
  medium: 240px,
  large: 300px,
  tablet: 600px,
  extra: 700px,
);

// Set your colors
$primary: #2153b5;
$primary-invert: findColorInvert($primary);
$secondary: #35a1d6;
$secondary-invert: findColorInvert($secondary);
$success: #568259;
$success-invert: findColorInvert($success);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black,
  ),
  'black': (
    $black,
    $white,
  ),
  'light': (
    $light,
    $light-invert,
  ),
  'dark': (
    $dark,
    $dark-invert,
  ),
  'primary': (
    $primary,
    $primary-invert,
  ),
  'info': (
    $info,
    $info-invert,
  ),
  'success': (
    $success,
    $success-invert,
  ),
  'warning': (
    $warning,
    $warning-invert,
  ),
  'danger': (
    $danger,
    $danger-invert,
  ),
  'secondary': (
    $secondary,
    $secondary-invert,
  ),
);

// $size-1: 1rem;

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import '~bulma';
@import '~buefy/src/scss/buefy';

.modal .animation-content {
  max-width: 560px;
}

.columns:last-child {
  margin-bottom: unset;
}

.uppercase {
  text-transform: uppercase;
}

*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}

::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}

@media (max-width: 1407px) {
  .is-title-fullhd {
    max-width: none !important;
    padding: 0 32px;
    width: 100%;
  }
}

.br-4 {
  border-radius: 4px !important;
}

.dropdown-menu .dropdown-content {
  margin-bottom: 1rem;
}

.save-button {
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .modal .animation-content {
    max-width: 90vw;
  }

  .table-wrapper {
    overflow: unset;
  }
}

@media (max-width: 1023px) {
  .column-direction-touch {
    width: 100%;

    .field.is-grouped {
      flex-direction: column;

      .field:not(:last-child) {
        margin-bottom: 15px;
      }

      .break-attachment-field .field-body .field {
        display: block;
        flex-wrap: wrap;
      }

      .field:not(:last-child) {
        margin-right: unset;
      }
    }
  }
}
</style>
