import Vue from 'vue';
import VueSocketIoExtended from 'vue-socket.io-extended';
import socketIo from 'socket.io-client';
import store from '@/vuex/store';

const basePath = process.env.VUE_APP_API_PATH;

Vue.use(
  VueSocketIoExtended,
  socketIo(basePath, {
    debug: true,
    autoConnect: false,
    timeout: 3000,
  }),
  { store },
);
