<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <template v-if="usuario.temPin">
          <h3 class="title has-text-white">Informe seu PIN</h3>
          <div class="box">
            <form @submit.prevent="validaPin">
              <b-field>
                <b-input
                  ref="pin"
                  placeholder="PIN"
                  type="password"
                  v-model="pin"
                  password-reveal
                />
              </b-field>
              <a
                :class="['button is-block is-primary is-large', estaValidando ? 'is-loading' : '']"
                @click.prevent="validaPin"
                >Continuar</a
              >
            </form>
          </div>
        </template>
        <div class="box" v-else>
          <p>Não tem PIN? Clique para cadastrar o seu PIN.</p>
          <router-link
            :to="{
              name: 'ChangePin',
              params: { id: usuario.id },
            }"
          >
            <button class="button is-primary botao-criar-pin">Criar PIN</button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Pin',
  props: {
    reason: { type: String, default: '' },
  },
  data() {
    return {
      pin: '',
      estaValidando: false,
    };
  },
  computed: {
    ...mapGetters({
      pinValido: 'getValidPin',
      usuario: 'getUser',
    }),
  },
  methods: {
    ...mapActions(['validatePin']),
    async validaPin() {
      this.estaValidando = true;
      if (this.pin === '') {
        this.$alerta('PIN inválido!', 'is-danger');
        this.estaValidando = false;
      } else {
        try {
          await this.validatePin({ pin: this.pin, reason: this.reason });
          this.$parent.close();
          if (this.pinValido) {
            this.$emit('validar', true);
          }
        } catch (erro) {
          this.$alerta('PIN inválido!', 'is-danger');
        }
        this.estaValidando = false;
      }
    },
  },
  mounted() {
    if (this.usuario.temPin) this.$refs.pin.focus();
  },
};
</script>

<style lang="scss" scoped>
.botao-criar-pin {
  margin-top: 0.6rem;
}
</style>
