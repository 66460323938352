import {
  saveConfigurations,
  searchConfigurations,
} from '@/resources/companies/companiesConfigurations';

const types = {
  COMPANY_CONFIGURATIONS: 'COMPANY_CONFIGURATIONS',
  COMPANY_CONFIGURATIONS_UPDATE: 'COMPANY_CONFIGURATIONS_UPDATE',
};

const mutations = {
  [types.COMPANY_CONFIGURATIONS](state, data) {
    if (data && typeof data === 'object') {
      state.configurations = data;
    }
  },
  [types.COMPANY_CONFIGURATIONS_UPDATE](state, data) {
    if (data && typeof data === 'object') {
      state.configurations = data;
    }
  },
};

const actions = {
  async saveConfigurations({ commit }, data) {
    const { companyId } = data;
    const response = await saveConfigurations(companyId, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_CONFIGURATIONS_UPDATE, response.data);
  },
  async searchConfigurations({ commit }, data) {
    const { companyId } = data;
    const response = await searchConfigurations(companyId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY_CONFIGURATIONS, response.data);
  },
};

const getters = {
  getConfigurations(state) {
    return state.configurations;
  },
};

const state = {
  configurations: [],
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export { types };
