import {
  getAuctionClarifications,
  createAuctionClarification,
  answerAuctionClarification,
} from '@/resources/electronicAuctions/auctionsClarifications';

const types = {
  CLARIFICATION_NEW: 'CLARIFICATION_NEW',
  CLARIFICATION_RESPONSE: 'CLARIFICATION_RESPONSE',
  CLARIFICATIONS: 'CLARIFICATIONS',
};

const mutations = {
  [types.CLARIFICATIONS](state, clarifications) {
    if (clarifications && Array.isArray(clarifications)) {
      const updatedClarifications = clarifications.map(clarification => {
        const clarificationClone = { ...clarification };

        clarificationClone.createdAt = new Date(clarification.createdAt);
        if (clarificationClone.response.date) {
          clarificationClone.response.date = new Date(clarificationClone.response.date);
        }

        return clarificationClone;
      });

      state.clarifications = updatedClarifications;
    }
  },

  [types.CLARIFICATION_RESPONSE](state, clarification) {
    if (clarification && typeof clarification === 'object') {
      const index = state.clarifications.findIndex(element => element.id === clarification.id);
      if (index > -1) {
        state.clarifications.splice(index, 1, clarification);
      }
    }
  },
  [types.CLARIFICATION_NEW](state, clarification) {
    if (clarification && typeof clarification === 'object') {
      const index = state.clarifications.findIndex(element => element.id === clarification.id);
      if (index > -1) {
        state.clarifications.splice(index, 1, clarification);
      } else {
        state.clarifications.unshift(clarification);
      }
    }
  },
};

const actions = {
  async answerAuctionClarification(_, { auctionId, clarificationId, data }) {
    const response = await answerAuctionClarification(auctionId, clarificationId, data);
    if (response.status !== 200) return Promise.reject(response.data);
    return response.data;
  },
  async createAuctionClarification(_, { auctionId, data }) {
    const response = await createAuctionClarification(auctionId, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return response.data;
  },
  async getAuctionClarifications({ commit }, ofertaCompraId) {
    const response = await getAuctionClarifications(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.CLARIFICATIONS, response.data);
  },
  updateAuctionClarifications({ commit }, clarification) {
    commit(types.CLARIFICATION_NEW, clarification);
  },
};

const state = {
  clarifications: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
