import ItemPesquisa from '@/views/Item/ItemPesquisa';

import ItemItem from '@/views/Item/Item';
import ItemNegociacoes from '@/views/Item/ItemNegociacoes';
import ItemPrecos from '@/views/Item/ItemPrecos';
import ItemFornecedores from '@/views/Item/ItemFornecedores';

import { adminNotAllowed } from '@/utils/session';

export default [
  {
    path: '/itens',
    name: 'ItemPesquisa',
    component: ItemPesquisa,
    beforeEnter: adminNotAllowed,
  },
  {
    path: '/itens/:id',
    redirect: { name: 'ItemNegociacoes' },
    name: 'Item',
    component: ItemItem,
    children: [
      {
        path: 'negociacoes',
        name: 'ItemNegociacoes',
        component: ItemNegociacoes,
      },
      {
        path: 'precos',
        name: 'ItemPrecos',
        component: ItemPrecos,
      },
      {
        path: 'fornecedores',
        name: 'ItemFornecedores',
        component: ItemFornecedores,
      },
    ],
  },
];
