<template>
  <div class="columns without-margin">
    <div class="column is-half without-padding">
      <div class="is-relative container-login">
        <div class="container-logo">
          <img :src="logo" alt="" class="logo-image" />
          <p class="logo-text">
            Reunindo um sistema eletrônico integrado de licitação inédito do Brasil.
          </p>
        </div>

        <img :src="groupImage" alt="" class="group-image" />
      </div>
    </div>

    <div class="column is-half has-text-centered without-padding">
      <div class="container-login-form">
        <LoginForm class="login-form" />
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/images/logo-login.svg';
import groupImage from '@/assets/images/img-login.svg';

import LoginForm from '@/views/User/Login';

export default {
  name: 'LoginWrapper',
  components: {
    LoginForm,
  },
  data() {
    return {
      groupImage,
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Gotham Light';
  src: url('~@/assets/fonts/gotham/gotham-htf-light.woff2') format('woff2'),
    url('~@/assets/fonts/gotham/gotham-htf-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

.container-login {
  justify-content: center;
  align-items: center;
  background-color: #177caf;
  height: 100vh;
}

.container-login-form {
  align-items: center;
  background-color: #0e6e9e;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100vh;
}

.container-logo {
  position: absolute;
  top: 14%;
  left: 10%;
  right: 10%;
  justify-content: center;
}

.logo-text {
  margin-top: 0.5rem;
}

.without-padding {
  padding: 0;
}

.without-margin {
  margin: 0;
}

.logo-text {
  font-family: 'Gotham Light';
  font-size: 20px;
  color: white;
  font-weight: 500;
}

.group-image {
  position: absolute;
  top: 54%;
}

@media (min-width: 1500px) {
  .logo-text {
    font-size: 1.8rem;
  }

  .login-form {
    position: absolute;
    left: 25%;
    top: 15%;
  }
}

@media (max-width: 1023px) {
  .login-form {
    max-width: 90%;
  }
}

@media (min-height: 768px) {
  .container-logo {
    top: 16%;
  }
}
</style>
