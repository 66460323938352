import Vue from 'vue';

import {
  todas as dadosEmpresasPendentes,
  obter as porId,
  validacoesEmpresa,
  atualizar as atualizarValidacao,
  solicitarValidacao,
  fetchLastValidation,
} from '@/resources/empresasPendentes';

const types = {
  COMPANY_LAST_VALIDATION: 'COMPANY_LAST_VALIDATION',
  EMPRESAS_PENDENTES: 'EMPRESAS_PENDENTES',
  EMPRESAS_PENDENTES_LOADING: 'EMPRESAS_PENDENTES_LOADING',
  EMPRESAS_PENDENTES_TOTAL: 'EMPRESAS_PENDENTES_TOTAL',
  EMPRESAS_PENDENTES_ATUALIZAR: 'EMPRESAS_PENDENTES_ATUALIZAR',
  EMPRESAS_PENDENTES_FILTRAR: 'EMPRESAS_PENDENTES_FILTRAR',
  EMPRESAS_PENDENTES_ALTERAR_POR_PAGINA: 'EMPRESAS_PENDENTES_ALTERAR_POR_PAGINA',
  EMPRESAS_PENDENTES_ADMIN_LIMPAR_FILTROS: 'EMPRESAS_PENDENTES_ADMIN_LIMPAR_FILTROS',
  EMPRESA_VALIDACAO: 'EMPRESA_VALIDACAO',
  EMPRESA_VALIDACOES: 'EMPRESA_VALIDACOES',
  UPDATE_INVOICES: 'UPDATE_INVOICES',
};

const mutations = {
  [types.COMPANY_LAST_VALIDATION](state, data) {
    if (data && typeof data === 'object') {
      state.lastValidation = data;
    }
  },
  [types.EMPRESAS_PENDENTES](state, data) {
    if (data && Array.isArray(data)) {
      state.empresasPendentes = data;
    } else if (data && typeof data === 'object') {
      state.empresasPendentes.push(data);
    }
  },
  [types.EMPRESAS_PENDENTES_LOADING](state, data) {
    state.loadingEmpresasPendentes = data;
  },
  [types.EMPRESAS_PENDENTES_TOTAL](state, data) {
    state.total = data;
  },
  [types.EMPRESAS_PENDENTES_FILTRAR](state, filters) {
    if (filters.page) {
      Vue.set(state.dataFilters, 'page', filters.page);
    }

    if (filters.perPage) {
      Vue.set(state.dataFilters, 'perPage', filters.perPage);
    }
    if (filters.period) {
      Vue.set(state.dataFilters.period, 'start', filters.period.start);
      Vue.set(state.dataFilters.period, 'end', filters.period.end);
    }
    if (filters.status) {
      Vue.set(state.dataFilters, 'status', filters.status);
    }
    Vue.set(state.dataFilters, 'contextFilter', filters.contextFilter);
  },
  [types.EMPRESAS_PENDENTES_ALTERAR_POR_PAGINA](state, perPage) {
    state.dataFilters.perPage = perPage;
    state.dataFilters.page = 1;
  },
  [types.EMPRESAS_PENDENTES_ADMIN_LIMPAR_FILTROS](state) {
    state.dataFilters = {
      contextFilter: null,
      status: 'todas',
      period: {
        start: null,
        end: null,
      },
      page: 1,
      perPage: 20,
    };
  },
  [types.EMPRESAS_PENDENTES_ATUALIZAR](state, data) {
    if (data && Array.isArray(data)) {
      state.empresasPendentes = data;
    } else if (data && typeof data === 'object') {
      const index = state.empresasPendentes.findIndex(
        empresaPendente => empresaPendente.id === data.id,
      );
      if (index > -1) {
        Vue.set(state.empresasPendentes, index, data);
      }
    }
  },
  [types.EMPRESA_VALIDACAO](state, data) {
    if (data && typeof data === 'object') {
      state.empresaValidacao = data;
    }
  },
  [types.EMPRESA_VALIDACOES](state, data) {
    if (data && Array.isArray(data)) {
      state.empresaValidacoes = data;
    } else if (data && typeof data === 'object') {
      state.empresaValidacoes.push(data);
    }
  },
  [types.UPDATE_INVOICES](state, data) {
    if (data && Array.isArray(data.data)) {
      state.invoices = data.data;
    }
  },
};

const actions = {
  async buscarEmpresasPendentes({ commit }, filtros = {}) {
    commit(types.EMPRESAS_PENDENTES_LOADING, true);
    const empresasPendentes = await dadosEmpresasPendentes(filtros);
    commit(types.EMPRESAS_PENDENTES, empresasPendentes.data.data);
    commit(types.EMPRESAS_PENDENTES_TOTAL, empresasPendentes.data.count);
    commit(types.EMPRESAS_PENDENTES_LOADING, false);
  },
  async buscarEmpresaValidacao({ commit }, validacaoId) {
    const response = await porId(validacaoId);
    const { data: validation } = response;
    if (response.status !== 200) return Promise.reject(validation.data);
    return commit(types.EMPRESA_VALIDACAO, validation.data);
  },
  async buscarEmpresaValidacoes({ commit }, empresaId) {
    const response = await validacoesEmpresa(empresaId);
    const { data: validations } = response;
    if (response.status !== 200) return Promise.reject(validations.data);
    return commit(types.EMPRESA_VALIDACOES, validations.data);
  },
  async fetchLastValidation({ commit }, companyId) {
    const response = await fetchLastValidation(companyId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.COMPANY_LAST_VALIDATION, response.data);
  },
  async solicitarValidacaoEmpresa({ commit }, empresaId) {
    const response = await solicitarValidacao(empresaId);
    if (response.status !== 200) return Promise.reject(response);
    const { data: validations } = response;
    const pendingCompany = validations.data.some(validation => validation.status === 'PENDENTE');
    if (pendingCompany) {
      commit('companies/COMPANY_STATUS_UPDATE', 'INABILITADA');
    }
    commit(types.EMPRESAS_PENDENTES, validations.data);
    commit(types.EMPRESA_VALIDACAO, validations.data);
    return commit(types.EMPRESA_VALIDACOES, validations.data);
  },
  async validarEmpresa({ commit, state }, dados) {
    const validacaoId = state.empresaValidacao.id;
    const { data: validacao } = await atualizarValidacao(validacaoId, dados);
    if (validacao.data.status === 'VALIDA') {
      commit('companies/COMPANY_STATUS_UPDATE', 'HABILITADA');
    }
    if (validacao.data.status === 'INVALIDA') {
      commit('companies/COMPANY_STATUS_UPDATE', 'INABILITADA');
    }
    commit(types.EMPRESAS_PENDENTES_ATUALIZAR, validacao.data);
    commit(types.EMPRESA_VALIDACAO, validacao.data);
  },
  filtrarEmpresasPendentes({ dispatch, commit, state }, filters) {
    const dataFilters = filters ? { ...filters } : state.dataFilters;
    commit(types.EMPRESAS_PENDENTES_FILTRAR, dataFilters);
    dispatch('buscarEmpresasPendentes', state.dataFilters);
  },
  alterarPorPaginaPendentes({ dispatch, commit, state }, perPage) {
    commit(types.EMPRESAS_PENDENTES_ALTERAR_POR_PAGINA, perPage);
    dispatch('buscarEmpresasPendentes', state.dataFilters);
  },
};

const getters = {
  lastValidation(state) {
    return state.lastValidation;
  },
  obterEmpresasPendentes(state) {
    return state.empresasPendentes;
  },
  obterEmpresaValidacao(state) {
    return state.empresaValidacao;
  },
  obterEmpresaValidacoes(state) {
    return state.empresaValidacoes;
  },
  obterLoadingEmpresasPendentes(state) {
    return state.loadingEmpresasPendentes;
  },
  obterTotalEmpresasPendentes(state) {
    return state.total;
  },
  obterDadosFiltrosEmpresasPendentes(state) {
    return state.dataFilters;
  },
};

const state = {
  empresasPendentes: [],
  empresaValidacao: {},
  empresaValidacoes: [],
  lastValidation: {},
  loadingEmpresasPendentes: false,
  total: 0,
  dataFilters: {
    contextFilter: null,
    status: 'todas',
    period: {
      start: null,
      end: null,
    },
    page: 1,
    perPage: 20,
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
