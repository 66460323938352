import {
  abdicarDireito,
  adesaoMenorPreco,
  fetchAuctionNegotiations,
  fetchDispensationNegotiations,
  fetchItemUnits,
  fetchNegotiationValues,
  obter as dadosItem,
  pesquisa as dadosPesquisaItens,
} from '../../resources/itens';

import { types as typesLances } from './lances';

const ITENS = 'ITENS';
const ITENS_TOTAL = 'ITENS_TOTAL';
const ITEM = 'ITEM';
const ITEM_AUCTION_NEGOTIATIONS = 'ITEM_AUCTION_NEGOTIATIONS';
const ITEM_AUCTION_NEGOTIATIONS_COUNT = 'ITEM_AUCTION_NEGOTIATIONS_COUNT';
const ITEM_DISPENSATION_NEGOTIATIONS = 'ITEM_DISPENSATION_NEGOTIATIONS';
const ITEM_DISPENSATION_NEGOTIATIONS_COUNT = 'ITEM_DISPENSATION_NEGOTIATIONS_COUNT';
const ITEM_NEGOTIATION_VALUES = 'ITEM_NEGOTIATION_VALUES';
const ITEM_UNITS = 'ITEM_UNITS';
const LIMPAR_PESQUISA = 'LIMPAR_PESQUISA';

const mutations = {
  [ITENS](state, itens) {
    if (itens && Array.isArray(itens)) {
      state.itens = itens;
    }
  },
  [ITENS_TOTAL](state, data) {
    state.total = data;
  },
  [ITEM](state, item) {
    if (item && typeof item === 'object') {
      state.item = item;
    }
  },
  [ITEM_NEGOTIATION_VALUES](state, data) {
    state.negotiationValues.auction.average = data.auction.average;
    state.negotiationValues.auction.total = data.auction.total;
    state.negotiationValues.dispensation.average = data.dispensation.average;
    state.negotiationValues.dispensation.total = data.dispensation.total;
  },
  [ITEM_AUCTION_NEGOTIATIONS](state, negotiations) {
    if (negotiations && Array.isArray(negotiations)) {
      state.auctionNegotiations = negotiations;
    }
  },
  [ITEM_AUCTION_NEGOTIATIONS_COUNT](state, count) {
    state.auctionNegotiationsCount = count;
  },
  [ITEM_DISPENSATION_NEGOTIATIONS](state, negotiations) {
    if (negotiations && Array.isArray(negotiations)) {
      state.dispensationNegotiations = negotiations;
    }
  },
  [ITEM_DISPENSATION_NEGOTIATIONS_COUNT](state, count) {
    state.dispensationNegotiationsCount = count;
  },
  [ITEM_UNITS](state, data) {
    if (data && Array.isArray(data)) {
      state.units = data;
    }
  },
  [LIMPAR_PESQUISA](state) {
    state.itens = [];
    state.total = 0;
  },
};

const actions = {
  async pesquisarItens({ commit }, pesquisa) {
    const { data: itens } = await dadosPesquisaItens(pesquisa);
    commit(ITENS, itens.result);
    commit(ITENS_TOTAL, itens.count);
  },
  async buscarItem({ commit }, itemId) {
    const { data: item } = await dadosItem(itemId);
    commit(ITEM, item.result);
  },
  async abdicarPreferencia({ commit, rootState }, itemId) {
    const response = await abdicarDireito(rootState.ofertasCompra.ofertaCompra.id, itemId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(ITEM, response.data);
  },
  async getItemUnits({ commit }) {
    const response = await fetchItemUnits();
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(ITEM_UNITS, response.data);
  },
  async fetchNegotiationValues({ commit }, itemId) {
    const response = await fetchNegotiationValues(itemId);
    if (response.status !== 200) return Promise.reject();
    return commit(ITEM_NEGOTIATION_VALUES, response.data);
  },
  async fetchAuctionNegotiations({ commit }, { itemId, filters }) {
    const response = await fetchAuctionNegotiations(itemId, filters);
    if (response.status !== 200) return Promise.reject();
    commit(ITEM_AUCTION_NEGOTIATIONS_COUNT, response.data.count);
    return commit(ITEM_AUCTION_NEGOTIATIONS, response.data.data);
  },
  async fetchDispensationNegotiations({ commit }, { itemId, filters }) {
    const response = await fetchDispensationNegotiations(itemId, filters);
    if (response.status !== 200) return Promise.reject();
    commit(ITEM_DISPENSATION_NEGOTIATIONS_COUNT, response.data.count);
    return commit(ITEM_DISPENSATION_NEGOTIATIONS, response.data.data);
  },
  async adesaoMenorPrecoItem({ commit, rootState }, { itemId, aderido }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const { data: lances } = await adesaoMenorPreco(ofertaCompraId, itemId, { aderido });
    commit(typesLances.OFERTA_COMPRA_LANCES, lances.data, { root: true });
  },
  limparPesquisa({ commit }) {
    commit(LIMPAR_PESQUISA);
  },
};

const getters = {
  obterTotalItens(state) {
    return state.total;
  },
};

const state = {
  itens: [],
  units: [],
  item: {
    session: {},
    division: {},
    group: {},
    category: {},
  },
  negotiationValues: {
    auction: {
      average: 0,
      total: 0,
    },
    dispensation: {
      average: 0,
      total: 0,
    },
  },
  auctionNegotiations: [],
  auctionNegotiationsCount: 0,
  dispensationNegotiations: [],
  dispensationNegotiationsCount: 0,
  total: 0,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
