<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <form @keyup.enter="adicionar">
      <b-field
        expanded
        label="Descrição Detalhada"
        :type="campoInvalido('descricao') ? 'is-danger' : ''"
        :message="campoInvalido('descricao') ? 'Descrição Detalhada é um campo obrigatório' : ''"
      >
        <b-autocomplete
          expanded
          open-on-focus
          class="dispensation-items-autocomplete"
          custom-class="uppercase"
          field="description"
          placeholder="Pesquise por descrição ou código"
          maxlength="2000"
          v-model="dados.descricao"
          :data="pesquisa"
          :loading="loading.descricao"
          @blur="evento => converterMaiusculo('descricao', evento.target.value)"
          @input="pesquisarDadosItens"
          @select="selecionarItem"
        >
          <template v-slot:empty
            >Nenhum resultado para {{ dados.descricao.toUpperCase() }}</template
          >

          <template v-slot:header>
            <div class="media header-complete">
              <div class="media-left code-media">
                <b>Código</b>
              </div>

              <div class="media-content description-media">
                <b>Descrição Detalhada</b>
              </div>

              <div class="media-right type-media">
                <b>Tipo</b>
              </div>
            </div>
          </template>

          <template v-slot="props">
            <div class="media">
              <div class="media-left code-media">{{ props.option.code }}</div>
              <div class="media-content description-media" :class="{ 'justify-align': justify }">
                {{ props.option.description }}
              </div>
              <div class="media-right type-media">
                {{ props.option.type | formatType }}
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Tipo"
          :type="campoInvalido('tipo') ? 'is-danger' : ''"
          :message="campoInvalido('tipo') ? 'Selecione um tipo válido' : ''"
        >
          <b-select expanded placeholder="Tipo" v-model="$v.dados.tipo.$model">
            <option v-for="type in types" :key="type.value" :value="type.value">
              {{ type.name }}
            </option>
          </b-select>
        </b-field>

        <b-field
          expanded
          label="Unidade"
          :type="campoInvalido('unidade') ? 'is-danger' : ''"
          :message="campoInvalido('unidade') ? 'Selecione uma unidade válida' : ''"
        >
          <b-select expanded placeholder="Unidade" v-model="$v.dados.unidade.$model">
            <option v-for="unit in units" :key="unit.id" :value="unit.name">{{ unit.name }}</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Quantidade"
          :type="campoInvalido('quantidade') ? 'is-danger' : ''"
          :message="campoInvalido('quantidade') ? 'Selecione uma quantidade válida' : ''"
          @input.native="evento => verificarTipoNegociacao()"
        >
          <b-input
            placeholder="Quantidade"
            v-mascara:valor.integer
            v-model="valoresMascara.quantidade"
            @input.native="evento => atualizarValor('quantidade', evento)"
          />
        </b-field>

        <AppValor
          fieldGrow
          :cancelar-validacao="false"
          :carregarValor="carregarDadosItem"
          ref="referencialUnitario"
          :label="'Referencial Unitário'"
          :placeholder="'Unitário'"
          :valor-monetario="dados.referencia"
          :valor-valido="valorUnitarioValido"
          @valor="
            dados => {
              atualizarReferencial(dados, 'unitario');
            }
          "
        />

        <AppValor
          fieldGrow
          ref="reducaoLances"
          :cancelar-validacao="false"
          :carregarValor="carregarDadosItem"
          :label="'Redução Mínima Lances'"
          :placeholder="'Valor'"
          :valor-monetario="dados.reducaoLances"
          :valor-valido="valorReducaoValido"
          @valor="dados => atualizarReducao(dados)"
        />

        <AppValor
          fieldGrow
          ref="referencialTotal"
          :cancelar-validacao="false"
          :carregarValor="carregarDadosItem"
          :label="'Referencial Total'"
          :placeholder="'Total'"
          :valor-monetario="dados.total"
          :valor-valido="valorTotalValido"
          @valor="
            dados => {
              atualizarReferencial(dados, 'total');
            }
          "
        />

        <b-field>
          <button
            class="button is-primary is-fullwidth adicionar save-button"
            :class="{ ' is-loading': loading.botao }"
            @click.stop.prevent="adicionar"
          >
            {{ modo === 'editar' ? 'Salvar' : 'Adicionar' }}
          </button>
        </b-field>
      </b-field>
    </form>

    <hr />

    <b-table
      detailed
      hoverable
      class="items-table-edit"
      detail-key="id"
      v-if="itens.length"
      :data="itens"
      :opened-detailed.sync="detailsList"
    >
      <b-table-column centered width="40" label="ID" v-slot="props">
        {{ props.row.identificador }}
      </b-table-column>

      <b-table-column cell-class="elipsis-size" label="Descrição" v-slot="props">
        <div
          class="elipsis-column"
          :class="{
            'justify-align': justify,
          }"
        >
          <a
            :class="{ 'elipsis-column': mobile, 'justify-align': justify }"
            @click.stop.prevent="openDetails(props.row)"
            >{{ props.row.item.descricao }}</a
          >
        </div>
      </b-table-column>

      <b-table-column numeric label="Qtde." v-slot="props">{{
        props.row.quantidade | formatarNumero
      }}</b-table-column>

      <b-table-column numeric label="Unidade" v-slot="props">{{
        props.row.unidade.nome
      }}</b-table-column>

      <b-table-column numeric label="Unitário" v-slot="props">{{
        Number(props.row.referencia) | formatarValor
      }}</b-table-column>

      <b-table-column numeric label="Valor Total" v-slot="props">{{
        Number(props.row.total) | formatarValor
      }}</b-table-column>

      <b-table-column numeric label="Redução" v-slot="props">{{
        props.row.reducaoLances | formatarValor
      }}</b-table-column>

      <b-table-column
        centered
        custom-key="opcoes"
        label="Opções"
        v-slot="props"
        :visible="exibeOpcoes"
      >
        <b-field id="dispensation-items-options">
          <p class="control" v-if="situacao < 2">
            <b-tooltip label="Editar">
              <button class="button is-primary" @click.prevent.stop="editarItem(props.row.id)">
                <b-icon icon="pencil" />
              </button>
            </b-tooltip>
          </p>

          <p class="control" v-if="situacao < 2">
            <b-tooltip label="Excluir">
              <button
                class="button is-danger"
                :disabled="desabilitar.remover"
                @click.prevent.stop="removerItem(props.row.id)"
              >
                <b-icon icon="delete" />
              </button>
            </b-tooltip>
          </p>

          <p class="control" v-if="exibirRevogarItem(props.row.estado)">
            <b-tooltip label="Revogar">
              <button
                class="button is-danger"
                :disabled="desabilitar.revogar"
                @click.prevent.stop="abrirModal(props.row.id, 'dispensaItemId')"
              >
                <b-icon icon="cancel" />
              </button>
            </b-tooltip>
          </p>

          <div v-if="exibirTagRevogado(props.row.estado)" class="tag-revogado">
            <h3 style="font-weight: bold">Revogado</h3>
          </div>
        </b-field>
      </b-table-column>

      <template v-slot:detail="props">
        <b-table
          hoverable
          class="description-table is-flex-touch column-direction-touch"
          :data="[...props]"
        >
          <b-table-column :class="{ 'justify-align': justify }" label="Descrição Detalhada"
            ><div :class="{ 'justify-align': justify }">
              {{ props.row.item.descricao }}
            </div></b-table-column
          >

          <b-table-column label="Tipo">{{ props.row.item.tipo | formatType }}</b-table-column>
        </b-table>
      </template>

      <template v-slot:footer>
        <th class="is-hidden-mobile" style="width: 40px">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-numeric">Total:</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-numeric">{{ totalGeral }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap"></div>
        </th>
      </template>
    </b-table>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificativa"
      @justificate="enviar"
    >
      <AppJustification
        v-if="justificativa"
        :displayFailButton="exibirFalha"
        :displaySuccessButton="exibirSucesso"
        :failText="textoFalha"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Big from 'big.js';

import { formatarValor, formatarNumero, formatType } from '@/utils/format';

import campo from '@/mixins/formulario';
import itemRegistration from '@/mixins/itemRegistration';
import { itemTypes } from '@/utils/data';

import AppJustification from '@/components/AppJustification';
import AppValor from '@/components/AppValor';

export default {
  name: 'DispensaEditarItens',
  components: {
    AppJustification,
    AppValor,
  },
  mixins: [campo, itemRegistration],
  data() {
    const dados = {
      dispensaItemId: 0,
      descricao: '',
      tipo: null,
      quantidade: 1,
      referencia: null,
      total: null,
      reducaoLances: null,
      itemSelected: null,
      unidade: null,
    };

    const valoresMascara = {
      quantidade: '1',
      referencia: 'R$ 1,00',
      reducaoLances: 'R$ 0,01',
      total: 'R$ 1,00',
    };

    const loading = {
      general: false,
      botao: false,
      descricao: false,
    };

    const desabilitar = {
      remover: false,
      revogar: false,
    };

    return {
      dados,
      detailsList: [],
      types: [],
      valoresMascara,
      loading,
      desabilitar,
      item: '',
      modo: '',
      textoFalha: '',
      exibirSucesso: false,
      exibirFalha: false,
      justificativa: false,
      exibirBotaoRevogarItem: false,
      carregarDadosItem: false,
      formatarValor,
    };
  },
  filters: {
    formatarValor,
    formatarNumero,
    formatType,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('itens', ['units']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      dispensa: 'obterDispensa',
      funcao: 'getFunction',
      itens: 'obterTodosItensDispensa',
    }),
    justify() {
      return this.windowWidth > 960;
    },
    situacao() {
      return this.dispensa.situacao.id;
    },
    tipoNegociacao() {
      return this.dispensa.tipo_negociacao.id;
    },
    valorUnitarioValido() {
      const valorUnitario = new Big(Number(this.dados.referencia));

      return valorUnitario.gt(0);
    },
    valorTotalValido() {
      const valorTotal = new Big(Number(this.dados.total));

      return valorTotal.gt(0);
    },
  },
  methods: {
    ...mapActions('itens', ['getItemUnits']),
    ...mapActions([
      'buscarItensDispensa',
      'buscarDispensa',
      'adicionarItemDispensa',
      'atualizarItemCompradorDispensa',
      'removerItemDispensa',
      'revogarItemDispensa',
    ]),
    atualizarReducao(dados) {
      this.dados.reducaoLances = dados.valor;
    },
    exibirRevogarItem(estado) {
      if ([2, 3, 4, 15, 16, 17].indexOf(this.situacao) > -1 && estado !== 'REVOGADO') {
        this.exibirBotaoRevogarItem = true;
        return true;
      }
      return false;
    },
    async salvarItem() {
      const item = {
        itemId:
          this.dados.itemSelected && this.dados.tipo !== 'LOT'
            ? this.dados.itemSelected.code
            : null,
        descricao: this.dados.descricao,
        unidade: this.dados.unidade,
        tipo: this.dados.tipo,
        quantidade: this.dados.quantidade,
        referencia: this.dados.referencia,
        reducaoLances: this.dados.reducaoLances,
        total: this.dados.total,
        dispensaItemId: this.dados.dispensaItemId,
      };

      this.loading.botao = true;
      if (this.modo === 'editar') {
        try {
          await this.atualizarItemCompradorDispensa(item);
          this.$alerta('Item atualizado', 'is-success');
          this.limparCampos('dispensa');
          this.modo = '';
        } catch (error) {
          this.$alerta('Erro ao editar item', 'is-danger');
        }
      } else {
        try {
          await this.adicionarItemDispensa(item);
          this.$alerta('Item adicionado', 'is-success');
          this.limparCampos('dispensa');
          this.modo = '';
        } catch (error) {
          this.$alerta('Erro ao adicionar item', 'is-danger');
        }
      }
      this.loading.botao = false;
      this.carregarDadosItem = false;
    },

    async montarDadosItem(item) {
      const selecionar = {
        code: item.item.id,
        description: item.item.descricao,
        type: item.item.tipo,
      };
      this.dados.itemSelected = selecionar;
      this.dados.descricao = item.item.descricao;
      this.dados.tipo = item.item.tipo;
      this.dados.dispensaItemId = item.id;
      this.dados.unidade = item.unidade.nome;
      this.dados.lote = item.lote;
      this.dados.quantidade = item.quantidade;
      this.dados.referencia = Number(item.referencia);
      this.dados.reducaoLances = item.reducaoLances;
      this.valoresMascara.quantidade = formatarNumero(this.dados.quantidade);
      this.valoresMascara.referencia = formatarValor(this.dados.referencia);
      this.valoresMascara.reducaoLances = formatarValor(this.dados.reducaoLances);

      this.calcularTotalItem();
      this.$nextTick(() => {
        this.carregarDadosItem = true;
      });
    },
    openDetails(row) {
      if (this.detailsList.length) {
        const openItem = this.detailsList.findIndex(itemLista => itemLista === row.id);
        if (openItem > -1) {
          this.detailsList.splice(openItem, 1);
          return;
        }
      }
      this.detailsList.push(row.id);
    },
    async removerItem(dispensaItemId) {
      this.desabilitar.remover = true;
      try {
        await this.removerItemDispensa(dispensaItemId);
        this.$alerta('Item removido', 'is-success');
        this.limparCampos('dispensa');
      } catch (error) {
        this.$alerta('Erro ao remover item', 'is-danger');
      }
      this.desabilitar.remover = false;
    },
    async enviar({ justification }) {
      await this.revogarItem({
        dispensaItemId: this.dados.dispensaItemId,
        justificativa: justification,
      });
    },
    async revogarItem(dados) {
      this.desabilitar.revogar = true;
      try {
        await this.revogarItemDispensa(dados);
        this.$alerta('O item foi revogado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao revogar item', 'is-danger');
      }
      this.desabilitar.revogar = false;
      this.$refs.justificationModal.close();
    },
  },
  async created() {
    this.loading.general = true;
    this.types = itemTypes.filter(itemType => itemType.value !== 'ALL');

    try {
      await Promise.all([
        this.buscarItensDispensa(this.$route.params.id),
        this.getItemUnits(),
        !this.dispensa.id ? this.buscarDispensa(this.$route.params.id) : Promise.resolve(),
      ]);
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style scoped>
.code-media {
  width: 15%;
}

.description-media {
  width: 65%;
  white-space: normal;
}

.description-table {
  font-size: 0.88rem;
}

.elipsis-size {
  max-width: 1px;
  width: 100%;
}

.elipsis-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-complete {
  background-color: LightGray;
}

.items-table-edit {
  font-size: 0.92rem;
}

.items-title {
  background-color: LightGray;
  padding-right: 32px;
}

.justify-align {
  text-align: justify;
}

pre {
  background-color: #ededed;
  font-family: unset;
  word-break: break-all;
  white-space: pre-wrap;
}

.save-button {
  height: 100%;
  margin-top: 0.4rem;
  width: 100px;
}

.tag-revogado {
  background-color: #f80338;
  color: #fff;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.3em;
  padding-right: 0.3em;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 2em;
  -webkit-box-pack: center;
}

.type-media {
  width: 20%;
}

.values-field {
  margin-top: 1rem;
}

@media (min-width: 300px) and (max-width: 422px) {
  .elipsis-column {
    max-width: 20ch;
  }
}

@media (min-width: 423px) and (max-width: 545px) {
  .elipsis-column {
    max-width: 25ch;
  }
}

@media (min-width: 546px) and (max-width: 768px) {
  .elipsis-column {
    max-width: 35ch;
  }
}

@media (max-width: 1023px) {
  .code-media,
  .description-media,
  .type-media {
    font-size: 0.8rem;
    white-space: normal;
  }
}
</style>

<style>
.dispensation-items-autocomplete .dropdown-menu .dropdown-content .dropdown-item {
  padding-right: 1rem;
}

#dispensation-items-options .field-body .field {
  justify-content: center;
}
</style>
