<template>
  <div>
    <AppHeader />
    <main>
      <router-view :key="$route.path" />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import { AppHeader, AppFooter } from '@/components/app/admin';

export default {
  name: 'Default',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
