export default {
  methods: {
    campoInvalido(campo, validacao = 'dados') {
      return this.$v[validacao][campo].$invalid && this.$v[validacao][campo].$dirty;
    },
    atualizarValor(field, event, validation = 'dados', maskValues = 'valoresMascara') {
      this.atualizarDado(
        field,
        Number(event.target.vCleave.getRawValue()),
        event.target.vCleave.getFormattedValue(),
        validation,
        maskValues,
      );
    },
    converterMaiusculo(campo, valor, validacao = 'dados') {
      if (this.$v) {
        this.$v[validacao][campo].$model = valor && valor.toUpperCase();
      } else {
        this[validacao][campo] = valor && valor.toUpperCase();
      }
    },
    limparData(campo, field = 'dados', maskField = 'valoresMascara') {
      this[maskField][campo] = null;
      this[field][campo] = null;
    },
    atualizarData(field, value, validation = 'dados', maskValues = 'valoresMascara') {
      if (this[maskValues][field] !== this[validation][field]) {
        this.$v[validation][field].$model = value;
        this.$v[validation][field].$touch();
      }
    },
    inserirHoje(campo, field = 'dados', maskField = 'valoresMascara') {
      this[field][campo] = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      );
      this[maskField][campo] = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      );
    },
    cleanDateFilter(field) {
      this.filters.period[field] = null;
      if (field === 'start') {
        this.filters.period.end = null;
      }
    },
    insertTodayFilter(field) {
      this.filters.period[field] = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      );
    },
    atualizarHora(campo, valor) {
      if (this.valoresMascara[campo] !== this.dados[campo]) {
        this.atualizarDado(campo, valor);
      }
    },
    atualizarDado(
      field,
      value,
      formattedValue = '',
      validation = 'dados',
      maskValues = 'valoresMascara',
    ) {
      if (formattedValue) {
        this[maskValues][field] = formattedValue;
      }
      this.$v[validation][field].$model = value;
      this.$v[validation][field].$touch();
    },
    validar(validacao = 'dados') {
      if (this.$v[validacao].$invalid) {
        this.$v[validacao].$touch();
        this.$alerta('Preencha todo os dados');
        return false;
      }
      return true;
    },
    limparCampos(validacao = 'dados') {
      Object.keys(this[validacao]).forEach(key => {
        this[validacao][key] = null;
      });
      this.$v[validacao].$reset();
    },
    teclasPermitidasValor(evento) {
      if ((evento.charCode >= 48 && evento.charCode <= 57) || evento.charCode === 44) {
        return true;
      }
      return evento.preventDefault();
    },
    teclasPermitidasNumero(evento) {
      if (evento.charCode >= 48 && evento.charCode <= 57) {
        return true;
      }
      return evento.preventDefault();
    },
  },
};
