<template>
  <div>
    <b-loading can-cancel is-full-page :active="loading.loadPage" />

    <div class="columns is-column-reverse-custom">
      <div class="column">
        <div>
          <b-upload
            multiple
            drag-drop
            v-if="bidder"
            class="custom-upload"
            v-model="data.files"
            :disabled="situation >= 9 && item.estado !== 'HABILITACAO'"
            @input="addFiles"
          >
            <div class="content has-text-centered custom-upload-content">
              <b-icon class="custom-upload-icon" icon="upload" size="is-medium" />

              <p>Clique aqui ou arraste nessa área para adicionar outro(s) documento(s)</p>
            </div>
          </b-upload>
        </div>

        <div class="panel">
          <div class="panel-heading is-flex is-justify-content-space-between">
            <div>Documentos Enviados</div>

            <b-button
              icon-right="zip-box"
              size="is-small"
              type="is-primary"
              v-if="crier"
              :loading="loading.downloadZip"
              @click.stop.prevent="downloadDocumentsZip()"
              >Download Zip</b-button
            >
          </div>

          <div v-if="!proposals.length" class="panel-block empty-panel">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Para habilitar o envio de documentos, é necessário enviar proposta(s).</p>
              </div>
            </section>
          </div>

          <div v-else-if="!documents.length && bidder" class="panel-block empty-panel">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhum documento enviado.</p>
              </div>
            </section>
          </div>

          <b-table scrollable v-else class="documents-table" :data="documents">
            <template v-slot:empty>
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Nenhum documento enviado.</p>
                </div>
              </section>
            </template>

            <b-table-column class="column-id" numeric label="ID" v-slot="props">{{
              props.index + 1
            }}</b-table-column>

            <b-table-column class="column-document" label="Documento" v-slot="props">
              <a :href="props.row.file | fileServer" tabindex="-1" download>
                {{ props.row.fileName }}
              </a>
            </b-table-column>

            <b-table-column
              cell-class="column-type"
              header-class="column-type"
              label="Tipo"
              v-slot="props"
            >
              <div v-if="props.row.createdAt">
                <b-tooltip
                  class="tooltips-tag"
                  :label="validDocument(props.row.validity) ? 'Válido' : 'Vencido'"
                  :type="validDocument(props.row.validity) ? 'is-success' : 'is-danger'"
                  v-if="props.row.validity"
                >
                  <b-tag
                    class="custom-tag"
                    :type="validDocument(props.row.validity) ? 'is-success' : 'is-danger'"
                  >
                    {{ new Date(props.row.validity).toLocaleDateString('pt-BR') }}
                  </b-tag>
                </b-tooltip>

                <b-tooltip
                  v-if="props.row.origin"
                  class="tooltips-tag"
                  type="is-primary"
                  :label="
                    props.row.origin === 'PROPOSTAS'
                      ? 'Entrega de Propostas'
                      : props.row.origin === 'HABILITACAO'
                      ? 'Habilitação'
                      : 'Habilitação da Adesão'
                  "
                >
                  <b-tag class="custom-tag" type="is-primary">
                    {{
                      props.row.origin === 'PROPOSTAS'
                        ? 'P'
                        : props.row.origin === 'HABILITACAO'
                        ? 'H'
                        : 'HA'
                    }}
                  </b-tag>
                </b-tooltip>

                <span>{{ props.row.type }}</span>
              </div>

              <b-field v-else expanded>
                <b-autocomplete
                  open-on-focus
                  class="select-type"
                  placeholder="Selecione um tipo de documento"
                  tabindex="-1"
                  v-model="props.row.type"
                  :data="filteredDocumentTypes"
                  @keydown.native.prevent
                  @select="option => selectType(props.index, option.type)"
                >
                  <template slot="empty">Nenhum documento requerido</template>

                  <template slot-scope="props">
                    <div class="media">
                      <div class="media-content document-type">
                        {{ props.option.type }}
                      </div>
                    </div>
                  </template>
                </b-autocomplete>
              </b-field>
            </b-table-column>

            <b-table-column
              class="column-name"
              label="Nome"
              v-slot="props"
              :visible="displayNameColumn"
            >
              <b-input
                class="input-name"
                custom-class="uppercase"
                placeholder="Insira um nome"
                maxlength="225"
                :ref="`name-${props.index}`"
                v-if="customDocument(props.row.type)"
                v-model="props.row.name"
                @blur="event => updateTypeName(props.index, props.row.type, event.target.value)"
              ></b-input>
            </b-table-column>

            <b-table-column
              centered
              class="column-date"
              label="Enviado em"
              v-slot="props"
              :visible="documents.some(document => document.createdAt)"
            >
              {{ props.row.createdAt | formatDate }}
            </b-table-column>

            <b-table-column centered label="Aceito" v-slot="props" :visible="displayAcceptedColumn">
              <b-icon
                custom-size="mdi-24px"
                icon="check"
                size="is-medium"
                type="is-success"
                v-if="props.row.accept === true"
              />
              <b-icon
                custom-size="mdi-24px"
                icon="close"
                type="is-danger"
                v-if="props.row.accept === false"
              />
            </b-table-column>

            <b-table-column centered label="Opções" v-slot="props" :visible="displayOptionsColumn">
              <div class="options-column">
                <button
                  v-if="displayDeleteButton(props.row)"
                  class="button is-danger delete-button"
                  tabindex="-1"
                  :disabled="disable.delete"
                  @click.prevent.stop="deleteDocument(props.index, props.row.id)"
                >
                  <b-icon icon="close-circle" />
                </button>

                <div
                  class="is-flex is-justify-content-center"
                  v-if="crier && props.row.accept === null"
                >
                  <button
                    class="button is-primary actions-buttons"
                    :disabled="disable.accept"
                    @click.prevent.stop="evaluateDocument(props.row.id, 'accept')"
                  >
                    Aceitar
                  </button>

                  <button
                    class="button is-danger actions-buttons"
                    :disabled="disable.refuse"
                    @click.prevent.stop="evaluateDocument(props.row.id, props.row.type, 'refuse')"
                  >
                    Recusar
                  </button>
                </div>
              </div>
            </b-table-column>

            <template
              v-if="this.situation !== 17 && (crier || (bidder && documents.length))"
              v-slot:footer
            >
              <div class="columns">
                <div v-if="bidder" :class="calculateUploadColumn">
                  <b-progress
                    show-value
                    v-if="bidder"
                    class="footer-elements ml-1"
                    format="percent"
                    type="is-info"
                    size="is-medium"
                    :value="auctionUpload"
                  ></b-progress>
                </div>

                <div :class="calculateButtonsColumn">
                  <div :class="bidder ? 'has-text-right' : 'has-text-centered'">
                    <b-tag
                      v-if="bidder && documentToSend"
                      class="footer-elements documents-warning-tag ml-1"
                      type="is-danger"
                      >Há documento(s) não enviado(s)</b-tag
                    >
                    <button
                      class="button is-primary footer-elements ml-1"
                      v-if="bidder && documents.length"
                      :disabled="situation >= 9 && item.estado !== 'HABILITACAO'"
                      :class="{ ' is-loading': loading.send }"
                      @click.prevent.stop="saveDocuments()"
                    >
                      Enviar
                    </button>

                    <button
                      v-if="crier"
                      class="button is-primary footer-elements ml-1"
                      :disabled="disableQualificationButton"
                      @click.prevent.stop="openModal('habilitar')"
                    >
                      Habilitar
                    </button>

                    <button
                      v-if="crier"
                      class="button is-danger footer-elements ml-1"
                      :disabled="disableIncapacitateButton"
                      @click.prevent.stop="openModal('inabilitar')"
                    >
                      Inabilitar
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justification.exibir"
      @justificate="evaluateBidder"
    >
      <AppJustification
        v-if="justification.exibir"
        :failText="justification.textoFalha"
        :displayFailButton="justification.exibirFalha"
        :displaySuccessButton="justification.exibirSucesso"
        :successText="justification.textoSucesso"
      />
    </b-modal>
  </div>
</template>

<script>
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { mapActions, mapGetters, mapState } from 'vuex';
import saveAs from 'save-as';

import formMixin from '@/mixins/formulario';
import { fileServer } from '@/utils/functions';
import { funcoes as functions } from '@/utils/enumerados';
import { formatarData as formatDate } from '@/utils/format';
import { uploadLimit } from '@/utils/data';
import sanitizeFileName from '@/utils/sanitizes';

import AppJustification from '@/components/AppJustification';

export default {
  name: 'ListFiles',
  components: { AppJustification },
  props: {
    item: Object,
    qualificationCompany: Object,
    situation: Number,
  },
  mixins: [formMixin],
  data() {
    const data = {
      files: [],
      name: null,
    };
    const disable = {
      accept: false,
      delete: false,
      refuse: false,
    };
    const justification = {
      textoSucesso: '',
      textoFalha: '',
      exibirSucesso: false,
      exibirFalha: false,
      exibir: false,
    };
    const loading = {
      downloadZip: false,
      loadPage: false,
      send: false,
    };

    return {
      data,
      disable,
      documents: [],
      justification,
      loading,
      loaded: false,
      selectedTypes: [],
    };
  },
  filters: {
    fileServer,
    formatDate,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('auctionsDocuments', ['auctionDocuments', 'auctionUpload']),
    ...mapState('companies', ['company']),
    ...mapState('companiesDocuments', ['companyDocuments']),
    ...mapState('documentTypes', ['documentTypes']),
    ...mapState('proposals', { proposals: 'proposals' }),
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapState('auctionsBidders', ['userLoggedBidder']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      bids: 'obterOfertaCompraLances',
      deadlines: 'obterOfertaCompraPrazos',
      function: 'getFunction',
      items: 'obterTodosItens',
    }),

    filteredDocumentTypes() {
      const filteredDocumentTypes = this.documentTypes.filter(documentType => {
        return !this.auctionDocuments.some(auctionDocument => {
          return auctionDocument.type === documentType.type;
        });
      });

      if (this.proposalStep) {
        return this.documentTypes.filter(documentType => {
          return documentType.proposals === true;
        });
      }

      return filteredDocumentTypes;
    },
    adhesionStep() {
      return this.deadlines.some(deadline => deadline.prazo_id === 13);
    },
    proposalStep() {
      return this.auction.situacao.id === 3;
    },
    authority() {
      return this.function === functions.AUTORIDADE;
    },
    bidder() {
      return this.function === functions.LICITANTE;
    },
    calculateButtonsColumn() {
      if (this.bidder && this.documentToSend) return 'column is-two-thirds';
      if (this.bidder) return 'column is-one-third';
      return 'column';
    },
    calculateUploadColumn() {
      if (this.bidder && this.documentToSend) return 'column is-one-third';
      if (this.bidder) return 'column is-two-thirds';
      return 'column';
    },
    complementaryExists() {
      return this.documents.some(document => document.origin !== 'PROPOSTAS');
    },
    crier() {
      return this.function === functions.PREGOEIRO;
    },
    disableIncapacitateButton() {
      return (
        this.item?.estado === 'NEGOCIACAO' ||
        this.item?.estado === 'HABILITACAO-ENCERRADA' ||
        this.docWithoutEvaluation
      );
    },
    disableQualificationButton() {
      return (
        this.item?.estado === 'HABILITACAO-ENCERRADA' ||
        !this.documents.length ||
        this.docWithoutEvaluation
      );
    },
    displayAcceptedColumn() {
      return this.documents.some(document => [true, false].indexOf(document.accept) > -1);
    },
    displayNameColumn() {
      return this.documents.some(
        document =>
          ['Documentos Complementares', 'Declarações', 'Outros Documentos'].indexOf(document.type) >
          -1,
      );
    },
    displayOptionsColumn() {
      if (this.situation === 17) return false;

      if (
        this.situation === 3 &&
        this.bidder &&
        (this.profileDocument || this.documentBeforeSession)
      ) {
        return true;
      }

      return !!(
        (this.crier && this.documentToAccepted) ||
        (this.bidder && this.documentNotAccepted && this.complementaryExists)
      );
    },
    documentBeforeSession() {
      return this.documents.some(document => document.origin && document.origin === 'PROPOSTAS');
    },
    documentNotAccepted() {
      return this.documents.some(document => [null, false].indexOf(document.accept) > -1);
    },
    documentToAccepted() {
      return this.documents.some(document => document.accept === null);
    },
    documentToSend() {
      return this.documents.some(document => !document.createdAt || !document.origin);
    },
    docWithoutEvaluation() {
      return this.documents.some(document => document.accept === null);
    },
    proposalsDocumentTypes() {
      return this.documentTypes
        .filter(document => document.proposals)
        .concat(this.requiredDocuments)
        .reduce((documents, document) => {
          const documentExists = documents.some(element => element.type === document.type);
          if (!documentExists) documents.push(document);
          return documents;
        }, []);
    },
    profileDocument() {
      return this.documents.some(document => !document.origin);
    },
    proposalsDelivery() {
      return this.situation === 3;
    },
    qualificationDocumentTypes() {
      if (this.adhesionStep) {
        return this.documentTypes.filter(
          document => document.qualification && document.type !== 'Proposta Readequada',
        );
      }

      return this.documentTypes.filter(document => document.qualification);
    },
  },
  watch: {
    auctionDocuments() {
      if (this.crier) this.documents = this.auctionDocuments.slice();
    },
    bids() {
      const enabledItem = this.bids.some(
        bid =>
          bid.ofertaCompraItemId === this.item.id &&
          (['HABILITADO', 'INABILITADO'].indexOf(bid.adesao) > -1 ||
            this.item?.estado !== 'HABILITACAO'),
      );
      if (enabledItem) this.$emitBus('voltar');
    },
    items() {
      if (!this.adhesionStep && this.item && this.item?.estado !== 'HABILITACAO') {
        this.$emitBus('voltar');
      }
    },
  },
  sockets: {
    'auction:documents': function updateAuctionDocuments(documents) {
      const updateDocuments = this.verifyUpdateDocuments(documents);
      if (updateDocuments) {
        if (documents && Array.isArray(documents)) {
          const filteredDocuments = documents.filter(
            document => !document.auctionItemId || document.auctionItemId === this.item.id,
          );
          this.updateAuctionDocuments(filteredDocuments);
        } else {
          this.updateAuctionDocuments(documents);
        }
      }
    },
  },
  methods: {
    ...mapActions('auctionsDocuments', [
      'addAuctionDocuments',
      'cleanAuctionDocuments',
      'removeAuctionDocument',
      'resetAuctionDocumentsUpload',
      'updateAuctionDocuments',
    ]),
    ...mapActions('auctionsQualifications', [
      'acceptAuctionDocument',
      'refuseAuctionDocument',
      'qualifyBidder',
      'disqualifyBidder',
    ]),
    addFiles(files) {
      this.documents = this.documents.concat(
        files.map(file => {
          if (this.adhesionStep) {
            return { fileName: file.name, file, type: 'Documentos Complementares' };
          }
          return { fileName: file.name, file };
        }),
      );
      if (this.adhesionStep) {
        const index = this.documents.length - files.length;
        this.selectFirstInput(index);
      }
      this.data.files = [];

      this.resetAuctionDocumentsUpload();
    },
    backToItems() {
      if (this.$refs && this.$refs.justificationModal) {
        this.$refs.justificationModal.close();
      }
      this.$emitBus('voltar');
    },
    customDocument(type) {
      return ['Documentos Complementares', 'Declarações', 'Outros Documentos'].indexOf(type) > -1;
    },
    async deleteDocument(index, documentId) {
      this.selectedTypes.splice(index, 1);
      this.documents.splice(index, 1);
      this.documents = this.documents.slice();

      const exists = this.auctionDocuments.some(document => document.id === documentId);
      if (exists) {
        this.deleteAuctionDocument(documentId);
      }
    },
    async deleteAuctionDocument(documentId) {
      this.disable.delete = true;
      try {
        await this.removeAuctionDocument({
          auctionId: this.auction.id,
          bidderId: this.userLoggedBidder.id,
          documentId,
        });
        this.$alerta('Documento excluído', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir documento', 'is-danger');
      }
      this.disable.delete = false;
    },
    displayDeleteButton(row) {
      if (this.situation === 3 && (row.origin === 'PROPOSTAS' || !row.origin)) return true;
      if (!this.crier && !row.accept && ['PROPOSTAS'].indexOf(row.origin) === -1) {
        return true;
      }
      return false;
    },
    async downloadDocumentsZip() {
      const zip = new JSZip();
      const zipFilename = `${sanitizeFileName(this.qualificationCompany.socialDenomination)}.zip`;
      let count = 0;
      let errorCount = 0;

      this.loading.downloadZip = true;
      const promises = this.documents.map(async (document, index) => {
        try {
          const file = await JSZipUtils.getBinaryContent(fileServer(document.file));
          const fileExtension = document.file.split('.').pop();
          zip.file(
            `${this.formatDocumentName(this.documents, index, document)}.${fileExtension}`,
            file,
            {
              binary: true,
            },
          );
          count += 1;

          if (count === this.documents.length) {
            zip.generateAsync({ type: 'blob' }).then(content => {
              saveAs(content, zipFilename);
            });
          }
        } catch (_) {
          errorCount += 1;
        }
      });

      await Promise.all(promises);

      if (errorCount) this.$alerta('Erro no download', 'is-danger');

      this.loading.downloadZip = false;
    },
    async evaluateBidder({ justification, state }) {
      const enableBidder = state === 'success';

      const data = {
        itemId: this.item.id,
        bidder: this.qualificationCompany
          ? this.qualificationCompany.alias
          : this.item.menor_lance.fornecedor,
        justification: enableBidder ? justification || 'Habilitada.' : justification,
      };

      try {
        if (enableBidder) {
          await this.qualifyBidder(data);
        } else {
          await this.disqualifyBidder(data);
        }
        this.$alerta(`Licitante ${enableBidder ? 'habilitado' : 'inabilitado'}`, 'is-success');
      } catch (error) {
        this.$alerta(
          `Erro ao ${enableBidder ? 'habilitar' : 'inabilitar'} o licitante`,
          'is-danger',
        );
      }

      this.documents = this.auctionDocuments.slice();
      this.backToItems();
    },
    async evaluateDocument(documentId, action) {
      const acceptAction = action === 'accept';

      this.disable.accept = true;
      this.disable.refuse = true;
      try {
        if (acceptAction) {
          await this.acceptAuctionDocument({
            auctionId: this.auction.id,
            bidderId: this.qualificationCompany.bidderId,
            documentId,
          });
        } else {
          await this.refuseAuctionDocument({
            auctionId: this.auction.id,
            bidderId: this.qualificationCompany.bidderId,
            documentId,
          });
        }
        this.$alerta(`Documento ${acceptAction ? 'aceito' : 'recusado'}`, 'is-success');
        this.documents = this.auctionDocuments.slice();
      } catch (error) {
        this.$alerta(`Erro ao ${acceptAction ? 'aceitar' : 'recusar'} documento`, 'is-danger');
      }
      this.disable.accept = false;
      this.disable.refuse = false;
    },
    formatDocumentName(companyDocuments, index, document) {
      const isReadjustedProposal = document.type === 'Proposta Readequada';
      const notReadjustedProposal = companyDocuments.filter(
        element => element.type === 'Proposta Readequada',
      );
      const getReadjustedProposalIndex = documentId =>
        notReadjustedProposal.findIndex(element => element.id === documentId) + 1;

      return `${index + 1}-${sanitizeFileName(document.type.replaceAll('/', ' '))}${
        isReadjustedProposal ? `-Item-${getReadjustedProposalIndex(document.id)}` : ''
      }`;
    },
    loadDocuments() {
      this.documents = this.auctionDocuments.slice();
    },
    openModal(action) {
      if (action === 'habilitar') {
        this.justification.textoSucesso = 'Habilitar';
        this.justification.exibirSucesso = true;
        this.justification.exibirFalha = false;
      } else if (action === 'inabilitar') {
        this.justification.textoFalha = 'Inabilitar';
        this.justification.exibirSucesso = false;
        this.justification.exibirFalha = true;
      }
      this.justification.exibir = true;
    },
    async saveDocuments() {
      if (this.validate()) {
        const data = new FormData();

        const documentsToSend = this.documents.slice(this.auctionDocuments.length);
        this.selectedTypes = this.selectedTypes.slice(this.auctionDocuments.length);

        data.append('types', JSON.stringify(this.selectedTypes));

        const validities = [];
        const profileDocuments = [];
        const newDocuments = [];
        documentsToSend.forEach(document => {
          if (typeof document.file !== 'string') {
            newDocuments.push(document.file);
          } else {
            profileDocuments.push(document);
          }
          validities.push(document.validity);
        });

        if (profileDocuments.length) {
          data.append('files', JSON.stringify(profileDocuments));
        }
        if (newDocuments.length) {
          newDocuments.forEach(document => {
            data.append('files', document);
          });
        }
        data.append('validities', JSON.stringify(validities));
        data.append('itemId', JSON.stringify(this.item ? this.item.id : null));

        this.disable.delete = true;
        this.loading.send = true;
        try {
          await this.addAuctionDocuments({
            auctionId: this.auction.id,
            bidderId: this.userLoggedBidder.id,
            data,
          });

          this.$alerta('Documento(s) adicionado(s)', 'is-success');
          this.documents = this.auctionDocuments.slice();
          this.data.files = [];
          this.selectedTypes = [];
        } catch (error) {
          const customError = error.response.data;
          if (
            error.response.status === 400 &&
            customError &&
            customError.details.message === 'Fora do prazo'
          ) {
            this.$alerta('Fora do prazo', 'is-danger');
          } else {
            this.$alerta('Erro ao adicionar documento(s)', 'is-danger');
          }
        }
        this.loading.send = false;
        this.disable.delete = false;
      }
    },
    selectFirstInput(index) {
      setTimeout(() => {
        this.$refs[`name-${index}`].focus();
      }, 100);
    },
    selectType(index, type) {
      if (type) {
        this.selectedTypes[index] = type;
        this.documents[index].type = type;
        this.documents = this.documents.slice();
      }

      if (this.customDocument(type)) {
        this.selectFirstInput(index);
      }
    },
    updateTypeName(index, typeOrigin, typeName) {
      const uppercaseTypeName = `${typeName.toUpperCase()} (${typeOrigin})`;
      if (typeName) {
        this.documents[index].typeName = uppercaseTypeName;
        this.selectedTypes[index] = uppercaseTypeName;
      }
    },
    validate() {
      if (!this.documents.length || !this.filteredDocumentTypes.length) {
        this.$alerta('Adicione um ou mais documentos', 'is-danger');
        return false;
      }

      if (this.$refs) {
        const missedNames = Object.keys(this.$refs).filter(
          key => key.startsWith('name-') && this.$refs[key] && !this.$refs[key].value,
        );
        if (missedNames.length) {
          this.$alerta('Existe documento sem nome', 'is-danger');
          return false;
        }
      }

      const valid = this.documents.filter(element => !element.type);
      if (valid.length) {
        this.$alerta('Selecione o tipo de cada documento', 'is-danger');
        return false;
      }

      const mappedDocuments = this.documents.map(
        document => document.typeName || document.name || document.type,
      );
      const documentRepeat = mappedDocuments.some(
        (document, index) => mappedDocuments.indexOf(document) !== index,
      );

      if (documentRepeat) {
        this.$alerta('Tipo de documento deve ser único', 'is-danger', 3000);
        return false;
      }

      if (this.documents.some(document => !document.status && document.file.size > uploadLimit)) {
        this.$alerta('Insira arquivo(s) com até 50MB', 'is-danger');
        return false;
      }

      return true;
    },
    validDocument(date) {
      const now = new Date();
      const documentDate = new Date(date);
      return now <= documentDate;
    },
    verifyUpdateDocuments(documents) {
      if (this.crier && documents && Array.isArray(documents)) {
        if (documents[0].bidderId === this.qualificationCompany.bidderId) return true;
      }
      if (this.crier && documents && typeof documents === 'object') {
        if (documents.bidderId === this.qualificationCompany.bidderId) return true;
      }
      if ([true, false].indexOf(documents.accept) > -1) return true;

      return false;
    },
  },
  created() {
    this.loadDocuments();
    this.resetAuctionDocumentsUpload();
  },
};
</script>

<style lang="scss" scoped>
.actions-buttons {
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
}

.column-document {
  width: 10vw;
}

.column-type,
.column-name {
  max-width: 30vw;
}

.custom-tag {
  font-size: 0.7rem;
}

.custom-upload {
  margin-bottom: 1.5rem;
  display: block;
}

.custom-upload-content {
  padding: 0.8rem;
}

.custom-upload-icon {
  margin: 0.4rem;
}

.delete-button {
  margin-left: 0.2rem;
}

.documents-table {
  margin: 0.5rem 0;
  font-size: 0.92rem;
}

.document-type {
  white-space: normal;
}

.documents-warning-tag {
  margin-right: 30%;
}

.empty-panel {
  justify-content: center !important;
}

.footer-elements {
  margin-top: 1.5rem;
}

.options-column {
  justify-content: center;
  min-width: fit-content;
}

.select-type {
  text-align: left;
}

.sticky-content {
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
}

.tooltips-tag {
  margin-right: 0.2rem;
}

@media (max-width: 1366px) {
  .column-type,
  .column-name {
    max-width: 20vw;
  }

  .documents-table {
    font-size: 0.88rem;
  }
}

@media (max-width: 961px) {
  .custom-column {
    width: unset !important;
  }

  .is-column-reverse-custom {
    display: flex;
    flex-direction: column-reverse;
  }

  .reset-width {
    width: unset !important;
  }
}

@media (max-width: 769px) {
  .column-type,
  .column-name {
    max-width: 90vw;
  }

  .input-name,
  .select-type {
    width: 60vw;
  }
}
</style>

<style lang="scss">
.documents-table .table-container {
  overflow: unset;
}

.documents-table .table-wrapper .table {
  max-width: 98.4%;
}

.select-type > div > input {
  caret-color: transparent;
  cursor: pointer;
}
</style>
