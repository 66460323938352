import store from '@/vuex/store';

class SocketResource {
  constructor(socket) {
    this.socket = socket;
  }

  setToken() {
    if (store.getters.getToken) {
      this.socket.client.io.opts.query = {
        token: `Bearer ${store.getters.getToken}`,
      };
    } else {
      this.socket.client.io.opts.query = {
        token: 'Bearer',
      };
    }
  }

  openConnection() {
    this.setToken();
    this.socket.client.open();
  }

  closeConnection() {
    this.socket.client.close();
  }

  enterAuction(auctionId, userId, companyId) {
    this.socket.client.emit('auction:enter', { auctionId, userId, companyId });
  }

  leaveAuction(auctionId) {
    this.socket.client.emit('auction:leave', { auctionId });
  }

  enterDispensation(dispensationId, userId) {
    this.socket.client.emit('dispensation:enter', { dispensationId, userId });
  }

  leaveDispensation(dispensationId) {
    this.socket.client.emit('dispensation:leave', { dispensationId });
  }

  sendMessage({ id, message }) {
    this.socket.client.emit('chat:submit', { id, message });
  }
}

export default SocketResource;
