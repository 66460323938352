<template>
  <div class="is-flex-touch column-direction-touch">
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <section class="bm-3">
      <div class="box">
        <div class="level dispensation-bids-column-direction">
          <div class="level-item has-text-centered">
            <div class="margin-2">
              <p class="heading">Item</p>
              <p>
                <strong>{{ item.identificador }}</strong>
              </p>
            </div>
          </div>

          <div
            class="level-item has-text-centered custom-level-item"
            :class="{ 'description-level-item': !descriptionText }"
          >
            <div class="margin-2 elipsis-description" ref="description-link">
              <p class="heading">Descrição</p>
              <a
                :class="{ 'justify-align': justify }"
                @click.stop.prevent="openDescription = !openDescription"
                >{{ item.item.descricao }}</a
              >
            </div>

            <div v-show="descriptionText">
              <p class="heading">Descrição</p>
              <p class="description-text" ref="description-text">
                <strong>{{ item.item.descricao }}</strong>
              </p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div class="margin-2">
              <p class="heading">Unidade</p>
              <p>
                <strong>{{ item.unidade.nome }}</strong>
              </p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <div class="margin-2">
              <p class="heading">Quantidade</p>
              <p>
                <strong>{{ item.quantidade }}</strong>
              </p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <button
              class="button is-primary dispensation-bids-back-button"
              @click.stop.prevent="voltarItens"
            >
              Voltar
            </button>
          </div>
        </div>

        <div v-if="openDescription" class="complete-description">
          <p>{{ item.item.descricao }}</p>
        </div>
      </div>
    </section>

    <section>
      <div v-if="!lances.length" class="panel">
        <div class="panel-block is-justify-content-center">
          <section v-if="!lances.length" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Nenhum Lance.</p>
            </div>
          </section>
        </div>
      </div>

      <b-table
        v-if="lances.length"
        class="table is-fullwidth bids-table"
        default-sort-direction="ASC"
        :data="lances"
      >
        <b-table-column centered sortable label="Data" field="data_lance" v-slot="props">
          <div class="date-column">
            <div v-if="props.row.prorrogacao && !mobile">
              {{ props.row.data_lance | formatarDataSegundo }}
              <b-tooltip label="Prorrogação de Lances" :position="mobile ? 'is-left' : 'is-top'">
                <b-icon icon="timer" type="is-black" size="is-small"></b-icon>
              </b-tooltip>
            </div>

            <div v-if="props.row.prorrogacao && mobile">
              <b-tooltip label="Prorrogação de Lances" :position="mobile ? 'is-left' : 'is-top'">
                <b-icon icon="timer" type="is-black" size="is-small"></b-icon>
              </b-tooltip>
              {{ props.row.data_lance | formatarDataSegundo }}
            </div>

            <div class="date-without-bid-extension" v-if="!props.row.prorrogacao">
              {{ props.row.data_lance | formatarDataSegundo }}
            </div>
          </div>
        </b-table-column>

        <b-table-column centered label="Fornecedor" v-slot="props">
          {{ situacao > 6 ? props.row.denominacao_social : props.row.apelido }}
        </b-table-column>

        <b-table-column centered label="Marca" v-slot="props">
          {{ props.row.marca }}
        </b-table-column>

        <b-table-column centered label="Importado" v-slot="props">
          {{ converteImportado(props.row.fabricado) }}
        </b-table-column>

        <b-table-column centered label="Valor" v-slot="props">
          {{ props.row.valor | formatarValor }}
        </b-table-column>

        <b-table-column label="Status" v-slot="props">
          <div class="status-column">{{ converteStatusOferta(props.row) }}</div>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { formatarData, formatarDataSegundo, formatarValor } from '@/utils/format';

export default {
  name: 'DispensaLances',
  props: {
    item: Object,
    dispensaId: Number,
  },
  data() {
    const loading = {
      general: false,
    };

    return {
      loading,
      carregado: false,
      descriptionText: false,
      openDescription: false,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      dispensa: 'obterDispensa',
      lances: 'obterDispensaItemLances',
    }),
    isTouch() {
      return this.windowWidth < 1024;
    },
    justify() {
      return this.windowWidth > 960;
    },
    situacao() {
      return this.dispensa.situacao.id;
    },
  },
  filters: {
    formatarData,
    formatarDataSegundo,
    formatarValor,
  },
  sockets: {
    'dispensa:lances': function atualizarLancesDispensa(lance) {
      if (lance.dispensaItemId === this.item.id) this.adicionarLanceItemDispensa(lance);
    },
  },
  methods: {
    ...mapActions(['buscarDispensaItemLances', 'adicionarLanceItemDispensa']),
    converteImportado(fabricado) {
      return {
        BRASIL: 'Não',
        IMPORTADO: 'Sim',
      }[fabricado];
    },
    converteStatusOferta(row) {
      return {
        VALIDO: 'Válido',
        INVALIDOREFERENCIAL: 'Inválido - Valor acima do referencial.',
        INVALIDOSUPERIOR: 'Inválido - Valor igual ou superior a outro já registrado.',
        INVALIDOREDUCAO: 'Inválido - Não atingiu a redução mínima entre lances.',
        ENVIADO: 'Em processamento.',
      }[row.status + (row.motivo || '')];
    },
    verifyOverflowed() {
      const descriptionLink = this.$refs['description-link'];
      const descriptionText = this.$refs['description-text'];
      const descriptionOverflowed = descriptionLink.offsetWidth < descriptionLink.scrollWidth;
      if (descriptionOverflowed) {
        descriptionText.hidden = true;
      } else {
        this.descriptionText = true;
        descriptionLink.hidden = true;
      }
    },
    voltarItens() {
      this.$emit('voltar');
    },
  },
  async created() {
    this.loading.general = true;
    try {
      await this.buscarDispensaItemLances({
        dispensaId: this.dispensaId,
        itemId: this.item.id,
      });
      this.verifyOverflowed();
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.carregado = true;
      this.loading.general = false;
    }
  },
};
</script>

<style scoped>
a {
  color: #1a0dab !important;
}

a:hover {
  color: #363636 !important;
}

.bm-3 {
  margin-bottom: 3rem;
}

.bids-table {
  font-size: 0.98rem;
  margin-top: 0.5rem;
}

.complete-description {
  font-size: 0.88rem;
  margin-top: 2rem;
  text-align: justify;
}

.custom-level-item {
  flex: 1;
  flex-grow: 5 !important;
  min-width: 135px;
}

.date-column {
  min-width: fit-content;
  white-space: nowrap;
}

.date-without-bid-extension {
  width: calc(100% - 18px);
}

.description-level-item {
  flex-basis: 25%;
  min-width: 1px;
}

.description-text {
  white-space: nowrap;
}

.elipsis-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.justify-align {
  text-align: justify;
}

.margin-2 {
  margin: 0 2rem;
}

.status-column {
  min-width: fit-content;
}

@media (min-width: 769px) and (max-width: 1120px) {
  .item-information-column-direction {
    flex-direction: column !important;
  }

  .item-information-back-button {
    margin-top: 0.25rem;
  }

  .margin-2 {
    margin: 0.5rem 0;
  }
}

@media (max-width: 768px) {
  .margin-2 {
    margin: unset;
  }
}
</style>
