<template>
  <div>
    <DispensaItensTodos :loaded="loaded" />
  </div>
</template>

<script>
import DispensaItensTodos from '@/views/Dispensa/DispensaItem/DispensaItensTodos';

export default {
  name: 'DispensaItens',
  components: {
    DispensaItensTodos,
  },
  props: {
    loaded: Boolean,
  },
};
</script>
