<template>
  <div class="panel">
    <div class="panel-heading">Filtros</div>
    <ListaFiltrosComprador v-if="company.buyer" />
    <ListaFiltrosVendedor v-if="company.seller" />
    <ListaFiltrosVisitante v-if="visitor" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { funcoes as functions } from '@/utils/enumerados';

import ListaFiltrosComprador from './ListaFiltrosComprador';
import ListaFiltrosVendedor from './ListaFiltrosVendedor';
import ListaFiltrosVisitante from './ListaFiltrosVisitante';

export default {
  name: 'PregaoListaFiltros',
  components: {
    ListaFiltrosComprador,
    ListaFiltrosVendedor,
    ListaFiltrosVisitante,
  },
  computed: {
    ...mapGetters({
      function: 'getFunction',
    }),
    ...mapState('companies', ['company']),
    visitor() {
      return this.function === functions.VISITANTE;
    },
  },
};
</script>

<style lang="scss">
.field.is-grouped .field {
  flex-shrink: 1 !important;
}

.filters-buttons-container {
  display: flex;

  button {
    height: 75px;
    margin: 5px;
  }
}

@media (max-width: 1023px) {
  .filters-buttons-container button {
    height: unset;
    margin: 10px 0;
  }
}
</style>
