import axios from 'axios';
import Vue from 'vue';
import store from '@/vuex/store';

const sendTimeout = 3600000;

const api = axios.create({
  baseURL: process.env.VUE_APP_API_PATH,
  timeout: 30000,
});

api.interceptors.request.use(config => {
  const auth = config;
  if (store.getters.getToken) {
    auth.headers.Authorization = `Bearer ${store.getters.getToken}`;
  }
  return auth;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error && error.message === 'timeout of 30000ms exceeded') {
      Vue.prototype.$alerta('Sem conexão. Tente novamente', 'is-danger');
    }
    return Promise.reject(error);
  },
);

export { api, sendTimeout };
