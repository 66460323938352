<template>
  <div class="user-verification-container">
    <AppMessage>
      <div slot="message-icon">
        <b-icon icon="check-circle-outline" size="is-large" type="is-success" />
      </div>

      <p slot="message-title">
        Usuário {{ registeredUser ? 'atualizado' : 'cadastrado' }} com sucesso!
      </p>

      <div slot="message">
        Foi enviado o código de verificação com validade de 10 minutos para o email
        <b>{{ user.email }}</b
        >. Caso não encontre, por favor verifique o lixo eletrônico.
      </div>
    </AppMessage>

    <div class="form-container">
      <div class="user-register-controls-container">
        <form @keyup.enter.prevent="verifyEmail()">
          <b-field
            label="Código de Verificação"
            :type="campoInvalido('verificationCode', 'data') ? 'is-danger' : ''"
            :message="
              campoInvalido('verificationCode', 'data')
                ? 'Código de verificação é um campo obrigatório'
                : ''
            "
          >
            <b-input
              placeholder="Código de Verificação"
              maxlength="4"
              ref="code-verify"
              v-model="$v.data.verificationCode.$model"
              @keypress.native="teclasPermitidasValor($event)"
            />
          </b-field>

          <b-button
            expanded
            class="email-confirmation-button"
            type="is-primary"
            :loading="loading.verify"
            @click.prevent.stop="verifyEmail()"
            >Confirmar</b-button
          >

          <div class="resent-code">
            <a @click.prevent.stop="resentEmailCode()">Reenviar Código de Verificação</a>
            <b-loading is-full-page :active="loading.resent"></b-loading>
          </div>
        </form>

        <form
          v-if="user.emailVerificado === 'PERFIL'"
          @submit.prevent
          @keyup.enter.prevent="changeUserEmail()"
        >
          <div>
            <b-collapse
              aria-id="contentIdForA11y1"
              ref="change-email"
              :open="false"
              @close="changeEmail = !changeEmail"
              @open="(changeEmail = !changeEmail), $refs.email.focus()"
            >
              <template #trigger>
                <b-button
                  label="Alterar E-mail"
                  type="is-primary"
                  aria-controls="contentIdForA11y1"
                />
              </template>

              <div class="mt-4 change-email">
                <b-field
                  expanded
                  :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
                  :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
                >
                  <b-input
                    placeholder="Alterar E-mail"
                    ref="email"
                    v-model="$v.data.email.$model"
                    @input="data.email = data.email.toLowerCase()"
                  ></b-input>
                </b-field>

                <b-button
                  class="button is-primary is-fullwidth save-button"
                  :loading="loading.change"
                  @click.prevent.stop="changeUserEmail()"
                >
                  Alterar
                </b-button>
              </div>
            </b-collapse>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, requiredIf } from 'vuelidate/lib/validators';

import AppMessage from '@/components/AppMessage';
import formValidation from '@/mixins/formulario';

export default {
  name: 'UserVerification',
  mixins: [formValidation],
  components: {
    AppMessage,
  },
  data() {
    const data = {
      email: '',
      verificationCode: '',
    };

    const loading = {
      change: false,
      verify: false,
      resent: false,
    };

    return {
      changeEmail: false,
      data,
      loading,
    };
  },
  validations: {
    data: {
      email: {
        email,
        required: requiredIf(function verifyChangeEmail() {
          return this.changeEmail;
        }),
      },
      verificationCode: {
        required: requiredIf(function verifyChangeEmail() {
          return !this.changeEmail;
        }),
      },
    },
  },
  computed: {
    ...mapState('companies', ['company', 'invitation']),
    ...mapGetters({
      registeredUser: 'getRegisteredUser',
      user: 'getUser',
    }),
  },
  methods: {
    ...mapActions(['linkGuestUser', 'requestEmailCode', 'updateUser', 'validateEmailCode']),
    cleanFields() {
      this.data.verificationCode = '';
      this.$v.data.$reset();
    },
    async changeUserEmail() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        return;
      }

      this.loading.changeEmail = true;
      try {
        await this.updateUser({ email: this.data.email });
        this.$v.data.$reset();
        this.$refs['change-email'].toggle();
        this.$alerta('Código enviado para novo e-mail', 'is-success');
        this.requestEmailCode();
      } catch (error) {
        const { response } = error;
        if (response.data.error.type === 'EmailExists') {
          this.$alerta('E-mail já cadastrado', 'is-danger');
          return;
        }

        this.$alerta(error.message, 'is-danger');
      } finally {
        this.loading.changeEmail = false;
      }
    },
    async resentEmailCode() {
      this.loading.resent = true;
      try {
        await this.requestEmailCode();
        this.$alerta('Código de verificação enviado', 'is-success');
        this.cleanFields();
      } catch (error) {
        this.$alerta('Erro ao reenviar novo código', 'is-danger');
      } finally {
        this.loading.resent = false;
      }
    },
    async verifyEmail() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        return;
      }

      this.loading.verify = true;
      try {
        await this.validateEmailCode({ emailCode: this.data.verificationCode });
        this.$alerta('E-mail confirmado', 'is-success');

        const registeredStatus = this.company.status === 'CADASTRADA';

        if (this.invitation || (this.company.id && !registeredStatus)) {
          this.$router.push({
            name: 'Start',
          });
        } else if (registeredStatus) {
          this.$emit('check-register-step');
        } else {
          this.$emit('user-registered');
        }
      } catch (error) {
        if (
          error.response.status === 400 &&
          error.response.data.message === 'ValidationError' &&
          error.response.data.details.message === 'Invalid code'
        ) {
          this.$alerta('Código inválido', 'is-danger');
        } else {
          this.$alerta('Erro ao confirmar e-mail', 'is-danger');
        }
      } finally {
        this.loading.verify = false;
      }
    },
  },
  mounted() {
    this.$refs['code-verify'].focus();
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.resent-code {
  margin: 2rem 0 3rem;
}

@media (min-width: 1024px) {
  .user-register-controls-container {
    width: 400px;
  }
}
</style>
