<template>
  <div class="user-register-container">
    <div v-if="!renderUserExists">
      <div v-if="invitation">
        <h3 class="title is-4">
          {{ title }}
        </h3>

        <hr />
      </div>

      <form @keyup.enter="registrationUser">
        <b-field
          label="CPF"
          :type="campoInvalido('cpf', 'data') ? 'is-danger' : ''"
          :message="
            campoInvalido('cpf', 'data')
              ? 'CPF é um campo obrigatório. Insira um número válido'
              : ''
          "
        >
          <b-input
            placeholder="CPF"
            type="text"
            maxlength="14"
            :has-counter="false"
            v-mascara:cpf
            v-model="$v.data.cpf.$model"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
        </b-field>

        <b-field
          label="Nome"
          :type="campoInvalido('name', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('name', 'data') ? 'Nome é um campo obrigatório' : ''"
        >
          <b-input placeholder="Nome" type="text" v-model="$v.data.name.$model" />
        </b-field>

        <b-field
          label="E-mail"
          :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
        >
          <b-input
            placeholder="E-mail"
            type="email"
            v-model="$v.data.email.$model"
            :disabled="invitation"
            @input="data.email = data.email.toLowerCase()"
          />
        </b-field>

        <b-field
          v-if="!invitation"
          label="Confirmar E-mail"
          :type="campoInvalido('confirmEmail', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('confirmEmail', 'data') ? 'Confirma Insira um e-mail válido' : ''"
        >
          <b-input
            autocomplete="off"
            autofill="off"
            placeholder="Confirmar E-mail"
            type="email"
            v-model="$v.data.confirmEmail.$model"
            @click.right.native.prevent
            @input="data.confirmEmail = data.confirmEmail.toLowerCase()"
            @paste.native.prevent
          />
        </b-field>

        <b-field
          label="Senha"
          :type="passwordValidation.errors.length && data.password ? 'is-danger' : ''"
        >
          <b-input
            password-reveal
            autocomplete="new-password"
            placeholder="Senha"
            type="password"
            v-model="$v.data.password.$model"
          />
        </b-field>

        <b-notification
          has-icon
          aria-close-label="Close notification"
          class="custom-notification"
          role="alert"
          type="is-danger"
          v-if="passwordValidation.errors.length && data.password"
          :closable="false"
        >
          <li v-for="error in passwordValidation.errors" :key="error.id">
            <b-icon icon="arrow-right-bold-outline" size="is-small"></b-icon>
            {{ error.message }}
          </li>
        </b-notification>

        <b-field
          autocomplete="off"
          label="Confirmar Senha"
          :type="campoInvalido('confirmPassword', 'data') ? 'is-danger' : ''"
          :message="
            campoInvalido('confirmPassword', 'data') ? 'Confirmar senha é um campo obrigatório' : ''
          "
        >
          <b-input
            password-reveal
            placeholder="Confirmar Senha"
            type="password"
            v-model="$v.data.confirmPassword.$model"
            @click.left.native.prevent
            @click.right.native.prevent
            @paste.native.prevent
          />
        </b-field>

        <b-field
          class="terms-field"
          :type="campoInvalido('acceptedTerms', 'data') ? 'is-danger' : ''"
          :message="
            campoInvalido('acceptedTerms', 'data')
              ? 'Você deve concordar com os Termos de Condições de Uso'
              : ''
          "
        >
          <div class="terms">
            <router-link :to="{ name: 'TermsOfUse' }" target="_blank">
              <a>Termos de Condições de Uso</a>
            </router-link>

            <b-checkbox v-model="$v.data.acceptedTerms.$model" class="check-term"
              >Concordo com os termos de condições de uso.</b-checkbox
            >
          </div>
        </b-field>

        <b-button
          expanded
          type="is-primary"
          :loading="loading.button"
          @click.prevent.stop="registerUser()"
        >
          Cadastrar Usuário
        </b-button>
      </form>
    </div>

    <div class="user-exists-container" v-if="renderUserExists">
      <AppMessage renderButton>
        <div slot="message-icon">
          <b-icon icon="account-check" size="is-large" type="is-success" />
        </div>

        <p slot="message-title">Esse e-mail de usuário já está cadastrado</p>

        <div slot="message">
          Efetue login para continuar o cadastro de uma nova empresa.
        </div>

        <router-link
          :to="{
            name: 'Login',
            query: {
              redirect: this.$route.fullPath,
            },
          }"
          class="button is-primary is-large"
          slot="message-button"
          >Login</router-link
        >
      </AppMessage>
    </div>
  </div>
</template>

<script>
import { isValidCpf } from '@brazilian-utils/validators';
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, required, requiredIf, sameAs } from 'vuelidate/lib/validators';

import AppMessage from '@/components/AppMessage';
import formValidation from '@/mixins/formulario';
import { passwordRules } from '@/utils/data';

export default {
  name: 'UserRegister',
  mixins: [formValidation],
  components: {
    AppMessage,
  },
  data() {
    const data = {
      acceptedTerms: null,
      confirmEmail: '',
      confirmPassword: '',
      cpf: '',
      email: '',
      name: '',
      password: '',
    };

    const loading = {
      button: false,
    };

    return {
      data,
      loading,
      renderUserExists: false,
      rules: [...passwordRules],
    };
  },
  validations: {
    data: {
      acceptedTerms: { required },
      confirmEmail: {
        required: requiredIf(function verifyInvitation() {
          return !this.invitation;
        }),
        samePassword: sameAs('email'),
      },
      confirmPassword: { required, samePassword: sameAs('password') },
      cpf: { isValidCpf },
      name: { required },
      email: { email, required },
      password: { required },
    },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company', 'invitation']),
    ...mapGetters({
      logged: 'getLogged',
      user: 'getUser',
    }),
    passwordValidation() {
      const { password } = this.data;

      const errors = [];
      this.rules.forEach(condition => {
        if (!condition.regex.test(password)) {
          errors.push(condition);
        }
      });

      if (!errors.length) {
        return { valid: true, errors };
      }

      return { valid: false, errors };
    },
    title() {
      const data = this.invitation ? this.invitation : this.company;
      return `${data.socialDenomination} (${data.cnpj})`;
    },
  },
  methods: {
    ...mapActions(['createUser', 'linkGuestUser', 'login']),
    customValidate(validation) {
      if (!this[validation].acceptedTerms) {
        this[validation].acceptedTerms = null;
        this.$v[validation].$touch();

        return false;
      }

      if (this.$v[validation].$invalid || !this.passwordValidation.valid) {
        this.$v[validation].$touch();

        return false;
      }

      return true;
    },
    async registerUser() {
      if (this.customValidate('data')) {
        this.loading.button = true;
        try {
          await this.createUser(this.data);
          await this.login({
            email: this.data.email,
            password: this.data.password,
            withoutReload: this.$route.query.convit,
          });

          if (this.invitation) {
            await this.linkGuestUser({
              invitation: this.invitation.hashInvitation,
              companyId: this.invitation.companyId,
              userId: this.user.id,
            });
            await this.login({
              chooseCompany: true,
              companyId: this.invitation.companyId,
              withoutReload: this.$route.query.convite,
            });

            if (this.user.emailVerificado) {
              this.$router.push({
                name: 'Start',
              });
            }
          }

          this.$emit('user-registered');
        } catch (error) {
          const response = error.response.data;
          if (['EmailExists', 'CpfExists'].indexOf(response.error.type) > -1) {
            this.renderUserExists = true;
            this.$emit('hide-steps');
          } else {
            this.$alerta(error.message, 'is-danger');
          }
        } finally {
          this.loading.button = false;
        }
      }
    },
  },
  mounted() {
    if (this.invitation) {
      this.data.email = this.invitation.email;
      this.data.confirmEmail = this.invitation.email;
    }
  },
};
</script>

<style lang="scss" scoped>
.check-term {
  margin-top: 2rem;
  display: flex;
}

.custom-notification > div > div > li {
  display: block;
}

.terms {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.terms-field {
  margin-bottom: 2rem !important;
}

.user-exists-container {
  align-items: center;
  display: flex;
  height: 50vh;
}

@media (max-width: 769px) {
  .custom-notification {
    font-size: 0.92rem;
    padding: 0.5rem;
  }

  .terms {
    font-size: 0.92rem;
  }
}
</style>
