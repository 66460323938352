import Vue from 'vue';

import {
  searchAuctionDeclarations,
  searchDeclarations,
  createDeclaration,
  removeDeclaration,
} from '@/resources/electronicAuctions/auctionsDeclarations';

const types = {
  ELECTRONIC_AUCTIONS_DECLARATIONS: 'ELECTRONIC_AUCTIONS_DECLARATIONS',
  ELECTRONIC_AUCTIONS_DECLARATIONS_CLEAN: 'ELECTRONIC_AUCTIONS_DECLARATIONS_CLEAN',
  ELECTRONIC_AUCTIONS_DECLARATIONS_UPDATE: 'ELECTRONIC_AUCTIONS_DECLARATIONS_UPDATE',
  ELECTRONIC_AUCTIONS_DECLARATIONS_REMOVE: 'ELECTRONIC_AUCTIONS_DECLARATIONS_REMOVE',
};

const mutations = {
  [types.ELECTRONIC_AUCTIONS_DECLARATIONS](state, data) {
    if (data && Array.isArray(data)) {
      state.declarations = data;
      state.declarationsLoaded = true;
    }
  },
  [types.ELECTRONIC_AUCTIONS_DECLARATIONS_UPDATE](state, data) {
    if (data && Array.isArray(data)) {
      while (data) {
        const current = data.shift();
        const index = state.declarations.findIndex(
          element => element.declaration === current.declaration,
        );

        if (index > -1) {
          Vue.set(state.declarations, index, data);
        } else {
          state.declarations = [...state.declarations, ...data];
        }
      }
    } else if (data && typeof data === 'object') {
      const index = state.declarations.findIndex(
        element => element.declaration === data.declaration,
      );

      if (index > -1) {
        Vue.set(state.declarations, index, data);
      } else {
        state.declarations.push(data);
      }
    }
  },
  [types.ELECTRONIC_AUCTIONS_DECLARATIONS_REMOVE](state, data) {
    if (data && typeof data === 'object') {
      const index = state.declarations.findIndex(element => element.id === data.id);

      if (index > -1) state.declarations.splice(index, 1);
    }
  },
  [types.ELECTRONIC_AUCTIONS_DECLARATIONS_CLEAN](state) {
    state.declarations = [];
  },
};

const actions = {
  async searchAuctionDeclarations({ commit }, data) {
    const { auctionId } = data;
    const response = await searchAuctionDeclarations(auctionId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.ELECTRONIC_AUCTIONS_DECLARATIONS, response.data);
  },
  async searchDeclarations({ commit }, data) {
    const { auctionId, companyId } = data;
    const response = await searchDeclarations(auctionId, companyId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.ELECTRONIC_AUCTIONS_DECLARATIONS, response.data);
  },
  async createAuctionDeclaration({ commit }, data) {
    const { auctionId, companyId } = data;
    const response = await createDeclaration(auctionId, companyId, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.ELECTRONIC_AUCTIONS_DECLARATIONS_UPDATE, response.data);
  },
  async removeAuctionDeclaration({ commit }, data) {
    const { auctionId, companyId, declarationId } = data;
    const response = await removeDeclaration(auctionId, companyId, declarationId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.ELECTRONIC_AUCTIONS_DECLARATIONS_REMOVE, response.data);
  },
  cleanAuctionDeclarations({ commit }) {
    return commit(types.ELECTRONIC_AUCTIONS_DECLARATIONS_CLEAN);
  },
};

const getters = {
  getDeclarations(state) {
    return state.declarations;
  },
  getDeclarationsLoaded(state) {
    return state.declarationsLoaded;
  },
};

const state = {
  declarations: [],
  declarationsLoaded: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export { types };
