<template>
  <div>
    <b-tabs type="is-boxed" :value="internalTabSelected">
      <b-tab-item label="Adjudicação" v-if="auction.hasTermoAdjudicacao">
        <AuctionAdjudicationTerm />
      </b-tab-item>

      <b-tab-item label="Homologação" v-if="auction.hasTermoHomologacao">
        <AuctionHomologationTerm />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuctionAdjudicationTerm from './AuctionAdjudicationTerm';
import AuctionHomologationTerm from './AuctionHomologationTerm';

export default {
  name: 'AuctionTermsWrapper',
  components: {
    AuctionAdjudicationTerm,
    AuctionHomologationTerm,
  },
  computed: {
    ...mapGetters({
      auction: 'obterOfertaCompra',
    }),
  },
};
</script>

<style></style>
