<template>
  <header class="navbar-header">
    <b-navbar transparent wrapper-class="container is-fluid admin-header-container">
      <template v-slot:brand>
        <b-navbar-item tag="router-link" :to="{ name: 'Start' }">
          <img :src="logo" alt="SelCorp - Sistema Eletrônico de Licitações" />
        </b-navbar-item>
      </template>

      <template v-slot:[startDynamicSlot]>
        <b-navbar-dropdown :boxed="!tablet" hoverable label="Financeiro">
          <b-navbar-item tag="router-link" to="/admin/faturas"> Faturas </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-dropdown :boxed="!tablet" hoverable label="Empresas">
          <b-navbar-item tag="router-link" to="/admin/validacao"> Validações </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-dropdown :boxed="!tablet" hoverable label="Cadastros">
          <b-navbar-item tag="router-link" to="/admin/prefeituras"> Prefeituras </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-item tag="router-link" to="/admin/eventos"> Eventos </b-navbar-item>
      </template>

      <template v-slot:[endDynamicSlot]>
        <b-navbar-dropdown :boxed="!tablet" hoverable :label="`${nameToDisplay}`">
          <b-navbar-item tag="a" @click.prevent="handleLogout">Sair</b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import logo from '@/assets/logosel.png';

export default {
  name: 'TheHeader',
  data() {
    return {
      navbarOpen: false,
      logando: false,
      logo,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapGetters('app', { tablet: 'tablet' }),
    ...mapGetters({
      usuario: 'getUser',
    }),
    endDynamicSlot() {
      return this.tablet ? 'start' : 'end';
    },
    nomeUsuarioReduzido() {
      return this.usuario.nome
        ? this.usuario.nome
            .split(' ')
            .slice(0, 3)
            .join(' ')
        : '';
    },
    nameToDisplay() {
      return this.company.id && this.company.socialDenomination
        ? this.company.socialDenomination
        : this.usuario.nome;
    },
    startDynamicSlot() {
      return this.tablet ? 'end' : 'start';
    },
    temVariasEmpresas() {
      return this.usuario.empresas && this.usuario.empresas.length > 1;
    },
  },
  methods: {
    ...mapActions(['logout']),
    openNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    handleLogout() {
      this.logout();
    },
  },
};
</script>

<style lang="scss">
.navbar-header {
  position: relative;
  z-index: 9;
}

@media (max-width: 1023px) {
  .admin-header-container .navbar-menu {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin: 0.5rem 0 1rem;
  }

  .navbar-menu a {
    background-color: unset !important;
  }
}
</style>
