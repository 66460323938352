<template>
  <div class="panel">
    <div class="panel-heading">Filtros</div>
    <FiltrosComprador v-if="company.buyer" />
    <FiltrosVendedor v-if="company.seller" />
    <FiltrosVisitante v-if="visitor" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { funcoes } from '@/utils/enumerados';

import FiltrosComprador from './FiltrosComprador';
import FiltrosVendedor from './FiltrosVendedor';
import FiltrosVisitante from './FiltrosVisitante';

export default {
  name: 'DispensaListaFiltros',
  components: {
    FiltrosComprador,
    FiltrosVendedor,
    FiltrosVisitante,
  },
  computed: {
    ...mapGetters({
      function: 'getFunction',
    }),
    ...mapState('companies', ['company']),
    visitor() {
      return this.function === funcoes.VISITANTE;
    },
  },
};
</script>

<style lang="scss">
.field.is-grouped .field {
  flex-shrink: 1 !important;
}

.filters-buttons-container {
  display: flex;

  button {
    height: 75px;
    margin: 5px;
  }
}

@media (max-width: 1023px) {
  .filters-buttons-container button {
    height: unset;
    margin: 10px 0;
  }
}
</style>
