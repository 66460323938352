import { getAnexos as dadosAnexos, criarAnexos, deletarAnexo } from '@/resources/dispensas';

const DISPENSA_ANEXOS = 'DISPENSA_ANEXOS';
const DISPENSA_ANEXOS_ADICIONAR = 'DISPENSA_ANEXOS_ADICIONAR';
const DISPENSA_ANEXOS_LIMPAR = 'DISPENSA_ANEXOS_LIMPAR';
const DISPENSA_ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD = 'DISPENSA_ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD';
const DISPENSA_ANEXOS_ZERAR_PROGRESSO_UPLOAD = 'DISPENSA_ANEXOS_ZERAR_PROGRESSO_UPLOAD';
const DISPENSA_ANEXO_REMOVER = 'DISPENSA_ANEXO_REMOVER';

const mutations = {
  [DISPENSA_ANEXOS](state, data) {
    if (data && Array.isArray(data)) {
      state.anexos = data;
    }
  },
  [DISPENSA_ANEXOS_ADICIONAR](state, data) {
    if (data.tipo === 'EDITAL') {
      const indice = state.anexos.findIndex(anexo => anexo.tipo === 'EDITAL');
      if (indice > -1) {
        state.anexos.splice(indice, 1, data);
      } else {
        state.anexos = [...state.anexos, data];
      }
    } else {
      state.anexos = [...state.anexos, data];
    }
  },
  [DISPENSA_ANEXO_REMOVER](state, id) {
    const index = state.anexos.findIndex(anexo => anexo.id === id);
    if (index > -1) {
      state.anexos.splice(index, 1);
    }
  },
  [DISPENSA_ANEXOS_LIMPAR](state) {
    state.anexos = [];
  },
  [DISPENSA_ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD](state, progresso) {
    state.anexosUpload = Math.round((progresso.loaded / progresso.total) * 100);
  },
  [DISPENSA_ANEXOS_ZERAR_PROGRESSO_UPLOAD](state) {
    state.anexosUpload = 0;
  },
};

const actions = {
  async buscarAnexosDispensa({ commit }, dispensaId) {
    const response = await dadosAnexos(dispensaId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(DISPENSA_ANEXOS, response.data.data);
  },
  async adicionarAnexosDispensa({ commit, rootState }, dados) {
    const dispensaId = rootState.dispensas.dispensa.id;
    const response = await criarAnexos(dispensaId, dados, {
      onUploadProgress: progress => commit(DISPENSA_ANEXOS_ATUALIZAR_PROGRESSO_UPLOAD, progress),
    });
    if (response.status !== 201) return Promise.reject(response);
    return commit(DISPENSA_ANEXOS_ADICIONAR, response.data.data);
  },
  validarAnexosDispensa({ state }) {
    return state.anexos.length && state.anexos.some(anexo => anexo.tipo === 'EDITAL');
  },
  limparAnexosDispensa({ commit }) {
    commit(DISPENSA_ANEXOS_LIMPAR);
  },
  async removerAnexoDispensa({ commit, rootState }, id) {
    const dispensaId = rootState.dispensas.dispensa.id;
    const anexo = await deletarAnexo(dispensaId, id);
    if (anexo.status === 204) commit(DISPENSA_ANEXO_REMOVER, id);
  },
  zerarAnexosUploadDispensa({ commit }) {
    commit(DISPENSA_ANEXOS_ZERAR_PROGRESSO_UPLOAD);
  },
};

const getters = {
  obterAnexosDispensa(state) {
    return state.anexos;
  },
  obterAnexosUploadDispensa(state) {
    return state.anexosUpload;
  },
};

const state = {
  anexos: [],
  anexosUpload: 0,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
