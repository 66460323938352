import Vue from 'vue';

import {
  createCompany,
  fetchPrefecturesCities,
  getAll,
  getCompanyById,
  updateCompany,
  sendCompanyLogo,
} from '@/resources/companies';

const types = {
  PREFECTURE: 'PREFECTURE',
  PREFECTURE_ADMIN_CLEAN_FILTERS: 'PREFECTURE_ADMIN_CLEAN_FILTERS',
  PREFECTURE_CLEAN: 'PREFECTURE_CLEAN',
  PREFECTURE_STATUS_UPDATE: 'PREFECTURE_STATUS_UPDATE',
  PREFECTURES: 'PREFECTURES',
  PREFECTURES_CHANGE_PER_PAGE: 'PREFECTURES_CHANGE_PER_PAGE',
  PREFECTURES_CITIES: 'PREFECTURES_CITIES',
  PREFECTURES_FILTERS: 'PREFECTURES_FILTERS',
  PREFECTURES_LOADING: 'PREFECTURES_LOADING',
  PREFECTURES_TOTAL: 'PREFECTURES_TOTAL',
};

const mutations = {
  [types.PREFECTURE](state, data) {
    if (data && typeof data === 'object') {
      state.prefecture = data;
      state.prefecture.shareCapitalDate = data.shareCapitalDate
        ? new Date(data.shareCapitalDate)
        : new Date();
      state.prefecture.registryDate = data.registryDate ? new Date(data.registryDate) : new Date();
      state.prefecture.simpleNationalDate = data.simpleNationalDate
        ? new Date(data.simpleNationalDate)
        : new Date();
    }
  },
  [types.PREFECTURE_ADMIN_CLEAN_FILTERS](state) {
    state.prefecturesFilters = {
      period: {
        start: null,
        end: null,
      },
      prefectureName: '',
      page: 1,
      perPage: 20,
      buyer: true,
    };
  },
  [types.PREFECTURE_CLEAN](state) {
    state.prefecture = {};
  },
  [types.PREFECTURE_STATUS_UPDATE](state, data) {
    state.prefecture.status = data;
  },
  [types.PREFECTURES](state, data) {
    if (data && Array.isArray(data)) {
      state.prefectures = data;
    }
  },
  [types.PREFECTURES_CITIES](state, data) {
    if (data && Array.isArray(data)) {
      state.prefecturesCities = data;
    }
  },
  [types.PREFECTURES_CHANGE_PER_PAGE](state, perPage) {
    state.prefecturesFilters.perPage = perPage;
    state.prefecturesFilters.page = 1;
  },
  [types.PREFECTURES_FILTERS](state, filters) {
    Vue.set(state.prefecturesFilters, 'prefectureName', filters.prefectureName);

    if (filters.period) {
      Vue.set(state.prefecturesFilters.period, 'start', filters.period.start);
      Vue.set(state.prefecturesFilters.period, 'end', filters.period.end);
    }

    if (filters.page) {
      Vue.set(state.prefecturesFilters, 'page', filters.page);
    }

    if (filters.perPage) {
      Vue.set(state.prefecturesFilters, 'perPage', filters.perPage);
    }
  },
  [types.PREFECTURES_LOADING](state, data) {
    state.prefecturesLoading = data;
  },
  [types.PREFECTURES_TOTAL](state, data) {
    state.prefecturesTotal = data;
  },
};

const actions = {
  changePerPagePrefectures({ dispatch, commit, state }, perPage) {
    commit(types.PREFECTURES_CHANGE_PER_PAGE, perPage);
    return dispatch('fetchPrefectures', state.prefecturesFilters);
  },
  async createPrefecture({ commit }, cnpj) {
    const data = {
      cnpj,
      buyer: true,
      seller: false,
    };
    const response = await createCompany(data);
    if (response.status !== 201) return Promise.reject(response.data);
    commit(types.PREFECTURE, response.data);
    return response.data.id;
  },
  async fetchPrefectures({ commit }, filters = {}) {
    commit(types.PREFECTURES_LOADING, true);
    const { data: prefectures } = await getAll(filters);
    commit(types.PREFECTURES, prefectures.data);
    commit(types.PREFECTURES_TOTAL, prefectures.buyerCount);
    commit(types.PREFECTURES_LOADING, false);
  },
  async fetchPrefecture({ commit }, prefectureId) {
    const response = await getCompanyById(prefectureId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.PREFECTURE, response.data);
  },
  async fetchPrefecturesCities({ commit }) {
    const response = await fetchPrefecturesCities();
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.PREFECTURES_CITIES, response.data);
  },
  filterPrefectures({ dispatch, commit, state }, filters) {
    const filtersData = filters ? { ...filters } : state.prefecturesFilters;
    commit(types.PREFECTURES_FILTERS, filtersData);
    return dispatch('fetchPrefectures', state.prefecturesFilters);
  },
  async savePrefectureData({ commit, dispatch, rootState, state }, data) {
    const companyId = state.prefecture.id;
    const response = await updateCompany(companyId, data);
    if (response.status !== 200) return Promise.reject(response.data);
    if (rootState.companies.company.id === state.prefecture.id) {
      dispatch('companies/updateCompanyData', response.data, { root: true });
    }
    return commit(types.PREFECTURE, response.data);
  },
  async sendPrefectureLogo({ commit }, data) {
    const { companyId, dataLogo } = data;
    const response = await sendCompanyLogo(companyId, dataLogo);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.PREFECTURE, response.data);
  },
};

const state = {
  prefecture: {
    id: 0,
  },
  prefectures: [],
  prefecturesCities: [],
  prefecturesLoading: false,
  prefecturesTotal: 0,
  prefecturesFilters: {
    period: {
      start: null,
      end: null,
    },
    prefectureName: '',
    page: 1,
    perPage: 20,
    buyer: true,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

export { types };
