<template>
  <div class="send-document-component">
    <form>
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Documento"
          :type="campoInvalido('type', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('type', 'data') ? 'Documento é um campo obrigatório' : ''"
        >
          <b-autocomplete
            expanded
            open-on-focus
            class="select-type"
            placeholder="Selecione um documento"
            v-model="data.type"
            :data="filteredDocumentTypes"
            :disabled="!filteredDocumentTypes.length"
            @keydown.native.prevent
            @select="event => selectType(event)"
          >
            <template v-slot:empty>Nenhum documento requerido</template>

            <template v-slot="props">
              <div class="media">
                <div class="media-content document-type">
                  {{ props.option.type }}
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>

        <b-field :expanded="tablet">
          <div class="field company-register-upload-field">
            <b-upload
              expanded
              v-model="data.file"
              :disabled="!data.type"
              @input="
                {
                  resetCompanyUpload(), saveDocument();
                }
              "
            >
              <a
                :disabled="!filteredDocumentTypes.length || !data.type"
                class="button is-primary is-fullwidth"
              >
                <b-icon icon="upload"></b-icon>
                <span>Carregar Arquivo</span>
              </a>
            </b-upload>
          </div>
        </b-field>
      </b-field>
    </form>

    <div>
      <b-progress
        show-value
        class="progress-upload"
        format="percent"
        type="is-info"
        size="is-medium"
        :value="companyUpload"
      ></b-progress>
    </div>

    <hr />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import { fileServer } from '@/utils/functions';
import { uploadLimit } from '@/utils/data';

import campo from '@/mixins/formulario';

export default {
  name: 'CompanySendDocument',
  mixins: [campo],
  props: {
    requiredDocuments: { type: Array, default: () => [] },
  },
  data() {
    const data = {
      type: null,
      file: [],
    };
    const loading = {
      general: false,
      save: false,
    };

    return {
      data,
      loading,
      yearsLimit: [-119, 31],
    };
  },
  validations: {
    data: {
      type: { required },
      file: { required },
    },
  },
  filters: {
    fileServer,
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapState('companiesDocuments', ['companyDocuments', 'companyUpload']),
    ...mapGetters('app', { tablet: 'tablet' }),
    filteredDocumentTypes() {
      return this.requiredDocuments
        .reduce((documents, document) => {
          const exists = this.companyDocuments.find(element => element.type === document.type);
          if (!exists) documents.push(document);

          return documents;
        }, [])
        .sort((a, b) => a.type.localeCompare(b.type));
    },
  },
  methods: {
    ...mapActions('companiesDocuments', ['addCompanyDocument', 'resetCompanyUpload']),
    cleanFields() {
      this.data.type = null;
      this.data.file = [];
      this.$v.data.$reset();
    },
    async saveDocument() {
      if (!this.validate()) {
        this.data.file = [];
        return;
      }

      const data = new FormData();

      let documentData;
      data.append('type', this.data.type);
      if (this.data.validity) {
        documentData = this.setDocumentDate();
        data.append('validity', documentData);
      }
      data.append('file', this.data.file);

      this.loading.save = true;
      try {
        await this.addCompanyDocument({ companyId: this.company.id, data });

        this.$alerta('Documento adicionado', 'is-success');
        this.cleanFields();
      } catch (error) {
        this.$alerta('Erro ao adicionar documento', 'is-danger');
      }
      this.loading.save = false;
    },
    validate() {
      if (this.data.file.size > uploadLimit) {
        this.$alerta('Insira um arquivo com até 50MB', 'is-danger');
        return false;
      }

      const { unique } = this.requiredDocuments.find(
        requiredDocument => requiredDocument.type === this.data.type,
      );
      const exists = this.companyDocuments.find(documento => documento.type === this.data.type);
      if (unique && exists) {
        return this.$alerta('Documento já adicionado', 'is-danger');
      }

      return true;
    },
    setDocumentDate() {
      return new Date(
        new Date(
          this.data.validity.getFullYear(),
          this.data.validity.getMonth(),
          this.data.validity.getDate(),
        ).setHours(0, 0, 0, 0),
      );
    },
    selectType(option) {
      setTimeout(() => {
        if (option.type) this.data.type = option.type;
      }, 1);
    },
  },
  mounted() {
    this.resetCompanyUpload();
  },
};
</script>

<style lang="scss" scoped>
.document-type {
  white-space: normal;
}

.progress-upload {
  margin-top: 1.5rem;
}

.send-document-component {
  margin-top: 2rem;
}

.select-type {
  text-align: left;
}

@media (min-width: 1024px) {
  .company-register-upload-field {
    margin-top: 31px;
  }
}

@media (max-width: 1023px) {
  .company-register-upload-field {
    margin: 0.5rem 0;
  }
}
</style>

<style lang="scss">
.select-type > div > input {
  caret-color: transparent;
  cursor: pointer;
}
</style>
