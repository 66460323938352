import Vue from 'vue';

import './plugins/axios';
import './plugins/socket';
import './plugins/buefy';
import './plugins/vuex-router-sync';
import './plugins/validate';
import './plugins/clipboard';
import './plugins/alerta';
import './plugins/snackbar';
import './plugins/bus';
import './plugins/progress-bar';
import './plugins/confirmacao';
import './plugins/confirmacaoAlerta';

import './directives/mascara';

import App from './App';
import store from './vuex/store';
import router from './router';

Vue.config.productionTip = false;
Vue.prototype.window = window;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
