import { api, sendTimeout } from '../http';

export const getAll = filters => {
  let params = '?';

  if (filters) {
    if (filters.buyer) {
      params += `buyer=${filters.buyer}&`;
    }
    if (filters.period) {
      if (filters.period.start) {
        params += `start=${filters.period.start.toISOString()}&`;
      }
      if (filters.period.end) {
        params += `end=${filters.period.end.toISOString()}&`;
      }
    }

    if (filters.prefectureName) {
      params += `socialDenomination=${filters.prefectureName}&`;
    }

    if (filters.page) params += `page=${filters.page}&`;
    if (filters.perPage) params += `per_page=${filters.perPage}&`;
  }
  return api.get(`/companies${params}`);
};

export const acceptCompanyServiceContract = companyId =>
  api.post(`/companies/${companyId}/accept-service-contract`);

export const createCompany = data => api.post('/companies', data);
export const createInterestedCompany = data => api.post('/companies/interested', data);
export const fetchPrefecturesCities = () => api.get('/companies/cities');
export const getCompanyById = companyId => api.get(`/companies/${companyId}`);
export const getCompanyHistory = companyHistoryId =>
  api.get(`/companies/history/${companyHistoryId}`);
export const getCompanyInvitation = hash => api.get(`/companies/invitation/${hash}`);

export const updateCompany = (companyId, data) => api.patch(`/companies/${companyId}`, data);

export const sendCompanyLogo = (companyId, data) =>
  api.post(`/companies/${companyId}/logo`, data, { timeout: sendTimeout });
