import { todas as relatorioVencedores } from '@/resources/relatorios';

const types = {
  RELATORIO_VENCEDORES: 'RELATORIO_VENCEDORES',
};

const mutations = {
  [types.RELATORIO_VENCEDORES](state, data) {
    if (data && Array.isArray(data)) {
      state.relatorios = data;
    }
  },
};

const actions = {
  async buscarRelatorioVencedores({ commit }, filtros = {}) {
    const relatorios = await relatorioVencedores(filtros);
    commit(types.RELATORIO_VENCEDORES, relatorios.data.data);
  },
};

const getters = {
  obterRelatoriosVencedores(state) {
    return state.relatorios;
  },
};

const state = {
  relatorios: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
