<template>
  <div>
    <AppValor
      ref="inputValor"
      v-if="exibirInputValor && (exibirCampoLance || exibirCampoLanceFechado)"
      :calcular-percentual="maiorDesconto"
      :exibir-input="!maiorDesconto"
      :item="item"
      :valor-valido="valorValido"
      :valores="valores"
      :width="maiorDesconto ? '140' : '160'"
      @valor="dados => atualizarValores(dados, 'inputValor')"
      @blur="limpaMensagem"
      @keyup.enter.native="enviarLance"
    />

    <div v-if="exibirTagEnviado" class="tag-enviado">
      <h3 style="font-weight: bold">
        {{ lanceFechadoEnviado.valor | formatarValor }}
      </h3>
    </div>
  </div>
</template>

<script>
import Big from 'big.js';

import { mapGetters } from 'vuex';
import campoMixin from '@/mixins/formulario';
import auctionValuesMixin from '@/mixins/auctionValues';

import { formatarValor, formatarPorcentagem } from '@/utils/format';

import { funcoes } from '@/utils/enumerados';

import AppValor from '@/components/AppValor';

export default {
  name: 'PregaoValor',
  props: {
    item: Object,
    lancesFechadosEmEnvio: Array,
    valores: Object,
  },
  components: {
    AppValor,
  },
  mixins: [campoMixin, auctionValuesMixin],
  data() {
    const lance = {
      ofertaCompraItemId: 0,
      identificador: 0,
      descricao: '',
      valor: 0,
      percentual: 0,
      invalido: false,
      valores: {},
    };

    return {
      lance,
      formatarValor,
      formatarPorcentagem,
    };
  },
  filters: {
    formatarValor,
  },
  computed: {
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
      lances: 'obterOfertaCompraLances',
      funcao: 'getFunction',
    }),
    exibirInputValor() {
      return !this.maiorDesconto || (this.maiorDesconto && this.existeReferencial);
    },
    existeReferencial() {
      return this.item.referencia && this.item.total;
    },
    divulgarReferencial() {
      return this.ofertaCompra.divulgar_referencial;
    },
    valorValido() {
      if (!this.maiorDesconto) {
        const valor = new Big(this.lance.valor);
        const menorLance = new Big(this.item.menor_lance.valor);

        if (this.situacao === 8) {
          return valor.lt(menorLance);
        }

        if (this.maiorDesconto && this.divulgarReferencial) {
          const valorReferencia = new Big(this.item.total);

          return valor.lt(valorReferencia);
        }

        return valor.gt(0) && valor.lt(999999999.9999);
      }

      return true;
    },
    valoresMascara() {
      return this.$refs.inputValor.valoresMascara;
    },
    licitante() {
      return this.funcao === funcoes.LICITANTE;
    },
  },
  methods: {
    focus() {
      if ((this.exibirCampoLanceFechado && this.modoFechado) || this.exibirCampoLance) {
        if (!this.maiorDesconto) {
          this.$refs.inputValor.focus();
        }
      }
    },
  },
};
</script>

<style scoped>
.detail-container {
  font-size: 0.9rem;
  margin: 0%;
}

.tag-enviado {
  background-color: #2153b5;
  color: #fff;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  display: inline-flex;
  font-size: 1rem;
  height: 1.5em;
  -webkit-box-pack: center;
}
</style>
