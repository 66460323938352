import { api } from './http';

// eslint-disable-next-line import/prefer-default-export
export const todas = filtros => {
  let parametros = '?';

  if (filtros) {
    if (filtros.vendedor) {
      parametros += `vendedor=${filtros.vendedor}&`;
    }

    if (filtros.periodo) {
      if (filtros.periodo.inicio) {
        parametros += `inicio=${filtros.periodo.inicio.toISOString()}&`;
      }
      if (filtros.periodo.fim) {
        parametros += `fim=${filtros.periodo.fim.toISOString()}&`;
      }
    }

    if (filtros.pagina) parametros += `pagina=${filtros.pagina}&`;
  }
  return api.get(`/relatorios${parametros}`);
};
