import { api } from '../http';

export const searchAuctionDeclarations = auctionId =>
  api.get(`/ofertascompra/${auctionId}/declaracoes`);
export const searchDeclarations = (auctionId, companyId) =>
  api.get(`/ofertascompra/${auctionId}/fornecedores/${companyId}/declaracoes`);
export const createDeclaration = (auctionId, companyId, declarations) =>
  api.post(`/ofertascompra/${auctionId}/fornecedores/${companyId}/declaracoes`, declarations);
export const removeDeclaration = (auctionId, companyId, declarationId) =>
  api.delete(`/ofertascompra/${auctionId}/fornecedores/${companyId}/declaracoes/${declarationId}`);
