import {
  fetchAuctionBidderDocuments,
  fetchAuctionDocumentsList,
  addAuctionDocuments,
  removeAuctionDocument,
} from '@/resources/electronicAuctions/auctionsDocuments';

export const types = {
  AUCTION_DOCUMENTS: 'AUCTION_DOCUMENTS',
  AUCTION_DOCUMENTS_ADD: 'AUCTION_DOCUMENTS_ADD',
  AUCTION_DOCUMENTS_UPDATE: 'AUCTION_DOCUMENTS_UPDATE',
  AUCTION_DOCUMENTS_UPDATE_UPLOAD: 'AUCTION_DOCUMENTS_UPDATE_UPLOAD',
  AUCTION_DOCUMENTS_RESET_UPLOAD: 'AUCTION_DOCUMENTS_RESET_UPLOAD',
  AUCTION_DOCUMENTS_CLEAN: 'AUCTION_DOCUMENTS_CLEAN',
  AUCTION_DOCUMENTS_LIST: 'AUCTION_DOCUMENTS_LIST',
  AUCTION_DOCUMENTS_LIST_COUNT: 'AUCTION_DOCUMENTS_LIST_COUNT',
  AUCTION_DOCUMENT_REMOVE: 'AUCTION_DOCUMENT_REMOVE',
};

const mutations = {
  [types.AUCTION_DOCUMENTS](state, data) {
    if (data && Array.isArray(data)) {
      state.auctionDocuments = data;
    }
  },
  [types.AUCTION_DOCUMENTS_LIST](state, data) {
    if (data && Array.isArray(data)) {
      state.auctionDocumentsList = data;
    }
  },
  [types.AUCTION_DOCUMENTS_LIST_COUNT](state, count) {
    state.auctionDocumentsListCount = count;
  },
  [types.AUCTION_DOCUMENTS_ADD](state, data) {
    if (data && Array.isArray(data)) {
      data.forEach(document => {
        state.auctionDocuments.push(document);
      });
    } else if (data && typeof data === 'object') {
      state.auctionDocuments = [...state.auctionDocuments, data];
    }
    state.auctionDocuments.sort((a, b) => a.type.localeCompare(b.type));
  },
  [types.AUCTION_DOCUMENTS_UPDATE](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const auctionDocument = data.shift();
        const index = state.auctionDocuments.findIndex(
          element => element.id === auctionDocument.id,
        );

        if (index > -1) {
          state.auctionDocuments[index] = Object.assign(
            state.auctionDocuments[index],
            auctionDocument,
          );
        }
      }
    } else if (data && typeof data === 'object') {
      const index = state.auctionDocuments.findIndex(element => element.id === data.id);
      if (index > -1) {
        state.auctionDocuments[index] = Object.assign(state.auctionDocuments[index], data);
      }
    }
  },
  [types.AUCTION_DOCUMENT_REMOVE](state, data) {
    const index = state.auctionDocuments.findIndex(element => element.id === data.id);
    if (index > -1) state.auctionDocuments.splice(index, 1);
  },
  [types.AUCTION_DOCUMENTS_UPDATE_UPLOAD](state, progress) {
    state.auctionUpload = Math.round((progress.loaded / progress.total) * 100);
  },
  [types.AUCTION_DOCUMENTS_RESET_UPLOAD](state) {
    state.auctionUpload = 0;
  },
  [types.AUCTION_DOCUMENTS_CLEAN](state) {
    state.auctionDocuments = [];
  },
};

const actions = {
  async fetchAuctionBidderDocuments({ commit }, { auctionId, bidderId, itemId = null }) {
    const response = await fetchAuctionBidderDocuments(auctionId, bidderId, itemId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.AUCTION_DOCUMENTS, response.data);
  },
  async fetchAuctionDocumentsList({ commit }, { auctionId, filters }) {
    const response = await fetchAuctionDocumentsList(auctionId, filters);
    if (response.status !== 200) return Promise.reject(response.data);
    commit(types.AUCTION_DOCUMENTS_LIST_COUNT, response.data.count);
    return commit(types.AUCTION_DOCUMENTS_LIST, response.data.data);
  },
  async addAuctionDocuments({ commit }, { auctionId, bidderId, data }) {
    const response = await addAuctionDocuments(auctionId, bidderId, data, {
      onUploadProgress: progress => commit(types.AUCTION_DOCUMENTS_UPDATE_UPLOAD, progress),
    });
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.AUCTION_DOCUMENTS_ADD, response.data);
  },
  async removeAuctionDocument({ commit }, { auctionId, bidderId, documentId }) {
    const response = await removeAuctionDocument(auctionId, bidderId, documentId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.AUCTION_DOCUMENT_REMOVE, response.data);
  },
  updateAuctionDocuments({ commit }, data) {
    if (Array.isArray(data)) {
      commit(types.AUCTION_DOCUMENTS_ADD, data);
    } else if (typeof data === 'object') {
      if (data.delete) {
        commit(types.AUCTION_DOCUMENT_REMOVE, data);
      } else if (data.aceito !== null) {
        commit(types.AUCTION_DOCUMENTS_UPDATE, data);
      }
    }
  },
  resetAuctionDocumentsUpload({ commit }) {
    commit(types.AUCTION_DOCUMENTS_RESET_UPLOAD);
  },
  cleanAuctionDocuments({ commit }) {
    commit(types.AUCTION_DOCUMENTS_CLEAN);
  },
};

const getters = {};

const state = {
  auctionDocuments: [],
  auctionDocumentsList: [],
  auctionDocumentsListCount: 0,
  auctionUpload: 0,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
