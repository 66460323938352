<template>
  <div class="panel">
    <div class="panel-heading">
      Documentos Requeridos
    </div>

    <div class="panel-content">
      <ul class="required-documents-list">
        <li
          class="required-documents-item"
          v-for="requiredDocument in requiredDocuments"
          :key="requiredDocument.id"
        >
          <div class="level">
            <div class="level-left">
              <b-icon
                v-if="companyDocuments.some(document => document.type === requiredDocument.type)"
                class="custom-icon"
                icon="check"
                type="is-success"
              ></b-icon>
              <b-icon v-else class="custom-icon" icon="close" type="is-danger"></b-icon>

              <div>{{ requiredDocument.type }}</div>

              <a
                download
                class="custom-document-link"
                v-if="companyDocuments.some(document => document.type === requiredDocument.type)"
                :href="findDocumentPath(requiredDocument.type)"
                >{{ findDocumentName(requiredDocument.type) }}</a
              >
            </div>

            <div class="level-right">
              <b-tooltip
                v-if="companyDocuments.some(document => document.type === requiredDocument.type)"
                class="required-document-delete"
                label="Excluir"
              >
                <button
                  class="button is-danger is-small"
                  :disabled="disableDelete"
                  @click.prevent.stop="deleteDocument(requiredDocument.type)"
                >
                  <b-icon icon="delete" />
                </button>
              </b-tooltip>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { fileServer } from '@/utils/functions';

export default {
  name: 'CompanyRequiredDocuments',
  props: {
    requiredDocuments: { type: Array, default: () => [] },
  },
  data() {
    return {
      disableDelete: false,
    };
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapState('companiesDocuments', ['companyDocuments']),
  },
  methods: {
    ...mapActions('companiesDocuments', ['removeCompanyDocument']),
    async deleteDocument(type) {
      const document = this.companyDocuments.find(element => element.type === type);
      this.disableDelete = true;
      try {
        await this.removeCompanyDocument({ companyId: this.company.id, documentId: document.id });
        this.$alerta('Documento excluído', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir documento', 'is-danger');
      }
      this.disableDelete = false;
    },
    findDocumentName(type) {
      const document = this.companyDocuments.find(companyDocument => companyDocument.type === type);
      if (document) return document.fileName;
      return '';
    },
    findDocumentPath(type) {
      const document = this.companyDocuments.find(companyDocument => companyDocument.type === type);
      if (document) return fileServer(document.file);
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-document-link {
  margin-left: 1rem;
  min-width: 20%;
}

.custom-icon {
  margin-right: 0.5rem;
  vertical-align: middle;
}

.empty-panel {
  justify-content: center !important;
}

.level-left {
  max-width: 90%;
}

.level-right {
  max-width: 10%;
}

.panel-content {
  font-size: 0.92rem;
  padding: 0.5rem;
  height: unset !important;
}

.required-document-delete {
  margin-left: 0.2rem;
  vertical-align: middle;
}

.required-documents-item {
  margin: 0.2rem 0 0.2rem;
}

@media (max-width: 769px) {
  .level {
    display: flex;
  }

  .level-left {
    display: flex;
  }

  .panel-content {
    font-size: 0.88rem;
  }
}

@media (max-width: 1500px) {
  .panel-content {
    font-size: 0.88rem;
  }
}
</style>
