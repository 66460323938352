<template>
  <footer class="footer">
    <div class="container">
      <div class="content has-text-centered">
        <p>
          <a href="http://www.selcorp.com.br">SelCorp</a> - Sistema Eletrônico de Licitações.
          Copyright (C) {{ new Date().getFullYear() }}. Versão: {{ version }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooting',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
};
</script>
