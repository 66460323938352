<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container has-text-centered login-container">
        <div class="column">
          <h3 class="title has-text-white">Entrar</h3>

          <div class="box login-box-container">
            <form @keyup.enter="validateLogin" v-show="!chooseCompany">
              <b-field
                :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
                :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
              >
                <b-input
                  ref="email"
                  type="email"
                  placeholder="Email"
                  v-model="$v.data.email.$model"
                  @input="data.email = data.email.toLowerCase()"
                />
              </b-field>

              <b-field
                :type="campoInvalido('password', 'data') ? 'is-danger' : ''"
                :message="campoInvalido('password', 'data') ? 'Senha é um campo obrigatório' : ''"
              >
                <b-input
                  password-reveal
                  type="password"
                  placeholder="Senha"
                  v-model="$v.data.password.$model"
                />
              </b-field>

              <b-field
                class="terms-field"
                :type="campoInvalido('termsAccepted', 'data') ? 'is-danger' : ''"
                :message="
                  campoInvalido('termsAccepted', 'data')
                    ? 'Você deve concordar com os Termos de Condições de Uso'
                    : ''
                "
              >
                <div class="terms">
                  <router-link :to="{ name: 'TermsOfUse' }" target="_blank">
                    <a>Termos de Condições de Uso</a>
                  </router-link>

                  <b-checkbox v-model="$v.data.termsAccepted.$model" class="check-term"
                    ><p class="checkbox-text">
                      Declaro que li e concordo com os termos do regulamento de uso do sistema
                      SelCorp.
                    </p></b-checkbox
                  >
                </div>
              </b-field>

              <div class="keep-connected">
                <b-checkbox v-model="data.keepConnected"
                  ><p class="checkbox-text">
                    Manter conectado.
                  </p></b-checkbox
                >
              </div>

              <a
                expanded
                :class="[
                  'button is-block is-primary is-large is-fullwidth',
                  isLogging ? 'is-loading' : '',
                ]"
                @click.prevent.stop="validateLogin"
              >
                Login
              </a>

              <div class="links-container">
                <a href="../pre-cadastro">Novo por aqui? Cadastre-se</a>

                <br />

                <a href="../esqueci-senha">Esqueceu a senha?</a>
              </div>
            </form>

            <form @keyup.enter="changeCompany" v-show="chooseCompany">
              <div v-if="user.id && user.empresas.length">
                <b-table
                  hoverable
                  class="table is-fullwidth"
                  :data="user.empresas"
                  :selected.sync="selected"
                  @select="row => (selected = row)"
                >
                  <b-table-column numeric label="ID" width="40" v-slot="props">
                    <div class="column-table has-text-left">
                      {{ props.row.id }}
                    </div>
                  </b-table-column>

                  <b-table-column label="Empresa" v-slot="props">
                    <div class="column-table has-text-left">
                      {{ props.row.fantasyName ? props.row.fantasyName : props.row.cnpj }}
                    </div>
                  </b-table-column>
                </b-table>

                <a
                  :class="['button is-block is-primary is-large', isLogging ? 'is-loading' : '']"
                  @click.prevent.stop="changeCompany"
                  >Entrar</a
                >
              </div>

              <div v-else>
                <h1 class="subtitle is-spaced is-6">
                  Você ainda não está participando de uma empresa.
                </h1>

                <router-link
                  :to="{
                    name: 'Register',
                  }"
                  class="button is-block is-primary is-large"
                  >Cadastrar Empresa</router-link
                >
              </div>

              <a @click.prevent.stop="cancelLogin">Cancelar</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';

import formValidation from '@/mixins/formulario';

export default {
  name: 'Login',
  mixins: [formValidation],
  data() {
    const data = {
      email: '',
      keepConnected: null,
      password: '',
      termsAccepted: null,
    };
    return {
      data,
      isLogging: false,
      selected: null,
    };
  },
  validations: {
    data: {
      email: { email, required },
      password: { required },
      termsAccepted: { required },
    },
  },
  computed: {
    ...mapState('companies', ['company', 'invitation']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      chooseCompany: 'getChooseCompany',
      confirmedUserEmail: 'getConfirmedUserEmail',
      user: 'getUser',
      userToken: 'getUserToken',
    }),
    dashboardRoute() {
      return this.$route.name === 'Dashboard';
    },
    enterRoute() {
      return this.$route.path === '/entrar';
    },
    redirectRoute() {
      return this.$route.query.redirect;
    },
    registerRoute() {
      return this.$route.path === '/cadastrar' || this.$route.path === '/confirmar-usuario';
    },
    situation() {
      return this.auction.situacao.id;
    },
  },
  methods: {
    ...mapActions('auctionsDocuments', ['fetchAuctionBidderDocuments']),
    ...mapActions(['cleanAuctions', 'cleanDispensations', 'chooseCompanyUser', 'login', 'logout']),
    cancelLogin() {
      if (!this.company.id) {
        this.logout();
      }
      this.chooseCompanyUser(false);
      this.$parent.close();
    },
    async changeCompany() {
      if (this.company) {
        this.isLogging = true;
        try {
          this.cleanAuctions();
          this.cleanDispensations();

          await this.login({
            companyId: this.chooseCompany ? this.selected.id : this.company.id,
          });
          this.chooseCompanyUser(false);
          this.checkRedirect();
        } catch (erro) {
          this.$alerta('Não foi possível realizar o login', 'is-danger');
        } finally {
          this.isLogging = false;
        }
      }
    },
    customValidate() {
      if (!this.data.termsAccepted) {
        this.data.termsAccepted = null;
      }

      if (this.$v.data.$invalid || !this.data.termsAccepted) {
        this.$v.data.$touch();

        return false;
      }
      return true;
    },
    checkRedirect() {
      if (this.redirectRoute) {
        this.$router.push(this.$route.query.redirect);
        return;
      }

      if (!this.dashboardRoute) {
        this.$router.push({ path: '/dashboard' });
      }
    },
    finishLogin() {
      if (!this.confirmedUserEmail) {
        this.$router.push({ path: '/confirmar-email' });
        return;
      }

      if (this.company.id === 1) {
        this.$router.push({ path: 'Admin' });
        return;
      }

      if (this.user.empresas.length > 1) {
        this.chooseCompanyUser(true);
        return;
      }

      this.checkRedirect();
    },
    async validateLogin() {
      if (this.customValidate()) {
        this.isLogging = true;
        try {
          this.cleanAuctions();
          this.cleanDispensations();

          await this.login({
            email: this.data.email,
            keepConnected: this.data.keepConnected,
            password: this.data.password,
            withoutReload: this.registerRoute && this.$route.query.convite,
          });

          if (this.company.status !== 'BLOQUEADA') {
            this.finishLogin();
          }
        } catch (error) {
          if (!error.response) {
            this.$alerta('Ocorreu um erro, tente novamente', 'is-danger');
          } else if (error && error.response.status === 401) {
            this.$alerta('Usuário ou senha incorretos', 'is-danger');
          }
        } finally {
          this.isLogging = false;
        }
      }
    },
  },
  mounted() {
    this.$refs.email.focus();
  },
};
</script>

<style lang="scss" scoped>
.checkbox-text {
  margin-left: 0.2rem;
  font-size: 0.96rem;
}

.column-table {
  cursor: pointer;
}

.keep-connected {
  text-align: initial;
  padding: 15px;
  width: 100%;
  margin: 0 0 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.links-container {
  margin-top: 1rem;
}

.terms {
  text-align: initial;
  padding: 15px;
  width: 100%;
  margin-top: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.terms-field {
  margin-bottom: 1rem !important;
}

.check-term {
  margin-top: 1rem;
  display: flex;
}

.landing .container {
  font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.login-container {
  max-width: 100% !important;

  @media (min-width: 768px) {
    width: 440px !important;
  }
}

.landing .container {
  max-width: 100% !important;
}

@media (max-width: 768px) {
  .login-box-container {
    max-height: 77vh;
    max-width: 90vw;
  }

  .login-container {
    font-size: 0.8rem;
  }
}
</style>
