<template>
  <div class="has-text-centered message-content" :class="{ 'with-margin': withMargin }">
    <div class="app-message-container">
      <div class="message-icon">
        <slot name="message-icon"></slot>
      </div>

      <h1 class="title is-spaced" :class="{ 'is-3': !mobile, 'is-5': mobile }">
        <slot name="message-title"></slot>
      </h1>

      <h3 class="subtitle is-6">
        <slot name="message"></slot>
      </h3>

      <div v-if="renderButton" class="message-button">
        <slot name="message-button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AppMessage',
  props: {
    renderButton: { type: Boolean, default: false },
    withMargin: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters('app', { mobile: 'mobile' }),
  },
};
</script>

<style lang="scss" scoped>
.app-message-container {
  max-width: 900px;
}

.message-button {
  margin-top: 1rem;
}

.message-icon {
  margin-bottom: 0.5rem;
}

.with-margin {
  margin: 10px 0;
}
</style>
