import Vue from 'vue';

import {
  buscarOfertaAdesaoEmpresas,
  criar as criarOfertaCompra,
  deletar as deletarOfertaCompra,
  editAuction,
  get as dadosOfertaCompra,
  getElegiveisFechado as dadosElegiveisFechado,
  generateAuctionMinutes,
  getParticipantesFechado as dadosParticipantesFechado,
  getItens as dadosItens,
  encerrarAnalise,
  encerrarLances,
  voltarParaAnaliseDePropostas,
  encerrarOferta,
  openAuctionSession,
  publishAuction,
  reabrirSessao,
  exportAccountIntegration,
  stats as dadosStats,
  todas as dadosOfertasCompra,
} from '@/resources/ofertasCompra';

import { fetchItemsLastProposal } from '@/resources/electronicAuctions/proposals';

import {
  cancelAuction,
  revokeAuction,
  suspendAuction,
  updateReopeningDate,
} from '@/resources/electronicAuctions/auctions';

import { types as typesItens } from './ofertasCompraItens';

const types = {
  OFERTAS_COMPRA: 'OFERTAS_COMPRA',
  OFERTAS_COMPRA_STATS: 'OFERTAS_COMPRA_STATS',
  OFERTAS_COMPRA_CARREGANDO: 'OFERTAS_COMPRA_CARREGANDO',
  OFERTA_COMPRA_EM_PUBLICACAO: 'OFERTA_COMPRA_EM_PUBLICACAO',
  OFERTAS_COMPRA_CLEAN: 'OFERTAS_COMPRA_CLEAN',
  OFERTAS_COMPRA_TOTAL: 'OFERTAS_COMPRA_TOTAL',
  OFERTAS_COMPRA_FILTRAR: 'OFERTAS_COMPRA_FILTRAR',
  OFERTAS_COMPRA_ALTERAR_POR_PAGINA: 'OFERTAS_COMPRA_ALTERAR_POR_PAGINA',
  OFERTA_COMPRA: 'OFERTA_COMPRA',
  OFERTA_COMPRA_ELEGIVEIS_FECHADO: 'OFERTA_COMPRA_ELEGIVEIS_FECHADO',
  OFERTA_COMPRA_PARTICIPANTES_FECHADO: 'OFERTA_COMPRA_PARTICIPANTES_FECHADO',
  OFERTA_COMPRA_ADESOES: 'OFERTA_COMPRA_ADESOES',
  OFERTA_COMPRA_ADICIONAR_ADESAO: 'OFERTA_COMPRA_ADICIONAR_ADESAO',
  OFERTA_COMPRA_STATUS: 'OFERTA_COMPRA_STATUS',
  OFERTA_COMPRA_LIMPAR: 'OFERTA_COMPRA_LIMPAR',
  OFERTA_COMPRA_LIMPAR_FILTROS: 'OFERTA_COMPRA_LIMPAR_FILTROS',
  OFERTA_COMPRA_UPDATE: 'OFERTA_COMPRA_UPDATE',
  OFERTA_COMPRA_ITENS: 'OFERTA_COMPRA_ITENS',
  OFERTA_COMPRA_ULTIMA_PROPOSTA_ITENS: 'OFERTA_COMPRA_ULTIMA_PROPOSTA_ITENS',
  OFERTAS_COMPRA_EXCLUIR: 'OFERTAS_COMPRA_EXCLUIR',
};

const mutations = {
  [types.OFERTAS_COMPRA](state, data) {
    if (data && Array.isArray(data)) {
      state.ofertasCompra = data;
      state.carregandoOfertasCompra = false;
    } else if (data && typeof data === 'object') {
      const index = state.ofertasCompra.findIndex(elemento => elemento.id === data.id);
      if (index > -1) {
        state.ofertasCompra[index] = Object.assign(state.ofertasCompra[index], data);
      }
    }
  },
  [types.OFERTAS_COMPRA_EXCLUIR](state, ofertaCompraId) {
    state.ofertasCompra = state.ofertasCompra.filter(
      ofertaCompra => ofertaCompra.id !== ofertaCompraId,
    );
  },
  [types.OFERTAS_COMPRA_CARREGANDO](state, data) {
    state.carregandoOfertasCompra = data;
  },
  [types.OFERTA_COMPRA_EM_PUBLICACAO](state, valor) {
    state.emPublicacao = valor;
  },
  [types.OFERTAS_COMPRA_STATS](state, { inProgress, missingValidations, suspended }) {
    state.stats.inProgress = inProgress;
    state.stats.missingValidations = missingValidations;
    state.stats.suspended = suspended;
  },
  [types.OFERTAS_COMPRA_TOTAL](state, data) {
    state.total = Number(data);
  },
  [types.OFERTAS_COMPRA_FILTRAR](state, filters) {
    state.dataFilters = {
      ...state.dataFilters,
      page: 1,
      ...filters,
    };
  },

  [types.OFERTAS_COMPRA_ALTERAR_POR_PAGINA](state, perPage) {
    state.dataFilters.perPage = perPage;
    state.dataFilters.page = 1;
  },
  [types.OFERTA_COMPRA](state, data) {
    if (data && typeof data === 'object') {
      state.ofertaCompra = Object.assign(state.ofertaCompra, data);
      if (state.ofertaCompra.data_abertura) {
        state.ofertaCompra.data_abertura = new Date(state.ofertaCompra.data_abertura);
      }
      if (state.ofertaCompra.data_publicacao) {
        state.ofertaCompra.data_publicacao = new Date(state.ofertaCompra.data_publicacao);
      }
      if (state.ofertaCompra.data_reabertura) {
        state.ofertaCompra.data_reabertura = new Date(state.ofertaCompra.data_reabertura);
      }
    }
  },
  [types.OFERTA_COMPRA_ELEGIVEIS_FECHADO](state, data) {
    if (data && Array.isArray(data)) {
      state.elegiveisFechado = data;
    } else if (data && typeof data === 'object') {
      const index = state.elegiveisFechado.findIndex(elemento => elemento.id === data.id);
      if (index > -1) {
        state.elegiveisFechado[index] = Object.assign(state.elegiveisFechado[index], data);
      }
    }
  },
  [types.OFERTA_COMPRA_PARTICIPANTES_FECHADO](state, data) {
    if (data && Array.isArray(data)) {
      state.participantesFechado = data;
    } else if (data && typeof data === 'object') {
      const index = state.participantesFechado.findIndex(elemento => elemento.id === data.id);
      if (index > -1) {
        state.participantesFechado[index] = Object.assign(state.participantesFechado[index], data);
      }
    }
  },
  [types.OFERTA_COMPRA_ADESOES](state, data) {
    if (data && Array.isArray(data)) {
      state.adesoes = data;
    } else if (data && typeof data === 'object') {
      const index = state.adesoes.findIndex(elemento => elemento.id === data.id);
      if (index > -1) {
        state.adesoes[index] = Object.assign(state.adesoes[index], data);
      } else {
        state.adesoes.push(data);
      }
    }
  },
  [types.OFERTA_COMPRA_ADICIONAR_ADESAO](state, data) {
    if (data && typeof data === 'object') {
      const index = state.adesoes.findIndex(
        elemento => elemento.itemId === data.itemId && elemento.fornecedorId === data.fornecedorId,
      );
      if (index > -1) {
        state.adesoes[index] = Object.assign(state.adesoes[index], data);
      } else {
        state.adesoes.push(data);
      }
    }
  },
  [types.OFERTA_COMPRA_STATUS](state, data) {
    if (data === 'PREFERENCIA') {
      state.ofertaCompra.status = data;
      state.ofertaCompra.situacao.id = 7;
      state.ofertaCompra.situacao.nome = 'Preferência';
    }
  },
  [types.OFERTA_COMPRA_LIMPAR](state) {
    state.ofertaCompra = {
      id: 0,
      comprador: {},
      tipo_negociacao: {},
      situacao: {},
      tipo_edital: '',
      tipo_pregao: '',
      modo_disputa: '',
      origem_recursos: [],
    };
  },
  [types.OFERTA_COMPRA_LIMPAR_FILTROS](state) {
    state.dataFilters = {
      buyerId: null,
      number: '',
      page: 1,
      perPage: state.dataFilters.perPage,
      period: {
        start: null,
        end: null,
      },
      situation: 'todas',
    };
  },
  [types.OFERTA_COMPRA_UPDATE](state, data) {
    if (data && typeof data === 'object') {
      state.ofertaCompra = Object.assign(state.ofertaCompra, data);
    }
  },
  [types.OFERTA_COMPRA_ITENS](state, { ofertaCompraId, itens }) {
    if (itens && Array.isArray(itens)) {
      const indice = state.ofertasCompra.findIndex(
        ofertaCompra => ofertaCompra.id === ofertaCompraId,
      );
      if (indice > -1) {
        const novoObjeto = state.ofertasCompra[indice];
        novoObjeto.itens = itens;
        Vue.set(state.ofertasCompra, indice, novoObjeto);
      } else {
        state.ofertasCompra.push({
          id: ofertaCompraId,
          itens,
        });
      }
    }
  },
  [types.OFERTA_COMPRA_ULTIMA_PROPOSTA_ITENS](state, { ofertaCompraId, itens }) {
    if (itens && Array.isArray(itens)) {
      const indice = state.ofertasCompra.findIndex(
        ofertaCompra => ofertaCompra.id === ofertaCompraId,
      );
      if (indice > -1) {
        const itensOC = state.ofertasCompra[indice].itens;
        itens.forEach(item => {
          const index = itensOC.findIndex(itemOC => itemOC.id === item.id);

          if (index > -1) {
            const novoObjeto = state.ofertasCompra[indice].itens[index];
            novoObjeto.ultima_proposta = item.createdAt;
            Vue.set(state.ofertasCompra[indice].itens, index, novoObjeto);
          }
        });
      }
    }
  },
  [types.OFERTAS_COMPRA_CLEAN](state) {
    state.ofertasCompra = [];
  },
};

const actions = {
  async buscarOfertasCompra({ commit, rootState }, filtros = {}) {
    const { company } = rootState.companies;
    const dataFilters = filtros;
    dataFilters.indexed = !!company.buyer;
    commit(types.OFERTAS_COMPRA_CARREGANDO, true);
    try {
      const { data: ofertasCompra } = await dadosOfertasCompra(filtros);
      commit(types.OFERTAS_COMPRA, ofertasCompra.data);
      commit(types.OFERTAS_COMPRA_TOTAL, ofertasCompra.count);
    } finally {
      commit(types.OFERTAS_COMPRA_CARREGANDO, false);
    }
  },
  async buscarOfertaCompra({ commit }, ofertaCompraId) {
    const response = await dadosOfertaCompra(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    commit(types.OFERTA_COMPRA, response.data.data);
    return commit(types.OFERTAS_COMPRA_CARREGANDO, false);
  },
  async editAuction({ state, commit }, dados) {
    const auctionId = state.ofertaCompra.id;
    const auction = await editAuction(auctionId, dados);
    if (auction.status !== 200) return Promise.reject(auction.data.data);
    return commit(types.OFERTA_COMPRA, auction.data.data);
  },
  async publishAuction({ state, commit }) {
    const auctionId = state.ofertaCompra.id;
    const auction = await publishAuction(auctionId);
    if (auction.status !== 200) return Promise.reject(auction.data.data);
    return commit(types.OFERTA_COMPRA, auction.data.data);
  },
  async openAuctionSession({ state, commit }) {
    const auctionId = state.ofertaCompra.id;
    const auction = await openAuctionSession(auctionId);
    if (auction.status !== 200) return Promise.reject(auction.data.data);
    return commit(types.OFERTA_COMPRA, auction.data.data);
  },
  async generateAuctionMinutes({ state }, data) {
    const auctionId = state.ofertaCompra.id;
    const ata = await generateAuctionMinutes(auctionId, data);
    if (ata.status !== 201) return Promise.reject(ata.data);
    return Promise.resolve(ata.data);
  },
  async buscarOfertaCompraElegiveisFechado({ commit, rootState }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const { data: elegiveisFechado } = await dadosElegiveisFechado(ofertaCompraId);
    commit(types.OFERTA_COMPRA_ELEGIVEIS_FECHADO, elegiveisFechado.data);
  },
  async buscarOfertaCompraParticipantesFechado({ commit, rootState }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const { data: participantesFechado } = await dadosParticipantesFechado(ofertaCompraId);
    commit(types.OFERTA_COMPRA_PARTICIPANTES_FECHADO, participantesFechado.data);
  },
  async buscarAdesoesPregao({ commit, rootState }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const { data: adesoes } = await buscarOfertaAdesaoEmpresas(ofertaCompraId);
    commit(types.OFERTA_COMPRA_ADESOES, adesoes.data);
  },
  async cancelAuction({ commit }, { auctionId, justification }) {
    const response = await cancelAuction(auctionId, { justification });
    if (response.status !== 200) return Promise.reject(response.data);
    commit(types.OFERTA_COMPRA, response.data);
    return commit(types.OFERTAS_COMPRA, response.data);
  },
  async revokeAuction({ commit }, { auctionId, justification }) {
    const response = await revokeAuction(auctionId, { justification });
    if (response.status !== 200) return Promise.reject(response.data);
    commit(types.OFERTA_COMPRA, response.data);
    return commit(types.OFERTAS_COMPRA, response.data);
  },
  async suspendAuction({ commit }, { auctionId, justification, reopenDate }) {
    const response = await suspendAuction(auctionId, { justification, reopenDate });
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.OFERTAS_COMPRA, response.data);
  },
  async updateReopeningDate({ commit }, { auctionId, reopeningDate }) {
    const response = await updateReopeningDate(auctionId, { reopeningDate });
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.OFERTAS_COMPRA, response.data);
  },
  async encerrarAnalisePropostas({ commit, rootState }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await encerrarAnalise(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    commit(types.OFERTA_COMPRA, response.data.oferta);
    return commit(typesItens.ITEM_UPDATE, response.data.itens);
  },
  async voltarParaAnaliseDePropostas({ commit, rootState }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await voltarParaAnaliseDePropostas(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    commit(types.OFERTA_COMPRA, response.data.oferta);
    return commit(typesItens.ITEM_UPDATE, response.data.itens);
  },
  async encerrarEtapaLances({ commit, rootState }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await encerrarLances(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    commit(types.OFERTA_COMPRA, response.data.oferta);
    return commit(typesItens.ITEM_UPDATE, response.data.itens);
  },
  async excluirOfertaCompra({ commit }, ofertaCompraId) {
    const { status, data } = await deletarOfertaCompra(ofertaCompraId);
    if (status !== 204) {
      throw new Error(data.message);
    }
    commit(types.OFERTAS_COMPRA_EXCLUIR, ofertaCompraId);
  },
  async reabrirOfertaCompra({ commit }, ofertaCompraId) {
    const response = await reabrirSessao(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.OFERTAS_COMPRA, response.data.data);
  },
  async encerrarPregao({ commit }, ofertaCompraId) {
    const response = await encerrarOferta(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.OFERTAS_COMPRA, response.data.data);
  },
  async novaOfertaCompra({ commit }) {
    const ofertaCompra = await criarOfertaCompra();
    commit(types.OFERTA_COMPRA, ofertaCompra.data.data);
    return new Promise((resolve, reject) => {
      try {
        resolve(ofertaCompra.data.data.id);
      } catch (error) {
        reject(error);
      }
    });
  },
  validarOfertaCompra({ state }) {
    if (!state.ofertaCompra) return false;
    if (!state.ofertaCompra.comprador.cnpj) return false;
    if (!state.ofertaCompra.comprador.endereco) return false;
    if (!state.ofertaCompra.comprador.numero) return false;
    if (!state.ofertaCompra.comprador.bairro) return false;
    if (!state.ofertaCompra.comprador.municipio) return false;
    if (!state.ofertaCompra.comprador.uf) return false;
    if (!state.ofertaCompra.comprador.cep) return false;
    if (!state.ofertaCompra.comprador.nome) return false;
    if (!state.ofertaCompra.comprador.telefone) return false;
    if (!state.ofertaCompra.data_abertura) return false;
    if (!state.ofertaCompra.data_publicacao) return false;
    if (!state.ofertaCompra.endereco_entrega) return false;
    if (!state.ofertaCompra.endereco_entrega_numero) return false;
    if (!state.ofertaCompra.endereco_entrega_bairro) return false;
    if (!state.ofertaCompra.endereco_entrega_cep) return false;
    if (!state.ofertaCompra.endereco_entrega_municipio) return false;
    if (!state.ofertaCompra.endereco_entrega_uf) return false;
    if (!state.ofertaCompra.id) return false;
    if (!state.ofertaCompra.numero_oc) return false;
    if (!state.ofertaCompra.numero_processo) return false;
    if (!state.ofertaCompra.situacao || state.ofertaCompra.situacao.id < 1) {
      return false;
    }
    if (!state.ofertaCompra.estado) return false;
    if (!state.ofertaCompra.tipo_pregao) return false;
    if (!state.ofertaCompra.modo_disputa) return false;
    if (!state.ofertaCompra.tipo_edital) return false;
    if (!state.ofertaCompra.objeto) return false;
    if (!state.ofertaCompra.tipo_negociacao || state.ofertaCompra.tipo_negociacao.id < 1) {
      return false;
    }
    if (!state.ofertaCompra.origem_recursos.length) return false;
    if (
      state.ofertaCompra.ano_instrumento &&
      (state.ofertaCompra.ano_instrumento < 1900 || state.ofertaCompra.ano_instrumento > 2099)
    ) {
      return false;
    }
    return true;
  },
  setOfertaCompraStatus({ commit }, data) {
    commit(types.OFERTA_COMPRA_STATUS, data);
  },
  limparPregao({ commit }) {
    commit(types.OFERTA_COMPRA_LIMPAR);
  },
  atualizarOfertaCompra({ commit }, data) {
    commit(types.OFERTA_COMPRA_UPDATE, data);
  },
  atualizarElegiveisFechado({ commit }, data) {
    commit(types.OFERTA_COMPRA_ELEGIVEIS_FECHADO, data);
  },
  atualizarParticipantesFechado({ commit }, data) {
    commit(types.OFERTA_COMPRA_PARTICIPANTES_FECHADO, data);
  },
  adicionarAdesaoPregao({ commit }, data) {
    commit(types.OFERTA_COMPRA_ADICIONAR_ADESAO, data);
  },
  alternarEmPublicacaoPregao({ commit }, valor) {
    commit(types.OFERTA_COMPRA_EM_PUBLICACAO, valor);
  },
  async buscarOfertaCompraItens({ commit }, ofertaCompraId) {
    const itens = await dadosItens(ofertaCompraId);
    commit(types.OFERTA_COMPRA_ITENS, {
      ofertaCompraId,
      itens: itens.data.data,
    });
  },
  async fetchItemsLastProposal({ commit }, ofertaCompraId) {
    const { data: itens } = await fetchItemsLastProposal(ofertaCompraId);
    commit(types.OFERTA_COMPRA_ULTIMA_PROPOSTA_ITENS, {
      ofertaCompraId,
      itens: itens.data,
    });
  },
  async exportAccountIntegration(_, ofertaCompraId) {
    const response = await exportAccountIntegration(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    const blob = new Blob([JSON.stringify(response.data, null, 2)], {
      type: 'application/json',
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    const now = new Date();
    const timestamp = now
      .toISOString()
      .replace(/[-:]/g, '')
      .replace('T', '-')
      .slice(0, -5);
    link.download = `integracao-contabilidade-${timestamp}.json`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    return response;
  },
  async buscarStatsPregao({ commit }) {
    const response = await dadosStats();
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.OFERTAS_COMPRA_STATS, response.data);
  },
  filtrarPregoes({ dispatch, commit, state }, filters) {
    const dataFilters = filters ? { ...filters } : state.dataFilters;
    commit(types.OFERTAS_COMPRA_FILTRAR, dataFilters);
    dispatch('buscarOfertasCompra', state.dataFilters);
  },
  alterarPorPaginaPregoes({ dispatch, commit, state }, perPage) {
    commit(types.OFERTAS_COMPRA_ALTERAR_POR_PAGINA, perPage);
    dispatch('buscarOfertasCompra', state.dataFilters);
  },
  limparFiltrosPregoes({ commit }) {
    commit(types.OFERTA_COMPRA_LIMPAR_FILTROS);
  },
  cleanAuctions({ commit }) {
    commit(types.OFERTAS_COMPRA_CLEAN);
  },
};

const getters = {
  obterOfertaCompra(state) {
    return state.ofertaCompra;
  },
  obterElegiveisFechado(state) {
    return state.elegiveisFechado;
  },
  obterParticipantesFechado(state) {
    return state.participantesFechado;
  },
  obterOfertasCompra(state) {
    return state.ofertasCompra;
  },
  obterCarregandoOfertasCompra(state) {
    return state.carregandoOfertasCompra;
  },
  obterEmPublicacaoPregao(state) {
    return state.emPublicacao;
  },
  obterTotalOfertasCompra(state) {
    return state.total;
  },
  obterStatsPregoes(state) {
    return state.stats;
  },
  obterDadosFiltros(state) {
    return state.dataFilters;
  },
  obterAdesoesPregao(state) {
    return state.adesoes;
  },
};

const state = {
  ofertaCompra: {
    id: 0,
    comprador: {},
    tipo_negociacao: {},
    situacao: {},
    tipo_edital: '',
    tipo_pregao: '',
    modo_disputa: '',
    origem_recursos: [],
  },
  elegiveisFechado: [],
  participantesFechado: [],
  adesoes: [],
  ofertasCompra: [],
  carregandoOfertasCompra: false,
  emPublicacao: false,
  total: 0,
  dataFilters: {
    buyerId: null,
    number: '',
    page: 1,
    perPage: 20,
    period: {
      start: null,
      end: null,
    },
    situation: 'todas',
  },
  stats: {
    inProgress: 0,
    suspended: 0,
    missingValidations: 0,
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
