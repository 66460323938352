<template>
  <div>
    <b-loading is-full-page :active="loading.documents" can-cancel></b-loading>

    <section>
      <div class="columns">
        <div class="column has-text-right">
          <div v-if="crier || authority">
            <button
              v-if="displayStartBids"
              class="button field is-primary actions"
              :class="{ ' is-loading': loading.general }"
              :disabled="!selectedItems.length || bidItems"
              @click="startBids"
            >
              <span>Iniciar Lances</span>
            </button>

            <button
              v-if="verifySituation(7)"
              class="button field is-primary actions"
              :class="{ ' is-loading': loading.general }"
              :disabled="!selectedItems.length || bidItems"
              @click="startPreference"
            >
              <span>Iniciar Preferência</span>
            </button>

            <button
              v-if="verifySituation(8)"
              class="button field is-primary actions"
              :class="{ ' is-loading': loading.general }"
              :disabled="!selectedItems.length"
              @click="
                openModal(
                  selectedItems.map(item => item.id),
                  'negotiation',
                )
              "
            >
              <span>Negociar Todos</span>
            </button>

            <button
              v-if="displayAdjudicateButton"
              class="button field is-primary actions"
              :class="{ 'is-loading': loading.adjudicarItens }"
              :disabled="!selectedItems.length"
              @click="adjudicarFornecedoresItens"
            >
              <span>Adjudicar</span>
            </button>

            <button
              v-if="displayHomologateButton"
              class="button field is-primary actions"
              :class="{ 'is-loading': loading.homologarItens }"
              :disabled="!selectedItems.length"
              @click="homologarFornecedoresItens"
            >
              <span>Homologar</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div v-if="!items.length" class="panel">
      <div class="panel-block is-justify-content-center">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhum item.</p>
          </div>
        </section>
      </div>
    </div>

    <b-table
      hoverable
      scrollable
      class="table is-fullwidth auction-items-table"
      v-if="items.length"
      :checked-rows.sync="selectedItems"
      :checkable="displayCheckable"
      :is-row-checkable="checkableItems"
      :data="itemsToShow"
    >
      <b-table-column centered label="Exclusivo" width="60" v-slot="props" :visible="exclusiveItem">
        <b-icon
          custom-size="mdi-24px"
          icon="check"
          type="is-success"
          v-show="props.row.item.exclusivo"
        />
      </b-table-column>

      <b-table-column centered label="ID" width="40" field="identificador" v-slot="props">{{
        props.row.identificador
      }}</b-table-column>

      <b-table-column
        cell-class="elipsis-size"
        label="Descrição"
        v-slot="props"
        :key="descriptionKey"
      >
        <div
          class="elipsis-column"
          :class="{
            'cursor-pointer': displayEyeIcon(props.row.id),
            'justify-align': justify,
          }"
          :ref="`description-container-${props.row.id}`"
          @click.stop.prevent="openDescription(props.row.id)"
        >
          <b-icon
            class="custom-eye-icon mr-1"
            icon="eye-outline"
            v-if="displayEyeIcon(props.row.id)"
          ></b-icon>

          <a
            v-if="displayEyeIcon(props.row.id)"
            :class="{ 'justify-align': justify }"
            :ref="`description-link-${props.row.id}`"
            >{{ props.row.item.descricao }}</a
          >
          <p v-else>{{ props.row.item.descricao }}</p>
        </div>
      </b-table-column>

      <b-table-column centered label="Unidade" v-slot="props" :visible="auctionSituation < 6"
        ><div class="unity-column">{{ props.row.unidade.nome }}</div></b-table-column
      >

      <b-table-column centered label="Quantidade" v-slot="props" :visible="auctionSituation < 6">{{
        props.row.quantidade | formatarNumero
      }}</b-table-column>

      <b-table-column centered label="Referência" v-slot="props" :visible="displayReferralColumn"
        ><div v-if="!props.row.referencia && !props.row.total">
          <b-tag type="is-dark" size="is-small">VIDE EDITAL</b-tag>
        </div>
        <div v-else>{{ formatReference(props.row) | formatarValor }}</div></b-table-column
      >

      <b-table-column centered label="Total" v-slot="props" :visible="displayTotalReferential">
        <div v-if="!props.row.referencia && !props.row.total">
          <b-tag type="is-dark" size="is-small">VIDE EDITAL</b-tag>
        </div>
        <div v-else>{{ displayTotalReferential ? formatarValor(props.row.total) : 0 }}</div>
      </b-table-column>

      <b-table-column centered label="Redução" v-slot="props" :visible="displayReductionColumn"
        ><b-tooltip
          v-if="biggestDiscount && props.row.referencia && props.row.total"
          size="is-medium"
          :label="formatarValor(props.row.reducaoLances)"
          >{{ formatPercentageReduction(props.row) }}</b-tooltip
        >

        <div v-else-if="biggestDiscount">
          {{ formatPercentageReduction(props.row) }}
        </div>

        <div v-else>
          {{ props.row.reducaoLances | formatarValor }}
        </div></b-table-column
      >

      <b-table-column
        centered
        class="lower-bid-column"
        v-slot="props"
        :visible="displayMinorBid"
        :label="biggestDiscount ? 'Maior Desconto' : 'Menor Lance'"
      >
        <div class="has-text-centered" :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <b-tooltip
            v-if="biggestDiscount && props.row.referencia && props.row.total"
            size="is-medium"
            class="discount-tooltip"
            :type="lowerBidColor(props.row)"
            :label="
              props.row.menor_lance.percentual !== null
                ? formatMinorBid(props.row)
                : 'Nenhum Fornecedor'
            "
          >
            <span
              v-if="displayMinorBidTag"
              class="tag tag-extended"
              :class="lowerBidColor(props.row)"
              >{{ formatDiscountPercentage(props.row) }}</span
            >
          </b-tooltip>

          <div v-else>
            <span
              v-if="displayMinorBidTag"
              class="tag tag-extended"
              :class="lowerBidColor(props.row)"
              >{{
                biggestDiscount
                  ? formatDiscountPercentage(props.row)
                  : formatarValor(minorBidExists(props.row))
              }}</span
            >
          </div>

          <b-tooltip
            v-if="biggestDiscount && props.row.referencia && props.row.total"
            size="is-medium"
            type="is-light"
            class="discount-tooltip"
            :label="
              props.row.preferencia.percentual !== null
                ? formatPreferenceBid(props.row)
                : 'Nenhum Fornecedor'
            "
          >
            <span
              v-if="displayPreference(props.row.preferencia.fornecedor)"
              class="tag small tag-extended"
              >{{ formatPreferenceDiscount(props.row) }}</span
            >
          </b-tooltip>

          <div v-else>
            <span
              v-if="displayPreference(props.row.preferencia.fornecedor)"
              class="tag small tag-extended"
              >{{
                biggestDiscount
                  ? formatPreferenceDiscount(props.row)
                  : formatarValor(preferenceValueExists(props.row))
              }}</span
            >
          </div>
        </div>
      </b-table-column>

      <b-table-column centered label="Fornecedor" v-slot="props" :visible="displayMinorBid">
        <div :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <span
            v-if="displayProviderTag"
            class="tag tag-extended"
            :class="lowerBidColor(props.row)"
            >{{ props.row.menor_lance.fornecedor | formatarPadrao('Nenhum Fornecedor') }}</span
          >

          <span v-if="displayCompany" class="tag tag-extended" :class="lowerBidColor(props.row)">{{
            props.row.menor_lance.razao_social | formatarPadrao('Nenhum Fornecedor')
          }}</span>

          <span
            class="tag tag-extended"
            v-if="displayPreference(props.row.preferencia.fornecedor)"
            >{{ preferenceBidExists(props.row) }}</span
          >
        </div>
      </b-table-column>

      <b-table-column centered label="Porte" v-slot="props" :visible="displaySizeColumn">
        <div class="has-text-centered" :class="{ 'is-flex': mobile, 'justify-end': mobile }">
          <div class="company-size-text mr-1">{{ formatWinnerCompanySize(props.row) }}</div>

          <div class="tag small" v-if="displayPreference(props.row.preferencia.fornecedor)">
            {{ preferenceSizeExists(props.row) }}
          </div>
        </div>
      </b-table-column>

      <b-table-column
        centered
        label="Propostas"
        width="60"
        v-slot="props"
        :visible="[3, 4].indexOf(auctionSituation) > -1 && company.id === auction.comprador.id"
      >
        <div v-if="['REVOGADO', 'DESERTO'].indexOf(props.row.estado) === -1">
          {{ totalProposals(props.row.id) ? totalProposals(props.row.id) : 0 }}
        </div>
      </b-table-column>

      <b-table-column centered label="Estado" v-slot="props" :visible="displayStatusColumn">
        <span
          class="tag is-primary tag-extended"
          :class="props.row.estado | estadoItem(auction.modo_disputa)"
          >{{ props.row.estado | tituloEstadoItem(auction.modo_disputa) }}</span
        >
      </b-table-column>

      <b-table-column
        centered
        label="Adesões"
        v-slot="props"
        width="60"
        :visible="auctionSituation === 11 && company.id === auction.comprador.id"
      >
        {{ buyer && auctionSituation === 11 ? totalAdhesions(props.row.id) : 0 }}
      </b-table-column>

      <b-table-column
        centered
        class="actions-column"
        label="Ações"
        v-slot="props"
        :visible="displayActions"
      >
        <div class="is-flex justify-center has-text-centered" :class="{ 'justify-end': mobile }">
          <button
            class="button is-primary mb-1"
            :disabled="disable.accept"
            v-if="displayAcceptRefuse(props.row.estado)"
            @click="openModal(props.row.id, 'accept', props.row.menor_lance.fornecedor)"
          >
            Aceitar
          </button>

          <button
            class="button is-danger ml-1"
            :disabled="disable.refuse"
            v-if="displayAcceptRefuse(props.row.estado)"
            @click="openModal(props.row.id, 'refuse', props.row.menor_lance.fornecedor)"
          >
            Recusar
          </button>

          <button
            class="button is-primary ml-1 mb-1"
            v-if="displaySendDocuments(props.row.id, props.row.estado)"
            @click="sendDocuments(props.row)"
          >
            Enviar Documentos
          </button>

          <SelecionarEmpresaAdesao
            v-if="adheredPriceItem(props.row.id) && crier && props.row.estado === 'HABILITACAO'"
            :item="props.row"
            @selecionar-empresa="company => selectCompany(props.row, company)"
          />

          <button
            class="button is-primary is-outlined documents-button"
            v-if="displayViewDocuments(props.row)"
            @click.prevent.stop="viewCompanyDocuments(props.row)"
          >
            Ver Documentos
          </button>

          <button
            v-if="
              displayAdherenceButton(
                props.row.id,
                props.row.menor_lance.fornecedor_id,
                props.row.estado,
              )
            "
            class="button is-primary actions"
            :disabled="disable.adhere"
            @click.stop.prevent="
              mountAdhesionConfirmation(
                props.row.id,
                props.row.identificador,
                props.row.item.descricao,
                props.row.unidade.nome,
                true,
              )
            "
          >
            Aderir
          </button>

          <button
            v-if="
              displayAdherenceButton(
                props.row.id,
                props.row.menor_lance.fornecedor_id,
                props.row.estado,
              )
            "
            class="button is-danger actions"
            :disabled="disable.adhere"
            @click.stop.prevent="
              mountAdhesionConfirmation(
                props.row.id,
                props.row.identificador,
                props.row.item.descricao,
                props.row.unidade.nome,
                false,
              )
            "
          >
            Não Aderir
          </button>

          <div
            v-if="adheredPriceItem(props.row.id) && auctionSituation === 11 && bidder"
            class="tag is-primary tag-extended"
          >
            <h3 style="font-weight: bold">Aderido</h3>
          </div>

          <div
            v-if="abdicateAdhesionItem(props.row.id) && auctionSituation === 11 && bidder"
            class="tag is-danger tag-extended"
          >
            <h3 style="font-weight: bold">Não Aderido</h3>
          </div>

          <button
            class="button is-danger actions"
            v-if="displayRevokeItem(props.row.estado)"
            :disabled="disable.revokeItem"
            @click.prevent.stop="openModal(props.row.id, 'revoke-item')"
          >
            Revogar
          </button>
        </div>
      </b-table-column>

      <b-table-column
        class="options-column"
        centered
        label="Opções"
        v-slot="props"
        :visible="displayOptions"
      >
        <button
          class="button is-primary is-small is-outlined offers-button"
          v-if="displayOffersList(props.row)"
          @click.prevent.stop="viewOffers(props.row)"
        >
          Ofertas
        </button>
      </b-table-column>

      <template v-slot:footer>
        <div class="columns">
          <div class="column has-text-left">
            <button
              v-if="displayMyItemsButton"
              class="button is-primary my-items-button"
              style="height: 100%"
              @click.stop.prevent="viewMyItems()"
            >
              Estou Participando
            </button>
          </div>
          <AppPagination
            class="column is-narrow"
            :has-margin="false"
            :data-filters="{
              page: pagination.page,
              perPage: pagination.perPage,
            }"
            :max-lines="50"
            :total="items.length"
            @change-page="page => changePage({ page })"
            @change-per-page="perPage => changePerPage(perPage)"
          />
        </div>
      </template>
    </b-table>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justification"
      :onCancel="resetStateButtons"
      @justificate="sendJustification"
    >
      <AppJustification
        v-if="justification"
        :displayFailButton="displayFailButton"
        :displaySuccessButton="displaySuccessButton"
        :failText="failText"
        :successText="successText"
      />
    </b-modal>

    <b-modal
      has-modal-card
      :active.sync="displayConfirmation"
      :onCancel="resetStateButtons"
      @confirm="priceAdhesion"
    >
      <AppActionConfirmation
        v-if="displayConfirmation"
        :confirm-type="adhesionConfirmation.confirmType"
        :display="adhesionConfirmation.display"
        :iconType="adhesionConfirmation.iconType"
        :items="adhesionConfirmation.items"
        :message="adhesionConfirmation.message"
        :mobile="mobile"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { formatarValor, formatarPorcentagem } from '@/utils/format';

import auctionItems from '@/mixins/auctionItems';

import AppPagination from '@/components/AppPagination';
import AppJustification from '@/components/AppJustification';
import AppActionConfirmation from '@/components/AppActionConfirmation';
import SelecionarEmpresaAdesao from '@/views/Pregao/AuctionContainer/SelecionarEmpresaAdesao';

export default {
  name: 'AllItems',
  props: ['closedBid', 'closedEligibleBidder', 'myItemsFilter', 'loaded'],
  components: {
    AppPagination,
    AppActionConfirmation,
    AppJustification,
    SelecionarEmpresaAdesao,
  },
  mixins: [auctionItems],
  data() {
    const disable = {
      abdicateAdherence: false,
      accept: false,
      adhere: false,
      adjudicate: false,
      disqualify: false,
      homologate: false,
      qualify: false,
      refuse: false,
      revokeItem: false,
    };
    const pagination = {
      page: 1,
      perPage: 20,
    };

    return {
      action: '',
      adhesionData: {},
      adhesionConfirmation: {},
      bidItems: true,
      disable,
      displayConfirmation: false,
      displayFailButton: false,
      displaySuccessButton: false,
      failText: '',
      formatarValor,
      formatarPorcentagem,
      justification: false,
      itemId: null,
      rowAlias: null,
      selectedItems: [],
      successText: '',
      descriptionKey: 0,
      pagination,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('proposals', ['proposals']),
    ...mapState('auctionsQualifications', ['qualifications']),
    ...mapState('auctionsBidders', ['userLoggedBidder']),
    ...mapGetters({
      adhesions: 'obterAdesoesPregao',
      appeals: 'obterOfertaCompraRecursos',
      bids: 'obterOfertaCompraLances',
      items: 'obterTodosItens',
    }),
    itemsToShow() {
      return this.items.slice(
        (this.pagination.page - 1) * this.pagination.perPage,
        this.pagination.page * this.pagination.perPage,
      );
    },
    adheredBid() {
      return this.bids.some(lance => lance.adesao === 'ADERIDO');
    },
    closedFirstCall() {
      return this.items.some(
        item => ['PRIMEIRA-CHAMADA', 'LANCE-ENCERRADA'].indexOf(item.estado) > -1,
      );
    },
    closedNewCall() {
      return this.items.every(
        item =>
          [
            'NOVA-CHAMADA',
            'LANCE-ENCERRADA',
            'NEGOCIACAO-ENCERRADA',
            'HABILITACAO-ENCERRADA',
            'FRACASSADA',
            'DESERTO',
            'REVOGADO',
          ].indexOf(item.estado) > -1,
      );
    },
    displayActions() {
      if ([2, 3, 4].indexOf(this.auctionSituation) > -1 && this.authority) {
        return this.items.some(
          item => ['REVOGADO', 'FRACASSADA', 'DESERTO', 'HOMOLOGADO'].indexOf(item.estado) === -1,
        );
      }

      if (
        ((this.auctionSituation === 8 ||
          this.auctionSituation === 9 ||
          this.suspendedQualification) &&
          this.authorizedBuyer &&
          !this.adherenceStep) ||
        ((this.auctionSituation === 9 || this.suspendedQualification) &&
          this.authorizedBuyer &&
          this.adherenceStep &&
          this.qualifyingItem) ||
        (this.auctionSituation === 9 && this.bidder && this.adheredBid)
      ) {
        return true;
      }

      if (this.auctionSituation === 11 && this.bidder && this.bids.length) {
        const itemsForAdherence = [];
        this.items.forEach(item => {
          this.bids.forEach(lance => {
            if (
              item.id === lance.ofertaCompraItemId &&
              ['PODEADERIR', 'ADERIDO', 'NAOADERIDO'].indexOf(lance.adesao) > -1 &&
              item.menor_lance.fornecedor_id !== this.userLoggedBidder.id &&
              item.estado === 'ADESAO'
            ) {
              itemsForAdherence.push(lance);
            }
          });
        });

        if (itemsForAdherence.length) return true;
      }
      return false;
    },
    displayCheckable() {
      return (
        this.displaySessaoAberta || this.displayAdjudicateButton || this.displayHomologateButton
      );
    },
    displayAdjudicateButton() {
      if ([15, 16].indexOf(this.auctionSituation) === -1) return false;
      if (!this.crier && this.authority && this.appeals.length === 0) return false;
      const itensAdjudicar = this.items.filter(item => {
        return item.estado === 'HABILITACAO-ENCERRADA';
      });
      return itensAdjudicar.length > 0;
    },
    displayHomologateButton() {
      if (!this.authority) return false;
      if ([15, 16].indexOf(this.auctionSituation) === -1) return false;
      const itensHomologar = this.items.filter(item => {
        return item.estado === 'ADJUDICADO';
      });
      return itensHomologar.length > 0;
    },
    displaySessaoAberta() {
      return [5, 6, 7, 8].indexOf(this.auctionSituation) > -1 && this.crier;
    },
    displayMinorBid() {
      return [6, 7, 8, 9, 10, 11, 15, 16, 17].indexOf(this.auctionSituation) > -1;
    },
    displayMinorBidTag() {
      return [6, 7, 8, 9, 10, 11, 15, 16, 17].indexOf(this.auctionSituation) > -1;
    },
    displayMyItemsButton() {
      if (
        this.auctionSituation === 6 &&
        this.bidder &&
        this.myItemsFilter &&
        this.closedBid &&
        this.closedEligibleBidder &&
        !this.adherenceStep
      ) {
        return true;
      }

      return (
        [6, 7, 8, 9].indexOf(this.auctionSituation) > -1 &&
        this.bidder &&
        this.myItemsFilter &&
        !this.adherenceStep &&
        !this.closedBid
      );
    },
    displayOptions() {
      return (
        (this.auctionSituation > 4 && this.items.some(item => item.existe_proposta)) ||
        this.items.some(item => item.menor_lance.valor)
      );
    },
    displayProviderTag() {
      return (
        [6, 7, 8].indexOf(this.auctionSituation) > -1 ||
        (this.auctionSituation === 17 && !this.enablingDeadline)
      );
    },
    displayStartBids() {
      return this.verifySituation(6) && this.crier;
    },
    displayTotalReferential() {
      return (
        this.authorizedBuyer &&
        [2, 3, 4, 5].indexOf(this.auctionSituation) > -1 &&
        this.negotiationType === 1
      );
    },
    itemsForBids() {
      return this.selectedItems.map(item => item.id);
    },
    qualifyingItem() {
      return this.items.some(item => item.estado === 'HABILITACAO');
    },
    suspendedQualification() {
      return (
        this.auctionSituation === 17 &&
        this.deadlines &&
        this.deadlines[this.deadlines.length - 2]?.prazo_id === 11
      );
    },
  },
  watch: {
    auctionSituation() {
      if (this.auctionSituation === 7) this.selectedItems = [];
    },
    items(items) {
      if (Array.isArray(items)) {
        items.forEach(item => {
          if (item.referencia !== null) formatarValor(item.referencia);
        });
      } else if (items.referencia !== null) {
        formatarValor(items.referencia);
      }
    },
    loaded() {
      if (this.loaded) {
        this.verifyOverflowed();
      }
    },
  },
  sockets: {
    'ofertacompra:adesoes': function atualizarOfertaCompraAdesoes(adesao) {
      if (this.buyer) this.adicionarAdesaoPregao(adesao);
    },
  },
  methods: {
    ...mapActions([
      'permitirLances',
      'permitirPreferencia',
      'aceitarLanceNegociacao',
      'recusarLanceNegociacao',
      'negotiationItemsSelected',
      'removerItem',
      'revogarItem',
      'adjudicarVencedorItens',
      'homologarVencedorItens',
      'inabilitarLicitante',
      'adicionarAdesaoPregao',
    ]),
    changePage({ page }) {
      this.pagination.page = page;
    },
    changePerPage(perPage) {
      this.pagination.page =
        Number(perPage) > Number(this.pagination.perPage) ? 1 : this.pagination.page;
      this.pagination.perPage = perPage;
    },
    abdicateAdhesionItem(itemId) {
      return this.bids.some(
        lance => lance.ofertaCompraItemId === itemId && lance.adesao === 'NAOADERIDO',
      );
    },
    async acceptOffer({ itemId, justificativa }) {
      const aceita = await this.aceitarLanceNegociacao({ itemId, justificativa });
      if (aceita) {
        this.$alerta('Proposta aceita', 'is-success');
      } else {
        this.$alerta('Erro ao aceitar proposta', 'is-danger');
      }
      this.disable.accept = false;
      this.disable.refuse = false;
      this.sendCloseModal();
    },
    async negotiationItems({ items, accept, justification }) {
      const success = await this.negotiationItemsSelected({ items, accept, justification });
      if (success) {
        const message = accept ? 'Propostas aceitas' : 'Propostas recusadas';
        const typeMessage = accept ? 'is-success' : 'is-danger';
        this.$alerta(message, typeMessage);
      } else {
        const message = accept ? 'Erro ao aceitar as propostas' : 'Erro ao recusar as propostas';
        this.$alerta(message, 'is-danger');
      }
      this.disable.accept = false;
      this.disable.refuse = false;
      this.selectedItems = [];
      this.sendCloseModal();
    },
    adheredPriceItem(itemId) {
      return this.bids.some(
        lance => lance.ofertaCompraItemId === itemId && lance.adesao === 'ADERIDO',
      );
    },
    async adjudicateItens(data) {
      try {
        await this.adjudicarVencedorItens(data);
        this.$alerta('Os vencedores foram adjudicados', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao adjudicar os vencedores', 'is-danger');
      }
      this.selectedItems = [];
      this.disable.adjudicate = false;
      this.sendCloseModal();
    },
    async homologateItens(data) {
      try {
        await this.homologarVencedorItens(data);
        this.$alerta('Os vencedores foram homologado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao homologar os vencedores', 'is-danger');
      }
      this.selectedItems = [];
      this.disable.homologate = false;
      this.sendCloseModal();
    },
    canAdhereItem(itemId) {
      return this.bids.some(
        lance => lance.ofertaCompraItemId === itemId && lance.adesao === 'PODEADERIR',
      );
    },
    checkableItems(item) {
      if (item.estado === 'LANCE-ENCERRADA' && this.auction.modo_disputa === 'FECHADO') {
        return false;
      }

      if (
        this.auctionSituation === 6 &&
        !this.bidStarted &&
        ['CADASTRADA', 'LANCE-ENCERRADA'].indexOf(item.estado) > -1
      ) {
        return true;
      }

      if (
        this.auctionSituation === 6 &&
        !this.bidStarted &&
        ((item.estado === 'PRIMEIRA-CHAMADA' && this.closedFirstCall) ||
          (item.estado === 'NOVA-CHAMADA' && this.closedNewCall))
      ) {
        return true;
      }

      if (this.auctionSituation === 7 && item.estado === 'PREFERENCIA' && !this.preferenceStarted) {
        return true;
      }

      if (this.auctionSituation === 8 && item.estado === 'NEGOCIACAO') {
        return true;
      }

      if ([15, 16].indexOf(this.auctionSituation) > -1 && item.estado === 'HABILITACAO-ENCERRADA') {
        return true;
      }

      if ([15, 16].indexOf(this.auctionSituation) > -1 && item.estado === 'ADJUDICADO') {
        return !this.items.some(it => it.estado === 'HABILITACAO-ENCERRADA');
      }

      return false;
    },
    async sendJustification({ justification, state }) {
      if (this.action === 'adjudicate') {
        await this.adjudicateItens({
          itens: this.selectedItems.map(item => item.id),
          justificativa: justification || 'Item adjudicado.',
        });
      } else if (this.action === 'homologate') {
        await this.homologateItens({
          itens: this.selectedItems.map(item => item.id),
          justificativa: justification || 'Item homologado.',
        });
      } else if (this.itemId) {
        if (this.action === 'accept') {
          await this.acceptOffer({
            itemId: this.itemId,
            justificativa: justification || 'Preço aceito.',
          });
        } else if (this.action === 'refuse') {
          await this.refuseOffer({
            itemId: this.itemId,
            justificativa: justification,
          });
        } else if (this.action === 'negotiation') {
          await this.negotiationItems({
            items: this.itemId,
            accept: state === 'success',
            justification: justification || 'Preço aceito.',
          });
        } else if (this.action === 'disqualify') {
          await this.disqualifyBidder({
            itemId: this.itemId,
            fornecedor: this.rowAlias,
            justificativa: justification,
          });
        } else if (this.action === 'revoke-item') {
          await this.revokeAuctionItem({
            ofertaCompraItemId: this.itemId,
            justificativa: justification,
          });
        }
        this.itemId = null;
      }
      this.action = '';
    },
    adjudicarFornecedoresItens() {
      this.openModal(0, 'adjudicate');
    },
    homologarFornecedoresItens() {
      this.openModal(0, 'homologate');
    },
    displayAcceptRefuse(itemState) {
      return itemState === 'NEGOCIACAO' && this.auctionSituation === 8;
    },
    displayAdherenceButton(itemId, bidderId, itemState) {
      const disabledItem = this.qualifications.some(
        habilitacao => habilitacao.item_id === itemId && habilitacao.status === 'INABILITADA',
      );

      return (
        this.auctionSituation === 11 &&
        this.bidder &&
        bidderId !== this.userLoggedBidder.id &&
        this.itemParticipant(itemId) &&
        !disabledItem &&
        this.canAdhereItem(itemId) &&
        itemState === 'ADESAO'
      );
    },
    displayRevokeItem(itemState) {
      return (
        this.authority &&
        [2, 3, 4, 15, 16, 17].indexOf(this.auctionSituation) > -1 &&
        ['REVOGADO', 'DESERTO', 'FRACASSADA'].indexOf(itemState) === -1
      );
    },
    displaySendDocuments(itemId, itemState) {
      return (
        this.adheredPriceItem(itemId) &&
        this.bidder &&
        this.auctionSituation === 9 &&
        itemState === 'HABILITACAO'
      );
    },
    displayViewDocuments(item) {
      if (
        ['FRACASSADA', 'DESERTO', 'REVOGADO', 'NEGOCIACAO', 'NOVA-CHAMADA'].indexOf(item.estado) >
        -1
      ) {
        return false;
      }

      return (
        (this.auctionSituation === 9 || this.suspendedQualification) &&
        this.authorizedBuyer &&
        !this.adherenceStep
      );
    },
    async disqualifyBidder(data) {
      try {
        await this.inabilitarLicitante(data);
        this.$alerta('Licitante inabilitado', 'is-danger');
      } catch (error) {
        this.$alerta('Erro ao inabilitar licitante', 'is-danger');
      }
      this.disable.disqualify = false;
      this.sendCloseModal();
    },
    itemParticipant(itemId) {
      return this.bids.some(lance => lance.ofertaCompraItemId === itemId);
    },
    mountAdhesionConfirmation(itemId, identifier, itemDescription, itemUnity, adhesion) {
      this.adhesionConfirmation.display = {
        identifier: true,
        description: true,
        unity: true,
        brand: false,
        imported: false,
        percent: false,
        value: false,
      };

      this.adhesionConfirmation.message = `Você ${
        adhesion ? 'está' : 'não está'
      } aderindo ao menor preço do Item:`;
      this.adhesionConfirmation.iconType = adhesion ? 'is-primary' : 'is-danger';
      this.adhesionConfirmation.confirmType = adhesion ? 'is-primary' : 'is-danger';

      this.adhesionConfirmation.items = [];
      this.adhesionConfirmation.items.push({
        identifier,
        description: itemDescription,
        unity: itemUnity,
        percent: null,
      });

      this.adhesionData = { itemId, identifier, adhesion };
      this.displayConfirmation = true;
    },
    openModal(itemId, action, fornecedor = '') {
      if (action === 'adjudicate') {
        this.disable.adjudicate = true;
        this.successText = 'Adjudicar';
        this.displaySuccessButton = true;
        this.displayFailButton = false;
      } else if (action === 'homologate') {
        this.disable.homologate = true;
        this.successText = 'Homologar';
        this.displaySuccessButton = true;
        this.displayFailButton = false;
      } else if (action === 'disqualify') {
        this.disable.disqualify = true;
        this.failText = 'Inabilitar';
        this.displaySuccessButton = false;
        this.displayFailButton = true;
      } else if (action === 'accept') {
        this.disable.accept = true;
        this.disable.refuse = true;
        this.displaySuccessButton = true;
        this.displayFailButton = false;
        this.successText = 'Aceitar';
      } else if (action === 'refuse') {
        this.disable.refuse = true;
        this.disable.accept = true;
        this.displayFailButton = true;
        this.failText = 'Recusar';
        this.displaySuccessButton = false;
      } else if (action === 'negotiation') {
        this.disable.refuse = true;
        this.disable.accept = true;
        this.displaySuccessButton = true;
        this.displayFailButton = true;
        this.successText = 'Aceitar';
        this.failText = 'Recusar';
      } else if (action === 'revoke-item') {
        this.disable.revokeItem = true;
        this.failText = 'Revogar';
        this.displaySuccessButton = false;
        this.displayFailButton = true;
      }

      this.itemId = itemId;
      this.rowAlias = fornecedor;
      this.action = action;
      this.justification = true;
    },
    async priceAdhesion() {
      this.disable.adhere = true;
      this.disable.abdicateAdherence = true;
      try {
        await this.adesaoMenorPrecoItem({
          itemId: this.adhesionData.itemId,
          aderido: this.adhesionData.adhesion,
        });
        this.$alerta(
          `${this.biggestDiscount ? 'Maior desconto' : 'Menor preço'} ${
            this.adhesionData.adhesion ? 'aderido' : 'não aderido'
          } no Item ${this.adhesionData.identifier}`,
          `${this.adhesionData.adhesion ? 'is-success' : 'is-danger'}`,
        );
      } catch (error) {
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.error.type === 'AuctionAdhesionClockFinished'
        ) {
          this.$alerta('Tempo finalizado', 'is-danger');
          return;
        }

        this.$alerta(
          `Erro ao ${this.adhesionData.adhesion ? 'aderir' : 'não aderir'} o ${
            this.biggestDiscount ? 'maior desconto' : 'menor preço'
          } do item`,
          'is-danger',
        );
      }
      this.disable.adhere = false;
      this.disable.abdicateAdherence = false;
    },
    async refuseOffer({ itemId, justificativa }) {
      const refused = await this.recusarLanceNegociacao({ itemId, justificativa });
      if (refused) {
        this.$alerta('Proposta recusada', 'is-danger');
      } else {
        this.$alerta('Erro ao recusar proposta', 'is-danger');
      }
      this.disable.refuse = false;
      this.disable.accept = false;
      this.sendCloseModal();
    },
    resetStateButtons() {
      this.loading.general = false;
      this.loading.documents = false;
      this.disable.accept = false;
      this.disable.refuse = false;
      this.disable.disqualify = false;
      this.disable.revokeItem = false;
      this.disable.adjudicate = false;
      this.disable.homologate = false;
    },
    async revokeAuctionItem(data) {
      try {
        await this.revogarItem(data);
        this.$alerta('O item foi revogado', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao revogar item', 'is-danger');
      }
      this.disable.revokeItem = false;
      this.sendCloseModal();
    },
    async selectCompany(item, company) {
      this.loading.documents = true;
      await Promise.all([
        this.fetchRequiredDocuments(this.auction.id),
        this.fetchAuctionBidderDocuments({
          auctionId: this.auction.id,
          bidderId: company.bidderId,
          itemId: item.id,
        }),
      ]);
      await this.$emitBus('documentos', { item, company });
      this.loading.documents = false;
    },
    sendCloseModal() {
      this.$refs.justificationModal.close();
    },
    async startBids() {
      this.loading.general = true;
      this.bidItems = await this.permitirLances(this.itemsForBids);
      if (this.bidItems) {
        if (this.selectedItems.length === 1) {
          this.$alerta('Item iniciado para lance', 'is-success');
        } else if (this.selectedItems.length > 1) {
          this.$alerta('Itens iniciados para lances', 'is-success');
        }
        this.selectedItems = [];
      } else {
        this.$alerta('Erro ao iniciar lances', 'is-danger');
      }
      this.loading.general = false;
    },
    async startPreference() {
      this.loading.general = true;
      this.bidItems = await this.permitirPreferencia(this.itemsForBids);
      if (this.bidItems) {
        if (this.selectedItems.length === 1) {
          this.$alerta('Item aberto para preferência', 'is-success');
        } else if (this.selectedItems.length > 1) {
          this.$alerta('Itens abertos para preferência', 'is-success');
        }
        this.selectedItems = [];
      } else {
        this.$alerta('Erro ao iniciar lances', 'is-danger');
      }
      this.loading.general = false;
    },

    totalAdhesions(itemId) {
      const adhesionsItem = this.adhesions
        ? this.adhesions.filter(elemento => elemento.itemId === itemId)
        : [];
      return adhesionsItem.length;
    },
    totalProposals(itemId) {
      const propostasItem = this.proposals.filter(
        elemento => elemento.oferta_compra_item_id === itemId,
      );
      return propostasItem.length;
    },
    verifyItems() {
      this.bidItems =
        this.items.filter(item => item.estado in ['LANCE', 'PREFERENCIA-INICIADA']).length > 0;
    },
    async viewCompanyDocuments(item) {
      const company = {
        alias: item.menor_lance.fornecedor,
        bidderId: item.menor_lance.fornecedor_id,
        socialDenomination: item.menor_lance.razao_social,
      };
      await Promise.all([
        this.fetchRequiredDocuments(this.auction.id),
        this.fetchAuctionBidderDocuments({
          auctionId: this.auction.id,
          bidderId: company.bidderId,
          itemId: item.id,
        }),
      ]);
      this.$emitBus('documentos', { item, company });
    },
    viewMyItems() {
      this.$emit('view-my-items');
    },
    verifySituation(auctionSituation) {
      return this.auction.situacao.id === auctionSituation;
    },
  },
  mounted() {
    this.$onBus('onTerminar:itensEmLance', () => {
      this.bidItems = false;
    });
    this.verifyItems();
    this.$nextTick(() => {
      this.verifyOverflowed();
    });
  },
};
</script>

<style lang="scss" scoped>
.blink-button {
  &:not(hover) {
    -webkit-animation: blink 2s infinite both;
    animation: blink 2s infinite both;
  }

  &:hover {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes blink {
  0%,
  50%,
  100% {
    background-color: #2153b5;
  }
  25%,
  75% {
    background-color: #568259;
  }
}
@keyframes blink {
  0%,
  50%,
  100% {
    background-color: #2153b5;
  }
  25%,
  75% {
    background-color: #568259;
  }
}

.my-items-button {
  margin-top: 0.6rem;
}
</style>

<style lang="scss">
.custom-eye-icon .mdi-eye-outline::before {
  font-size: 22px;
}

.auction-items-table .table-wrapper .table {
  max-width: 99.8%;
}

@media (min-width: 769px) {
  .elipsis-size {
    max-width: 1px;
    width: 100%;
  }
}
</style>

<style src="@/styles/items.scss" lang="scss" scoped />
