import { api, sendTimeout } from '../http';

export const getAuctionItems = (auctionId, auctionItemsId) =>
  api.get(`/auctions/${auctionId}/items`, auctionItemsId);

export const deleteAuctionItems = (auctionId, auctionItemsId) => {
  return api.post(`/auctions/${auctionId}/items/delete`, auctionItemsId);
};

export const importAuctionItems = (
  auctionId,
  data,
  { onUploadProgress } = { onUploadProgress: () => {} },
) =>
  api.post(`/auctions/${auctionId}/items/import`, data, {
    onUploadProgress,
    timeout: sendTimeout,
  });

export const createAuctionItem = (auctionId, item) =>
  api.post(`/auctions/${auctionId}/items`, item);

export const createAuctionBatchItem = (auctionId, auctionItemId, item) =>
  api.post(`/auctions/${auctionId}/items/${auctionItemId}/batch-items`, item);

export const updateAuctionBatchItem = (auctionId, auctionItemId, auctionLoteItemId, item) =>
  api.patch(`/auctions/${auctionId}/items/${auctionItemId}/batch-items/${auctionLoteItemId}`, item);
