<template>
  <div>
    <AppValor
      ref="inputValor"
      :exibirInput="true"
      :item="item"
      :style="{ width: width + 'px' }"
      @valor="dados => atualizarValor('inputValor', dados)"
      @blur="limpaMensagem"
      @keyup.enter.native="enviarLance"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import campoMixin from '@/mixins/formulario';

import AppValor from '@/components/AppValor';

export default {
  name: 'DispensaValor',
  props: {
    item: Object,
    width: { type: String, default: '' },
  },
  components: {
    AppValor,
  },
  mixins: [campoMixin],
  data() {
    const lance = {
      valor: 0,
    };

    return {
      lance,
    };
  },
  computed: {
    ...mapGetters({
      lances: 'obterDispensaLances',
      lancesParaEnviar: 'obterLancesParaEnviar',
    }),
    existeLanceValidoItem() {
      return this.lances.find(
        elemento => elemento.dispensaItemId === this.item.id && elemento.status === 'VALIDO',
      );
    },
    valoresMascara() {
      return this.$refs.inputValor.valoresMascara;
    },
  },
  methods: {
    ...mapActions(['adicionarDispensaLance', 'alternarExibirAviso']),
    atualizarValor(campo, dados) {
      this.verificaValor(dados.valor);
      this.lance.valor = dados.valorMascara !== '' ? dados.valor : 0;

      const valores = this.montarDados();
      this.adicionarDispensaLance(valores);
    },
    montarDados() {
      const dados = this.lancesParaEnviar.find(lance => lance.dispensaItemId === this.item.id);
      const marca = this.calcularMarca(dados);
      const importado = this.calcularImportado(dados);

      return {
        dispensaItemId: this.item.id,
        identificador: this.item.identificador,
        itemDescricao: this.item.item.descricao,
        itemUnidadeNome: this.item.unidade.nome,
        valor: this.lance.valor,
        marca,
        importado,
        primeiroLance: !this.existeLanceValidoItem,
      };
    },
    calcularMarca(dados) {
      if (dados) return dados.marca;
      if (this.existeLanceValidoItem) {
        const ultimoLance = this.calcularUltimoLance();
        return ultimoLance.marca;
      }
      return '';
    },
    calcularImportado(dados) {
      if (dados) return dados.importado;
      if (this.existeLanceValidoItem) {
        const ultimoLance = this.calcularUltimoLance();
        if (ultimoLance.fabricado === 'IMPORTADO') {
          return true;
        }
      }
      return false;
    },
    calcularUltimoLance() {
      return this.lances
        .filter(elemento => elemento.dispensaItemId === this.item.id)
        .reduce((anterior, atual) => (anterior.id > atual.id ? anterior : atual));
    },
    verificaValor(valor) {
      const valorPorcentagem = Number(this.item.menor_lance.valor * 0.8);
      if (valor > 0 && valor < valorPorcentagem) {
        if (this.valoresMascara.valor !== '' && valor < valorPorcentagem) {
          this.alternarExibirAviso(true);
        } else {
          this.alternarExibirAviso(false);
        }
      } else {
        this.alternarExibirAviso(false);
      }
    },
    limpaMensagem() {
      this.alternarExibirAviso(false);
    },
    focus() {
      this.$refs.inputValor.focus();
    },
    enviarLance() {
      this.$emit('enter-lance');
    },
  },
};
</script>
