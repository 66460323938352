import { api, sendTimeout } from '../http';

export const fetchCompanyDocuments = companyId => api.get(`/empresas/${companyId}/documents`);
export const addCompanyDocument = (
  companyId,
  data,
  { onUploadProgress } = { onUploadProgress: () => {} },
) => api.post(`/empresas/${companyId}/documents`, data, { onUploadProgress, timeout: sendTimeout });
export const removeCompanyDocument = (companyId, documentId) =>
  api.delete(`/empresas/${companyId}/documents/${documentId}`);
