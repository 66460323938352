import {
  fetchDispensationWatch,
  updateDispensationWatch,
} from '@/resources/dispensations/dispensationsOptions';

const types = {
  DISPENSATION_WATCH: 'DISPENSATION_WATCH',
};

const mutations = {
  [types.DISPENSATION_WATCH](state, data) {
    if (data && typeof data === 'object') {
      state.dispensationWatch = data;
    }
  },
};

const actions = {
  async fetchDispensationWatch({ commit }, { dispensationId }) {
    const response = await fetchDispensationWatch(dispensationId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.DISPENSATION_WATCH, response.data);
  },
  async updateDispensationWatch({ commit }, { dispensationId }) {
    const response = await updateDispensationWatch(dispensationId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.DISPENSATION_WATCH, response.data);
  },
};

const state = {
  dispensationWatch: {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
