<template>
  <div :class="{ 'app-pagination': hasMargin }">
    <b-field grouped>
      <div class="selected-pagina">
        <b-select
          :value="dataFilters.perPage"
          @input.native="event => changePerPage(event.target.value)"
        >
          <option v-for="perPage in getPageList" :key="perPage.id" :value="perPage.lines">
            {{ perPage.lines }}
          </option>
        </b-select>
      </div>

      <div>
        <b-pagination
          :total="total"
          :current="dataFilters.page"
          :per-page="dataFilters.perPage"
          range-before="2"
          range-after="2"
          @change="changePage"
          order="is-right"
          icon-prev="chevron-left"
          icon-next="chevron-right"
          aria-next-label="Próxima Página"
          aria-previous-label="Página anterior"
          aria-page-label="Página"
          aria-current-label="Página atual"
        >
        </b-pagination>
      </div>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    dataFilters: { type: Object },
    hasMargin: { type: Boolean, default: true },
    total: { type: Number },
    maxLines: { type: Number, default: 100 },
  },
  data() {
    const perPageList = [
      { id: 1, lines: 5 },
      { id: 2, lines: 10 },
      { id: 3, lines: 20 },
      { id: 4, lines: 25 },
      { id: 5, lines: 50 },
      { id: 6, lines: 100 },
    ];

    return {
      perPageList,
    };
  },
  computed: {
    getPageList() {
      return this.perPageList.filter(perPage => perPage.lines <= this.maxLines);
    },
  },
  methods: {
    changePage(page) {
      this.$emit('change-page', page);
    },
    changePerPage(perPage) {
      this.$emit('change-per-page', perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-pagination {
  float: right;
  margin-top: 2rem;
  text-align: right !important;
}

.selected-pagina {
  margin-right: 2rem;
}
</style>
