import Vue from 'vue';

import {
  getItens as dadosItensDispensa,
  getItem as dadosItem,
  criarItem,
  atualizarItem,
  deletarItem,
  revogarItem,
  iniciarLance,
  ratificarVencedorItem,
} from '@/resources/dispensas';

const types = {
  DISPENSA_ITEM: 'DISPENSA_ITEM',
  DISPENSA_ITENS: 'DISPENSA_ITENS',
  DISPENSA_ITENS_LIMPAR: 'DISPENSA_ITENS_LIMPAR',
  DISPENSA_ITEM_UPDATE: 'DISPENSA_ITEM_UPDATE',
  DISPENSA_ITEM_UPDATE_BUYER: 'DISPENSA_ITEM_UPDATE_BUYER',
  DISPENSA_ITEM_UPDATE_ESTADO: 'DISPENSA_ITEM_UPDATE_ESTADO',
  DISPENSA_ITEM_REMOVER: 'DISPENSA_ITEM_REMOVER',
  DISPENSA_ITEM_ADICIONAR: 'DISPENSA_ITEM_ADICIONAR',
};

const mutations = {
  [types.DISPENSA_ITENS](state, itens) {
    if (itens && Array.isArray(itens)) {
      state.itens = itens;
    }
  },
  [types.DISPENSA_ITEM](state, item) {
    if (item && typeof item === 'object') {
      state.item = item;
    }
  },
  [types.DISPENSA_ITEM_REMOVER](state, id) {
    const itemParaExcluir = state.itens.find(item => item.id === id);

    const index = state.itens.findIndex(item => item.id === id);
    if (index > -1) {
      state.itens.splice(index, 1);
    }

    state.itens.forEach((item, indice) => {
      if (item.identificador > itemParaExcluir.identificador) {
        const novoObjeto = state.itens[indice];
        novoObjeto.identificador -= 1;
        Vue.set(state.itens, indice, novoObjeto);
      }
    });
  },
  [types.DISPENSA_ITEM_ADICIONAR](state, item) {
    state.itens = [...state.itens, item];
  },
  [types.DISPENSA_ITEM_UPDATE](state, itens) {
    if (itens && Array.isArray(itens) && itens.length > 0) {
      while (itens.length) {
        const item = itens.shift();
        const index = state.itens.findIndex(elemento => elemento.id === item.id);
        if (index > -1) {
          delete item.referencia;
          delete item.total;
          const itemAtualizado = Object.assign(state.itens[index], item);
          Vue.set(state.itens, index, itemAtualizado);
        }
      }
    } else if (itens && typeof itens === 'object') {
      const index = state.itens.findIndex(elemento => elemento.id === itens.id);
      if (index > -1) {
        const itemClone = { ...itens };
        delete itemClone.referencia;
        delete itemClone.total;
        const item = Object.assign(state.itens[index], itens);
        Vue.set(state.itens, index, item);
      }
    }
  },
  [types.DISPENSA_ITEM_UPDATE_BUYER](state, item) {
    if (item && typeof item === 'object') {
      const index = state.itens.findIndex(elemento => elemento.id === item.id);
      if (index > -1) {
        const itemAtualizado = Object.assign(state.itens[index], item);
        Vue.set(state.itens, index, itemAtualizado);
      }
    }
  },
  [types.DISPENSA_ITEM_UPDATE_ESTADO](state, itens) {
    if (itens && Array.isArray(itens) && itens.length > 0) {
      while (itens.length) {
        const item = itens.shift();
        if (item.referencia) item.referencia = Number(item.referencia);
        const index = state.itens.findIndex(elemento => elemento.id === item.id);
        if (index > -1) {
          const novoObjeto = state.itens[index];
          novoObjeto.estado = item.estado;
          Vue.set(state.itens, index, novoObjeto);
        }
      }
    } else if (itens && typeof itens === 'object') {
      const index = state.itens.findIndex(elemento => elemento.id === itens.id);
      if (index > -1) {
        const novoObjeto = state.itens[index];
        novoObjeto.estado = itens.estado;
        Vue.set(state.itens, index, novoObjeto);
      }
    }
  },
  [types.DISPENSA_ITENS_LIMPAR](state) {
    state.itens = [];
    state.item = {};
  },
};

const actions = {
  async buscarItensDispensa({ commit }, dispensaId) {
    const itens = await dadosItensDispensa(dispensaId);
    commit(types.DISPENSA_ITENS, itens.data.data);
  },
  async buscarItemDispensa({ commit }, { dispensaId, itemId }) {
    const { data: item } = await dadosItem(dispensaId, itemId);
    commit(types.DISPENSA_ITEM, item.data);
  },
  async adicionarItemDispensa({ commit, rootState }, data) {
    const dispensaId = rootState.dispensas.dispensa.id;
    const response = await criarItem(dispensaId, data);
    if (response.status !== 201) return Promise.reject(response);
    return commit(types.DISPENSA_ITEM_ADICIONAR, response.data.data);
  },
  async atualizarItemCompradorDispensa({ commit, rootState }, data) {
    const dispensaId = rootState.dispensas.dispensa.id;
    const response = await atualizarItem(dispensaId, data.dispensaItemId, data);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.DISPENSA_ITEM_UPDATE_BUYER, response.data.data);
  },
  async removerItemDispensa({ commit, rootState }, dispensaItemId) {
    const dispensaId = rootState.dispensas.dispensa.id;
    const item = await deletarItem(dispensaId, dispensaItemId);
    if (item.status === 204) commit(types.DISPENSA_ITEM_REMOVER, dispensaItemId);
  },
  async revogarItemDispensa({ commit, rootState }, { dispensaItemId, justificativa }) {
    const dispensaId = rootState.dispensas.dispensa.id;
    const item = await revogarItem(dispensaId, dispensaItemId, { justificativa });
    commit(types.DISPENSA_ITEM_UPDATE, item.data.data);
  },
  async ratificarVencedorItem(
    { commit, rootState },
    { dispensaItemId, dataRatificacao, justificativa },
  ) {
    const dispensaId = rootState.dispensas.dispensa.id;
    const item = await ratificarVencedorItem(dispensaId, dispensaItemId, {
      justificativa,
      dataRatificacao,
    });
    commit(types.DISPENSA_ITEM_UPDATE, item.data.data);
  },
  validarItensDispensa({ state }) {
    return state.itens.length > 0;
  },
  limparItensDispensa({ commit }) {
    commit(types.DISPENSA_ITENS_LIMPAR);
  },
  atualizarItensDispensa({ commit }, itens) {
    commit(types.DISPENSA_ITEM_UPDATE, itens);
  },
  atualizarItensEstadoDispensa({ commit }, itens) {
    commit(types.DISPENSA_ITEM_UPDATE_ESTADO, itens);
  },
  async permitirLancesDispensa({ rootState }, itens) {
    const response = await iniciarLance(rootState.dispensas.dispensa.id, { itens });
    const {
      status,
      data: { type },
    } = response;
    if (status !== 200) return Promise.reject(response);
    return status === 200 && type === 'IniciadoLances';
  },
};

const getters = {
  obterItemDispensa(state) {
    return state.item;
  },
  obterTodosItensDispensa(state) {
    return state.itens;
  },
};

const state = {
  item: {
    menor_lance: {},
  },
  itens: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
