<template>
  <div>
    <b-loading is-full-page :active="loading.dispensa" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container is-fluid">
          <div class="level is-flex-touch column-direction-touch">
            <div class="level-left level-column margin-1">
              <div class="level-item">
                <div>
                  <h1 class="title">Dispensa de Licitação</h1>
                  <br />
                  <div class="info">
                    <ul>
                      <li v-if="dispensa.comprador.nome">
                        <strong class="has-text-white">Comprador:</strong>
                        {{ capitalize(dispensa.comprador.nome) }}
                      </li>

                      <li>
                        <strong class="has-text-white">Número:</strong>
                        {{ dispensa.numero_dispensa }}
                      </li>

                      <li>
                        <strong class="has-text-white">Negociação:</strong>
                        {{ dispensa.tipo_negociacao.nome }}
                      </li>

                      <li>
                        <strong class="has-text-white">Tipo de Participação:</strong>
                        {{ tipoParticipacao }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="level-item has-text-centered level-column margin-1" v-if="mostraApelido">
              <div>
                <p class="heading">Apelido</p>
                <p class="title">{{ this.userLoggedBidder.alias }}</p>
              </div>
            </div>

            <div
              class="level-item has-text-centered level-column margin-1"
              v-if="verificarSituacao([1, 2, 3, 4])"
            >
              <div>
                <p class="heading">Previsão de Abertura</p>
                <p class="title">{{ dispensa.data_abertura | formatarData }}</p>
              </div>
            </div>

            <div v-if="verificarSituacao([6, 7, 11])" class="level-column margin-1">
              <AppTimer />
            </div>

            <div class="level-right level-column margin-1">
              <div class="level-item has-text-centered level-column">
                <div>
                  <p class="heading">Situação</p>
                  <p class="title">{{ nomeSituacao(dispensa.situacao.nome) }}</p>

                  <b-button
                    inverted
                    outlined
                    v-if="loaded && activeDispensation && fornecedor"
                    class="level-button"
                    icon-left="comment-eye-outline"
                    :type="dispensationWatch.id ? 'is-danger' : 'is-success'"
                    :loading="loading.watchDispensation"
                    @click.prevent.stop="watchDispensation()"
                  >
                    {{ dispensationWatch.id ? 'Não Assistir' : 'Assistir' }}
                  </b-button>

                  <button
                    v-if="exibirAbrirSessao"
                    class="button is-primary is-inverted is-outlined header-botoes"
                    :class="{ ' is-loading': loading.general }"
                    @click.prevent.stop="iniciarSessao"
                  >
                    Abrir Sessão
                  </button>

                  <button
                    v-if="exibirRevogarDispensa"
                    class="button is-primary is-inverted is-outlined header-botoes"
                    @click.prevent.stop="abrirModal(dispensa.id, 'revogar')"
                  >
                    Revogar Dispensa
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="exibirMensagemPendente" class="columns">
            <div class="column has-text-centered">
              <b-icon
                class="icon-pending-company"
                type="is-warning"
                icon="alert-box"
                size="is-large"
              />
              <span class="text-pending-company">
                Empresa pendente de aprovação da SelCorp. Caso não tenha solicitado validação, por
                favor solicite no perfil de sua empresa.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="needReload && loaded" class="disconnection-content">
      <b-notification
        indefinite
        class="disconnection-notification"
        role="alert"
        type="is-warning"
        :closable="false"
      >
        <b-icon class="notification-icon" icon="alert-box" size="is-medium" />
        <span class="notification-text"
          >O navegador perdeu a conexão com o servidor. Por favor, recarregue a página ou clique
          <a class="notification-link" @click.stop.prevent="reloadPage()">aqui</a> para
          recarregar.</span
        >
      </b-notification>
    </div>

    <div class="limit">
      <div class="container-dispensa">
        <section>
          <b-tabs
            expanded
            ref="tabs"
            type="is-toggle"
            destroy-on-hide
            @input="verificarItens"
            v-model="selecionado"
            :animated="false"
          >
            <b-tab-item label="Comprador">
              <DispensaComprador class="components" />
            </b-tab-item>

            <b-tab-item label="Edital/Anexos">
              <DispensaEdital class="components" />
            </b-tab-item>

            <b-tab-item label="Itens">
              <DispensaLances
                class="components"
                v-if="exibirLances"
                :item="item"
                :dispensa-id="dispensaId"
                @voltar="exibirDispensaItens"
              />
              <DispensaItens class="components" v-if="exibirItens" :loaded="loaded" />
            </b-tab-item>

            <b-tab-item label="Gestão de Prazos">
              <DispensaPrazos class="components" />
            </b-tab-item>
          </b-tabs>
        </section>
      </div>
    </div>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificativa"
      :onCancel="resetarLoadings"
      @justificate="enviar"
    >
      <AppJustification
        v-if="justificativa"
        :displayDateTime="exibirDataHora"
        :displayFailButton="exibirFalha"
        :displaySuccessButton="exibirSucesso"
        :failText="textoFalha"
        :workDays="diasUteis"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { capitalize } from '@/utils/functions';
import { formatarData } from '@/utils/format';
import { funcoes } from '@/utils/enumerados';

import AppJustification from '@/components/AppJustification';
import AppTimer from '@/components/AppTimer';
import DispensaComprador from '@/views/Dispensa/DispensaItem/DispensaComprador';
import DispensaEdital from '@/views/Dispensa/DispensaItem/DispensaEdital';
import DispensaItens from '@/views/Dispensa/DispensaItem/DispensaItens';
import DispensaPrazos from '@/views/Dispensa/DispensaItem/DispensaPrazos';
import DispensaLances from '@/views/Dispensa/DispensaItem/DispensaLances';
import SocketResource from '@/resources/socket';

export default {
  name: 'DispensaItem',
  components: {
    AppJustification,
    AppTimer,
    DispensaComprador,
    DispensaEdital,
    DispensaItens,
    DispensaPrazos,
    DispensaLances,
  },
  data() {
    const loading = {
      general: false,
      dispensa: false,
      suspender: false,
      watchDispensation: false,
    };

    return {
      acao: '',
      capitalize,
      diasUteis: false,
      dispensaId: 0,
      item: null,
      exibirDataHora: false,
      exibirFalha: false,
      exibirItens: true,
      exibirLances: false,
      exibirSucesso: false,
      justificativa: false,
      loaded: false,
      loading,
      needReload: false,
      selecionado: 0,
      socketResource: new SocketResource(this.$socket),
      textoFalha: '',
    };
  },
  beforeRouteLeave(to, from, next) {
    this.socketResource.leaveDispensation(this.dispensa.id);
    document.title = 'SelCorp - Sistema Eletrônico de Licitações';
    this.limparDispensa();
    this.limparAnexosDispensa();
    this.limparItensDispensa();
    this.limparLancesDispensa();
    this.invalidatePin();
    return next();
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapState('dispensationsOptions', ['dispensationWatch']),
    ...mapState('dispensationsBidders', ['userLoggedBidder']),
    ...mapGetters({
      dispensa: 'obterDispensa',
      funcao: 'getFunction',
      logged: 'getLogged',
      logando: 'getLogging',
      itens: 'obterTodosItensDispensa',
      lances: 'obterDispensaLances',
      prazos: 'obterDispensaPrazos',
    }),
    activeDispensation() {
      return this.situacao >= 2 && this.situacao <= 6;
    },
    exibirMensagemPendente() {
      return (
        [2, 4, 6].indexOf(this.situacao) > -1 &&
        ['CADASTRADA', 'INABILITADA'].indexOf(this.company.status) > -1 &&
        this.fornecedor
      );
    },
    situacao() {
      return this.dispensa.situacao.id;
    },
    exibirRevogarDispensa() {
      const existe = this.itens.find(item => item.estado === 'RATIFICADO');
      return !existe && this.comprador && [2, 4, 15].indexOf(this.situacao) > -1;
    },
    comprador() {
      return this.company.buyer && this.company.id === this.dispensa.comprador.id;
    },
    vendedor() {
      return this.funcao === funcoes.VENDEDOR;
    },
    fornecedor() {
      return this.funcao === funcoes.FORNECEDOR;
    },
    licitante() {
      return this.funcao === funcoes.LICITANTE;
    },
    itensParaLances() {
      return this.itens.some(item => ['CADASTRADA', 'LANCE'].indexOf(item.estado) > -1);
    },
    tipoParticipacao() {
      if (this.dispensa.tipo_participacao === 'AMPLA') {
        return 'Ampla';
      }
      return 'Exclusiva de ME/EPP/MEI/Cooperativa c/ Preferência';
    },
    exibirAbrirSessao() {
      if (
        this.verificarSituacao(4) &&
        this.comprador &&
        this.logged &&
        this.itens.some(item => item.estado === 'CADASTRADO')
      ) {
        return true;
      }
      return false;
    },
    mostraApelido() {
      return this.licitante && this.situacao === 6;
    },
    socketConnection() {
      return this.$socket.connected;
    },
  },
  watch: {
    logged() {
      if (this.logged && this.dispensa.id) {
        this.reconnectSocket();
      }
    },
    socketConnection() {
      if (!this.socketConnection && !this.logando) {
        this.needReload = true;
        this.reconnectSocket();
      } else {
        this.fetchChangingData(this.dispensa.id, true);
        this.needReload = false;
      }
    },
  },
  sockets: {
    'dispensa:atualizar': function updateDispensation(dispensa) {
      this.updateDispensation(dispensa);
    },
    'dispensa:itens': function atualizarDispensaItens(itens) {
      this.atualizarItensDispensa(itens);
    },
    'dispensa:itens-estado': function atualizarDispensaItensEstado(itens) {
      this.atualizarItensEstadoDispensa(itens);
    },
    'dispensa:lances-empresa': function atualizarDispensaLancesEmpresa(lances) {
      if (
        lances.length &&
        this.licitante &&
        this.lances[0].fornecedor_id === lances[0].fornecedor_id
      ) {
        this.atualizarLancesItemEmpresa(lances);
      }
    },
    'dispensa:prazos': function atualizarDispensaPrazos(prazos) {
      this.atualizarPrazosDispensa(prazos);
    },
  },
  filters: {
    formatarData,
  },
  methods: {
    ...mapActions('dispensationsOptions', ['fetchDispensationWatch', 'updateDispensationWatch']),
    ...mapActions([
      'atualizarItensDispensa',
      'atualizarItensEstadoDispensa',
      'atualizarPrazosDispensa',
      'atualizarLancesItemEmpresaDispensa',
      'buscarDispensa',
      'buscarAnexosDispensa',
      'buscarDispensaLances',
      'buscarItensDispensa',
      'buscarPrazosDispensa',
      'invalidatePin',
      'limparDispensa',
      'limparLancesDispensa',
      'limparAnexosDispensa',
      'limparItensDispensa',
      'openDispensationSession',
      'revogarDispensa',
      'updateDispensation',
    ]),
    ...mapActions('dispensationsBidders', ['fetchUserLoggedBidder']),
    async watchDispensation() {
      const confirmation = await this.$confirmacao({
        message: `Você está ${
          this.dispensationWatch.id ? 'desabilitando' : 'habilitando'
        } a opção para receber todas as notificações dessa dispensa. Deseja prosseguir?`,
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-info',
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      this.loading.watchDispensation = true;
      try {
        await this.updateDispensationWatch({ dispensationId: this.dispensa.id });
        this.$alerta(
          `Opção ${this.dispensationWatch.id ? 'habilitada' : 'desabilitada'}`,
          'is-success',
        );
      } catch (error) {
        this.$alerta(
          `Erro ao ${
            this.dispensationWatch.id ? 'habilitar' : 'desabilitar'
          } essa opção', 'is-danger`,
        );
      }
      this.loading.watchDispensation = false;
    },
    reloadPage() {
      window.location.reload(true);
    },
    async reconnectSocket() {
      await this.socketResource.closeConnection();
      await this.socketResource.openConnection();
      await this.enterDispensationRoom();
    },
    enterDispensationRoom() {
      this.socketResource.enterDispensation(this.dispensa.id);
    },
    nomeSituacao(situacao) {
      if (situacao === 'Encerrada com Vencedor') return 'Encerrada';
      return situacao;
    },
    exibirDetalhes({ tipo, item, dispensaId }) {
      this.item = item;
      this.dispensaId = dispensaId;
      if (tipo === 'lance') {
        this.exibirLances = true;
        this.exibirItens = false;
      }
    },
    esconderDetalhes() {
      this.item = null;
      this.dispensaId = 0;
      this.exibirLances = false;
      this.exibirItens = true;
    },
    exibirDispensaItens() {
      this.exibirLances = false;
      this.exibirItens = true;
    },
    verificarItens(aba) {
      if (aba === 2) this.esconderDetalhes();
    },
    async iniciarSessao() {
      this.loading.general = true;
      try {
        await this.openDispensationSession();
        this.buscarItensDispensa(this.dispensa.id);
        this.$alerta('Sessão aberta', 'is-success');
        this.selecionado = this.$refs.tabs.activeItem.index;
      } catch (error) {
        this.$alerta('Erro ao abrir sessão', 'is-danger');
      }
    },
    async requisitarDispensa(dispensationId) {
      this.loading.dispensa = true;
      try {
        await this.buscarDispensa(dispensationId);
      } catch (error) {
        this.$router.push({ name: 'PaginaInexistente' });
      }
      await this.fetchChangingData(dispensationId);
      if (this.activeDispensation && this.logged) {
        await this.fetchDispensationWatch({ dispensationId });
      }
      this.loading.dispensa = false;
    },
    async fetchChangingData(dispensationId, reloadDispensation = false) {
      if (reloadDispensation) {
        await this.buscarDispensa(dispensationId);
      }
      await Promise.all([
        this.buscarItensDispensa(dispensationId),
        this.buscarPrazosDispensa(dispensationId),
        this.buscarAnexosDispensa(dispensationId),
      ]);
      if ((this.fornecedor || this.licitante) && this.situacao >= 6) {
        await this.buscarDispensaLances({
          dispensaId: dispensationId,
          tipo: 'empresa',
        });
      }
    },
    abrirModal(dispensaId, acao) {
      if (acao === 'suspender') {
        this.loading.suspender = true;
        this.textoFalha = 'Suspender';
        this.acao = acao;
        this.textoSuspender = true;
        this.exibirDataHora = true;
        this.diasUteis = true;
      }
      if (acao === 'revogar') {
        this.loading.general = true;
        this.textoFalha = 'Revogar';
        this.acao = acao;
      }
      this.dispensaId = dispensaId;
      this.exibirSucesso = false;
      this.exibirFalha = true;
      this.justificativa = true;
    },
    async enviar({ justification }) {
      if (this.acao === 'revogar') {
        await this.revogar({
          dispensaId: this.dispensaId,
          justificativa: justification,
        });
      }
    },
    async revogar(dados) {
      try {
        await this.revogarDispensa(dados);
        this.$alerta('Dispensa revogada', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao revogar dispensa', 'is-danger');
      }
      this.loading.general = false;
      this.$refs.justificationModal.close();
    },
    verificarSituacao(etapa) {
      if (Array.isArray(etapa)) {
        return etapa.indexOf(this.situacao) > -1;
      }
      return this.situacao === etapa;
    },

    resetarLoadings() {
      this.loading.general = false;
    },
  },
  async created() {
    this.selecionado = 2;
    this.$onBus('detalhe', this.exibirDetalhes);
    await this.requisitarDispensa(this.$route.params.id);
    await this.enterDispensationRoom();
    this.loaded = true;
  },
};
</script>

<style>
.hero-body {
  padding: 1rem !important;
}

iframe {
  width: 100%;
  height: 50vh;
}

.acoes {
  margin-bottom: 0.3rem;
}

.components {
  margin-top: 1rem !important;
}

.container-dispensa {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.header-botoes {
  margin-top: 1rem;
  margin-right: 0.2rem;
}

.info {
  width: 320px;
}

.level-column {
  flex-grow: 0 !important;
}

.p-encerramento {
  margin-top: 1.5rem;
  margin-bottom: 2rem !important;
}

.icon-pending-company {
  vertical-align: middle;
}

.level-button {
  margin-top: 1rem;
  margin-right: 0.2rem;
}

.text-pending-company {
  font-weight: 800;
  margin-left: 0.2rem;

  color: yellow;
}

.disconnection-notification {
  border: unset;
  /* max-width: 920px; */
}

.notification-link {
  color: #2153b5 !important;
  font-weight: 600;
}

.notification-icon {
  vertical-align: middle;
}

.notification-text {
  margin-left: 0.5rem;
}

@media (min-width: 300px) and (max-width: 1023px) {
  .margin-1 {
    margin-bottom: 1rem !important;
  }
}
</style>
