import Vue from 'vue';

import {
  todas as dadosDispensas,
  get as dadosDispensa,
  criar as criarDispensa,
  editDispensation,
  deletar as deletarDispensa,
  openDispensationSession,
  publishDispensation,
  stats as dadosStats,
  revogarDispensa,
} from '@/resources/dispensas';

const types = {
  DISPENSAS: 'DISPENSAS',
  DISPENSAS_STATS: 'DISPENSAS_STATS',
  DISPENSAS_CARREGANDO: 'DISPENSAS_CARREGANDO',
  DISPENSA_EM_PUBLICACAO: 'DISPENSA_EM_PUBLICACAO',
  DISPENSAS_CLEAN: 'DISPENSAS_CLEAN',
  DISPENSAS_TOTAL: 'DISPENSAS_TOTAL',
  DISPENSAS_FILTRAR: 'DISPENSAS_FILTRAR',
  DISPENSAS_ALTERAR_POR_PAGINA: 'DISPENSAS_ALTERAR_POR_PAGINA',
  DISPENSA: 'DISPENSA',
  DISPENSA_LIMPAR: 'DISPENSA_LIMPAR',
  DISPENSAS_LIMPAR_FILTROS: 'DISPENSAS_LIMPAR_FILTROS',
  DISPENSA_UPDATE: 'DISPENSA_UPDATE',
  DISPENSA_ITENS: 'DISPENSA_ITENS',
  DISPENSAS_EXCLUIR: 'DISPENSAS_EXCLUIR',
};

const mutations = {
  [types.DISPENSAS](state, data) {
    if (data && Array.isArray(data)) {
      state.dispensas = data;
      state.carregandoDispensas = false;
    } else if (data && typeof data === 'object') {
      const index = state.dispensas.findIndex(elemento => elemento.id === data.id);
      if (index > -1) {
        state.dispensas[index] = Object.assign(state.dispensas[index], data);
      }
    }
  },
  [types.DISPENSAS_EXCLUIR](state, dispensaId) {
    state.dispensas = state.dispensas.filter(dispensa => dispensa.id !== dispensaId);
  },
  [types.DISPENSAS_CARREGANDO](state, data) {
    state.carregandoDispensas = data;
  },
  [types.DISPENSA_EM_PUBLICACAO](state, valor) {
    state.emPublicacao = valor;
  },
  [types.DISPENSAS_STATS](state, { inProgress, missingValidations }) {
    state.stats.inProgress = inProgress;
    state.stats.missingValidations = missingValidations;
  },
  [types.DISPENSAS_TOTAL](state, data) {
    state.total = Number(data);
  },
  [types.DISPENSAS_FILTRAR](state, filters) {
    state.dataFilters = {
      ...state.dataFilters,
      page: 1,
      ...filters,
    };
  },
  [types.DISPENSAS_ALTERAR_POR_PAGINA](state, perPage) {
    state.dataFilters.perPage = perPage;
    state.dataFilters.page = 1;
  },
  [types.DISPENSA](state, data) {
    if (data && typeof data === 'object') {
      state.dispensa = Object.assign(state.dispensa, data);
      if (state.dispensa.data_abertura) {
        state.dispensa.data_abertura = new Date(state.dispensa.data_abertura);
      }
      if (state.dispensa.data_publicacao) {
        state.dispensa.data_publicacao = new Date(state.dispensa.data_publicacao);
      }
      if (state.dispensa.data_reabertura) {
        state.dispensa.data_reabertura = new Date(state.dispensa.data_reabertura);
      }
    }
  },
  [types.DISPENSA_LIMPAR](state) {
    state.dispensa = {
      id: 0,
      comprador: {},
      tipo_negociacao: {},
      situacao: {},
      tipo_participacao: '',
    };
  },
  [types.DISPENSAS_LIMPAR_FILTROS](state) {
    state.dataFilters = {
      buyerId: null,
      number: '',
      page: 1,
      perPage: state.dataFilters.perPage,
      period: {
        start: null,
        end: null,
      },
      situation: 'todas',
    };
  },
  [types.DISPENSA_UPDATE](state, data) {
    if (data && typeof data === 'object') {
      state.dispensa = Object.assign(state.dispensa, data);
    }
  },
  [types.DISPENSA_ITENS](state, { dispensaId, itens }) {
    if (itens && Array.isArray(itens)) {
      const indice = state.dispensas.findIndex(dispensa => dispensa.id === dispensaId);
      if (indice > -1) {
        const novoObjeto = state.dispensas[indice];
        novoObjeto.itens = itens;
        Vue.set(state.dispensas, indice, novoObjeto);
      } else {
        state.dispensas.push({
          id: dispensaId,
          itens,
        });
      }
    }
  },
  [types.DISPENSAS_CLEAN](state) {
    state.dispensas = [];
  },
};

const actions = {
  async buscarDispensas({ commit, rootState }, filtros = {}) {
    const { company } = rootState.companies;
    const dataFilters = filtros;
    dataFilters.indexed = !!company.buyer;
    commit(types.DISPENSAS_CARREGANDO, true);
    const { data: dispensas } = await dadosDispensas(dataFilters);
    commit(types.DISPENSAS, dispensas.data);
    commit(types.DISPENSAS_TOTAL, dispensas.count);
    commit(types.DISPENSAS_CARREGANDO, false);
  },
  async buscarDispensa({ commit }, dispensaId) {
    const response = await dadosDispensa(dispensaId);
    if (response.status !== 200) return Promise.reject(response);
    commit(types.DISPENSA, response.data.data);
    return commit(types.DISPENSAS_CARREGANDO, false);
  },
  async editDispensation({ state, commit }, data) {
    const dispensationId = state.dispensa.id;
    const dispensation = await editDispensation(dispensationId, data);
    if (dispensation.status !== 201) return Promise.reject(dispensation.data.data);
    return commit(types.DISPENSA, dispensation.data.data);
  },
  async publishDispensation({ state, commit }) {
    const dispensationId = state.dispensa.id;
    const dispensation = await publishDispensation(dispensationId);
    if (dispensation.status !== 201) return Promise.reject(dispensation.data.data);
    return commit(types.DISPENSA, dispensation.data.data);
  },
  async openDispensationSession({ state, commit }) {
    const dispensationId = state.dispensa.id;
    const dispensation = await openDispensationSession(dispensationId);
    if (dispensation.status !== 201) return Promise.reject(dispensation.data.data);
    return commit(types.DISPENSA, dispensation.data.data);
  },
  async revogarDispensa({ commit }, { dispensaId, justificativa }) {
    const response = await revogarDispensa(dispensaId, { justificativa });
    if (response.status !== 200) return Promise.reject(response);
    commit(types.DISPENSA, response.data.data);
    return commit(types.DISPENSAS, response.data.data);
  },
  async excluirDispensa({ commit }, dispensaId) {
    const { status, data } = await deletarDispensa(dispensaId);
    if (status !== 204) {
      throw new Error(data.message);
    }
    commit(types.DISPENSAS_EXCLUIR, dispensaId);
  },
  async novaDispensa({ commit }) {
    const dispensa = await criarDispensa();
    commit(types.DISPENSA, dispensa.data.data);
    return new Promise((resolve, reject) => {
      try {
        resolve(dispensa.data.data.id);
      } catch (error) {
        reject(error);
      }
    });
  },
  validarDispensa({ state }) {
    if (!state.dispensa) return false;
    if (!state.dispensa.comprador.cnpj) return false;
    if (!state.dispensa.comprador.endereco) return false;
    if (!state.dispensa.comprador.numero) return false;
    if (!state.dispensa.comprador.bairro) return false;
    if (!state.dispensa.comprador.municipio) return false;
    if (!state.dispensa.comprador.uf) return false;
    if (!state.dispensa.comprador.cep) return false;
    if (!state.dispensa.comprador.nome) return false;
    if (!state.dispensa.comprador.telefone) return false;
    if (!state.dispensa.data_abertura) return false;
    if (!state.dispensa.data_publicacao) return false;
    if (!state.dispensa.endereco_entrega) return false;
    if (!state.dispensa.endereco_entrega_numero) return false;
    if (!state.dispensa.endereco_entrega_bairro) return false;
    if (!state.dispensa.endereco_entrega_cep) return false;
    if (!state.dispensa.endereco_entrega_municipio) return false;
    if (!state.dispensa.endereco_entrega_uf) return false;
    if (!state.dispensa.id) return false;
    if (!state.dispensa.numero_dispensa) return false;
    if (!state.dispensa.numero_processo) return false;
    if (!state.dispensa.objeto) return false;
    if (!state.dispensa.situacao || state.dispensa.situacao.id < 1) {
      return false;
    }
    if (!state.dispensa.estado) return false;
    if (!state.dispensa.tipo_participacao) return false;
    if (!state.dispensa.tipo_negociacao || state.dispensa.tipo_negociacao.id < 1) {
      return false;
    }
    return true;
  },
  setDispensaStatus({ commit }, data) {
    commit(types.DISPENSA_STATUS, data);
  },
  limparDispensa({ commit }) {
    commit(types.DISPENSA_LIMPAR);
  },
  updateDispensation({ commit }, data) {
    commit(types.DISPENSA_UPDATE, data);
  },
  alternarEmPublicacaoDispensa({ commit }, valor) {
    commit(types.DISPENSA_EM_PUBLICACAO, valor);
  },
  async buscarStatsDispensa({ commit }) {
    const response = await dadosStats();
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.DISPENSAS_STATS, response.data);
  },
  filtrarDispensas({ dispatch, commit, state }, filters) {
    const dataFilters = filters ? { ...filters } : state.dataFilters;
    commit(types.DISPENSAS_FILTRAR, dataFilters);
    dispatch('buscarDispensas', state.dataFilters);
  },
  alterarPorPaginaDispensas({ dispatch, commit, state }, perPage) {
    commit(types.DISPENSAS_ALTERAR_POR_PAGINA, perPage);
    dispatch('buscarDispensas', state.dataFilters);
  },
  limparFiltrosDispensas({ commit }) {
    commit(types.DISPENSAS_LIMPAR_FILTROS);
  },
  cleanDispensations({ commit }) {
    commit(types.DISPENSAS_CLEAN);
  },
};

const getters = {
  obterDispensa(state) {
    return state.dispensa;
  },
  obterDispensas(state) {
    return state.dispensas;
  },
  obterCarregandoDispensas(state) {
    return state.carregandoDispensas;
  },
  obterDispensaEmPublicacao(state) {
    return state.emPublicacao;
  },
  obterTotalDispensas(state) {
    return state.total;
  },
  obterStatsDispensas(state) {
    return state.stats;
  },
  obterDispensaDadosFiltros(state) {
    return state.dataFilters;
  },
};

const state = {
  dispensa: {
    id: 0,
    comprador: {},
    tipo_negociacao: {},
    situacao: {},
    tipo_participacao: '',
  },
  dispensas: [],
  carregandoDispensas: false,
  emPublicacao: false,
  total: 0,
  dataFilters: {
    city: '',
    number: '',
    page: 1,
    perPage: 20,
    period: {
      start: null,
      end: null,
    },
    situation: 'todas',
    state: '',
  },
  stats: {
    inProgress: 0,
    missingValidations: 0,
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
