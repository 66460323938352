var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-loading',{attrs:{"is-full-page":"","active":_vm.loading.search,"can-cancel":""}}),_c('AppTitulo',{attrs:{"titulo":"Pesquisa de Itens","expand-title":false}}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_vm._v("Filtros")]),_c('div',{staticClass:"panel-block items-filter-panel"},[_c('b-field',{staticClass:"column-direction-touch is-flex-grow-1",attrs:{"expanded":"","grouped":""}},[_c('b-field',{attrs:{"expanded":"","label":"Descrição","type":_vm.campoInvalido('description', 'filters') ? 'is-danger' : '',"message":_vm.campoInvalido('description', 'filters')
                  ? 'Descrição ou código do item é um campo obrigatório'
                  : ''}},[_c('b-input',{attrs:{"custom-class":"uppercase","expanded":"","placeholder":"Pesquise por descrição ou código","type":"search"},on:{"blur":function (event) { return _vm.converterMaiusculo('description', event.target.value, 'filters'); }},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.pesquisarDadosItens()},"input":function($event){return (function (event) {
                    event.target.value && event.target.value && _vm.$v.filters.$reset();
                  }).apply(null, arguments)}},model:{value:(_vm.filters.description),callback:function ($$v) {_vm.$set(_vm.filters, "description", $$v)},expression:"filters.description"}})],1),_c('b-field',{attrs:{"expanded":"","label":"Tipo"}},[_c('b-select',{attrs:{"expanded":"","placeholder":"Tipo"},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}},_vm._l((_vm.types),function(type){return _c('option',{key:type.value,domProps:{"value":type.value}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0)],1),_c('b-field',{staticClass:"search-field"},[_c('button',{staticClass:"button is-medium is-primary is-fullwidth search-button",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.pesquisarDadosItens()}}},[_vm._v(" Pesquisar ")])])],1)],1)]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-block is-justify-content-center"},[(!_vm.itens.length)?_c('section',{staticClass:"section"},[_vm._m(0)]):_vm._e(),(_vm.itens.length)?_c('b-table',{staticClass:"table is-fullwidth items-table",attrs:{"backend-pagination":"","hoverable":"","default-sort-direction":"ASC","data":_vm.itens},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('AppPagination',{attrs:{"data-filters":{ page: _vm.filters.page, perPage: _vm.filters.perPage },"total":_vm.total},on:{"change-page":function (page) {
                    _vm.filters.page = page;
                    _vm.pesquisarDadosItens();
                  },"change-per-page":function (perPage) {
                    _vm.filters.perPage = perPage;
                    _vm.pesquisarDadosItens();
                  }}})]},proxy:true}],null,false,3144345646)},[_c('b-table-column',{attrs:{"numeric":"","label":"Código","width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.code)+" ")]}}],null,false,354675472)}),_c('b-table-column',{attrs:{"label":"Descrição"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"description-column is-clickable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openDetails(props.row)}}},[_c('a',[_vm._v(_vm._s(props.row.description))])])]}}],null,false,2912660493)}),_c('b-table-column',{attrs:{"label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"type-column"},[_vm._v(_vm._s(_vm._f("formatType")(props.row.type)))])]}}],null,false,2656642190)})],1):_vm._e()],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has-text-grey"},[_c('p',[_vm._v("Nenhum item.")])])}]

export { render, staticRenderFns }