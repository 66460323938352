<template>
  <aside class="menu">
    <p class="menu-label">{{ titulo }}</p>
    <ul class="menu-list">
      <li v-for="item in itens" :key="item.id">
        <router-link :to="rotaItem(item)">
          {{ item.titulo }}
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'MenuSecundario',
  props: {
    titulo: {
      type: String,
      default() {
        return 'Menu Secundário';
      },
    },
    itens: Array,
  },
  methods: {
    rotaItem(item) {
      if (Object.prototype.hasOwnProperty.call(item, 'parametros')) {
        return { name: item.rota, params: item.parametros };
      }
      return { name: item.rota };
    },
  },
};
</script>
