/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { DialogProgrammatic as Dialog } from 'buefy';

function confirmacao({
  message,
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  type = 'is-warning',
  hasIcon = true,
}) {
  return new Promise(resolve => {
    Dialog.confirm({
      message,
      cancelText,
      confirmText,
      type,
      hasIcon,
      onConfirm() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  });
}

const plugin = {
  install(vue) {
    vue.prototype.$confirmacao = confirmacao;
  },
};

Vue.use(plugin);
