<template>
  <div class="container">
    <div
      ref="contract"
      class="box service-contract-box"
      @scroll="event => handleScroll(event.target)"
    >
      <h4 class="title is-6 has-text-centered">
        TERMO DE CONTRATO DE PRESTAÇÃO DE SERVIÇOS PARA USO DA PLATAFORMA ELETRÔNICA DE LICITAÇÕES
        CORPORATIVAS - SELCORP
      </h4>

      <div class="owner-info" :class="{ 'justify-align': justify }">
        <span
          >Pelo presente instrumento particular de contrato de prestação de serviços para uso da
          plataforma eletrônica de licitações corporativas, entre os signatários, de um lado
          nomeado(a) e qualificado(a) na própria plataforma, no ambiente de CADASTRO, doravante aqui
          simplesmente designado(a) LICITANTE e, de outro lado SELCORP LICITAÇÕES S/A, com sede na
          cidade de São Paulo, Estado de São Paulo, na Rua Augusta nº 1.168 – 2º andar – Consolação
          , inscrita no CNPJ/MF sob o nº 29.945.708/0001-05, aqui doravante simplesmente designada
          CONTRATADA, têm entre si, justo e contratado o que segue e que mutuamente outorgam e
          aceitam, a saber.</span
        >
      </div>

      <h6 class="subtitle is-6 margin-1">
        CLÁUSULA PRIMEIRA
      </h6>
      <div class="first-clause" :class="{ 'justify-align': justify }">
        <span
          >Por meio do presente termo, a “LICITANTE”, manifesta sua vontade de contratar e obter
          acesso ao Sistema Eletrônico de Licitações Corporativas - SELCORP, do qual declara ter
          pleno conhecimento, em conformidade com as disposições que seguem.</span
        >
      </div>

      <h6 class="subtitle is-6 margin-1">
        CLÁUSULA SEGUNDA
      </h6>
      <div class="content second-clause" :class="{ 'justify-align': justify }">
        <span class="second-clause-span">São responsabilidades do Licitante:</span>

        <ol type="a">
          <li>
            tomar conhecimento e cumprir todos os dispositivos constantes dos editais de licitações
            promovidas pelo Sistema Eletrônico de Licitações Corporativas – SELCORP, dos processos
            dos quais venha a participar;
          </li>
          <li>
            observar e cumprir a regularidade fiscal, apresentando a documentação exigida nos
            editais para fins de habilitação nas licitações em que for vencedor;
          </li>
          <li>
            observar a legislação pertinente, bem como o disposto nas demais normas e regulamentos
            publicados no site da SELCORP, dos quais declara ter pleno conhecimento;
          </li>
          <li>
            designar representante responsável para operar o Sistema Eletrônico de Licitações
            Corporativas – SELCORP, que será detentor de login e senha de acesso conforme Anexo I
            deste termo; e
          </li>
          <li>
            pagar a taxa pela utilização do Sistema Eletrônico de Licitações Corporativas, quando
            vencedor e após o resultado ser homologado e ou ratificado pelo órgão promotor da
            licitação.
          </li>
        </ol>
      </div>

      <h6 class="subtitle is-6 margin-1">
        CLÁUSULA TERCEIRA
      </h6>
      <div class="content third-clause" :class="{ 'justify-align': justify }">
        <span class="clause-span">O Licitante reconhece que:</span>

        <ol type="a">
          <li>
            a Senha e a Chave Eletrônica de identificação do usuário para acesso ao sistema são de
            uso exclusivo de seu titular, não cabendo à SELCORP ou ao órgão promotor da licitação
            nenhuma responsabilidade por eventuais danos ou prejuízos decorrentes de seu uso
            indevido;
          </li>
          <li>
            a exclusão de Senha ou de Chave Eletrônica poderá ser feito pela SELCORP, mediante
            solicitação escrita do representante legal do Licitante;
          </li>
          <li>
            a perda de Senha ou de Chave Eletrônica ou a quebra de seu sigilo deverá ser comunicada
            imediatamente à SELCORP, para o necessário bloqueio de acesso; e
          </li>
          <li>
            o Licitante será responsável por todas as propostas, lances de preços e transações
            efetuadas no sistema, por seu usuário, por sua conta e ordem, assumindo-os como firmes e
            verdadeiros;
          </li>
          <li>
            <strong
              >que o acesso é gratuito, mas, a realização de negócios públicos através do Sistema
              Eletrônico de Licitações Corporativas – SELCORP implica em pagamento de taxas de
              utilização, conforme previsto no Anexo I deste termo.</strong
            >
          </li>
          <li>
            <strong
              >autoriza a SELCORP a expedir boleto(s) de cobrança bancária referente às taxas de
              utilização QUANDO VENCEDOR DE CERTAME, nos prazos e condições definidos no Anexo I
              desse termo.</strong
            >
          </li>
          <li>
            <strong
              >o não pagamento do(s) boleto(s) no vencimento indicado no mesmo, acarretará o
              bloqueio imediato do acesso, impedindo a participação em novas licitações eletrônicas
              promovidas pelo Sistema Eletrônico de Licitações Corporativas – SELCORP.</strong
            >
          </li>
          <li>
            <strong
              >o não pagamento da taxa ensejará a sua inclusão no cadastro de inadimplentes da
              SELCORP, nos Serviços de Proteção de Crédito e no SERASA, bem como o bloqueio imediato
              do acesso e, posterior cancelamento de sua Senha ou de Chave Eletrônica.</strong
            >
          </li>
        </ol>
      </div>

      <h6 class="subtitle is-6 margin-1">
        CLÁUSULA QUARTA
      </h6>
      <div class="content fourth-clause" :class="{ 'justify-align': justify }">
        <span class="clause-span"
          >O Licitante outorga plenos poderes ao(s) representante(s) legal(is) que indicar na forma
          desse termo, para:</span
        >

        <ol type="a">
          <li>
            declarar que conhece e atende as condições de habilitação previstas nos Editais;
          </li>
          <li>
            apresentar lances de preço;
          </li>
          <li>apresentar manifestação sobre os procedimentos adotados pelo pregoeiro;</li>
          <li>solicitar informações via sistema eletrônico;</li>
          <li>interpor recursos contra atos do pregoeiro;</li>
          <li>apresentar e retirar documentos;</li>
          <li>solicitar e prestar declarações e esclarecimentos;</li>
          <li>assinar documentos relativos às propostas; e</li>
          <li>
            praticar todos os atos em direito admitidos para o bom e fiel cumprimento do presente
            mandato, que não poderá ser substabelecido.
          </li>
        </ol>
      </div>

      <h6 class="subtitle is-6 margin-1">
        CLÁUSULA QUINTA
      </h6>
      <div class="content fifth-clause" :class="{ 'justify-align': justify }">
        <span class="clause-span"
          >O presente termo será válido por prazo indeterminado, tendo em vista, que o acesso ao
          sistema é gratuito e, apenas os negócios realizados gerarão obrigações com as taxas de
          utilização, logo, o Licitante poderá solicitar, a qualquer tempo, mediante comunicação
          expressa, sem prejuízo das responsabilidades assumidas durante o prazo de vigência ou
          decorrentes de negócios em andamento, a exclusão de seu login e senha de acesso.</span
        >
      </div>

      <h6 class="subtitle is-6 margin-1">
        CLÁUSULA SEXTA
      </h6>
      <div class="content sixth-clause" :class="{ 'justify-align': justify }">
        <span class="clause-span"
          >A desobediência a qualquer uma das cláusulas desse termo e seus anexos, acarretará o
          bloqueio imediato do acesso ao Sistema Eletrônico de Licitações Corporativas – SELCORP, da
          parte inadimplente.</span
        >
      </div>

      <div class="declaration-bidder" :class="{ 'justify-align': justify }">
        <p>
          O Licitante DECLARA que concorda e ratifica todas as cláusulas contidas neste termo de
          contrato de prestação de serviços para uso da plataforma eletrônica e seu anexo I, bem
          como, declara ciência do teor do termo de condições de uso e de acesso ao Sistema
          Eletrônico de Licitações Corporativas – SELCORP e, responsabiliza-se pelo cumprimento
          integral de suas cláusulas e demais condições.
        </p>

        <p class="declaration-state">São Paulo – Brasil.</p>
      </div>

      <h4 class="title is-6 has-text-centered">
        ANEXO I AO TERMO DE CONTRATO DE PRESTAÇÃO DE SERVIÇOS PARA USO DA PLATAFORMA ELETRÔNICA DE
        LICITAÇÕES CORPORATIVAS - SELCORP
      </h4>
      <h4 class="title is-6 has-text-centered">
        CUSTO PELA UTILIZAÇÃO DO SISTEMA
      </h4>

      <div class="attachment-one indentation-1" :class="{ 'justify-align': justify }">
        <ol type="1">
          <li class="spacing-half">
            A cobrança pela utilização do Sistema Eletrônico de Licitações Corporativas – SELCORP,
            será realizada apenas do Licitante Vencedor e quando homologado e/ou ratificado os
            Pregões Eletrônicos e as Dispensas Eletrônicas de Licitações.
          </li>

          <li>
            <p class="spacing-half">
              O valor dos custos pela utilização do sistema será calculado da seguinte forma e, está
              legalmente previsto no inciso III do artigo 5º da Lei Federal nº 10.520/2002, que
              institui a modalidade pregão e previu o uso de tecnologia da informação no
              processamento das licitações públicas:
            </p>

            <ol class="indentation-2" type="a">
              <li>
                <p class="spacing-half">
                  Licitações para contratações imediatas de materiais e serviços:
                </p>

                <ol class="indentation-one-half" type="i">
                  <li class="spacing-half">
                    1% (um por cento) sobre o valor total do(s) item(ns) ou lote(s) vencido(s) de
                    até R$ 999.999,99 (novecentos e noventa e nove mil e novecentos e noventa e nove
                    reais e noventa e nove centavos), com vencimento em 45 (quarenta e cinco) dias
                    após a adjudicação –
                    <strong
                      >limitado ao teto máximo de R$ 600,00 (seiscentos reais) por item(ns) ou
                      lote(s) vencido(s)</strong
                    >, cobrados mediante boleto bancário em favor da SELCORP LICITAÇÕES S/A.
                  </li>

                  <li class="spacing-half">
                    0,2% (zero, vinte por cento) sobre o valor total do(s) item(ns) ou lote(s)
                    vencido(s) a partir de R$ 1.000.000,00 (um milhão de reais), com vencimento em
                    45 (quarenta e cinco) dias após a adjudicação –
                    <strong
                      >limitado ao teto máximo de R$ 2.000,00 (dois mil reais) por item(ns) ou
                      lote(s) vencido(s)</strong
                    >, cobrados mediante boleto bancário em favor da SELCORP LICITAÇÕES S/A.
                  </li>
                </ol>
              </li>

              <li>
                <p class="spacing-half">
                  Licitações para a constituição de atas de registro de preços, para contratações
                  futuras:
                </p>

                <ol class="indentation-one-half" type="i">
                  <li class="spacing-half">
                    0,75% (zero, setenta e cinco por cento) sobre o valor total do(s) item(ns) ou
                    lote(s) vencido(s) de até R$ 999.999,99 (novecentos e noventa e nove mil e
                    novecentos e noventa e nove reais e noventa e nove centavos), com vencimento em
                    45 (quarenta e cinco) dias após a adjudicação –
                    <strong
                      >limitado ao teto máximo de R$ 600,00 (seiscentos reais) por item(ns) ou
                      lote(s) vencido(s)</strong
                    >, cobrados mediante boleto bancário em favor da SELCORP LICITAÇÕES S/A.
                  </li>
                  <li class="spacing-half">
                    0,15% (zero, quinze por cento) sobre o valor total do(s) item(ns) ou lote(s)
                    vencido(s), a partir de R$ 1.000.000,00 (um milhão de reais), com vencimento em
                    45 (quarenta e cinco) dias após a adjudicação –
                    <strong
                      >limitado ao teto máximo de R$ 2.000,00 (dois mil reais) por item(ns) ou
                      lote(s) vencido(s)</strong
                    >, cobrados mediante boleto bancário em favor da SELCORP LICITAÇÕES S/A.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            O não pagamento do(s) boleto(s) gerados pela forma de cobrança acima mencionados
            sujeitam o Licitante Vencedor ao pagamento de multa de 2% e juros moratórios de 1% ao
            mês, assim como ensejará a sua inclusão no cadastro de inadimplentes da SELCORP, nos
            Serviços de Proteção de Crédito e no SERASA, bem como o bloqueio imediato do acesso e,
            posterior cancelamento de sua Senha ou de Chave Eletrônica.
          </li>
        </ol>
      </div>

      <div class="notice-possible-change">
        <p><b>*Contrato sujeito a alterações sem aviso prévio.</b></p>
      </div>
    </div>

    <div class="service-contract-form-container">
      <div class="service-contract-download">
        <a download :href="fileServer('contrato.pdf', 'files')" target="_blank">
          Baixe aqui o contrato entre a sua empresa e a SelCorp</a
        >
      </div>

      <form @keyup.enter="registrationCompany">
        <b-field
          class="service-contract-field"
          :type="campoInvalido('accepted', 'contract') ? 'is-danger' : ''"
          :message="
            campoInvalido('accepted', 'contract')
              ? 'Você deve concordar com os termos do contrato de serviço'
              : ''
          "
        >
          <div class="service-contract-checkbox-container">
            <b-checkbox
              class="service-contract-checkbox"
              v-model="$v.contract.accepted.$model"
              :disabled="disableElements"
              >Concordo com os termos do contrato de serviço.
            </b-checkbox>

            <b-tooltip
              v-if="displayHelpTooltip"
              label="Leia todo o contrato"
              position="is-top"
              size="is-medium"
              ><b-icon class="custom-help" icon="help-circle" type="is-primary"></b-icon>
            </b-tooltip>
          </div>
        </b-field>

        <b-button
          expanded
          type="is-primary"
          :loading="loading"
          @click.prevent.stop="acceptServiceContract()"
        >
          Continuar
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import field from '@/mixins/formulario';
import { fileServer } from '@/utils/functions';

export default {
  name: 'ServiceContract',
  mixins: [field],
  data() {
    const contract = {
      accepted: null,
    };

    return {
      contract,
      fileServer,
      loading: false,
      disableElements: true,
    };
  },
  validations: {
    contract: {
      accepted: { required },
    },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapGetters('app', { mobile: 'mobile' }),
    displayHelpTooltip() {
      return this.windowWidth > 576;
    },
    justify() {
      return this.windowWidth > 960;
    },
  },
  methods: {
    ...mapActions('companies', ['acceptCompanyServiceContract']),
    async acceptServiceContract() {
      if (this.validate()) {
        this.loading = true;
        try {
          await this.acceptCompanyServiceContract({ companyId: this.company.id });
          this.$alerta('Contrato de serviço aceito', 'is-success');
          this.$router.push({ name: 'CompanyValidation', params: { id: this.company.id } });
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    handleScroll(contract) {
      if (contract.scrollHeight - contract.scrollTop <= contract.clientHeight + 50) {
        this.disableElements = false;
      }
    },
    validate() {
      if (this.$v.contract.$invalid || !this.contract.accepted) {
        this.contract.accepted = null;
        this.$v.contract.$touch();
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-one {
  margin-bottom: 2rem;
}

.clause-span {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.clause-span {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.declaration-bidder {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.declaration-state {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.justify-align {
  text-align: justify;
}

.indentation-one-half {
  margin-left: 1.5rem;
}

.indentation-1 {
  margin-left: 1rem;
}

.indentation-2 {
  margin-left: 2rem;
}

.margin-1 {
  margin: 1.5rem 0;
}

.notice-possible-change {
  margin-top: 3rem;
}

ol li {
  text-indent: 0.4rem;
}

.service-contract-box {
  height: 270px;
  overflow: hidden;
  overflow-y: auto;
}

.service-contract-checkbox {
  display: flex !important;
}

.service-contract-checkbox-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px;
  display: flex;
  justify-content: space-between;
}

.service-contract-field {
  margin-top: 1rem;
  margin-bottom: 1.5rem !important;
}

.service-contract-form-container {
  margin-top: 2rem;
}

.spacing {
  margin-bottom: 1rem;
}

.spacing-half {
  margin-bottom: 0.5rem;
}

@media (min-width: 1500px) {
  .service-contract-box {
    height: 350px;
  }
}
</style>
