<template>
  <div class="column-direction-touch">
    <section class="bm-2">
      <div class="box">
        <div class="level detail-item-column-direction">
          <div class="level-item has-text-centered is-flex-shrink-1">
            <div class="level-item-container">
              <p class="heading">Fornecedor</p>
              <p>
                <strong>{{ qualificationCompany.socialDenomination }}</strong>
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-flex-shrink-1">
            <div class="level-item-container">
              <p class="heading">Item</p>
              <p>
                <strong>{{ item.identificador }}</strong>
              </p>
            </div>
          </div>

          <div v-if="item.item.exclusivo" class="level-item has-text-centered is-flex-shrink-1">
            <div class="level-item-container">
              <p class="heading">Exclusivo</p>
              <b-icon icon="check" size="is-small" type="is-success" v-show="item.item.exclusivo" />
            </div>
          </div>

          <div
            class="level-item has-text-centered is-flex-grow-1 custom-level-item"
            :class="{ 'description-level-item': !descriptionText }"
          >
            <div class="level-item-container elipsis-description" ref="description-link">
              <p class="heading">Descrição</p>
              <a
                :class="{ 'justify-align': justify }"
                @click.stop.prevent="openDescription = !openDescription"
                >{{ item.item.descricao }}</a
              >
            </div>

            <div v-show="descriptionText">
              <p class="heading">Descrição</p>
              <p class="description-text" ref="description-text">
                <strong>{{ item.item.descricao }}</strong>
              </p>
            </div>
          </div>

          <div class="level-item has-text-centered is-flex-shrink-1">
            <div class="level-item-container">
              <p class="heading">Unidade</p>
              <p>
                <strong>{{ item.unidade.nome }}</strong>
              </p>
            </div>
          </div>

          <div class="level-item has-text-centered is-flex-shrink-1">
            <div class="level-item-container">
              <p class="heading">Quantidade</p>
              <p>
                <strong>{{ item.quantidade }}</strong>
              </p>
            </div>
          </div>

          <div class="level-item has-text-centered">
            <button
              class="button is-primary detail-item-back-button"
              type="button"
              @click.stop.prevent="backToItems()"
            >
              Voltar
            </button>
          </div>
        </div>

        <div v-if="openDescription" class="complete-description">
          <p>{{ item.item.descricao }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DetailItem',
  props: {
    item: Object,
    qualificationCompany: Object,
    situation: Number,
  },
  data() {
    return {
      descriptionText: false,
      openDescription: false,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    justify() {
      return this.windowWidth > 960;
    },
  },
  methods: {
    backToItems() {
      this.$emitBus('voltar');
    },
    verifyOverflowed() {
      const descriptionLink = this.$refs['description-link'];
      const descriptionText = this.$refs['description-text'];
      const descriptionOverflowed = descriptionLink.offsetWidth < descriptionLink.scrollWidth;
      if (descriptionOverflowed) {
        descriptionText.hidden = true;
      } else {
        this.descriptionText = true;
        descriptionLink.hidden = true;
      }
    },
  },
  async created() {
    this.$nextTick(() => {
      this.verifyOverflowed();
    });
  },
};
</script>

<style lang="scss" scoped>
.bm-2 {
  margin-bottom: 2rem;
}

.complete-description {
  font-size: 0.88rem;
  margin-top: 2rem;
  text-align: justify;
}

.custom-level-item {
  flex-basis: 25%;
  min-width: 1px;
}

.description-text {
  white-space: nowrap;
}

.elipsis-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.justify-align {
  text-align: justify;
}

.level-item-container {
  margin: 0 2rem;
}

@media (min-width: 769px) and (max-width: 1120px) {
  .detail-item-column-direction {
    display: block;
    flex-direction: column !important;
  }

  .detail-item-back-button {
    margin-top: 0.25rem;
  }

  .level-item-container {
    margin: 0.5rem 0;
  }
}

@media (max-width: 768px) {
  .level-item-container {
    margin: unset;
  }
}
</style>
