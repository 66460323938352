import { render, staticRenderFns } from "./DispensaComprador.vue?vue&type=template&id=08d14c70&scoped=true&"
import script from "./DispensaComprador.vue?vue&type=script&lang=js&"
export * from "./DispensaComprador.vue?vue&type=script&lang=js&"
import style0 from "./DispensaComprador.vue?vue&type=style&index=0&id=08d14c70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d14c70",
  null
  
)

export default component.exports