/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { SnackbarProgrammatic as Snackbar } from 'buefy';

function snackbar(
  message,
  type = 'is-danger',
  position = 'is-bottom',
  indefinite = false,
  duration = 5000,
) {
  Snackbar.open({
    duration,
    indefinite,
    message,
    position,
    queue: false,
    type,
  });
}

const plugin = {
  install(vue) {
    vue.prototype.$snackbar = snackbar;
  },
};

Vue.use(plugin);
