<template>
  <div class="send-date-time-container">
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h3 class="title has-text-white">Data de Reabertura</h3>
          <div class="box">
            <form>
              <div class="suspension-date-container">
                <strong>Suspenso em: </strong>{{ formatDateTime(automaticSuspensionDate) }}
              </div>

              <div>
                <AppDateTime
                  ref="datetime"
                  :menorData="minimumDate"
                  :maiorData="maximumDate"
                  :workDays="true"
                  @datetime="insertDateTime"
                />
              </div>

              <div class="buttons-container">
                <b-button
                  class="mr-1"
                  type="is-success"
                  :loading="loading"
                  @click.stop.prevent="validate"
                >
                  {{ successTextButton }}
                </b-button>

                <b-button @click.stop.prevent="closeModal()">
                  Cancelar
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { formatarDataSegundo } from '@/utils/format';
import AppDateTime from './AppDateTime';

export default {
  name: 'AppSendDateTime',
  props: {
    automaticSuspensionDate: { type: Date, default: null },
    loading: { type: Boolean, default: false },
    maximumDate: { type: Date, default: null },
    minimumDate: { type: Date, default: null },
  },
  data() {
    return {
      actionSuccess: false,
      dateTime: null,
      formatDateTime: formatarDataSegundo,
    };
  },
  components: {
    AppDateTime,
  },
  computed: {
    successTextButton() {
      return this.actionSuccess ? 'Confirmar' : 'Inserir';
    },
  },
  methods: {
    closeModal() {
      this.$parent.onCancel();
      this.$parent.close();
    },
    insertDateTime(dateTime) {
      this.dateTime = dateTime;
    },
    validate() {
      if (this.$refs.datetime.$v.data.$invalid || this.$refs.datetime.invalidDateTime) {
        this.$refs.datetime.$v.data.$touch();
        return;
      }
      if (!this.actionSuccess) {
        this.actionSuccess = true;
        return;
      }

      this.$parent.$emit('send-date-time', this.dateTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  margin-top: 1rem;
}

.send-date-time-container {
  margin-bottom: 40vh;
}

.suspension-date-container {
  margin-bottom: 1.5rem;
}
</style>
