<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <form>
      <b-field grouped class="is-flex-touch column-direction-touch">
        <b-field
          expanded
          label="Documento"
          :class="{ 'custom-document-select': customDocument }"
          :type="campoInvalido('type', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('type', 'data') ? 'Documento é um campo obrigatório' : ''"
        >
          <b-select
            expanded
            placeholder="Selecione um documento requerido"
            v-model="$v.data.type.$model"
            @click.native="$v.data.type.$touch()"
            @input.native="event => selectInputName(event.target.value)"
          >
            <option
              v-for="documentType in filteredDocumentTypes"
              :key="documentType.id"
              :value="documentType.type"
            >
              {{ documentType.type }}
            </option>
          </b-select>
        </b-field>

        <b-field
          expanded
          label="Nome"
          v-if="customDocument"
          :class="{ 'custom-input-name': customDocument }"
          :type="campoInvalido('name', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('name', 'data') ? 'Nome é um campo obrigatório' : ''"
        >
          <b-input
            expanded
            custom-class="uppercase"
            maxlength="235"
            ref="name"
            placeholder="Insira um nome"
            v-model="$v.data.name.$model"
            @input="value => converterMaiusculo('name', value, 'data')"
            @blur="event => converterMaiusculo('name', event.target.value, 'data')"
          ></b-input>
        </b-field>

        <b-field class="custom-save-button" label="Salvar">
          <button
            class="button is-primary is-fullwidth adicionar"
            @click.prevent.stop="saveRequiredDocument()"
          >
            Salvar
          </button>
        </b-field>
      </b-field>
    </form>

    <hr />

    <b-table
      hoverable
      paginated
      v-if="requiredDocuments.length"
      :per-page="8"
      :data="requiredDocuments"
    >
      <b-table-column label="Documento Requerido" v-slot="props">
        {{ props.row.type }}
      </b-table-column>

      <b-table-column centered label="Opções" width="120" v-slot="props">
        <b-tooltip label="Excluir" position="is-right">
          <button
            class="button is-danger"
            :disabled="disable.delete"
            @click.prevent.stop="deleteRequiredDocument(props.row.id)"
          >
            <b-icon icon="delete" />
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { required, requiredIf } from 'vuelidate/lib/validators';

import campo from '@/mixins/formulario';

export default {
  name: 'RequiredDocuments',
  mixins: [campo],
  data() {
    const data = {
      type: null,
      name: null,
    };
    const disable = {
      delete: false,
    };
    const loading = {
      general: false,
    };

    return {
      data,
      disable,
      loading,
    };
  },
  validations: {
    data: {
      type: { required },
      name: {
        required: requiredIf(function validateCustomDocument() {
          return this.customDocument;
        }),
      },
    },
  },
  computed: {
    ...mapState('documentTypes', ['documentTypes']),
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
    }),
    customDocument() {
      return ['Declarações', 'Outros Documentos'].indexOf(this.data.type) > -1;
    },
    filteredDocumentTypes() {
      return this.documentTypes
        .filter(documentType => documentType.required)
        .reduce((documents, document) => {
          if (!document.unique) {
            documents.push(document);
          } else {
            const exists = this.requiredDocuments.find(element => element.type === document.type);
            if (!exists) documents.push(document);
          }
          return documents;
        }, [])
        .sort((a, b) => a.type.localeCompare(b.type));
    },
  },
  methods: {
    ...mapActions('documentTypes', ['fetchDocumentTypes']),
    ...mapActions('requiredDocuments', [
      'addRequiredDocument',
      'fetchRequiredDocuments',
      'removeRequiredDocument',
    ]),
    ...mapActions([
      'alternarEmPublicacaoPregao',
      'atualizarOfertaCompraEdital',
      'buscarOfertaCompra',
    ]),
    async deleteRequiredDocument(id) {
      this.disable.delete = true;
      try {
        await this.removeRequiredDocument({ auctionId: this.auction.id, requiredDocumentId: id });
        this.$alerta('Documento requerido removido', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao remover documento requerido', 'is-danger');
      }
      this.disable.delete = false;
    },
    async saveRequiredDocument() {
      if (this.validar('data')) {
        const requiredType = this.customDocument
          ? `${this.data.name} (${this.data.type})`
          : this.data.type;
        const requiredTypeExists = this.requiredDocuments.some(
          element => element.type === requiredType,
        );
        if (requiredTypeExists) {
          this.$alerta('Documento requerido já adicionado', 'is-danger');
          return;
        }

        this.loading.general = true;
        try {
          await this.addRequiredDocument({
            auctionId: this.auction.id,
            type: requiredType,
          });
          this.data.type = null;
          this.data.name = null;
          this.$alerta('Documento requerido adicionado', 'is-success');
        } catch (error) {
          this.$alerta('Erro ao adicionar documento requerido', 'is-danger');
        }

        this.$v.data.$reset();
        this.loading.general = false;
      }
    },
    selectInputName(value) {
      if (['Declarações', 'Outros Documentos'].indexOf(value) > -1) {
        setTimeout(() => {
          this.$refs.name.focus();
        }, 100);
      }
    },
  },
  async created() {
    this.loading.general = true;
    try {
      await Promise.all([
        !this.auction.id ? this.buscarOfertaCompra(this.$route.params.id) : Promise.resolve(),
      ]);
      await Promise.all([
        !this.auction.id ? this.buscarOfertaCompra(this.$route.params.id) : Promise.resolve(),
        this.fetchRequiredDocuments(this.$route.params.id),
        this.fetchDocumentTypes(),
      ]);
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
  beforeDestroy() {
    this.alternarEmPublicacaoPregao(false);
  },
};
</script>

<style lang="scss" scoped>
.custom-document-select {
  width: 20%;
}

.custom-input-name {
  width: 70%;
}

.custom-save-button {
  width: 78px;
}
</style>
