import Big from 'big.js';
import { mapGetters, mapActions, mapState } from 'vuex';

import { funcoes } from '@/utils/enumerados';

import {
  formatarValor,
  formatarPorcentagem,
  formatarNumero,
  formatarPadrao,
  formatType,
  tituloEstadoItem,
  estadoItem,
} from '@/utils/format';

export default {
  data() {
    const loading = {
      confirm: false,
      documents: false,
      general: false,
    };

    return {
      loading,
    };
  },
  filters: {
    formatarValor,
    formatarNumero,
    formatType,
    tituloEstadoItem,
    estadoItem,
    formatarPadrao,
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters('proposals', { proposals: 'obterPropostasUsuarioLogado' }),
    ...mapState('auctionsBidders', ['userLoggedBidder']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
      function: 'getFunction',
      deadlines: 'obterOfertaCompraPrazos',
    }),
    adherenceStep() {
      return this.deadlines.some(prazo => prazo.prazo_id === 13);
    },
    authorizedBuyer() {
      return this.crier || this.authority;
    },
    bidStarted() {
      return this.items.some(
        item => ['LANCE', 'LANCE-RANDOMICO', 'LANCE-FECHADO'].indexOf(item.estado) > -1,
      );
    },
    bidsStep() {
      return this.deadlines.some(prazo => prazo.prazo_id === 8);
    },
    biggestDiscount() {
      return this.negotiationType === 3;
    },
    buyer() {
      return this.company.buyer && this.auction.comprador.id === this.company.id;
    },
    discloseReferential() {
      return this.auction.divulgar_referencial;
    },
    displayCompany() {
      return (
        (this.auctionSituation === 17 && this.enablingDeadline) ||
        [9, 10, 11, 15, 16].indexOf(this.auctionSituation) > -1
      );
    },
    displayFooter() {
      return (
        (this.bidder && this.auctionSituation === 6 && this.bidStarted) ||
        (this.auctionSituation === 7 && this.preferenceFilter) ||
        (this.auctionSituation === 8 && this.negotiationFilter) ||
        (this.auctionSituation === 9 && this.enablingFilter)
      );
    },
    displayReductionColumn() {
      return [6, 13, 14, 15, 16, 17].indexOf(this.auctionSituation) > -1;
    },
    displayReferralColumn() {
      return (
        this.authorizedBuyer ||
        this.biggestDiscount ||
        (this.auctionSituation > 7 && this.negotiationDeadline && this.discloseReferential)
      );
    },
    displaySizeColumn() {
      return (
        [6, 7, 8].indexOf(this.auctionSituation) > -1 ||
        (this.auctionSituation === 17 && !this.enablingDeadline)
      );
    },
    displayStatusColumn() {
      return this.auctionSituation > 1 && this.auctionSituation < 19;
    },
    enablingDeadline() {
      return this.deadlines.some(prazo => prazo.prazo_id === 11);
    },
    enablingFilter() {
      return this.items.find(
        item =>
          item.estado === 'HABILITACAO' &&
          this.userLoggedBidder.alias === item.menor_lance.fornecedor,
      );
    },
    exclusiveItem() {
      return this.items.some(item => item.item.exclusivo);
    },
    exclusiveNotice() {
      return this.auction.tipo_edital === 'RESERVADO';
    },
    justify() {
      return this.windowWidth > 960;
    },
    mobile() {
      return this.windowWidth < 769;
    },
    negotiationFilter() {
      return this.items.some(
        item =>
          item.estado === 'NEGOCIACAO' &&
          this.userLoggedBidder.alias === item.menor_lance.fornecedor,
      );
    },
    negotiationDeadline() {
      return this.deadlines.some(prazo => prazo.prazo_id === 10);
    },
    negotiationType() {
      return this.auction.tipo_negociacao.id;
    },
    auctionSituation() {
      return this.auction.situacao.id;
    },
    preferenceFilter() {
      return this.items.some(
        item =>
          item.estado === 'PREFERENCIA-INICIADA' &&
          this.userLoggedBidder.alias === item.preferencia.fornecedor,
      );
    },
    preferenceStarted() {
      return this.items.some(item => item.estado === 'PREFERENCIA-INICIADA');
    },
    provider() {
      return this.function === funcoes.FORNECEDOR;
    },
    regionalPreferenceEnabled() {
      return this.exclusiveNotice && this.auction.preferencia_regional;
    },
    seller() {
      return this.function === funcoes.VENDEDOR;
    },
    crier() {
      return this.function === funcoes.PREGOEIRO;
    },
    authority() {
      return this.function === funcoes.AUTORIDADE;
    },
    bidder() {
      return this.function === funcoes.LICITANTE;
    },
    lowerBidColor() {
      return item => {
        if (!item.menor_lance.fornecedor || ['FRACASSADA', 'REVOGADO'].indexOf(item.estado) > -1) {
          return '';
        }

        if (this.buyer) {
          if (!item.referencia && !item.total) {
            if (this.biggestDiscount && item.menor_lance.percentual === 0) {
              return 'is-danger';
            }
            return 'is-success';
          }

          if (this.negotiationType === 1 && item.referencia >= item.menor_lance.valor) {
            return 'is-success';
          }
          if ([2, 3].indexOf(this.negotiationType) > -1 && item.total >= item.menor_lance.valor) {
            return 'is-success';
          }
          return 'is-danger';
        }

        if (this.bidder) {
          if (item.menor_lance.fornecedor === this.userLoggedBidder.alias) {
            return 'is-success';
          }
          return 'is-danger';
        }

        return 'is-dark';
      };
    },
    visitor() {
      return this.function === funcoes.VISITANTE;
    },
  },
  methods: {
    ...mapActions('auctionsDocuments', ['fetchAuctionBidderDocuments']),
    ...mapActions('requiredDocuments', ['fetchRequiredDocuments']),
    ...mapActions('itens', ['abdicarPreferencia', 'adesaoMenorPrecoItem']),
    bidderExists(item) {
      return item.menor_lance.fornecedor ? item.menor_lance.fornecedor : 'Nenhum fornecedor';
    },
    displayDocumentsList(row) {
      return (
        this.auctionSituation > 8 &&
        this.auctionSituation !== 13 &&
        this.enablingDeadline &&
        row.estado !== 'DESERTO' &&
        ((!this.biggestDiscount && row.menor_lance.valor !== 0) || this.biggestDiscount)
      );
    },
    displayEyeIcon(id) {
      const container = this.$refs[`description-container-${id}`];
      if (container) {
        return container.scrollWidth >= container.parentElement.scrollWidth;
      }

      return false;
    },
    displayOffersList(row) {
      return (
        this.auctionSituation > 4 &&
        this.auctionSituation <= 18 &&
        this.auctionSituation !== 13 &&
        row.estado !== 'DESERTO'
      );
    },
    displayPreference(preferenciaFornecedor) {
      return preferenciaFornecedor && [6, 7, 17].indexOf(this.auctionSituation) > -1;
    },
    formatDiscountPercentage(item) {
      return this.verifyZeroValue(item.menor_lance.percentual)
        ? `${formatarPorcentagem(item.menor_lance.percentual)}%`
        : 'Nenhum Valor';
    },
    formatMinorBid(row) {
      if (row.total) {
        return formatarValor(row.menor_lance.valor);
      }

      return formatarValor(0);
    },
    formatPercentageReduction(row) {
      return `${formatarPorcentagem(row.reducaoLancesPorcentagem || 0)}%`;
    },
    formatPreferenceBid(row) {
      if (row.total) {
        return formatarValor(row.preferencia.valor);
      }

      return formatarValor(0);
    },
    formatPreferenceDiscount(item) {
      return this.verifyZeroValue(item.preferencia.percentual) && item.estado !== 'REVOGADO'
        ? `${formatarPorcentagem(item.preferencia.percentual)}%`
        : '';
    },
    formatReference(item) {
      return (this.negotiationType === 1 ? item.referencia : item.total) || 0;
    },
    formatWinnerCompanySize(item) {
      if (item.menor_lance.fornecedor_porte === 'COOPERATIVA_PREFERENCIA') {
        return 'COOP. C/DIR. PREF.';
      }

      if (item.menor_lance.fornecedor_porte) {
        return item.menor_lance.fornecedor_porte;
      }
      return 'N/A';
    },
    minorBidExists(item) {
      return item.menor_lance.valor ? item.menor_lance.valor : 'Nenhum Lance';
    },
    openDescription(id) {
      const tag = this.$refs[`description-container-${id}`];
      if (tag) {
        const { classList } = tag;
        const contains = classList.contains('elipsis-column');
        if (contains) {
          classList.remove('elipsis-column');
          return;
        }
        classList.add('elipsis-column');
      }
    },
    preferenceBidExists(item) {
      return item.preferencia.fornecedor && item.estado !== 'REVOGADO'
        ? item.preferencia.fornecedor
        : '';
    },
    preferenceSizeExists(item) {
      if (!item.preferencia.fornecedor || item.estado === 'REVOGADO') return '';
      if (this.exclusiveNotice && this.auction.preferencia_regional) {
        return 'PREFERÊNCIA REGIONAL';
      }

      return 'PREFERÊNCIA';
    },
    preferenceValueExists(item) {
      return item.preferencia.valor && item.estado !== 'REVOGADO' ? item.preferencia.valor : '';
    },
    async sendDocuments(item) {
      const bidderId = this.adherenceStep
        ? this.userLoggedBidder.id
        : item.menor_lance.fornecedor_id;
      await Promise.all([
        this.fetchRequiredDocuments(this.auction.id),
        this.fetchAuctionBidderDocuments({ auctionId: this.auction.id, bidderId, itemId: item.id }),
      ]);
      const company = {
        alias: this.userLoggedBidder.alias,
        bidderId,
        socialDenomination: this.company.socialDenomination,
      };

      this.$emitBus('documentos', { item, company });
    },
    verifyOverflowed() {
      const overflowed = this.items.some(item => this.displayEyeIcon(item.id));

      if (!overflowed) {
        const refs = Object.values(this.$refs);
        const rows = refs.filter(
          ref => ref && ref.parentNode && ref.parentNode.className === 'elipsis-size',
        );
        rows.forEach(row => row.parentNode.classList.remove('elipsis-size'));
      } else {
        this.descriptionKey += 1;
      }
    },
    verifyZeroValue(valor) {
      if ([null, undefined].indexOf(valor) === -1) {
        return new Big(valor).gte(0);
      }

      return false;
    },
    viewOffers(item) {
      this.$emitBus('detalhe', {
        tipo: 'oferta',
        item,
        ofertaCompraId: this.auction.id,
      });
    },
  },
};
