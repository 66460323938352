<template>
  <div>
    <b-field grouped>
      <b-field label="Período">
        <b-select placeholder="Período" v-model="periodoSelecionado">
          <option v-for="periodo in opcoesPeriodo" :key="periodo.id" :value="periodo.id">
            {{ periodo.nome }}
          </option>
        </b-select>
      </b-field>

      <b-field label="De" v-show="periodoPersonalizado">
        <b-datepicker
          editable
          placeholder="Data Período"
          icon="calendar-today"
          v-mascara:data
          v-model="valoresMascara.dataPeriodoInicio"
          :first-day-of-week="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('dataPeriodoInicio')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button
            class="button is-danger br-4"
            @click.prevent.stop="limparData('dataPeriodoInicio')"
          >
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>

      <b-field label="Até" v-show="periodoPersonalizado">
        <b-datepicker
          editable
          placeholder="Data Período"
          icon="calendar-today"
          v-mascara:data
          v-model="valoresMascara.dataPeriodoFim"
          :first-day-of-week="1"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('dataPeriodoFim')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button class="button is-danger br-4" @click.prevent.stop="limparData('dataPeriodoFim')">
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>

      <b-field label="Modalidades">
        <b-select placeholder="Modalidades">
          <option
            v-for="modalidade in opcoesModalidades"
            :key="modalidade.id"
            :value="modalidade.id"
          >
            {{ modalidade.nome }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Situação">
        <b-select placeholder="Situação">
          <option v-for="situacao in opcoesSituacoes" :key="situacao.id" :value="situacao.id">
            {{ situacao.nome }}
          </option>
        </b-select>
      </b-field>

      <button
        class="pesquisar button is-primary"
        :class="{ ' is-loading': loading }"
        @click.prevent="pesquisarPrecos"
      >
        Pesquisar
      </button>
    </b-field>

    <hr />

    <div class="precos" v-show="mostrarInfo">
      <div class="level">
        <div class="level-left">
          <div>
            <h1 class="primeira-compra title is-4">Primeira Compra</h1>
            <ul>
              <li><span class="precos-titulo">Data:</span> 02/01/1989</li>
              <li><span class="precos-titulo">Comprador:</span> Prefeitura Municipal de Avaré</li>
              <li><span class="precos-titulo">Valor:</span> R$ 15,20</li>
              <li><span class="precos-titulo">Quantidade:</span> 3 caixas</li>
              <li><span class="precos-titulo">Vencedor:</span> Primare Consultorias LTDA</li>
              <li><span class="precos-titulo">Cidade:</span> Avaré</li>
            </ul>
          </div>
        </div>

        <div class="level-item has-text-centered">
          <div class="info-middle">
            <div>
              <p class="heading">Preço Médio</p>
              <p class="title">R$ 21,00</p>
            </div>
            <div class="info-middle">
              <p class="heading">Quantidade</p>
              <p class="title">53</p>
            </div>
          </div>
        </div>

        <div class="level-right">
          <div>
            <h1 class="primeira-compra title is-4">Última Compra</h1>
            <ul>
              <li><span class="precos-titulo">Data:</span> 18/10/2017</li>
              <li><span class="precos-titulo">Comprador:</span> Prefeitura Municipal de Avaré</li>
              <li><span class="precos-titulo">Valor:</span> R$ 15,20</li>
              <li><span class="precos-titulo">Quantidade:</span> 3 caixas</li>
              <li><span class="precos-titulo">Vencedor:</span> Primare Consultorias LTDA</li>
              <li><span class="precos-titulo">Cidade:</span> Avaré</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import campo from '@/mixins/formulario';

export default {
  name: 'ItemPrecos',
  mixins: [campo],
  data() {
    const opcoesPeriodo = [
      { id: 1, nome: '30 dias' },
      { id: 2, nome: '60 dias' },
      { id: 3, nome: '6 meses' },
      { id: 4, nome: 'Personalizado' },
    ];
    const opcoesModalidades = [
      { id: 1, nome: 'Pregão' },
      { id: 2, nome: 'Convite' },
      { id: 3, nome: 'Dispensa' },
    ];
    const opcoesSituacoes = [
      { id: 1, nome: 'Abertas' },
      { id: 2, nome: 'Em Andamento' },
      { id: 3, nome: 'Encerradas' },
    ];
    const valoresMascara = {
      dataPeriodoInicio: new Date(),
      dataPeriodoFim: new Date(),
    };

    return {
      opcoesPeriodo,
      opcoesModalidades,
      opcoesSituacoes,
      valoresMascara,
      periodoSelecionado: Number,
      mostrarInfo: true,
      loading: true,
    };
  },
  computed: {
    periodoPersonalizado() {
      return this.periodoSelecionado === 4;
    },
  },
  methods: {
    pesquisarPrecos() {
      this.mostrarInfo = true;
    },
  },
  mounted() {
    this.valoresMascara.dataPeriodoInicio = null;
    this.valoresMascara.dataPeriodoFim = null;
  },
};
</script>

<style lang="scss">
.pesquisar {
  margin: 15px 10px;
}

.precos-titulo {
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.primeira-compra {
  height: 100%;
}

.info-middle {
  margin-top: 5px;
}
</style>
