/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { DialogProgrammatic as Dialog } from 'buefy';

function confirmacaoAlerta({
  message,
  confirmText = 'Confirmar',
  type = 'is-warning',
  hasIcon = true,
}) {
  return new Promise(resolve => {
    Dialog.alert({
      message,
      confirmText,
      type,
      hasIcon,
      onConfirm() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  });
}

const plugin = {
  install(vue) {
    vue.prototype.$confirmacaoAlerta = confirmacaoAlerta;
  },
};

Vue.use(plugin);
