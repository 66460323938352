import Vue from 'vue';
import Cleave from 'cleave.js';

import 'cleave.js/dist/addons/cleave-phone.br';
// require('cleave.js/dist/addons/cleave-phone.br');

const mascaras = {
  telefone(modifiers, region = 'BR') {
    return {
      phone: true,
      phoneRegionCode: region,
    };
  },
  valor(modifiers = {}) {
    const scale = modifiers.scale ? 4 : 2;
    return {
      prefix: !modifiers.integer && !modifiers.noPrefix ? 'R$ ' : '',
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralDecimalScale: !modifiers.integer ? scale : 0,
      numeralPositiveOnly: true,
      rawValueTrimPrefix: true,
    };
  },
  porcentagem(modifiers = {}) {
    const scale = modifiers.scale ? 4 : 2;
    return {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralIntegerScale: 3,
      numeralDecimalScale: scale || 0,
      numeralPositiveOnly: true,
      rawValueTrimPrefix: true,
      stripLeadingZeroes: false,
    };
  },
  cnpj() {
    return {
      numericOnly: true,
      delimiters: ['.', '.', '/', '-'],
      blocks: [2, 3, 3, 4, 2],
    };
  },
  cpf() {
    return {
      numericOnly: true,
      delimiters: ['.', '.', '-'],
      blocks: [3, 3, 3, 2],
    };
  },
  inscricaoEstadual() {
    return {
      numericOnly: true,
      delimiters: ['.', '.', '.'],
      blocks: [3, 3, 3, 3],
    };
  },
  cep() {
    return {
      numericOnly: true,
      delimiters: ['-'],
      blocks: [5, 3],
    };
  },
  data() {
    return {
      date: true,
      datePattern: ['d', 'm', 'Y'],
    };
  },
  hora() {
    return {
      time: true,
      timePattern: ['h', 'm'],
    };
  },
};

Vue.directive('mascara', {
  bind(el, binding) {
    const input = el.querySelector('input');
    input.vCleave = new Cleave(input, mascaras[binding.arg](binding.modifiers, binding.value));
  },
  update(el, binding) {
    if (binding.oldValue !== binding.value) {
      const input = el.querySelector('input');
      input.vCleave.destroy();
      input.vCleave = new Cleave(input, mascaras[binding.arg](binding.modifiers, binding.value));
    }
  },
  unbind(el) {
    const input = el.querySelector('input');
    input.vCleave.destroy();
  },
});
