<template>
  <section class="hero is-primary is-bold">
    <div class="hero-body">
      <div class="container is-title-fullhd" :class="{ 'is-fluid': expandTitle }">
        <h1 class="title ">{{ titulo }}</h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppTitulo',
  props: {
    titulo: String,
    expandTitle: { type: Boolean, default: false },
  },
};
</script>
