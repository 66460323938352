import fetchDocumentTypes from '@/resources/documentTypes';

const types = {
  DOCUMENTS_TYPES: 'DOCUMENTS_TYPES',
};

const mutations = {
  [types.DOCUMENTS_TYPES](state, documentTypes) {
    if (documentTypes && Array.isArray(documentTypes)) {
      state.documentTypes = documentTypes;
    }
  },
};

const actions = {
  async fetchDocumentTypes({ commit }) {
    const response = await fetchDocumentTypes();
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.DOCUMENTS_TYPES, response.data);
  },
};

const getters = {};

const state = {
  documentTypes: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
