import Vue from 'vue';

import {
  getLances as dadosLances,
  deletarLance,
  getOfertasItem as dadosOfertasItem,
} from '@/resources/ofertasCompra';

const types = {
  OFERTA_COMPRA_LANCES: 'OFERTA_COMPRA_LANCES',
  OFERTA_COMPRA_LANCES_ITEM: 'OFERTA_COMPRA_LANCES_ITEM',
  OFERTA_COMPRA_OFERTAS_ITEM: 'OFERTA_COMPRA_OFERTAS_ITEM',
  OFERTA_COMPRA_LANCES_ADICIONAR: 'OFERTA_COMPRA_LANCES_ADICIONAR',
  OFERTA_COMPRA_LANCES_EXCLUIR_ULTIMO: 'OFERTA_COMPRA_LANCES_EXCLUIR_ULTIMO',
  OFERTA_COMPRA_LANCES_ITEM_ATUALIZAR: 'OFERTA_COMPRA_LANCES_ITEM_ATUALIZAR',
  OFERTA_COMPRA_LANCES_ADESAO_ATUALIZAR: 'OFERTA_COMPRA_LANCES_ADESAO_ATUALIZAR',
  LIMPAR_OFERTA_COMPRA_LANCES: 'LIMPAR_OFERTA_COMPRA_LANCES',
  ATUALIZAR_CLASSIFICACAO_OFERTAS_ITEM: 'ATUALIZAR_CLASSIFICACAO_OFERTAS_ITEM',
  OFERTA_COMPRA_LANCES_DELETAR: 'OFERTA_COMPRA_LANCES_DELETAR',
};

const mutations = {
  [types.OFERTA_COMPRA_LANCES](state, data) {
    if (data && Array.isArray(data)) {
      state.lances = data;
    } else {
      state.lances.push(data);
    }
  },
  [types.OFERTA_COMPRA_LANCES_EXCLUIR_ULTIMO](state, data) {
    if (data) {
      const lancesFiltrados = state.lances.filter(
        lance =>
          lance.fornecedor_id === data.supplierId && lance.ofertaCompraItemId === data.itemId,
      );
      if (lancesFiltrados.length > 0) {
        const lancesOrdenados = lancesFiltrados.sort(
          (a, b) => new Date(b.dataLance) - new Date(a.dataLance),
        );
        const idLance = lancesOrdenados[0].lance_id;
        state.lances = state.lances.filter(lance => lance.lance_id !== idLance);
      }
    }
  },

  [types.OFERTA_COMPRA_LANCES_ADICIONAR](state, data) {
    if (data && Array.isArray(data)) {
      state.lances = [
        ...state.lances,
        ...data.map(lance => ({ ...lance, dataLance: lance.createdAt })),
      ];
    } else if (data && typeof data === 'object') {
      state.lances.push({ ...data, dataLance: data.createdAt });
    }
  },
  [types.OFERTA_COMPRA_LANCES_ITEM_ATUALIZAR](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const dataLance = data.shift();
        const index = state.lances.findIndex(
          lance =>
            lance.ofertaCompraItemId === dataLance.ofertaCompraItemId &&
            lance.valor === dataLance.valor,
        );
        if (index > -1) {
          Vue.set(state.lances, index, data);
        }
      }
    } else if (data && typeof data === 'object') {
      const index = state.lances.findIndex(
        lance => lance.ofertaCompraItemId === data.ofertaCompraItemId,
      );
      if (index > -1) {
        Vue.set(state.lances, index, data);
      }
    }
  },
  [types.OFERTA_COMPRA_LANCES_ADESAO_ATUALIZAR](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const dataLance = data.shift();
        const index = state.lances.findIndex(
          lance =>
            lance.ofertaCompraItemId === dataLance.ofertaCompraItemId &&
            lance.valor === dataLance.valor,
        );
        if (index > -1) {
          const novoObjeto = state.lances[index];
          novoObjeto.adesao = dataLance.adesao;
          Vue.set(state.lances, index, novoObjeto);
        }
      }
    } else if (data && typeof data === 'object') {
      const index = state.lances.findIndex(
        lance => lance.ofertaCompraItemId === data.ofertaCompraItemId,
      );
      if (index > -1) {
        const novoObjeto = state.lances[index];
        novoObjeto.adesao = data.adesao;
        Vue.set(state.lances, index, novoObjeto);
      }
    }
  },
  [types.OFERTA_COMPRA_OFERTAS_ITEM](state, data) {
    if (data && Array.isArray(data)) {
      state.ofertasItem = data;
    } else if (data && typeof data === 'object') {
      state.ofertasItem.unshift(data);
    }
    state.ofertasItem = state.ofertasItem.sort((a, b) => {
      const valor = a.valor - b.valor;
      if (valor !== 0) return valor;

      const dados = a.dados - b.dados;
      if (dados !== 0) return dados;

      return a.id - b.id;
    });
  },
  [types.ATUALIZAR_CLASSIFICACAO_OFERTAS_ITEM](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const proposal = data.shift();
        const index = state.ofertasItem.findIndex(element => element.id === proposal.id);
        if (index > -1) {
          Vue.set(state.ofertasItem, index, {
            ...state.ofertasItem[index],
            status: proposal.estado,
            justificativa: proposal.justificativa,
          });
        }
      }
    } else if (data && typeof data === 'object') {
      const index = state.ofertasItem.findIndex(element => element.id === data.id);
      if (index > -1) {
        Vue.set(state.ofertasItem, index, {
          ...state.ofertasItem[index],
          status: data.estado,
          justificativa: data.justificativa,
        });
      }
    }
  },
  [types.LIMPAR_OFERTA_COMPRA_LANCES](state) {
    state.lances = [];
    state.ofertasItem = [];
  },
  [types.OFERTA_COMPRA_LANCES_DELETAR](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const lance = data.shift();
        const index = state.lances.findIndex(lanceItem => lanceItem.lance_id === lance.id);
        if (index > -1) state.lances.splice(index, 1);
        const indexOfertasItem = state.ofertasItem.findIndex(
          lanceItem => lanceItem.id === lance.id,
        );
        if (indexOfertasItem > -1) state.ofertasItem.splice(indexOfertasItem, 1);
      }
    } else if (typeof data === 'object') {
      const index = state.lances.findIndex(lanceItem => lanceItem.lance_id === data.id);
      if (index > -1) state.lances.splice(index, 1);
      const indexOfertasItem = state.ofertasItem.findIndex(lanceItem => lanceItem.id === data.id);
      if (indexOfertasItem > -1) state.ofertasItem.splice(indexOfertasItem, 1);
    }
  },
};

const actions = {
  async buscarOfertaCompraItemOfertas({ commit }, { ofertaCompraId, itemId }) {
    const { data: ofertas } = await dadosOfertasItem(ofertaCompraId, itemId);
    commit(types.OFERTA_COMPRA_OFERTAS_ITEM, ofertas.data);
  },
  async buscarOfertaCompraLances({ commit, rootState }, { ofertaCompraId, tipo }) {
    const { logged } = rootState.users;
    if (logged) {
      const { data: lances } = await dadosLances(ofertaCompraId, tipo);
      commit(types.OFERTA_COMPRA_LANCES, lances.data);
    }
  },
  adicionarLancesEmpresa({ commit }, lances) {
    commit(types.OFERTA_COMPRA_LANCES_ADICIONAR, lances);
  },
  excluirUltimoLanceEmpresa({ commit }, lance) {
    commit(types.OFERTA_COMPRA_LANCES_EXCLUIR_ULTIMO, lance);
  },
  atualizarLancesItemEmpresa({ commit }, lances) {
    commit(types.OFERTA_COMPRA_LANCES_ITEM_ATUALIZAR, lances);
  },
  async deleteBid({ commit }, data) {
    const { ofertaCompraId, itemId, lanceId } = data;
    const { status, response } = await deletarLance(ofertaCompraId, itemId, lanceId);
    if (status !== 204) {
      throw new Error(response.message);
    }
    commit(types.OFERTA_COMPRA_LANCES_DELETAR, { id: lanceId });
  },
  atualizarLancesAdesaoEmpresa({ commit }, lances) {
    commit(types.OFERTA_COMPRA_LANCES_ADESAO_ATUALIZAR, lances);
  },
  adicionarLance({ commit }, lance) {
    commit(types.OFERTA_COMPRA_OFERTAS_ITEM, lance);
  },
  limparLancesPregao({ commit }) {
    commit(types.LIMPAR_OFERTA_COMPRA_LANCES);
  },
  updateAuctionBidsClassification({ commit }, data) {
    commit(types.ATUALIZAR_CLASSIFICACAO_OFERTAS_ITEM, data);
  },
};

const getters = {
  obterOfertaCompraLances(state) {
    return state.lances;
  },
  obterOfertaCompraItemOfertas(state) {
    return state.ofertasItem;
  },
};

const state = {
  lances: [],
  ofertasItem: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
