import {
  fetchCompanyDocuments,
  addCompanyDocument,
  removeCompanyDocument,
} from '@/resources/companies/companiesDocuments';

const types = {
  COMPANY_DOCUMENTS: 'COMPANY_DOCUMENTS',
  COMPANY_DOCUMENTS_ADD: 'COMPANY_DOCUMENTS_ADD',
  COMPANY_DOCUMENTS_REMOVE: 'COMPANY_DOCUMENTS_REMOVE',
  COMPANY_DOCUMENTS_UPDATE_UPLOAD: 'COMPANY_DOCUMENTS_UPDATE_UPLOAD',
  COMPANY_DOCUMENTS_RESET_UPLOAD: 'COMPANY_DOCUMENTS_RESET_UPLOAD',
};

const mutations = {
  [types.COMPANY_DOCUMENTS_ADD](state, data) {
    if (data && typeof data === 'object') {
      state.companyDocuments = [...state.companyDocuments, data].sort((a, b) =>
        a.type.localeCompare(b.type),
      );
    }
  },
  [types.COMPANY_DOCUMENTS_REMOVE](state, documentId) {
    if (documentId) {
      const index = state.companyDocuments.findIndex(document => document.id === documentId);
      if (index > -1) state.companyDocuments.splice(index, 1);
    }
  },
  [types.COMPANY_DOCUMENTS](state, documents) {
    if (documents && Array.isArray(documents)) {
      state.companyDocuments = documents;
    }
  },
  [types.COMPANY_DOCUMENTS_UPDATE_UPLOAD](state, progress) {
    state.companyUpload = Math.round((progress.loaded / progress.total) * 100);
  },
  [types.COMPANY_DOCUMENTS_RESET_UPLOAD](state) {
    state.companyUpload = 0;
  },
};

const actions = {
  async fetchCompanyDocuments({ commit }, companyId) {
    const response = await fetchCompanyDocuments(companyId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY_DOCUMENTS, response.data);
  },
  async addCompanyDocument({ commit }, { companyId, data }) {
    const response = await addCompanyDocument(companyId, data, {
      onUploadProgress: progress => commit(types.COMPANY_DOCUMENTS_UPDATE_UPLOAD, progress),
    });
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_DOCUMENTS_ADD, response.data);
  },
  async removeCompanyDocument({ commit }, { companyId, documentId }) {
    const response = await removeCompanyDocument(companyId, documentId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY_DOCUMENTS_REMOVE, documentId);
  },
  resetCompanyUpload({ commit }) {
    commit(types.COMPANY_DOCUMENTS_RESET_UPLOAD);
  },
};

const getters = {};

const state = {
  companyDocuments: [],
  companyUpload: 0,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
