import Vue from 'vue';

import { fetchChatMessages, fetchChatParticipants } from '@/resources/ofertasCompra';

const MESSAGES = 'MESSAGES';
const PARTICIPANTS = 'PARTICIPANTS';
const ADD_MESSAGE = 'ADD_MESSAGE';
const UPDATE_MESSAGE_DATA = 'UPDATE_MESSAGE_DATA';
const UPDATE_PARTICIPANT_STATUS = 'UPDATE_PARTICIPANT_STATUS';

const mutations = {
  [MESSAGES](state, messages) {
    if (messages && Array.isArray(messages)) {
      state.messages = messages;
    }
  },
  [PARTICIPANTS](state, participants) {
    if (participants && Array.isArray(participants)) {
      state.participants = participants;
    }
  },
  [ADD_MESSAGE](state, message) {
    if (message && typeof message === 'object') {
      state.messages.push(message);
    }
  },
  [UPDATE_MESSAGE_DATA](state, message) {
    if (message && typeof message === 'object') {
      const index = state.messages.findIndex(msg => msg.id === message.id);
      if (index > -1) {
        const newMessage = { ...state.messages[index] };
        newMessage.enviado = message.enviado;
        Vue.set(state.messages, index, newMessage);
      }
    }
  },
  [UPDATE_PARTICIPANT_STATUS](state, participant) {
    while (participant.id.length) {
      const index = state.participants.findIndex(
        element =>
          participant.id.some(id => element.id.includes(id)) &&
          participant.type.some(type => element.type.includes(type)),
      );
      if (index > -1) {
        Vue.set(state.participants, index, {
          ...state.participants[index],
          online: participant.online,
        });
      }

      participant.id.shift();
      participant.type.shift();
    }
  },
};

const actions = {
  async fetchMessages({ commit }, auctionId) {
    if (auctionId) {
      const chat = await fetchChatMessages(auctionId);
      commit(MESSAGES, chat.data.data);
    }
  },
  async fetchParticipants({ commit }, auctionId) {
    if (auctionId) {
      const chat = await fetchChatParticipants(auctionId);
      commit(PARTICIPANTS, chat.data);
    }
  },
  addMessage({ commit }, message) {
    commit(ADD_MESSAGE, message);
  },
  updateMessage({ commit }, message) {
    commit(UPDATE_MESSAGE_DATA, message);
  },
  updateParticipantStatus({ commit }, participant) {
    commit(UPDATE_PARTICIPANT_STATUS, participant);
  },
};

const getters = {};

const state = {
  messages: [],
  participants: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
