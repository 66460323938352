const CARREGAMENTO = 'CARREGAMENTO';
const WINDOW_WIDTH = 'WINDOW_WIDTH';

const mutations = {
  [CARREGAMENTO](state, data) {
    if (typeof data === 'boolean') {
      state.carregando = data;
    }
  },
  [WINDOW_WIDTH](state, data) {
    state.windowWidth = data;
  },
};

const actions = {
  carregando({ commit }, estado) {
    commit(CARREGAMENTO, estado);
  },
  updateWindowWidth({ commit }, data) {
    commit(WINDOW_WIDTH, data);
  },
};

const getters = {
  mobile(state) {
    return state.windowWidth < 769;
  },
  tablet(state) {
    return state.windowWidth < 1024;
  },
};

const state = {
  appLoading: false,
  windowWidth: window.innerWidth,
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
