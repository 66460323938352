import { adminNotAllowed, protectedPage, userProtectedPage } from '@/utils/session';

export default [
  {
    path: '/perfil/:id',
    redirect: { name: 'ProfileData' },
    name: 'Profile',
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      protectedPage(to, from, next);
      userProtectedPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile'),
    children: [
      {
        path: 'dados',
        name: 'ProfileData',
        component: () =>
          import(/* webpackChunkName: "profile-data" */ '@/views/Profile/ProfileData'),
      },
      {
        path: 'senha',
        name: 'ChangePassword',
        component: () =>
          import(/* webpackChunkName: "profile-password" */ '@/views/Profile/ChangePassword'),
      },
      {
        path: 'pin',
        name: 'ChangePin',
        component: () => import(/* webpackChunkName: "profile-pin" */ '@/views/Profile/ChangePin'),
      },
    ],
  },
];
