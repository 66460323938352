<template>
  <div>
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h3 class="title has-text-white">Justificativa</h3>
          <div class="box app-justification-box">
            <form>
              <div v-if="displayDateTime">
                <AppDateTime ref="datetime" :workDays="workDays" @datetime="insertDateTime" />
              </div>

              <b-field
                class="custom-justification-field"
                :type="campoInvalido('justification', 'data') ? 'is-danger' : ''"
                :message="
                  campoInvalido('justification', 'data')
                    ? 'Justificativa é um campo obrigatório'
                    : ''
                "
              >
                <b-input
                  type="textarea"
                  class="custom-textarea"
                  ref="justification"
                  rows="7"
                  maxlength="2000"
                  :placeholder="justificationPlaceholder"
                  v-model="data.justification"
                />
              </b-field>

              <div class="buttons-container">
                <button
                  v-if="displaySuccessButton"
                  class="button is-success rm-1 tm-2"
                  :class="{ 'is-loading': loading.success }"
                  @click.stop.prevent="validateSuccess('success')"
                >
                  {{ successTextButton }}
                </button>

                <button
                  v-if="displayFailButton"
                  class="button is-danger rm-1 tm-2"
                  :class="{ 'is-loading': loading.fail }"
                  @click.stop.prevent="validateFail('fail')"
                >
                  {{ failTextButton }}
                </button>

                <button class="button tm-2" @click.stop.prevent="closeModal()">
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';

import fieldMixin from '@/mixins/formulario';

import AppDateTime from './AppDateTime';

export default {
  name: 'AppJustification',
  mixins: [fieldMixin],
  props: {
    failText: { type: String, default: 'Desclassificar' },
    displayDateTime: { type: Boolean, default: false },
    displayFailButton: { type: Boolean, default: true },
    displaySuccessButton: { types: Boolean, default: true },
    successText: { type: String, default: 'Classificar' },
    suspensionText: { types: Boolean, default: false },
    workDays: { type: Boolean, default: false },
  },
  data() {
    const action = {
      fail: false,
      success: false,
    };
    const data = {
      justification: '',
    };
    const loading = {
      fail: false,
      success: false,
    };

    return {
      action,
      data,
      dateTime: null,
      loading,
      validation: '',
    };
  },
  components: {
    AppDateTime,
  },
  validations: {
    data: {
      justification: {
        required: requiredIf(function verifyEmptyJustification() {
          return this.validation === 'fail' && !this.data.justification;
        }),
      },
    },
  },
  computed: {
    justificationPlaceholder() {
      return this.suspensionText ? 'Justifique a suspensão.' : 'Justifique sua escolha.';
    },
    successTextButton() {
      return this.action.success ? 'Confirmar' : this.successText;
    },
    failTextButton() {
      return this.action.fail ? 'Confirmar' : this.failText;
    },
  },
  methods: {
    closeModal() {
      this.loading.fail = false;
      this.loading.success = false;
      this.$parent.onCancel();
      this.$parent.close();
    },
    insertDateTime(dateTime) {
      this.dateTime = dateTime;
    },
    validateFail(validation) {
      this.validation = validation;

      if (
        this.displayDateTime &&
        (this.$refs.datetime.$v.data.$invalid || this.$refs.datetime.invalidDateTime)
      ) {
        this.$refs.datetime.$v.data.$touch();

        if (!this.data.justification) {
          this.$v.data.$touch();
          return;
        }

        return;
      }
      if (!this.data.justification) {
        this.$v.data.$touch();
        return;
      }

      if (!this.action.fail) {
        this.action.fail = true;
      } else {
        const justification = {
          dateTime: this.dateTime,
          justification: this.data.justification,
          state: 'fail',
        };
        this.loading.fail = true;
        this.$parent.$emit('justificate', justification);
      }
    },
    validateSuccess() {
      if (!this.action.success) {
        this.action.success = true;
      } else {
        const justification = {
          justification: this.data.justification,
          state: 'success',
        };
        this.loading.success = true;
        this.$parent.$emit('justificate', justification);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.justification.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  margin-top: 1rem;
}

.custom-justification-field {
  margin-bottom: 1.5rem;
}

.custom-textarea {
  margin-top: 1rem;
  width: 350px;
}

.rm-1 {
  margin-right: 3px;
}

@media (max-width: 768px) {
  .app-justification-box {
    max-width: 90vw;
  }

  .tm-2 {
    margin-top: 10px;
  }
}
</style>
