import PregaoLista from '@/views/Pregao/PregaoLista/index';

import PregaoEditar from '@/views/Pregao/PregaoEditar';
import PregaoEditarDados from '@/views/Pregao/PregaoEditar/Dados';
import PregaoEditarAnexos from '@/views/Pregao/PregaoEditar/Anexos';
import PregaoEditarItens from '@/views/Pregao/PregaoEditar/Itens';
import PregaoEditarResponsaveis from '@/views/Pregao/PregaoEditar/Responsaveis';
import AuctionEditRequiredDocuments from '@/views/Pregao/PregaoEditar/RequiredDocuments';

import AuctionContainer from '@/views/Pregao/AuctionContainer';

import { adminNotAllowed, buyerPageProtected, editProtected } from '@/utils/session';

export default [
  {
    path: '/pregoes',
    name: 'Pregoes',
    component: PregaoLista,
    beforeEnter: adminNotAllowed,
  },
  {
    path: '/pregoes/editar/:id',
    redirect: { name: 'PregaoEditarDados' },
    name: 'PregaoEditar',
    component: PregaoEditar,
    beforeEnter: editProtected,
    children: [
      {
        path: 'dados',
        name: 'PregaoEditarDados',
        component: PregaoEditarDados,
      },
      {
        path: 'anexos',
        name: 'PregaoEditarAnexos',
        component: PregaoEditarAnexos,
      },
      {
        path: 'itens',
        name: 'PregaoEditarItens',
        component: PregaoEditarItens,
      },
      {
        path: 'documentos',
        name: 'AuctionRequiredDocuments',
        component: AuctionEditRequiredDocuments,
      },
      {
        path: 'responsaveis',
        name: 'PregaoEditarResponsaveis',
        component: PregaoEditarResponsaveis,
      },
    ],
  },
  {
    path: '/pregoes/:id',
    name: 'Pregao',
    component: AuctionContainer,
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      buyerPageProtected(to, from, next);
    },
  },
];
