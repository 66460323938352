import { api } from '../http';

const fetchInvoices = filters => {
  let params = '?';
  if (filters) {
    if (filters.status) {
      params += `status=${filters.status}&`;
    }
    if (filters.period) {
      if (filters.period.start) {
        params += `inicio=${filters.period.start.toISOString()}&`;
      }
      if (filters.period.end) {
        params += `fim=${filters.period.end.toISOString()}&`;
      }
    }
    if (filters.page) params += `pagina=${filters.page}&`;
    if (filters.perPage) params += `por_pagina=${filters.perPage}&`;
  }
  return api.get(`/payments${params}`);
};

const getInvoice = uuid => {
  return api.get(`/payments/${uuid}`);
};

const syncBills = () => {
  return api.post('/payments/sync');
};

const createInvoices = data => {
  return api.post(`/payments`, data);
};

const issueInvoice = data => {
  return api.post(`/payments/issue`, data);
};

const sendEmails = data => {
  return api.post(`/payments/email/send`, data);
};

export { fetchInvoices, createInvoices, issueInvoice, getInvoice, syncBills, sendEmails };
