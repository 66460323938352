import { api } from '../http';

export const cancelAuction = (auctionId, data) => api.patch(`/auctions/${auctionId}/cancel`, data);

export const revokeAuction = (auctionId, data) => api.patch(`/auctions/${auctionId}/revoke`, data);

export const suspendAuction = (auctionId, data) =>
  api.patch(`/auctions/${auctionId}/suspend`, data);

export const updateReopeningDate = (auctionId, data) =>
  api.patch(`/auctions/${auctionId}/reopening-date`, data);

export const fetchUserLoggedBidder = auctionId => api.get(`/auctions/${auctionId}/bidders/me`);
