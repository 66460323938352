<template>
  <div>
    <section class="section" v-if="!itemProposals.length">
      <div class="content has-text-grey has-text-centered">
        <p>Nenhuma proposta.</p>
      </div>
    </section>

    <b-table
      class="table is-fullwidth proposals-table"
      default-sort-direction="ASC"
      v-if="itemProposals.length"
      :data="itemProposals"
    >
      <b-table-column centered sortable field="data" label="Data" v-slot="props">
        <div class="date-column">
          {{ props.row.data | formatDatetime }}
        </div>
      </b-table-column>

      <b-table-column centered label="Fornecedor" v-slot="props">
        {{ displaySocialDenomination ? props.row.denominacaoSocial : props.row.fornecedor }}
      </b-table-column>

      <b-table-column centered label="Porte" v-slot="props">
        {{ formatCompanySize(props.row.fornecedor_porte) }}
      </b-table-column>

      <b-table-column centered label="Marca" v-slot="props">
        {{ props.row.marca }}
      </b-table-column>

      <b-table-column centered label="Importado" v-slot="props">
        {{ formatImported(props.row.fabricado) }}
      </b-table-column>

      <b-table-column centered label="Percentual" v-slot="props" :visible="highestDiscount">
        {{ `${formatPercentage(props.row.percentual || 0)}%` }}
      </b-table-column>

      <b-table-column
        centered
        label="Valor"
        v-slot="props"
        :visible="!highestDiscount || !!(highestDiscount && item.referencia && item.total)"
      >
        {{ props.row.valor ? formatValue(props.row.valor) : 0 }}
      </b-table-column>

      <b-table-column
        label="Proposta Inicial"
        v-slot="props"
        :visible="displayInitialProposalColumn"
      >
        <a v-if="props.row.arquivo" :href="props.row.arquivo | fileServer" download>{{
          props.row.arquivoNome
        }}</a>
        <b-tag v-else type="is-danger">Não Enviada</b-tag>
      </b-table-column>

      <b-table-column label="Status" v-slot="props">
        {{ formatStatusProposal(props.row) }}
      </b-table-column>

      <b-table-column centered label="Ações" v-slot="props" :visible="displayActions">
        <b-tooltip label="Excluir">
          <button class="button is-danger" @click.prevent.stop="removeProposal(props.row.id)">
            <b-icon icon="delete"></b-icon>
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { funcoes } from '@/utils/enumerados';
import {
  formatarDataSegundo as formatDatetime,
  formatarValor as formatValue,
  formatarPorcentagem as formatPercentage,
} from '@/utils/format';
import { fileServer } from '@/utils/functions';

export default {
  name: 'ProposalsList',
  props: {
    displaySocialDenomination: { type: Boolean, default: false },
    highestDiscount: { type: Boolean, default: false },
    item: { type: Object, default: () => {} },
    formatCompanySize: { type: Function, default: () => {} },
  },
  data() {
    return {
      formatPercentage,
      formatValue,
    };
  },
  filters: {
    formatDatetime,
    fileServer,
  },
  computed: {
    ...mapState('requiredDocuments', ['requiredDocuments']),
    ...mapGetters('proposals', ['obterPropostasItem']),
    ...mapGetters({ auction: 'obterOfertaCompra', function: 'getFunction' }),
    itemProposals() {
      return this.obterPropostasItem(this.item.id);
    },
    displayInitialProposalColumn() {
      return this.requiredDocuments.some(
        requiredDocument =>
          requiredDocument.type.toLowerCase() === 'Proposta Inicial'.toLowerCase(),
      );
    },
    displayActions() {
      return this.auction.situacao.id === 6 && this.function === funcoes.PREGOEIRO;
    },
  },
  methods: {
    ...mapActions('proposals', ['deleteProposalByCrier']),
    formatImported(fabricado) {
      return {
        BRASIL: 'Não',
        IMPORTADO: 'Sim',
      }[fabricado];
    },
    formatStatusProposal(row) {
      return {
        CLASSIFICAR: 'Classificar',
        EMPATADA: 'Empatada',
        CLASSIFICADA: `${
          row.justificativa !== 'Classificada.' ? ` - ${row.justificativa}` : 'Classificada.'
        }`,
        DESCLASSIFICADA: `Desclassificada - ${row.justificativa}`,
      }[row.estado];
    },
    async removeProposal(proposalId) {
      const proceed = await this.$confirmacao({
        message:
          'Essa ação irá excluir a proposta do item e lances (se houver) deste licitante, implicando na desistência de participação do licitante neste item. Deseja prosseguir?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-danger',
        hasIcon: true,
      });
      if (!proceed) return;
      try {
        await this.deleteProposalByCrier({
          ofertaCompraId: this.auction.id,
          itemId: this.item.id,
          propostaId: proposalId,
        });
        this.$alerta('A proposta foi excluida', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao excluir proposta', 'is-danger');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.date-column {
  min-width: fit-content;
}

@media (max-width: 1500px) {
  .proposals-table {
    font-size: 0.9rem;
  }
}
</style>
