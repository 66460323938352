import {
  fetchAuctionWatch,
  updateAuctionWatch,
} from '@/resources/electronicAuctions/auctionsOptions';

const types = {
  AUCTION_WATCH: 'AUCTION_WATCH',
};

const mutations = {
  [types.AUCTION_WATCH](state, data) {
    if (data && typeof data === 'object') {
      state.auctionWatch = data;
    }
  },
};

const actions = {
  async fetchAuctionWatch({ commit }, { auctionId }) {
    const response = await fetchAuctionWatch(auctionId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.AUCTION_WATCH, response.data);
  },
  async updateAuctionWatch({ commit }, { auctionId }) {
    const response = await updateAuctionWatch(auctionId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.AUCTION_WATCH, response.data);
  },
};

const state = {
  auctionWatch: {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
