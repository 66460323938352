<template>
  <div>
    <b-loading is-full-page :active="loading.search" can-cancel></b-loading>

    <AppTitulo titulo="Pesquisa de Itens" :expand-title="false" />

    <section class="section">
      <div class="container">
        <div class="panel">
          <div class="panel-heading">Filtros</div>
          <div class="panel-block items-filter-panel">
            <b-field expanded grouped class="column-direction-touch is-flex-grow-1">
              <b-field
                expanded
                label="Descrição"
                :type="campoInvalido('description', 'filters') ? 'is-danger' : ''"
                :message="
                  campoInvalido('description', 'filters')
                    ? 'Descrição ou código do item é um campo obrigatório'
                    : ''
                "
              >
                <b-input
                  custom-class="uppercase"
                  expanded
                  placeholder="Pesquise por descrição ou código"
                  type="search"
                  v-model="filters.description"
                  @keyup.enter.native="pesquisarDadosItens()"
                  @input.native="
                    event => {
                      event.target.value && event.target.value && $v.filters.$reset();
                    }
                  "
                  @blur="event => converterMaiusculo('description', event.target.value, 'filters')"
                />
              </b-field>

              <b-field expanded label="Tipo">
                <b-select expanded placeholder="Tipo" v-model="filters.type">
                  <option v-for="type in types" :key="type.value" :value="type.value">
                    {{ type.name }}
                  </option>
                </b-select>
              </b-field>

              <b-field class="search-field">
                <button
                  class="button is-medium is-primary is-fullwidth search-button"
                  @click.prevent.stop="pesquisarDadosItens()"
                >
                  Pesquisar
                </button>
              </b-field>
            </b-field>
          </div>
        </div>

        <div class="panel">
          <div class="panel-block is-justify-content-center">
            <section v-if="!itens.length" class="section">
              <div class="content has-text-grey">
                <p>Nenhum item.</p>
              </div>
            </section>

            <b-table
              backend-pagination
              hoverable
              v-if="itens.length"
              class="table is-fullwidth items-table"
              default-sort-direction="ASC"
              :data="itens"
            >
              <b-table-column numeric label="Código" width="40" v-slot="props">
                {{ props.row.code }}
              </b-table-column>

              <b-table-column label="Descrição" v-slot="props">
                <div
                  class="description-column is-clickable"
                  @click.stop.prevent="openDetails(props.row)"
                >
                  <a>{{ props.row.description }}</a>
                </div>
              </b-table-column>

              <b-table-column label="Tipo" v-slot="props">
                <div class="type-column">{{ props.row.type | formatType }}</div>
              </b-table-column>

              <template v-slot:footer>
                <AppPagination
                  :data-filters="{ page: filters.page, perPage: filters.perPage }"
                  :total="total"
                  @change-page="
                    page => {
                      filters.page = page;
                      pesquisarDadosItens();
                    }
                  "
                  @change-per-page="
                    perPage => {
                      filters.perPage = perPage;
                      pesquisarDadosItens();
                    }
                  "
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import AppTitulo from '@/components/AppTitulo';
import AppPagination from '@/components/AppPagination';

import { formatType } from '@/utils/format';
import campoMixin from '@/mixins/formulario';
import { itemTypes } from '@/utils/data';

export default {
  name: 'ItemPesquisa',
  mixins: [campoMixin],
  components: {
    AppTitulo,
    AppPagination,
  },
  data() {
    const filters = {
      description: '',
      type: 'ALL',
      page: 1,
      perPage: 20,
    };

    const loading = {
      search: false,
    };

    return {
      filters,
      loading,
      types: [],
    };
  },
  filters: {
    formatType,
  },
  validations: {
    filters: {
      description: { required },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'ItemNegociacoes') {
      this.limparPesquisa();
    }

    next();
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('itens', ['itens', 'total']),
    justify() {
      return this.windowWidth > 960;
    },
  },
  methods: {
    ...mapActions('itens', ['limparPesquisa', 'pesquisarItens']),
    openDetails(row) {
      this.$router.push({ name: 'Item', params: { id: row.code, item: row } });
    },
    async pesquisarDadosItens() {
      if (this.$v.filters.$invalid) {
        this.$v.filters.$touch();
        return;
      }

      this.loading.search = true;
      try {
        await this.pesquisarItens(this.filters);
      } catch (error) {
        this.$alerta('Erro ao pesquisar materiais', 'is-danger');
      }

      this.loading.search = false;
    },
  },
  created() {
    this.types = itemTypes.filter(itemType => itemType.value !== 'LOT');
  },
};
</script>

<style lang="scss" scoped>
a:hover {
  color: #2153b5;
}

.description-column {
  text-align: justify;
}

.items-table {
  font-size: 0.92rem;
  margin-top: 2rem;
}

.search-button {
  height: 40px;
}

.type-column {
  text-align: start;
}

@media (max-width: 1023px) {
  .search-button {
    margin: 0.5rem 0;
  }
}

@media (min-width: 1024px) {
  .items-filter-panel {
    padding-bottom: 15px;
  }

  .search-button {
    margin-top: 30px;
  }
}
</style>
