<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>
          <strong class="mr-2">{{ nome }}</strong>
          <small>{{ email }}</small>
          <small class="is-pulled-right">{{ data | formatarData }}</small>
        </p>
        <slot></slot>
      </div>
      <slot name="respostas"></slot>
    </div>
  </article>
</template>

<script>
import { formatarData } from '@/utils/format';

export default {
  name: 'AppMensagem',
  props: {
    nome: String,
    email: String,
    data: {
      type: Date,
      default() {
        return new Date();
      },
    },
  },
  filters: {
    formatarData,
  },
};
</script>

<style scoped>
.media-content {
  overflow: unset !important;
}
</style>
