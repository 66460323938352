<template>
  <div>
    <b-loading is-full-page :active="generalLoading" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="level">
            <div class="level-left">
              <div class="div-titulo-pregoes">
                <h1 class="title">Pregões Eletrônicos</h1>
              </div>
            </div>
            <div class="level-end has-text-centered">
              <div>
                <a
                  class="button is-primary is-inverted is-outlined"
                  :class="{ ' is-loading': loading.criarPregao }"
                  v-if="logged && company.buyer && permissaoOperarPregao"
                  @click.prevent.stop="criarPregao"
                >
                  Criar Pregão
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <Filtros />

        <div class="panel">
          <div class="panel-block div-table">
            <section v-if="!ofertasCompra.length" class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhum Pregão Eletrônico.</p>
              </div>
            </section>

            <b-table
              detailed
              backend-pagination
              v-if="ofertasCompra.length"
              class="table is-fullwidth tabela-pregoes"
              default-sort-direction="ASC"
              :data="ofertasCompra"
              :mobile-card="false"
              @details-open="carregarUltimaPropostaItens"
            >
              <b-table-column
                centered
                sortable
                field="ultima_visualizacao"
                label="Última Visualização"
                v-slot="props"
                :visible="logged"
              >
                <div class="last-view-column">
                  <b-icon
                    class="last-view-icon mr-1"
                    size="is-small"
                    v-show="props.row.situacao.id !== 0 && props.row.ultima_visualizacao"
                    :icon="props.row.situacao.id === 1 ? 'pencil' : 'eye'"
                  />

                  <span>{{ props.row.ultima_visualizacao | formatarData }}</span>
                </div>
              </b-table-column>

              <b-table-column
                centered
                sortable
                field="tipo_edital"
                width="60"
                label="Exclusivo"
                v-slot="props"
                :visible="
                  ofertasCompra.some(
                    element =>
                      element.tipo_edital === 'AMPLOCOTA' || element.tipo_edital === 'RESERVADO',
                  )
                "
              >
                <b-icon
                  custom-size="mdi-24px"
                  icon="check"
                  type="is-success"
                  v-show="exclusiva(props.row.tipo_edital)"
                />
              </b-table-column>

              <b-table-column centered sortable label="Pregão" field="numero_oc" v-slot="props">
                <router-link
                  v-if="props.row.situacao.id > 1"
                  :to="{ name: 'Pregao', params: { id: props.row.id } }"
                >
                  {{ props.row.numero_oc }}
                </router-link>
                <p v-else>{{ props.row.numero_oc }}</p>
              </b-table-column>

              <b-table-column
                centered
                field="comprador.nome"
                label="Comprador"
                v-slot="props"
                :sortable="isNotBuyer"
              >
                <div class="buyer-column">
                  {{ props.row.comprador.nome }}
                </div>
              </b-table-column>

              <b-table-column
                centered
                sortable
                field="situacao.nome"
                label="Situação"
                v-slot="props"
              >
                <div class="situation-column">
                  {{ nomeSituacao(props.row) }}
                </div>
              </b-table-column>

              <b-table-column centered sortable field="objeto" label="Objeto" v-slot="props">
                {{ props.row.objeto }}
              </b-table-column>

              <b-table-column
                centered
                sortable
                field="data_abertura"
                label="Previsão de Abertura"
                v-slot="props"
                width="200"
              >
                <div v-if="props.row.situacao.id === 17 && props.row.data_reabertura">
                  {{ props.row.data_reabertura | formatarData }}
                </div>

                <div v-else>{{ props.row.data_abertura | formatarData }}</div>
              </b-table-column>

              <b-table-column
                centered
                ref="opcoes"
                label="Opções"
                v-slot="props"
                :visible="exibirOpcoes"
              >
                <b-field
                  v-if="
                    company.id === props.row.comprador.id &&
                      permissaoOperarPregao &&
                      props.row.situacao.id === 1
                  "
                  id="auctions-list-options"
                >
                  <p class="control">
                    <b-tooltip label="Editar">
                      <button
                        class="button is-primary"
                        ref="editar"
                        v-if="company.id === props.row.comprador.id && props.row.situacao.id < 4"
                        @click.prevent.stop="editarPregao(props.row.id)"
                      >
                        <b-icon icon="pencil" size="is-small" />
                      </button>
                    </b-tooltip>
                  </p>
                  <p class="control">
                    <b-tooltip label="Excluir" type="is-danger">
                      <button
                        class="button is-danger"
                        ref="excluir"
                        loading="loading.delete"
                        v-if="company.id === props.row.comprador.id && props.row.situacao.id === 1"
                        @click.prevent.stop="excluirPregao(props.row.id)"
                      >
                        <b-icon icon="delete" size="is-small" />
                      </button>
                    </b-tooltip>
                  </p>
                </b-field>

                <b-tooltip label="Editar" v-else>
                  <button
                    class="button is-primary"
                    ref="editar"
                    v-if="company.id === props.row.comprador.id && props.row.situacao.id < 4"
                    @click.prevent.stop="editarPregao(props.row.id)"
                  >
                    <b-icon icon="pencil" size="is-small" />
                  </button>
                </b-tooltip>
              </b-table-column>

              <template v-slot:detail="props">
                <b-tabs expanded type="is-toggle">
                  <b-tab-item label="Informações">
                    <div class="columns">
                      <div class="column">
                        <ul>
                          <li v-if="props.row.numero_processo !== null">
                            <strong>Número de Processo:</strong>
                            {{ props.row.numero_processo }}
                          </li>

                          <li>
                            <strong>Comprador:</strong>
                            {{ props.row.comprador.nome }}
                          </li>

                          <li>
                            <strong>CNPJ:</strong>
                            {{ props.row.comprador.cnpj }}
                          </li>

                          <li v-if="props.row.endereco_entrega_cep">
                            <strong>CEP:</strong>
                            {{ props.row.endereco_entrega_cep }}
                          </li>

                          <li v-if="props.row.endereco_entrega">
                            <strong>Endereço de Entrega:</strong>
                            {{ formataEnderecoEntrega(props.row) }}
                          </li>
                        </ul>
                      </div>

                      <div class="column">
                        <ul>
                          <li v-if="props.row.tipo_pregao !== null">
                            <strong>Tipo de Pregão:</strong>
                            {{ formatarTipoPregao(props.row.tipo_pregao) }}
                          </li>

                          <li v-if="props.row.modo_disputa !== null">
                            <strong>Modo de Disputa:</strong>
                            {{ formatarModoDisputa(props.row.modo_disputa) }}
                          </li>

                          <li v-if="props.row.tipo_negociacao.nome !== null">
                            <strong>Negociação:</strong>
                            {{ props.row.tipo_negociacao.nome }}
                          </li>

                          <li v-if="props.row.tipo_edital !== null">
                            <strong>Edital:</strong>
                            {{ formatarTipoEdital(props.row.tipo_edital) }}
                          </li>

                          <li v-if="props.row.data_publicacao !== null">
                            <strong>Data de Publicação:</strong>
                            {{ props.row.data_publicacao | formatarDataSemHorario }}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <button
                      v-if="props.row.edital !== null"
                      class="button is-primary"
                      :class="{ ' is-loading': loading.notice }"
                      @click="baixarEdital(props.row.id)"
                    >
                      <b-icon icon="download" />
                      <span>Baixar Edital</span>
                    </button>
                  </b-tab-item>

                  <b-tab-item label="Itens">
                    <b-table
                      paginated
                      per-page="5"
                      class="items-table"
                      :data="props.row.itens"
                      :mobile-cards="false"
                    >
                      <b-table-column
                        numeric
                        label="ID"
                        field="identificador"
                        width="40"
                        v-slot="props"
                      >
                        {{ props.row.identificador }}
                      </b-table-column>

                      <b-table-column field="nome" label="Descrição" v-slot="props">
                        {{ props.row.descricao }}
                      </b-table-column>

                      <b-table-column numeric label="Quantidade" width="60" v-slot="props">
                        {{ props.row.quantidade || formatarNumero }}
                      </b-table-column>

                      <b-table-column centered label="Última Proposta" v-slot="props">
                        {{ dataUltimaProposta(props.row.ultima_proposta) }}
                      </b-table-column>
                    </b-table>
                  </b-tab-item>
                </b-tabs>
              </template>

              <template v-slot:footer>
                <AppPagination
                  :data-filters="dataFilters"
                  :total="total"
                  @change-page="page => filtrarPregoes({ page })"
                  @change-per-page="perPage => alterarPorPaginaPregoes(perPage)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { funcoes } from '@/utils/enumerados';
import { formatarData, formatarDataSemHorario, formatarNumero } from '@/utils/format';
import { fileServer } from '@/utils/functions';

import AppPagination from '@/components/AppPagination';
import Filtros from './Filtros';

export default {
  name: 'PregaoLista',
  components: {
    AppPagination,
    Filtros,
  },
  data() {
    const loading = {
      criarPregao: false,
      delete: false,
      edit: false,
      notice: false,
    };

    return {
      ofertaCompraId: 0,
      funcoes,
      loading,
    };
  },
  computed: {
    ...mapState('prefectures', ['prefecturesCities']),
    ...mapGetters({
      ofertasCompra: 'obterOfertasCompra',
      auctionLoading: 'obterCarregandoOfertasCompra',
      total: 'obterTotalOfertasCompra',
      usuario: 'getUser',
      funcao: 'getFunction',
      obterAnexos: 'obterOfertaCompraAnexos',
      logged: 'getLogged',
      dataFilters: 'obterDadosFiltros',
      prazos: 'obterOfertaCompraPrazos',
    }),
    ...mapState('companies', ['company']),
    isNotBuyer() {
      return ['AUTORIDADE', 'PREGOEIRO', 'COMPRADOR'].indexOf(this.funcao) === -1;
    },
    generalLoading() {
      return this.auctionLoading || this.loading.edit;
    },
    etapaAdesao() {
      return this.prazos.some(prazo => prazo.prazo_id === 13);
    },
    permissaoOperarPregao() {
      return this.usuario.empresas.some(
        empresaUsuario => empresaUsuario.id === this.company.id && empresaUsuario.operar_ofertas,
      );
    },
    exibirOpcoes() {
      const existeEditar = this.ofertasCompra.find(ofertaCompra => ofertaCompra.situacao.id < 4);
      if (this.logged && this.company.buyer && existeEditar && this.permissaoOperarPregao) {
        return true;
      }
      return false;
    },
  },
  filters: {
    formatarData,
    formatarDataSemHorario,
    formatarNumero,
  },
  beforeRouteLeave(to, from, next) {
    this.limparFiltrosPregoes();
    return next();
  },
  methods: {
    ...mapActions([
      'novaOfertaCompra',
      'excluirOfertaCompra',
      'buscarOfertaCompraAnexos',
      'fetchItemsLastProposal',
      'filtrarPregoes',
      'alterarPorPaginaPregoes',
      'limparFiltrosPregoes',
    ]),
    ...mapActions('prefectures', ['fetchPrefecturesCities']),
    formatarTipoPregao(tipo) {
      if (tipo === 'IMEDIATO') return 'Contratação Imediata';
      return 'Registro de Preços';
    },
    formatarModoDisputa(modo) {
      if (modo === 'ABERTO') return 'Aberto';
      return 'Aberto/Fechado';
    },
    formatarTipoEdital(edital) {
      if (edital === 'AMPLO') return 'Participação Ampla';
      if (edital === 'AMPLOCOTA') {
        return 'Quota Reservada para ME/EPP/MEI/Cooperativa c/ Preferência';
      }
      return 'Participação Exclusiva de ME/EPP/MEI/Cooperativa c/ Preferência';
    },
    nomeSituacao(row) {
      const situacao = row.situacao.nome;
      if (situacao === 'Lances' && row.modo_disputa === 'FECHADO' && this.modoFechado(row)) {
        return 'Lances - Fechado';
      }
      if (situacao === 'Lances' && row.modo_disputa === 'FECHADO') return 'Lances - Aberto';
      if (situacao === 'Habilitação' && row.prazo_adesao) {
        return 'Habilitação da Adesão';
      }
      if (situacao === 'Fracassada') return 'Fracassado';
      if (situacao === 'Deserta') return 'Deserto';
      if (situacao === 'Revogada') return 'Revogado';
      if (situacao === 'Encerrada com Vencedor') return 'Encerrado com Vencedor';
      if (situacao === 'Encerrada com Recurso') return 'Encerrado com Recurso';
      if (situacao === 'Suspensa') return 'Suspenso';
      return situacao;
    },
    modoFechado(row) {
      return this.ofertasCompra.some(
        ofertaCompra =>
          ofertaCompra.id === row.id &&
          ofertaCompra.itens.some(
            item => ['LANCE-FECHADO', 'PRIMEIRA-CHAMADA', 'NOVA-CHAMADA'].indexOf(item.estado) > -1,
          ),
      );
    },
    dataUltimaProposta(data) {
      if (data) return formatarData(data);
      return 'Nenhuma';
    },
    formataEnderecoEntrega(row) {
      if (row.endereco_entrega_complemento) {
        return `${row.endereco_entrega}, ${row.endereco_entrega_numero} - ${row.endereco_entrega_complemento} - ${row.endereco_entrega_bairro} - ${row.endereco_entrega_municipio}-${row.endereco_entrega_uf}`;
      }
      return `${row.endereco_entrega}, ${row.endereco_entrega_numero} - ${row.endereco_entrega_bairro} - ${row.endereco_entrega_municipio}-${row.endereco_entrega_uf}`;
    },
    exclusiva(tipoEdital) {
      if (tipoEdital === 'AMPLOCOTA' || tipoEdital === 'RESERVADO') return true;
      return false;
    },
    async baixarEdital(id) {
      this.loading.notice = true;
      await this.buscarOfertaCompraAnexos(id);
      const edital = this.obterAnexos.find(anexo => anexo.tipo === 'EDITAL');
      if (edital) window.location = fileServer(edital.arquivo);
      this.loading.notice = false;
    },
    async criarPregao() {
      this.loading.criarPregao = true;
      const id = await this.novaOfertaCompra('PREGAO');
      if (id) {
        await this.$router.push({ name: 'PregaoEditar', params: { id } });
      } else {
        this.$alerta('Erro ao criar pregão eletrônico', 'is-danger');
      }
      this.loading.criarPregao = false;
    },
    async editarPregao(id) {
      this.loading.edit = true;
      await this.$router.push({ name: 'PregaoEditar', params: { id } });
      this.loading.edit = false;
    },
    async excluirPregao(id) {
      const confirmacao = await this.$confirmacao({
        message: 'Tem certeza que deseja excluir esse pregão eletrônico?',
      });
      if (confirmacao) {
        this.loading.delete = true;
        try {
          await this.excluirOfertaCompra(id);
        } catch (error) {
          this.$alerta('Erro ao excluir pregão eletrônico', 'is-danger');
        } finally {
          this.loading.delete = false;
        }
      }
    },
    async carregarUltimaPropostaItens(row) {
      await this.fetchItemsLastProposal(row.id);
    },
  },
  async created() {
    try {
      await Promise.all([
        this.filtrarPregoes(),
        this.isNotBuyer && !this.prefecturesCities.length
          ? this.fetchPrefecturesCities()
          : Promise.resolve(),
      ]);
    } catch (error) {
      this.$alerta('Erro ao buscar pregões', 'is-danger');
    }
  },
};
</script>

<style lang="scss" scoped>
.situation-column {
  min-width: 150px;
}
</style>

<style lang="scss">
#auctions-list-options .field-body .field {
  justify-content: center;
}

.buyer-column {
  min-width: 150px;
}

.last-view-icon {
  vertical-align: text-bottom;
}

.div-table {
  justify-content: center !important;
}

.filtrar {
  margin-top: 0.2rem;
  height: 90%;
}

.field.is-grouped .field {
  flex-shrink: 1;
}

.last-view-column {
  min-width: 145px;
}

.items-table {
  background-color: #fafafa;
  font-size: 0.92rem;
}

.modal-card-body .media .media-content .field .control .input {
  text-transform: uppercase;
}

.tabela-pregoes {
  font-size: 0.96rem;
}

@media (max-width: 800px) {
  .div-titulo-pregoes {
    margin-bottom: 1rem;
    text-align: center !important;
  }
}
</style>
