<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <form>
      <b-field grouped class="is-flex-touch column-direction-touch">
        <b-field
          expanded
          label="Responsável"
          :type="campoValido('responsavel') ? 'is-danger' : ''"
          :message="campoValido('responsavel') ? 'Selecione um Responsável.' : ''"
        >
          <b-autocomplete
            class="auction-responsible-autocomplete"
            ref="responsavel"
            v-model="responsavel"
            placeholder="Insira um e-mail"
            field="userEmail"
            :data="pesquisa"
            :loading="pesquisando"
            @input="pesquisar"
            @select="option => (selected = option)"
          >
            <template v-slot:empty>Nenhum resultado para {{ responsavel }}</template>

            <template v-slot:header>
              <div class="media header-complete">
                <div class="media-left email-media">
                  <b>E-mail</b>
                </div>

                <div class="media-content name-media">
                  <b>Nome</b>
                </div>
              </div>
            </template>

            <template v-slot="props">
              <div class="media">
                <div class="media-left email-media">{{ props.option.userEmail }}</div>
                <div class="media-content name-media">{{ props.option.name }}</div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>

        <b-field label="Função">
          <b-select placeholder="Função" v-model="funcao">
            <option v-for="funcao in funcoes" :key="funcao.id" :value="funcao.enum">{{
              funcao.nome
            }}</option>
          </b-select>
        </b-field>

        <b-field>
          <button
            class="button is-primary adicionar"
            :class="{ ' is-loading': loading.adicionar }"
            @click.prevent.stop="adicionar"
          >
            Adicionar
          </button>
        </b-field>
      </b-field>
    </form>

    <hr />

    <b-table hoverable v-if="responsaveis.length" :data="responsaveis">
      <b-table-column label="E-mail" v-slot="props">{{ props.row.email }}</b-table-column>

      <b-table-column label="Responsável" v-slot="props">{{ props.row.nome }}</b-table-column>

      <b-table-column label="Função" v-slot="props"
        ><div class="column-funcao">{{ funcaoNome(props.row.funcao) }}</div></b-table-column
      >

      <b-table-column centered v-slot="props">
        <b-tooltip label="Excluir">
          <button
            class="button is-danger"
            :disabled="desabilitar.delete"
            @click.prevent.stop="removerResponsavel(props.row.id)"
          >
            <b-icon icon="delete" />
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { required } from 'vuelidate/lib/validators';

import { search } from '@/resources/companies/companiesUsers';

export default {
  name: 'PregaoEditarResponsaveis',
  data() {
    const funcoes = [
      { id: 1, nome: 'Autoridade Competente', enum: 'AUTORIDADE' },
      { id: 2, nome: 'Equipe de Apoio', enum: 'APOIO' },
      { id: 3, nome: 'Pregoeiro(a)', enum: 'PREGOEIRO' },
      { id: 4, nome: 'Pregoeiro(a) Substituto(a)', enum: 'PREGOEIRO-SUBSTITUTO' },
      { id: 5, nome: 'Subscritor(a) de Edital', enum: 'ESCRIVAO' },
    ];

    const loading = {
      general: false,
      adicionar: false,
    };

    const desabilitar = {
      delete: false,
    };

    return {
      funcoes,
      loading,
      desabilitar,
      pesquisa: [],
      responsavel: '',
      funcao: 'AUTORIDADE',
      selected: null,
      pesquisando: false,
    };
  },
  validations: {
    responsavel: { required },
    funcao: { required },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      ofertaCompra: 'obterOfertaCompra',
      responsaveis: 'obterOfertaCompraResponsaveis',
    }),
  },
  methods: {
    ...mapActions([
      'adicionarOfertaCompraResponsavel',
      'buscarOfertaCompraResponsaveis',
      'buscarOfertaCompra',
      'removerOfertaCompraResponsavel',
    ]),
    campoValido(campo) {
      return this.$v[campo].$invalid && this.$v[campo].$dirty;
    },
    pesquisar: debounce(async function obterPesquisa() {
      this.pesquisando = true;
      if (this.responsavel && !this.selected) {
        try {
          const { data: searchUsers } = await search(this.company.id, this.responsavel);
          this.pesquisa = searchUsers.data;
        } catch (error) {
          this.$alerta('Erro ao pesquisar usuários', 'is-danger');
        }
      }
      this.pesquisando = false;
    }, 500),
    async adicionar() {
      if (this.validar()) {
        const responsavel = {
          usuarioId: this.selected.id,
          usuarioNome: this.selected.name,
          funcao: this.funcao,
        };
        this.loading.adicionar = true;
        try {
          await this.adicionarOfertaCompraResponsavel(responsavel);
          this.$alerta('Responsável adicionado', 'is-success');
          this.limpar();
          this.focarInputResponsavel();
        } catch (error) {
          const resposta = error.response.data;
          if (resposta.error.type === 'FuncaoJaAdicionada') {
            this.$alerta('A função selecionada já está adicionada', 'is-danger');
          } else {
            this.$alerta('Erro ao adicionar usuário', 'is-danger');
          }
        }
        this.loading.adicionar = false;
        this.$v.responsavel.$reset();
      }
    },
    validar() {
      if (this.$v.responsavel.$invalid || this.$v.funcao.$invalid) {
        this.$v.$touch();
        return false;
      }

      if (!this.responsaveis.length) {
        return true;
      }

      if (
        ['AUTORIDADE', 'ESCRIVAO', 'PREGOEIRO', 'PREGOEIRO-SUBSTITUTO'].indexOf(this.funcao) > -1 &&
        this.responsaveis.some(element => element.funcao === this.funcao)
      ) {
        this.$alerta('O usuário selecionado já está adicionado', 'is-danger');
        return false;
      }

      const responsible = this.responsaveis.find(
        element => element.usuario_id === this.selected.id,
      );
      if (!responsible && this.funcao === 'APOIO') {
        return true;
      }

      if (responsible) {
        const userFunctions = this.responsaveis.filter(
          element => element.usuario_id === this.selected.id,
        );

        if (
          ['AUTORIDADE', 'APOIO', 'PREGOEIRO-SUBSTITUTO'].indexOf(this.funcao) > -1 ||
          userFunctions.length >= 2 ||
          (this.funcao === 'ESCRIVAO' && responsible.funcao !== 'PREGOEIRO') ||
          (this.funcao === 'PREGOEIRO' && responsible.funcao !== 'ESCRIVAO')
        ) {
          this.$alerta('O usuário selecionado já está adicionado', 'is-danger');
          return false;
        }
      }

      return true;
    },
    limpar() {
      this.responsavel = '';
      this.funcao = 'AUTORIDADE';
    },
    async removerResponsavel(responsavelId) {
      this.desabilitar.delete = true;
      try {
        await this.removerOfertaCompraResponsavel(responsavelId);
        this.$alerta('Usuário removido', 'is-success');
        this.focarInputResponsavel();
      } catch (error) {
        this.$alerta('Erro ao remover usuário', 'is-danger');
      }
      this.desabilitar.delete = false;
    },
    funcaoNome(value) {
      const funcao = this.funcoes.find(f => f.enum === value);
      if (funcao) return funcao.nome;
      return 'Função inválida';
    },
    focarInputResponsavel() {
      this.$refs.responsavel.focus();
    },
  },
  mounted() {
    this.focarInputResponsavel();
  },
  async created() {
    this.loading.general = true;
    try {
      await Promise.all([
        !this.responsaveis.length
          ? this.buscarOfertaCompraResponsaveis(this.$route.params.id)
          : Promise.resolve(),
        !this.ofertaCompra.id ? this.buscarOfertaCompra(this.$route.params.id) : Promise.resolve(),
      ]);
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.adicionar {
  height: 100%;
}

.email-media {
  width: 50%;
}

.name-media {
  white-space: normal;
  width: 50%;
}

.column-funcao {
  min-width: 20%;
}

.header-complete {
  background-color: LightGray;
}

@media (max-width: 1023px) {
  .email-media,
  .name-media {
    font-size: 0.8rem;
    white-space: normal;
  }
}
</style>

<style>
.auction-responsible-autocomplete .dropdown-menu .dropdown-content .dropdown-item {
  padding-right: 1rem;
}
</style>
