<template>
  <div class="import-proposals-container is-flex">
    <b-field expanded grouped class="column-direction-touch">
      <b-field expanded id="import-proposals-field">
        <div expanded class="field">
          <b-upload
            expanded
            v-model="data.file"
            accept=".xlsx,.xls"
            @input="
              {
                resetAuctionProposalsUpload(), importProposals();
              }
            "
          >
            <a
              :disabled="avoidImport"
              class="button is-primary is-fullwidth"
              :class="{ 'is-loading': loading.import }"
            >
              <b-icon icon="import"></b-icon>
              <span>Importar Propostas</span>
            </a>
          </b-upload>
        </div>
      </b-field>

      <b-field>
        <b-button
          expanded
          icon-left="file-download"
          type="is-primary"
          @click.prevent.stop="fetchImportProposalsModel()"
        >
          Modelo Importação
        </b-button>
      </b-field>
    </b-field>

    <b-progress
      show-value
      format="percent"
      id="import-proposals-upload-progress"
      type="is-info"
      size="is-medium"
      :value="auctionProposalsImportUpload"
    ></b-progress>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import { fileServer } from '@/utils/functions';

export default {
  name: 'ImportAuctionProposals',
  data() {
    const data = {
      file: [],
    };

    const loading = {
      import: false,
    };

    return {
      data,
      loading,
    };
  },
  computed: {
    ...mapState('proposals', ['auctionProposalsImportUpload']),
    ...mapGetters('auctionsDeclarations', {
      auctionDeclarations: 'getDeclarations',
    }),
    avoidImport() {
      return !this.auctionDeclarations.some(element => element.declaration === 'REQUISITOS');
    },
  },
  methods: {
    ...mapActions('proposals', ['importAuctionProposals', 'resetAuctionProposalsUpload']),
    fetchImportProposalsModel() {
      const url = fileServer('modelo-importar-propostas.xlsx', 'files');
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async importProposals() {
      if (
        ![
          'text/csv',
          'text/plain',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(this.data.file.type)
      ) {
        this.$alerta('Insira um arquivo com o formato .xlsx ou .csv', 'is-danger');
        return;
      }

      if (this.data.file.size > this.uploadLimit) {
        this.$alerta('Insira um arquivo com até 5MB', 'is-danger');
        return;
      }

      const requirements = this.auctionDeclarations.some(
        element => element.declaration === 'REQUISITOS',
      );

      if (!requirements) {
        this.$alerta('Antes de importar, você precisa aceitar os requerimentos!', 'is-danger');
        return;
      }

      const data = new FormData();
      data.append('proposals-import', this.data.file);

      this.$emit('mode', 'import');
      this.loading.import = true;
      try {
        await this.importAuctionProposals({ auctionId: this.$route.params.id, data });
        this.$alerta('Importação com sucesso', 'is-success');
      } catch (error) {
        if (error?.response?.data?.message === 'ValidationError') {
          this.$snackbar(error.response.data.details.message, 'is-warning', 'is-top', true);
          return;
        }
        this.$alerta('Erro ao realizar importação', 'is-danger');
      } finally {
        this.$emit('mode', '');
        this.loading.import = false;
        this.data.file = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#import-proposals-upload-progress {
  margin-left: 40px;
  margin-top: 10px;
  width: 100%;
}

.import-proposals-container {
  margin: 1rem 0;
}
</style>

<style lang="scss">
@media (max-width: 1023px) {
  #import-proposals-field .field {
    display: flex;
    flex-direction: column;

    .field .upload {
      width: 100%;
    }
  }

  #import-proposals-upload-progress {
    margin-left: unset;
  }

  .import-proposals-container {
    flex-direction: column;
  }
}
</style>
