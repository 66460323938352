import Vue from 'vue';
import { getPrazos as dadosPrazos } from '@/resources/dispensas';

const DISPENSA_PRAZO_OBJECT = 'DISPENSA_PRAZO_OBJECT';
const DISPENSA_PRAZO_COLLECTION = 'DISPENSA_PRAZO_COLLECTION';
const DISPENSA_PRAZOS_UPDATE = 'DISPENSA_PRAZOS_UPDATE';

const mutations = {
  [DISPENSA_PRAZO_OBJECT](state, data) {
    if (data && typeof data === 'object') {
      state.prazo = data;
      state.prazo.data_de = new Date(state.prazo.data_de);
      state.prazo.data_ate = new Date(state.prazo.data_ate);
    }
  },
  [DISPENSA_PRAZO_COLLECTION](state, data) {
    if (data && typeof data === 'object' && data.length) {
      const prazos = data.map(prazo => ({
        id: prazo.id,
        nome: prazo.nome,
        situacao_id: prazo.situacao_id,
        data_de: prazo.data_de !== null ? new Date(prazo.data_de) : '',
        data_ate: prazo.data_ate !== null ? new Date(prazo.data_ate) : '',
        dispensa_id: prazo.dispensa_id,
        justificativa_cancelamento: prazo.justificativa_cancelamento,
        prazo_id: prazo.prazo_id,
      }));
      state.prazos = prazos;
    }
  },
  [DISPENSA_PRAZOS_UPDATE](state, data) {
    if (data && Array.isArray(data)) {
      state.prazos = [];
      while (data.length) {
        const prazoData = data.shift();
        prazoData.data_de = prazoData.data_de !== null ? new Date(prazoData.data_de) : '';
        prazoData.data_ate = prazoData.data_ate !== null ? new Date(prazoData.data_ate) : '';
        state.prazos.push(prazoData);
      }
    } else if (data && typeof data === 'object') {
      const prazoData = data;
      prazoData.data_de = data.data_de ? new Date(data.data_de) : '';
      prazoData.data_ate = data.data_ate ? new Date(data.data_ate) : '';

      const index = state.prazos.findIndex(prazoLista => prazoLista.id === prazoData.id);

      if (index > -1) {
        Vue.set(state.prazos, index, Object.assign(state.prazos[index], prazoData));
      } else {
        prazoData.nome = data.nome;
        state.prazos.push(prazoData);
      }
    }
  },
};

const actions = {
  setPrazoObject({ commit }, data) {
    commit(DISPENSA_PRAZO_OBJECT, data);
  },
  async buscarPrazosDispensa({ commit, rootState }, dispensaId) {
    const { data: dados } = await dadosPrazos(dispensaId);
    const prazos = dados.data;

    if (prazos && Array.isArray(prazos)) {
      const ultimoPrazo = prazos[prazos.length - 1];

      if (ultimoPrazo.id === 19) {
        const justificativa = rootState.dispensas.dispensa.justificativa_cancelamento;
        ultimoPrazo.justificativa_cancelamento = justificativa;
        Vue.set(prazos, prazos.length - 1, ultimoPrazo);
      }
    }

    commit(DISPENSA_PRAZO_COLLECTION, prazos);
  },
  atualizarPrazosDispensa({ commit, rootState }, data) {
    const prazos = data;

    if (prazos && Array.isArray(prazos)) {
      const ultimoPrazo = data[data.length - 1];

      if (ultimoPrazo.id === 19) {
        const justificativa = rootState.dispensas.dispensa.justificativa_cancelamento;
        ultimoPrazo.justificativa_cancelamento = justificativa;
        Vue.set(prazos, prazos.length - 1, ultimoPrazo);
      }
    }

    commit(DISPENSA_PRAZOS_UPDATE, prazos);
  },
};

const getters = {
  getDispensaPrazoObject(state) {
    return state.prazo;
  },
  obterDispensaPrazos(state) {
    return state.prazos;
  },
};

const state = {
  prazo: {},
  prazos: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
