import { api, sendTimeout } from '../http';

export const fetchCompanyInvitedSuppliers = companyId =>
  api.get(`/companies/${companyId}/invited-suppliers`);

export const addCompanyInvitedSuppliers = (
  companyId,
  data,
  { onUploadProgress } = { onUploadProgress: () => {} },
) =>
  api.post(`/companies/${companyId}/invited-suppliers`, data, {
    onUploadProgress,
    timeout: sendTimeout,
  });
