<template>
  <div>
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container is-title-fullhd">
          <div>
            <ul>
              <li>
                <div
                  class="has-text-white elipsis-description cursor-pointer"
                  :class="{ 'justify-align': justify }"
                  :ref="`description-title`"
                  @click.stop.prevent="openDescription()"
                >
                  <strong class="has-text-white">Descrição: </strong>
                  {{ item.description }}
                </div>

                <div v-show="descriptionText">
                  <div ref="description-text">
                    <strong class="has-text-white">Descrição: </strong>{{ item.description }}
                  </div>
                </div>
              </li>
              <li><strong class="has-text-white">Código: </strong> {{ item.code }}</li>
              <li><strong class="has-text-white">Tipo: </strong>{{ item.type | formatType }}</li>

              <!-- <li v-if="item.type === 'service'"><strong>CPC:</strong> {{ item.cpc }}</li>
              <li v-if="item.type === 'service'">
                <strong>Sessão:</strong> {{ item.session.name }}
              </li>
              <li v-if="item.type === 'service'">
                <strong>Divisão:</strong> {{ item.division.name }}
              </li>
              <li><strong>Grupo:</strong> {{ item.group.name }}</li>
              <li><strong>Categoria:</strong> {{ item.category.name }}</li> -->
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns">
          <div class="column is-narrow">
            <MenuSecundario :itens="menu" :titulo="'Menu'" />
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import MenuSecundario from '@/components/MenuSecundario';

import { formatType } from '@/utils/format';

export default {
  name: 'Item',
  components: {
    MenuSecundario,
  },
  data() {
    return {
      item: {},
      descriptionText: false,
    };
  },
  filters: {
    formatType,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters('app', { mobile: 'mobile' }),
    hierarquia() {
      return `Hierarquia: ${this.item.group.id} - ${this.item.group.name} > ${this.item.category.id} - ${this.item.category.name}`;
    },
    menu() {
      return [
        {
          titulo: 'Negociações',
          rota: 'ItemNegociacoes',
          parametros: { id: this.item.id },
        },
      ];
    },
    justify() {
      return this.windowWidth > 960;
    },
  },
  methods: {
    openDescription() {
      const tag = this.$refs['description-title'];
      if (tag) {
        const { classList } = tag;
        const contains = classList.contains('elipsis-description');
        if (contains) {
          classList.remove('elipsis-description');
          return;
        }
        classList.add('elipsis-description');
      }
    },
    verifyOverflowed() {
      const descriptionTitle = this.$refs['description-title'];
      const descriptionText = this.$refs['description-text'];
      const descriptionOverflowed = descriptionTitle.offsetWidth < descriptionTitle.scrollWidth;
      if (descriptionOverflowed) {
        descriptionText.hidden = true;
      } else {
        this.descriptionText = true;
        descriptionTitle.hidden = true;
      }
    },
  },
  mounted() {
    if (this.$route.params.item) {
      this.item = { ...this.$route.params.item };
      this.$nextTick(() => {
        this.verifyOverflowed();
      });
    } else {
      this.$router.push({ name: 'ItemPesquisa' });
    }
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.description-text {
  white-space: nowrap;
}

.elipsis-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.justify-align {
  text-align: justify;
}
</style>
