<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li><a href="#">Início</a></li>
      <li><a href="#">Pregões Eletrônicos</a></li>
      <li><a href="#">Components</a></li>
      <li class="is-active"><a href="#" aria-current="page">Breadcrumb</a></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheBreadcrumb',
};
</script>
