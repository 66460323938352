import { api } from '../http';

export const search = (company, term) => api.get(`/companies/${company}/users?q=${term}`);

export const getUsers = company => api.get(`/companies/${company}/users`);

export const createAdministrator = (company, user) =>
  api.post(`/companies/${company}/users/administrator`, user);

export const deletePrefectureUser = (company, companyUserId) =>
  api.delete(`/companies/${company}/users/${companyUserId}/backoffice`);

export const deleteCompanyUser = (company, companyUserId) =>
  api.delete(`/companies/${company}/users/${companyUserId}`);

export const invitePrefectureUser = (prefecture, user) =>
  api.post(`/companies/${prefecture}/users/backoffice`, user);

export const inviteCompanyUser = (company, user) => api.post(`/companies/${company}/users`, user);

export const linkGuestUser = (company, user) => api.patch(`/companies/${company}/users`, user);

export const savePrefecturePermissions = (prefecture, prefectureUserId, data) =>
  api.patch(`/companies/${prefecture}/users/${prefectureUserId}/prefecture-permissions`, data);

export const saveCompanyPermissions = (company, companyUserId, data) =>
  api.patch(`/companies/${company}/users/${companyUserId}/permissions`, data);
