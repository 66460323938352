import Vue from 'vue';

import {
  fetchActiveEvent,
  fetchEvents,
  createEvent,
  updateEventStatus,
} from '@/resources/admin/adminEvents';

const types = {
  ADMIN_EVENTS: 'ADMIN_EVENTS',
  ADMIN_EVENTS_ACTIVE: 'ADMIN_EVENTS_ACTIVE',
  ADMIN_EVENTS_CREATE: 'ADMIN_EVENTS_CREATE',
  ADMIN_EVENTS_UPDATE_STATUS: 'ADMIN_EVENTS_UPDATE_STATUS',
};

const mutations = {
  [types.ADMIN_EVENTS_ACTIVE](state, data) {
    if (data && typeof data === 'object') {
      state.globalNotification = data.description;
    }
  },
  [types.ADMIN_EVENTS](state, data) {
    if (data && Array.isArray(data)) {
      state.events = data;
    }
  },
  [types.ADMIN_EVENTS_CREATE](state, data) {
    const activeExists = event => event.status === 'ATIVO';

    while (state.events.some(activeExists)) {
      const index = state.events.findIndex(activeExists);
      const newEvent = { ...state.events[index] };
      newEvent.status = 'INATIVO';
      Vue.set(state.events, index, newEvent);
    }

    if (data && typeof data === 'object') {
      state.events.unshift(data);
    }
  },
  [types.ADMIN_EVENTS_UPDATE_STATUS](state, data) {
    if (data && typeof data === 'object') {
      if (data.status === 'ATIVO') {
        const activeExists = event => event.status === 'ATIVO';

        while (state.events.some(activeExists)) {
          const index = state.events.findIndex(activeExists);
          const newEvent = { ...state.events[index] };
          newEvent.status = 'INATIVO';
          Vue.set(state.events, index, newEvent);
        }
      }

      const index = state.events.findIndex(event => event.id === data.id);
      if (index > -1) {
        Vue.set(state.events, index, data);
      }
    }
  },
};

const actions = {
  async fetchActiveEvent({ commit }) {
    const response = await fetchActiveEvent();
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.ADMIN_EVENTS_ACTIVE, response.data);
  },
  async fetchEvents({ commit }) {
    const response = await fetchEvents();
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.ADMIN_EVENTS, response.data);
  },
  async createEvent({ commit }, data) {
    const response = await createEvent(data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.ADMIN_EVENTS_CREATE, response.data);
  },
  async updateEventStatus({ commit }, data) {
    const response = await updateEventStatus(data.eventId, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.ADMIN_EVENTS_UPDATE_STATUS, response.data);
  },
};

const state = {
  events: [],
  globalNotification: '',
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};

export { types };
