<template>
  <b-field :style="fieldGrow ? 'flex-grow: 1;' : 'flex-grow: 0;'" :label="label">
    <b-field>
      <p class="control">
        <span
          class="button is-static"
          :class="{
            'percent-span': !desabilitarCampos,
            'percent-span-disabled': desabilitarCampos,
          }"
          >%</span
        >
      </p>
      <b-field
        :type="campoInvalido('percentual') ? 'is-danger' : ''"
        :message="campoInvalido('percentual') ? 'Percentual inválido' : ''"
      >
        <b-input
          custom-class="input-percent"
          ref="inputPercentual"
          maxlength="7"
          :disabled="desabilitarCampos"
          :has-counter="false"
          :key="chaveComponente"
          :placeholder="placeholder"
          :style="{ width: width + 'px' }"
          v-mascara:porcentagem.scale
          v-model="valoresMascara.percentual"
          @input.native="evento => atualizarValorPersonalizado('percentual', evento)"
          @keypress.native="teclasPermitidasValor($event)"
          @blur="evento => atualizarValorPersonalizado('percentual', evento)"
        ></b-input>
      </b-field>
    </b-field>
  </b-field>
</template>

<script>
import Big from 'big.js';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';

import campoMixin from '@/mixins/formulario';
import { formatarPorcentagem } from '@/utils/format';
import { calcularValorDePorcentagem } from '@/utils/calculations';

export default {
  name: 'AppPorcentagem',
  props: {
    cancelarValidacao: { type: Boolean, default: true },
    calcularValor: { type: Boolean, default: false },
    carregarPercentual: { type: Boolean, default: false },
    desabilitarCampos: { type: Boolean, default: false },
    fieldGrow: { type: Boolean, default: false },
    label: { type: String, default: '' },
    item: { type: Object, default: () => {} },
    percentualValido: { type: Boolean, default: true },
    permitirPercentualZero: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    valores: { type: Object, default: () => {} },
    valorPercentual: { type: Number, default: 0 },
    width: { type: String, default: '' },
  },
  mixins: [campoMixin],
  data() {
    const dados = {
      percentual: 0,
      valor: 0,
    };

    const valoresMascara = {
      percentual: '',
      valor: '',
    };

    return {
      dados,
      valoresMascara,
      chaveComponente: 1,
    };
  },
  validations: {
    dados: {
      percentual: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(99.9999),
        valorValidoPorcentagem() {
          return this.percentualValido && this.valoresMascara.percentual !== '';
        },
      },
    },
  },
  watch: {
    valores() {
      if (this.valores.emissor !== 'porcentagem' && this.valores.itemId === this.item.id) {
        const valorPercentual = new Big(this.valores.percentual);
        if (this.valores.valorMascara !== '' && valorPercentual.gte(0) && valorPercentual.lt(100)) {
          this.dados.percentual = this.valores.percentual;
          this.valoresMascara.percentual = formatarPorcentagem(this.dados.percentual);
        } else {
          this.dados.percentual = 0;
          this.valoresMascara.percentual = '';
        }
      }
    },
    carregarPercentual() {
      if (this.carregarPercentual) this.atualizarPercentual();
    },
  },
  methods: {
    atualizarPercentual() {
      this.valoresMascara.percentual = formatarPorcentagem(this.valorPercentual);
      this.dados.percentual = this.valorPercentual;
    },
    atualizarValorPersonalizado(campo, evento) {
      const valor = Number(evento.target.vCleave.getRawValue());
      const valorFormatado = evento.target.vCleave.getFormattedValue();

      this.valoresMascara[campo] = valorFormatado;

      this.$v.dados[campo].$model = valor;
      this.$v.dados[campo].$touch();

      this.validarValores();

      this.$emit('valor', {
        ...this.dados,
        emissor: 'porcentagem',
        valorMascara: this.valoresMascara.percentual,
        percentualValido: this.percentualValido,
        itemId: this.item ? this.item.id : 0,
      });
    },
    validarValores() {
      if (this.valoresMascara.percentual === '' && this.cancelarValidacao) {
        this.limparCampos();
        this.resetarEstado();
        return;
      }

      if (this.calcularValor) {
        const percentual = new Big(this.dados.percentual);

        if (!this.permitirPercentualZero) {
          if (percentual.eq(0)) {
            this.dados.valor = 0;
            this.valoresMascara.valor = '';
            return;
          }
        }

        const total = new Big(this.item.total);
        this.dados.valor = calcularValorDePorcentagem(total, this.dados.percentual);
      }
    },
    focus() {
      this.$refs.inputPercentual.focus();
    },
    formatarValor() {
      this.valoresMascara.percentual = formatarPorcentagem(this.dados.percentual);
    },
    resetarEstado() {
      this.$v.dados.percentual.$reset();
    },
    limparCampos() {
      this.dados.percentual = 0;
      this.valoresMascara.percentual = '';

      this.resetarEstado();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.carregarPercentual) this.atualizarPercentual();
    });

    this.$onBus('limparCampos', this.limparCampos);
    this.$onBus('resetarEstado', this.resetarEstado);
  },
};
</script>

<style lang="scss" scoped>
.field.is-grouped .field {
  flex-shrink: 1;
  flex-grow: 1;
}
</style>

<style>
.percent-span {
  color: #363636 !important;
  background-color: #ececec !important;
}

.percent-span-disabled {
  background-color: #ececec !important;
}

.input-percent {
  border-radius: 0 4px 4px 0 !important;
}

.input-percent:disabled {
  border-color: #dbdbdb !important;
  background-color: #ececec;
}

.field .field {
  margin-bottom: 0;
}
</style>
