import DispensaLista from '@/views/Dispensa/DispensaLista/ListaDispensas';

import DispensaEditar from '@/views/Dispensa/DispensaEditar';
import DispensaEditarDados from '@/views/Dispensa/DispensaEditar/EditarDados';
import DispensaEditarAnexos from '@/views/Dispensa/DispensaEditar/EditarAnexos';
import DispensaEditarItens from '@/views/Dispensa/DispensaEditar/EditarItens';

import DispensaItem from '@/views/Dispensa/DispensaItem';

import { adminNotAllowed, editProtected } from '@/utils/session';

export default [
  {
    path: '/dispensas',
    name: 'Dispensas',
    component: DispensaLista,
    beforeEnter: adminNotAllowed,
  },
  {
    path: '/dispensas/editar/:id',
    redirect: { name: 'DispensaEditarDados' },
    name: 'DispensaEditar',
    component: DispensaEditar,
    beforeEnter: editProtected,
    children: [
      {
        path: 'dados',
        name: 'DispensaEditarDados',
        component: DispensaEditarDados,
      },
      {
        path: 'anexos',
        name: 'DispensaEditarAnexos',
        component: DispensaEditarAnexos,
      },
      {
        path: 'itens',
        name: 'DispensaEditarItens',
        component: DispensaEditarItens,
      },
    ],
  },
  {
    path: '/dispensas/:id',
    name: 'Dispensa',
    component: DispensaItem,
  },
];
