<template>
  <div>
    <b-field grouped>
      <b-field
        expanded
        label="Data Reabertura"
        :type="campoInvalido('reopeningDate', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('reopeningDate', 'data') ? 'Data de reabertura é um campo obrigatório' : ''
        "
      >
        <b-datepicker
          editable
          placeholder="Data Reabertura"
          icon="calendar-today"
          v-mascara:data
          :min-date="minimumDate"
          :max-date="maiorData"
          :unselectable-days-of-week="unselectableDays"
          :unselectable-dates="nationalHolidays"
          v-model="$v.data.reopeningDate.$model"
          @input="value => updateReopeningDate('reopeningDate', value)"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        >
        </b-datepicker>
      </b-field>

      <b-field
        expanded
        label="Horário"
        :type="campoInvalido('reopeningHour', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('reopeningHour', 'data')
            ? 'Horário de reabertura é um campo obrigatório'
            : ''
        "
      >
        <b-timepicker
          editable
          maxlength="5"
          placeholder="00:00"
          icon="clock"
          v-mascara:hora
          v-model="$v.data.reopeningHour.$model"
          @input="value => updateTime(value)"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>
    </b-field>
  </div>
</template>

<script>
import Holidays from 'date-holidays';
import { required } from 'vuelidate/lib/validators';

import fieldMixin from '@/mixins/formulario';

export default {
  name: 'AppDateTime',
  mixins: [fieldMixin],
  props: {
    workDays: { type: Boolean, default: false },
    menorData: {
      type: Date,
      default: () =>
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    },
    maiorData: { type: Date, default: null },
  },
  data() {
    const data = {
      reopeningDate: null,
      reopeningHour: null,
    };

    const hd = new Holidays('BR');
    const unselectableDays = [0, 6];
    const nationalHolidays = [];

    return {
      data,
      unselectableDays,
      holidays: hd
        .getHolidays(new Date().getFullYear())
        .concat(hd.getHolidays(new Date().getFullYear() + 1)),
      nationalHolidays,
      invalidDateTime: false,
    };
  },
  validations: {
    data: {
      reopeningDate: { required },
      reopeningHour: { required },
    },
  },
  computed: {
    minimumDate() {
      const cloneData = new Date(this.menorData);
      return cloneData ? new Date(cloneData.setHours(0, 0, 0, 0)) : null;
    },
  },
  methods: {
    mapNationalHolidays() {
      this.nationalHolidays = this.holidays
        .filter(element => element.type === 'public')
        .map(holiday => new Date(holiday.date));
    },
    updateReopeningDate(field, value) {
      if (value) {
        this.$v.data[field].$model = value;

        if (this.data.reopeningHour && this.data.reopeningDate > new Date()) {
          this.data.reopeningDate.setHours(this.data.reopeningHour.getHours());
          this.data.reopeningDate.setMinutes(this.data.reopeningHour.getMinutes());
          this.$emit('datetime', this.data.reopeningDate);
        }
      }
      this.$v.data[field].$touch();
    },
    updateTime(value) {
      if (value) {
        this.data.reopeningDate.setHours(value.getHours());
        this.data.reopeningDate.setMinutes(value.getMinutes());
      }
      if (this.data.reopeningDate > new Date()) {
        if (this.menorData && this.data.reopeningDate < this.menorData) {
          this.$alerta('Data de reabertura menor que 24 horas', 'is-danger', 3000);
          this.invalidDateTime = true;
        } else if (this.maiorData && this.data.reopeningDate > this.maiorData) {
          this.$alerta('Data de reabertura maior que 60 dias', 'is-danger', 3000);
          this.invalidDateTime = true;
        } else {
          this.$emit('datetime', this.data.reopeningDate);
          this.invalidDateTime = false;
        }
      } else {
        this.$alerta('Data de reabertura menor que data atual', 'is-danger', 3000);
        this.invalidDateTime = true;
      }
    },
  },
  mounted() {
    if (this.workDays) this.mapNationalHolidays();
  },
};
</script>
