import Vue from 'vue';

import {
  createAdministrator,
  deleteCompanyUser,
  deletePrefectureUser,
  getUsers,
  invitePrefectureUser,
  inviteCompanyUser,
  linkGuestUser,
  savePrefecturePermissions,
  saveCompanyPermissions,
} from '@/resources/companies/companiesUsers';

const types = {
  COMPANY_USERS: 'COMPANY_USERS',
  COMPANY_USERS_CREATE: 'COMPANY_USERS_CREATE',
  COMPANY_USERS_UPDATE: 'COMPANY_USERS_UPDATE',
  COMPANY_USERS_DELETE: 'COMPANY_USERS_DELETE',
  COMPANY_USERS_CLEAR: 'COMPANY_USERS_CLEAR',
};

const mutations = {
  [types.COMPANY_USERS](state, users) {
    if (users && Array.isArray(users)) {
      state.companyUsers = users;
    }
  },
  [types.COMPANY_USERS_CREATE](state, user) {
    if (user && typeof user === 'object') {
      state.companyUsers = [...state.companyUsers, user];
    }
    state.companyUsers = state.companyUsers.sort((a, b) => a.function.localeCompare(b.function));
    state.companyUsers = state.companyUsers.sort(element => (element ? 1 : -1));
  },
  [types.COMPANY_USERS_UPDATE](state, user) {
    if (user && typeof user === 'object') {
      const index = state.companyUsers.findIndex(
        companyUser => companyUser.companyUserId === user.companyUserId,
      );
      if (index > -1) {
        Vue.set(state.companyUsers, index, user);
      } else {
        state.companyUsers = [...state.companyUsers, user];
      }
    }
  },
  [types.COMPANY_USERS_DELETE](state, companyUserId) {
    if (companyUserId) {
      const index = state.companyUsers.findIndex(user => user.companyUserId === companyUserId);
      if (index > -1) state.companyUsers.splice(index, 1);
    }
  },
  [types.COMPANY_USERS_CLEAR](state) {
    state.companyUsers = [];
  },
};

const actions = {
  async createAdministrator({ commit }, userData) {
    const response = await createAdministrator(userData.companyId, userData);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_USERS_CREATE, response.data);
  },
  async deleteCompanyUser({ commit }, data) {
    const response = await deleteCompanyUser(data.companyId, data.companyUserId);
    if (response.status !== 200) return Promise.reject(data.companyUserId);
    return commit(types.COMPANY_USERS_DELETE, data.companyUserId);
  },
  async deletePrefectureUser({ commit }, data) {
    const response = await deletePrefectureUser(data.companyId, data.companyUserId);
    if (response.status !== 200) return Promise.reject(data.companyUserId);
    return commit(types.COMPANY_USERS_DELETE, data.companyUserId);
  },
  async getUsers({ commit }, companyId) {
    const response = await getUsers(companyId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.COMPANY_USERS, response.data.data);
  },
  async invitePrefectureUser({ dispatch, commit }, userData) {
    const response = await invitePrefectureUser(userData.companyId, userData);
    if (response.status !== 201) return Promise.reject(response.data);
    if (response.data.function === 'ADMINISTRADOR') {
      return dispatch('getUsers', response.data.companyId);
    }
    return commit(types.COMPANY_USERS_CREATE, response.data);
  },
  async inviteCompanyUser({ commit }, userData) {
    const response = await inviteCompanyUser(userData.companyId, userData);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_USERS_CREATE, response.data);
  },
  async linkGuestUser({ commit }, userData) {
    const response = await linkGuestUser(userData.companyId, userData);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_USERS_UPDATE, response.data);
  },
  async savePrefecturePermissions({ dispatch, commit }, data) {
    const { empresaId, id } = data;
    const response = await savePrefecturePermissions(empresaId, id, data);
    if (response.status !== 201) return Promise.reject(response.data);
    if (response.data.function === 'ADMINISTRADOR') {
      return dispatch('getUsers', response.data.companyId);
    }
    return commit(types.COMPANY_USERS_UPDATE, response.data);
  },
  async saveCompanyPermissions({ commit }, data) {
    const { empresaId, id } = data;
    const response = await saveCompanyPermissions(empresaId, id, data);
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_USERS_UPDATE, response.data);
  },
};

const getters = {
  getCompanyUsers(state) {
    return state.companyUsers;
  },
};

const state = {
  companyUsers: [],
};

export default {
  state,
  mutations,
  getters,
  actions,
};

export { types };
