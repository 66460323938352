<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <section>
      <div class="columns is-vcentered">
        <div class="column">
          <h3 v-if="loaded" class="title is-4 accepted-documents-title">
            Documentos Aceitos
          </h3>
        </div>
      </div>
    </section>

    <div class="panel">
      <div class="panel-block empty-panel">
        <section v-if="!auctionDocumentsList.length" class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhum licitante habilitado.</p>
          </div>
        </section>

        <b-table
          v-if="auctionDocumentsList.length"
          class="table is-fullwidth documents-table"
          :data="auctionDocumentsList"
        >
          <b-table-column label="ID" numeric v-slot="props">
            {{ (filters.page - 1) * filters.perPage + props.index + 1 }}
          </b-table-column>

          <b-table-column label="Empresa" v-slot="props">
            <div class="max-content-width">
              {{ props.row.socialDenomination }}

              <b-button
                class="ml-3"
                icon-right="zip-box"
                size="is-small"
                type="is-primary"
                v-if="shouldRenderDownloadZip(props.row)"
                :loading="loading.bidderId === props.row.bidderId"
                @click.stop.prevent="downloadCompanyDocumentsZip(props.row)"
                >Download Zip</b-button
              >
            </div>
          </b-table-column>

          <b-table-column label="Tipo" v-slot="props">
            <a :href="props.row.file | fileServer" download>{{ props.row.type }}</a>
            <b-tag
              v-if="props.row.type === 'Proposta Readequada'"
              class="item-tag ml-2"
              type="is-primary"
              >{{ `Item ${props.row.identifier}` }}</b-tag
            >
          </b-table-column>

          <b-table-column label="Origem" v-slot="props">
            <div class="fit-content-width">{{ formatOrigin(props.row.origin) }}</div>
          </b-table-column>

          <b-table-column label="Enviado em" centered v-slot="props">
            <div class="fit-content-width">{{ props.row.createdAt | formatarData }}</div>
          </b-table-column>

          <template v-slot:footer>
            <AppPagination
              :data-filters="{ page: filters.page, perPage: filters.perPage }"
              :total="auctionDocumentsListCount"
              @change-page="
                page => {
                  filters.page = page;
                  fetchAuctionDocumentsList({ auctionId: auction.id, filters });
                }
              "
              @change-per-page="
                perPage => {
                  filters.perPage = perPage;
                  fetchAuctionDocumentsList({ auctionId: auction.id, filters });
                }
              "
            />
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { mapActions, mapGetters, mapState } from 'vuex';
import saveAs from 'save-as';

import { fileServer } from '@/utils/functions';
import { formatarData, formatarDataSegundo } from '@/utils/format';
import sanitizeFileName from '@/utils/sanitizes';

import AppPagination from '@/components/AppPagination';

export default {
  name: 'DocumentsList',
  components: {
    AppPagination,
  },
  data() {
    const filters = {
      page: 1,
      perPage: 20,
    };
    const loading = {
      bidderId: 0,
      general: false,
    };

    return {
      fileServer,
      filters,
      loading,
      loaded: false,
    };
  },
  computed: {
    ...mapState('auctionsDocuments', ['auctionDocumentsList', 'auctionDocumentsListCount']),
    ...mapGetters({
      auction: 'obterOfertaCompra',
    }),
  },
  filters: {
    formatarData,
    formatarDataSegundo,
    fileServer,
  },
  methods: {
    ...mapActions('auctionsDocuments', ['fetchAuctionDocumentsList']),
    async downloadCompanyDocumentsZip(row) {
      const companyDocuments = this.filterCompanyDocuments(row.bidderId).map(
        companyDocument => companyDocument,
      );

      const zip = new JSZip();
      const zipFilename = `${sanitizeFileName(row.socialDenomination)}.zip`;
      let count = 0;
      let errorCount = 0;

      this.loading.bidderId = row.bidderId;
      const promises = companyDocuments.map(async (document, index) => {
        try {
          const file = await JSZipUtils.getBinaryContent(fileServer(document.file));
          const fileExtension = document.file.split('.').pop();
          zip.file(
            `${this.formatDocumentName(companyDocuments, index, document)}.${fileExtension}`,
            file,
            {
              binary: true,
            },
          );
          count += 1;

          if (count === companyDocuments.length) {
            zip.generateAsync({ type: 'blob' }).then(content => {
              saveAs(content, zipFilename);
            });
          }
        } catch (_) {
          errorCount += 1;
        }
      });

      await Promise.all(promises);

      if (errorCount) this.$alerta('Erro no download', 'is-danger');

      this.loading.bidderId = 0;
    },
    filterCompanyDocuments(bidderId) {
      return this.auctionDocumentsList.filter(document => document.bidderId === bidderId);
    },
    formatDocumentName(companyDocuments, index, document) {
      const isReadjustedProposal = document.type === 'Proposta Readequada';
      const notReadjustedProposal = companyDocuments.filter(
        element => element.type === 'Proposta Readequada',
      );
      const getReadjustedProposalIndex = documentId =>
        notReadjustedProposal.findIndex(element => element.id === documentId) + 1;

      return `${index + 1}-${sanitizeFileName(document.type.replaceAll('/', ' '))}${
        isReadjustedProposal ? `-Item-${getReadjustedProposalIndex(document.id)}` : ''
      }`;
    },
    formatOrigin(origin) {
      return {
        PROPOSTAS: 'Propostas',
        HABILITACAO: 'Habilitação',
        'HABILITACAO-ADESAO': 'Habilitação da Adesão',
      }[origin];
    },
    shouldRenderDownloadZip(row) {
      const companyDocuments = this.filterCompanyDocuments(row.bidderId);
      return companyDocuments && companyDocuments.length && companyDocuments[0].id === row.id;
    },
  },
  async created() {
    this.loading.general = true;
    await this.fetchAuctionDocumentsList({
      auctionId: this.auction.id,
      filters: this.filters,
    });
    this.loading.general = false;

    this.loaded = true;
  },
};
</script>

<style scoped>
a {
  color: #1a0dab !important;
}

a:hover {
  color: #363636 !important;
}

.accepted-documents-title {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.fit-content-width {
  min-width: fit-content;
}

.max-content-width {
  min-width: max-content;
}

@media (max-width: 1500px) {
  .documents-table {
    font-size: 0.98rem;
  }
}

.empty-panel {
  justify-content: center !important;
}

.item-tag {
  margin-left: 0.2rem;
}
</style>
