<template>
  <div>
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h3 class="title has-text-white">Considerações</h3>
          <div class="box">
            <form>
              <b-field>
                <textarea
                  ref="consideration"
                  class="textarea"
                  placeholder="Digite aqui suas considerações finais."
                  v-model="consideracao"
                ></textarea>
              </b-field>
              <button
                class="button is-success rm-1"
                :class="{ ' is-loading': enviarLoading }"
                @click.stop.prevent="salvar()"
              >
                Enviar
              </button>

              <button class="button" @click.stop.prevent="fecharModal()">
                Cancelar
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AtaConsideracao',
  data() {
    return {
      consideracao: '',
      enviarLoading: false,
    };
  },
  methods: {
    salvar() {
      this.enviarLoading = true;
      this.$parent.$emit('considerar', this.consideracao);
    },
    fecharModal() {
      this.enviarLoading = false;
      this.$parent.onCancel();
      this.$parent.close();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.consideration.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
.rm-1 {
  margin-right: 3px;
}

.textarea {
  width: 350px;
}
</style>
