<template>
  <div class="action-confirmation-container">
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <h3 class="title has-text-white has-text-centered">Atenção!</h3>
          <div class="box custom-box">
            <div class="message-container has-text-left">
              <b-icon
                class="custom-icon"
                icon="alert-box"
                size="is-large"
                v-if="hasIcon"
                :type="iconType"
              ></b-icon>
              <div class="message-warning-container">
                <div class="warning-text" v-if="warning">{{ warning }}</div>
                <div>{{ message }}</div>
              </div>
            </div>

            <b-table class="items-table" :data="items">
              <b-table-column label="ID" v-slot="props" :visible="display.identifier">
                {{ props.row.identifier }}
              </b-table-column>

              <b-table-column
                label="Descrição"
                v-slot="props"
                :class="{ 'elipsis-column': !mobile }"
                :visible="display.description"
              >
                <div class="elipsis-column">{{ props.row.description }}</div>
              </b-table-column>

              <b-table-column label="Unidade" :visible="display.unity" v-slot="props">
                {{ props.row.unity }}
              </b-table-column>

              <b-table-column label="Marca" :visible="display.brand" v-slot="props">
                {{ props.row.brand }}
              </b-table-column>

              <b-table-column centered label="Importado" :visible="display.imported" v-slot="props">
                {{ props.row.imported ? 'Sim' : 'Não' }}
              </b-table-column>

              <b-table-column label="Percentual" :visible="display.percent" v-slot="props">
                {{ props.row.percent !== null ? `${formatPercent(props.row.percent)}%` : null }}
              </b-table-column>

              <b-table-column label="Valor" :visible="display.value" v-slot="props">
                {{ props.row.value ? formatValue(props.row.value) : 0 }}
              </b-table-column>
            </b-table>

            <div class="footer-container">
              <div class="buttons-container has-text-right">
                <b-button
                  class="rm-1"
                  :size="mobile ? 'is-small' : 'is-normal'"
                  @click.stop.prevent="closeModal()"
                >
                  {{ cancelText }}
                </b-button>

                <b-button
                  ref="confirm"
                  :type="confirmType"
                  :size="mobile ? 'is-small' : 'is-normal'"
                  @click.stop.prevent="sendConfirmation"
                >
                  {{ confirmText }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { formatarValor as formatValue, formatarPorcentagem as formatPercent } from '@/utils/format';

export default {
  name: 'AppActionConfirmation',
  props: {
    cancelText: { type: String, default: 'Cancelar' },
    confirmText: { type: String, default: 'Confirmar' },
    confirmType: { type: String, default: 'is-success' },
    display: {
      type: Object,
      default: () => ({
        identifier: true,
        description: true,
        unity: false,
        percent: false,
        value: true,
        brand: false,
        imported: false,
      }),
    },
    hasIcon: { type: Boolean, default: true },
    items: { type: Array, default: () => [] },
    iconType: { type: String, default: 'is-info' },
    message: { type: String, default: '' },
    mobile: { type: Boolean, default: false },
    warning: { type: String, default: '' },
  },
  data() {
    return {
      formatPercent,
      formatValue,
    };
  },
  methods: {
    closeModal() {
      this.$parent.onCancel();
      this.$parent.close();
    },
    sendConfirmation() {
      this.$parent.$emit('confirm');
      this.closeModal();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.confirm.$el.focus();
    });
  },
};
</script>

<style lang="scss" scoped>
.action-confirmation-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttons-container {
  margin-top: 1rem;
}

.custom-box {
  display: flex;
  flex-direction: column;
}

.custom-icon {
  margin-right: 0.5rem;
}

.elipsis-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer-container {
  margin-top: 0.5rem;
}

.items-table {
  font-size: 0.88rem;
  height: 100%;
  max-height: 40vh;
  overflow: auto;
}

.justify-align {
  text-align: justify;
}

.message-container {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
}

.message-warning-cotainer {
  display: flex;
  flex-direction: row;
}

.rm-1 {
  margin-right: 3px;
}

.warning-text {
  margin-bottom: 1rem;
}

@media (min-width: 300px), (max-width: 768px) {
  .elipsis-column {
    max-width: 15ch;
  }
}

@media (min-width: 769px), (max-width: 1407px) {
  .elipsis-column {
    max-width: 30ch;
  }
}

@media (min-width: 1408px) {
  .elipsis-column {
    max-width: 35ch;
  }
}
</style>
