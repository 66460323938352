<template>
  <div>
    <div v-if="!anexos.length" class="panel">
      <div class="panel-block is-justify-content-center">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nenhum edital ou anexo.</p>
          </div>
        </section>
      </div>
    </div>

    <b-table hoverable v-if="anexos.length" :data="anexos">
      <b-table-column label="Tipo" v-slot="props">
        <b-tooltip
          :label="props.row.tipo === 'EDITAL' ? 'Edital' : 'Anexo'"
          :position="windowWidth < 769 ? 'is-left' : 'is-right'"
        >
          <b-icon
            type="is-primary"
            :icon="props.row.tipo === 'EDITAL' ? 'alpha-e-box' : 'alpha-a-box'"
          ></b-icon>
        </b-tooltip>
      </b-table-column>

      <b-table-column label="Nome" v-slot="props">
        <a :href="props.row.arquivo | fileServer" download>{{ props.row.nome }}</a>
        <b-tag v-if="props.row.retificacao" type="is-info">Retificado</b-tag>
      </b-table-column>

      <b-table-column centered label="Tamanho" v-slot="props">
        {{ props.row.tamanho | tamanhoArquivo }}
      </b-table-column>

      <b-table-column
        expanded
        label="Observações"
        v-slot="props"
        :visible="anexos.some(anexo => anexo.observacao)"
      >
        <pre v-if="props.row.observacao">{{ props.row.observacao }}</pre>
      </b-table-column>

      <b-table-column centered label="Data" v-slot="props">
        {{ props.row.criado_em | formatarData }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { fileServer } from '@/utils/functions';
import { tamanhoArquivo, formatarData } from '@/utils/format';

export default {
  name: 'DispensaEdital',
  filters: {
    tamanhoArquivo,
    formatarData,
    fileServer,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters({
      anexos: 'obterAnexosDispensa',
    }),
  },
};
</script>

<style lang="scss" scoped>
pre {
  background-color: #ededed;
  font-family: unset;
  word-break: break-all;
  white-space: pre-wrap;
}
</style>
