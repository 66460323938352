<template>
  <div>
    <div v-if="exibirTexto" class="comprador">
      <ul>
        <li><strong>Nome:</strong> {{ dispensa.comprador.nome }}</li>
        <li><strong>CNPJ:</strong> {{ dispensa.comprador.cnpj }}</li>
        <li><strong>Endereço:</strong> {{ dispensa.comprador | enderecoComprador }}</li>
        <li><strong>Cidade:</strong> {{ dispensa.comprador.municipio }}</li>
        <li><strong>Telefone:</strong> {{ dispensa.comprador.telefone }}</li>
        <li><strong>Endereço de Entrega:</strong> {{ dispensa | enderecoEntrega }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DispensaComprador',
  filters: {
    enderecoEntrega(endereco) {
      if (endereco.endereco_entrega_complemento) {
        return `${endereco.endereco_entrega}, ${endereco.endereco_entrega_numero} - ${endereco.endereco_entrega_complemento} - ${endereco.endereco_entrega_bairro} - ${endereco.endereco_entrega_municipio}-${endereco.endereco_entrega_uf}`;
      }
      return `${endereco.endereco_entrega}, ${endereco.endereco_entrega_numero} - ${endereco.endereco_entrega_bairro} - ${endereco.endereco_entrega_municipio}-${endereco.endereco_entrega_uf}`;
    },
    enderecoComprador(endereco) {
      if (endereco.complemento) {
        return `${endereco.endereco}, ${endereco.numero} - ${endereco.complemento} - ${endereco.bairro} - ${endereco.municipio}-${endereco.uf}`;
      }
      return `${endereco.endereco}, ${endereco.numero} - ${endereco.bairro} - ${endereco.municipio}-${endereco.uf}`;
    },
  },
  computed: {
    ...mapGetters({
      dispensa: 'obterDispensa',
    }),
    exibirTexto() {
      return this.dispensa.id !== 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.div-paragraph {
  justify-content: center !important;
}
</style>
