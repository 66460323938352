/* eslint-disable no-confusing-arrow */
import Vue from 'vue';

import { getLances as dadosLances, getLancesItem as dadosLancesItem } from '@/resources/dispensas';

const types = {
  DISPENSA_LANCES: 'DISPENSA_LANCES',
  DISPENSA_LANCES_ITEM: 'DISPENSA_LANCES_ITEM',
  DISPENSA_LANCES_ADICIONAR: 'DISPENSA_LANCES_ADICIONAR',
  DISPENSA_LANCES_PARA_ENVIAR: 'DISPENSA_LANCES_PARA_ENVIAR',
  DISPENSA_LANCES_PARA_ENVIAR_REMOVER: 'DISPENSA_LANCES_PARA_ENVIAR_REMOVER',
  DISPENSA_LANCES_ATUALIZAR: 'DISPENSA_LANCES_ATUALIZAR',
  DISPENSA_LANCES_ITEM_ATUALIZAR: 'DISPENSA_LANCES_ITEM_ATUALIZAR',
  DISPENSA_LIMPAR_LANCES: 'DISPENSA_LIMPAR_LANCES',
  DISPENSA_LIMPAR_LANCES_PARA_ENVIAR: 'DISPENSA_LIMPAR_LANCES_PARA_ENVIAR',
  DISPENSA_EXIBIR_AVISO: 'DISPENSA_EXIBIR_AVISO',
};

const mutations = {
  [types.DISPENSA_LANCES](state, data) {
    if (data && Array.isArray(data)) {
      state.lances = data;
    } else {
      state.lances.push(data);
    }
  },
  [types.DISPENSA_LANCES_PARA_ENVIAR](state, data) {
    if (data && Array.isArray(data)) {
      state.lancesParaEnviar = data;
    } else if (data && typeof data === 'object') {
      const dados = data;

      if (!state.lancesParaEnviar.length) {
        state.lancesParaEnviar.push(dados);
      } else {
        const indice = state.lancesParaEnviar.findIndex(
          lance => lance.dispensaItemId === dados.dispensaItemId,
        );

        if (indice === -1) {
          state.lancesParaEnviar.push(dados);
        } else {
          const novoObjeto = { ...state.lancesParaEnviar[indice], ...dados };

          Vue.set(state.lancesParaEnviar, indice, novoObjeto);
        }
      }
    }
  },
  [types.DISPENSA_LANCES_PARA_ENVIAR_REMOVER](state, data) {
    const indice = data;
    state.lancesParaEnviar.splice(indice, 1);
  },
  [types.DISPENSA_LANCES_PARA_ENVIAR_ATUALIZAR_MARCA](state, data) {
    const novoObjeto = state.lancesParaEnviar[data.indice];
    novoObjeto.marca = data.marca;
    Vue.set(state.lancesParaEnviar, data.indice, novoObjeto);
  },
  [types.DISPENSA_LANCES_PARA_ENVIAR_ATUALIZAR_IMPORTADO](state, data) {
    const novoObjeto = state.lancesParaEnviar[data.indice];
    novoObjeto.importado = data.importado;
    Vue.set(state.lancesParaEnviar, data.indice, novoObjeto);
  },
  [types.DISPENSA_LANCES_ADICIONAR](state, data) {
    if (data && Array.isArray(data)) {
      state.lances = [...state.lances, ...data];
    } else if (data && typeof data === 'object') {
      state.lances.push(data);
    }
  },
  [types.DISPENSA_LANCES_ATUALIZAR](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const lance = data.shift();
        state.lances.push(lance);
      }
    } else if (data && typeof data === 'object') {
      state.lances.push(data);
    }
  },
  [types.DISPENSA_LANCES_ITEM_ATUALIZAR](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const dataLance = data.shift();
        const index = state.lances.findIndex(
          lance =>
            lance.dispensaItemId === dataLance.dispensaItemId && lance.valor === dataLance.valor,
        );
        if (index > -1) {
          Vue.set(state.lances, index, dataLance);
        } else {
          state.lances.push(data);
        }
      }
    } else if (data && typeof data === 'object') {
      const index = state.lances.findIndex(
        lance =>
          lance.dispensaItemId === data.dispensaItemId &&
          lance.valor === data.valor &&
          lance.status === data.status,
      );
      if (index > -1) {
        Vue.set(state.lances, index, data);
      } else {
        state.lances.push(data);
      }
    }
  },
  [types.DISPENSA_LANCES_ITEM](state, data) {
    if (data && Array.isArray(data)) {
      state.lancesItem = data;
    } else if (data && typeof data === 'object') {
      state.lancesItem.unshift(data);
    }
    state.lancesItem = state.lancesItem.sort((a, b) => {
      const valor = a.valor - b.valor;
      if (valor !== 0) return valor;

      const dados = a.dados - b.dados;
      if (dados !== 0) return dados;

      return a.id - b.id;
    });
  },
  [types.ATUALIZAR_CLASSIFICACAO_LANCES_ITEM](state, data) {
    if (data && Array.isArray(data)) {
      while (data.length) {
        const p = data.shift();
        const index = state.propostas.findIndex(proposta => proposta.id === p.id);
        if (index > -1) {
          const novoObjeto = state.lancesItem[index];
          novoObjeto.status = data.estado;
          Vue.set(state.lancesItem, index, novoObjeto);
        }
      }
    } else if (data && typeof data === 'object') {
      const index = state.lancesItem.findIndex(proposta => proposta.id === data.id);
      if (index > -1) {
        const novoObjeto = state.lancesItem[index];
        novoObjeto.status = data.estado;
        Vue.set(state.lancesItem, index, novoObjeto);
      }
    }
  },
  [types.DISPENSA_LIMPAR_LANCES](state) {
    state.lances = [];
    state.lancesItem = [];
  },
  [types.DISPENSA_LIMPAR_LANCES_PARA_ENVIAR](state) {
    state.lancesParaEnviar = [];
  },
  [types.DISPENSA_EXIBIR_AVISO](state, data) {
    state.exibirAviso = data;
  },
};

const actions = {
  async buscarDispensaItemLances({ commit }, { dispensaId, itemId }) {
    const response = await dadosLancesItem(dispensaId, itemId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(types.DISPENSA_LANCES_ITEM, response.data.data);
  },
  async buscarDispensaLances({ commit, rootState }, { dispensaId, tipo }) {
    const { logged } = rootState.users;
    if (logged) {
      const { data: lances } = await dadosLances(dispensaId, tipo);
      commit(types.DISPENSA_LANCES, lances.data);
    }
  },
  atualizarLancesDispensa({ commit }, lances) {
    commit(types.DISPENSA_LANCES_ATUALIZAR, lances);
  },
  adicionarDispensaLance({ commit }, lance) {
    commit(types.DISPENSA_LANCES_PARA_ENVIAR, lance);
  },
  removerLanceParaEnviar({ commit }, data) {
    commit(types.DISPENSA_LANCES_PARA_ENVIAR_REMOVER, data);
  },
  atualizarLanceParaEnviarMarca({ commit }, data) {
    commit(types.DISPENSA_LANCES_PARA_ENVIAR_ATUALIZAR_MARCA, data);
  },
  atualizarLanceParaEnviarImportado({ commit }, data) {
    commit(types.DISPENSA_LANCES_PARA_ENVIAR_ATUALIZAR_IMPORTADO, data);
  },
  adicionarLancesEmpresaDispensa({ commit }, lances) {
    commit(types.DISPENSA_LANCES_ADICIONAR, lances);
  },
  atualizarLancesItemEmpresaDispensa({ commit }, lances) {
    commit(types.DISPENSA_LANCES_ITEM_ATUALIZAR, lances);
  },
  adicionarLanceItemDispensa({ commit }, lance) {
    commit(types.DISPENSA_LANCES_ITEM, lance);
  },
  alternarExibirAviso({ commit }, data) {
    commit(types.DISPENSA_EXIBIR_AVISO, data);
  },
  limparLancesDispensa({ commit }) {
    commit(types.DISPENSA_LIMPAR_LANCES);
  },
  limparLancesParaEnviar({ commit }) {
    commit(types.DISPENSA_LIMPAR_LANCES_PARA_ENVIAR);
  },
};

const getters = {
  obterDispensaLances(state) {
    return state.lances;
  },
  obterDispensaItemLances(state) {
    return state.lancesItem;
  },
  obterLancesParaEnviar(state) {
    return state.lancesParaEnviar;
  },
  obterExibirAviso(state) {
    return state.exibirAviso;
  },
};

const state = {
  lances: [],
  lancesItem: [],
  lancesParaEnviar: [],
  exibirAviso: false,
};

export default {
  state,
  mutations,
  actions,
  getters,
};

export { types };
