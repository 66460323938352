<template>
  <div class="company-register-container">
    <form @keyup.enter="registrationCompany">
      <b-field
        label="Denominação Social"
        :type="campoInvalido('socialDenomination', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('socialDenomination', 'data')
            ? 'Denominação Social é um campo obrigatório'
            : ''
        "
      >
        <b-input
          custom-class="uppercase"
          placeholder="Denominação Social"
          v-model="$v.data.socialDenomination.$model"
          @blur="evento => converterMaiusculo('socialDenomination', evento.target.value, 'data')"
        />
      </b-field>

      <b-field
        label="CNPJ"
        :type="campoInvalido('cnpj', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('cnpj', 'data')
            ? 'CNPJ é um campo obrigatório. Insira um número válido'
            : ''
        "
      >
        <b-input
          maxlength="18"
          placeholder="CNPJ"
          v-mascara:cnpj
          :has-counter="false"
          v-model="$v.data.cnpj.$model"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>

      <b-button
        expanded
        class="register-button"
        type="is-primary"
        :loading="loading.button"
        @click.prevent.stop="registerCompany()"
        >Continuar</b-button
      >
    </form>
  </div>
</template>

<script>
import { isValidCnpj } from '@brazilian-utils/validators';
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import formValidation from '@/mixins/formulario';

export default {
  name: 'CompanyRegister',
  mixins: [formValidation],
  data() {
    const data = {
      cnpj: '',
      socialDenomination: '',
    };

    const loading = {
      button: false,
    };

    return {
      data,
      loading,
    };
  },
  validations: {
    data: {
      cnpj: { isValidCnpj },
      socialDenomination: { required },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      user: 'getUser',
    }),
  },
  methods: {
    ...mapActions('companies', ['createCompany']),
    ...mapActions(['createAdministrator', 'login']),
    async registerCompany() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        return;
      }

      this.loading.button = true;
      try {
        await this.createCompany(this.data);
        await this.createAdministrator({ companyId: this.company.id, userId: this.user.id });
        await this.login({ chooseCompany: true, companyId: this.company.id });
        this.$alerta('Cadastrada com sucesso', 'is-success');
        this.$emit('company-registered');
      } catch (error) {
        const { response } = error;
        if (response.status === 400 && response.data.message === 'ValidationError') {
          if (response.data.details.message === 'User is already a member of a buyer') {
            this.$alerta('Usuário de prefeitura não pode cadastrar empresa', 'is-danger');
            return;
          }

          if (response.data.details.message === 'Seller already registered') {
            this.$alerta('Empresa já cadastrada', 'is-danger');
            return;
          }
        }

        this.$alerta(error.message, 'is-danger');
      } finally {
        this.loading.button = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company-register-container {
  min-width: 80%;
}

.register-button {
  margin-top: 1.5rem;
}
</style>
