<template>
  <div>
    <object height="1000px" type="application/pdf" width="100%" :data="linkPdfTermoHomologacao">
      <div class="minutes-pdf-download">
        <p class="minutes-pdf-download-text">O navegador não suporta PDF.</p>

        <a download class="button is-primary is-medium" :href="getTermoHomologacaoArquivo"
          >Download Termo Homologacção</a
        >
      </div>
    </object>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fileServer } from '@/utils/functions';

export default {
  name: 'AuctionHomologationTerm',
  computed: {
    ...mapGetters({
      auction: 'obterOfertaCompra',
    }),
    linkPdfTermoHomologacao() {
      const proportion = this.windowWidth / 10;
      return `${this.getTermoHomologacaoArquivo}#zoom=${
        proportion < 120 ? proportion : 120
      }&view=FitB`;
    },
    getTermoHomologacaoArquivo() {
      return fileServer(this.auction.termoHomologacaoArquivo);
    },
  },
};
</script>

<style scoped>
.empty-panel {
  justify-content: center !important;
}

.message {
  white-space: pre-line;
}

.send-button {
  margin-top: 0.75rem;
  width: 150px;
}

.situation-custom-tooltip {
  vertical-align: middle;
}

@media (max-width: 1023px) {
  .send-button {
    width: 100%;
  }
}
</style>
