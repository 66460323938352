import { fetchUserLoggedBidder } from '@/resources/electronicAuctions/auctions';

const types = {
  UPDATE_USER_LOGGED_BIDDER: 'UPDATE_USER_LOGGED_BIDDER',
  CLEAR_USER_LOGGED_BIDDER: 'CLEAR_USER_LOGGED_BIDDER',
};

const mutations = {
  [types.UPDATE_USER_LOGGED_BIDDER](state, data) {
    if (data && typeof data === 'object') {
      state.userLoggedBidder = data;
    } else {
      state.userLoggedBidder = {
        id: 0,
        alias: null,
      };
    }
  },
  [types.CLEAR_USER_LOGGED_BIDDER](state) {
    state.userLoggedBidder = {
      id: 0,
      alias: null,
    };
  },
};

const actions = {
  async fetchUserLoggedBidder({ commit }, auctionId) {
    const response = await fetchUserLoggedBidder(auctionId);
    if (response.status !== 200 && response.status !== 204) return Promise.reject(response);
    return commit(types.UPDATE_USER_LOGGED_BIDDER, response.data.data);
  },
};

const state = {
  userLoggedBidder: {
    id: 0,
    alias: null,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
