import store from '@/vuex/store';

export const CREDENTIALS = {
  FLUSH: 'FLUSH',
  KEEP_CONNECTED: 'keep_connected',
  REQUESTING_SHARED: 'REQUESTING_SHARED',
  SHARING: 'SHARING',
  VALUE: 'credentials',
};

export const adminNotAllowed = (to, from, next) => {
  if (store.state.companies.company.admin) {
    return next({
      path: '/admin',
    });
  }

  return next();
};

export const adminProtectedPage = (to, from, next) => {
  if (!store.state.companies.company.admin) {
    return next({
      path: '/',
    });
  }

  return next();
};

export const buyerPageProtected = async (to, from, next) => {
  if (store.getters.getLogged) {
    const { company } = store.state.companies;

    if (company.buyer) {
      if (to.name === 'Pregao' && !store.getters.obterOfertaCompra.id) {
        await store.dispatch('buscarOfertaCompra', to.params.id);
        const auction = store.getters.obterOfertaCompra;

        if (company.id !== auction.comprador.id) {
          return next({
            path: '/',
          });
        }
      } else if (to.name === 'Dispensa' && !store.getters.obterDispensa.id) {
        await store.dispatch('buscarDispensa', to.params.id);
        const dispensation = store.getters.obterDispensa;

        if (company.id !== dispensation.comprador.id) {
          return next({
            path: '/',
          });
        }
      }
    }
  }

  return next();
};

export const companyPage = async (to, from, next) => {
  const { company } = store.state.companies;
  if (company.id !== Number(to.params.id)) {
    return next({
      path: '/',
    });
  }

  return next();
};

export const companyValidationProtection = (to, from, next) => {
  if (!store.getters.getLogged) {
    return next({
      path: '/entrar',
      query: {
        redirect: to.fullPath,
      },
    });
  }
  if (to.name === 'CompanyValidation') {
    const { company } = store.state.companies;
    if (company.id !== Number(to.params.id)) {
      return next({
        path: '/',
      });
    }
    if (['HABILITADA', 'INABILITADA'].indexOf(company.status) > -1) {
      return next({
        path: `/empresas/${company.id}/dados`,
      });
    }
  }
  return next();
};

export const editProtected = async (to, from, next) => {
  if (
    [
      'PregaoEditarDados',
      'PregaoEditarAnexos',
      'PregaoEditarItens',
      'PregaoEditarResponsaveis',
    ].indexOf(to.name) > -1
  ) {
    if (!store.getters.obterOfertaCompra.id) {
      await store.dispatch('buscarOfertaCompra', to.params.id);
    }
    if (
      store.state.companies.company.id !== store.getters.obterOfertaCompra.comprador.id ||
      store.getters.obterOfertaCompra.situacao.id > 3
    ) {
      return next({
        path: '/',
      });
    }
  }

  if (
    ['DispensaEditarDados', 'DispensaEditarAnexos', 'DispensaEditarItens'].indexOf(to.name) > -1
  ) {
    if (!store.getters.obterDispensa.id) await store.dispatch('buscarDispensa', to.params.id);
    if (
      store.state.companies.company.id !== store.getters.obterDispensa.comprador.id ||
      store.getters.obterDispensa.situacao.id !== 1
    ) {
      return next({
        path: '/',
      });
    }
  }

  return next();
};

export const handleSession = () => {
  window.addEventListener('storage', event => {
    const keepConnected = localStorage.getItem(CREDENTIALS.KEEP_CONNECTED);
    const credentials = keepConnected
      ? localStorage.getItem(CREDENTIALS.VALUE)
      : sessionStorage.getItem(CREDENTIALS.VALUE);

    if (event.key === CREDENTIALS.REQUESTING_SHARED && credentials) {
      localStorage.setItem(CREDENTIALS.SHARING, credentials);
      localStorage.removeItem(CREDENTIALS.SHARING);
    }

    if (event.key === CREDENTIALS.SHARING && !credentials) {
      if (keepConnected) {
        localStorage.setItem(CREDENTIALS.VALUE, event.newValue);
      } else {
        sessionStorage.setItem(CREDENTIALS.VALUE, event.newValue);
      }

      store.dispatch('startSession');
    }

    setTimeout(() => {
      if (event.key === CREDENTIALS.FLUSH && credentials) {
        if (keepConnected) {
          localStorage.removeItem(CREDENTIALS.VALUE);
        } else {
          sessionStorage.removeItem(CREDENTIALS.VALUE);
        }
      }
    }, 1000);
  });
};

const updateToken = async () => {
  store.dispatch('logout');
};

export const invalidateSession = () => {
  const tokenExpiracao = store.getters.getExpirationToken;
  if (tokenExpiracao) {
    const tempoRestante = tokenExpiracao - new Date();
    if (tokenExpiracao < new Date()) {
      updateToken();
    } else {
      setTimeout(updateToken, tempoRestante);
    }
  }
};

export const linkedCompanyPage = (to, from, next) => {
  if (!store.getters.getUser.empresas.length) {
    return next({
      path: '/',
      query: {
        redirect: to.fullPath,
      },
    });
  }
  return next();
};

export const mountSharedCredentials = async () => {
  localStorage.setItem(CREDENTIALS.REQUESTING_SHARED, Date.now().toString());
  localStorage.removeItem(CREDENTIALS.REQUESTING_SHARED);
};

export const protectedPage = (to, from, next) => {
  if (!store.getters.getLogged) {
    return next({
      path: '/entrar',
      query: {
        redirect: to.fullPath,
      },
    });
  }
  return next();
};

export const visitorPage = async (to, from, next) => {
  if (store.getters.getLogged) {
    return next({
      path: '/',
    });
  }
  return next();
};

export const userProtectedPage = (to, from, next) => {
  const userId = store.getters.getUser.id;
  if (userId !== Number(to.params.id)) {
    return next({
      path: '/',
    });
  }
  return next();
};
