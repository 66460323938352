import {
  fetchCompanyInvitedSuppliers,
  addCompanyInvitedSuppliers,
} from '@/resources/companies/companiesInvitedSuppliers';

const types = {
  COMPANY_INVITED_SUPPLIERS: 'COMPANY_INVITED_SUPPLIERS',
  COMPANY_INVITED_SUPPLIERS_ADD: 'COMPANY_INVITED_SUPPLIERS_ADD',
  COMPANY_INVITED_SUPPLIERS_UPDATE_UPLOAD: 'COMPANY_INVITED_SUPPLIERS_UPDATE_UPLOAD',
  COMPANY_INVITED_SUPPLIERS_RESET_UPLOAD: 'COMPANY_INVITED_SUPPLIERS_RESET_UPLOAD',
};

const mutations = {
  [types.COMPANY_INVITED_SUPPLIERS_ADD](state, data) {
    if (data && typeof data === 'object') {
      state.invitedSuppliers.unshift(data);
    }
  },
  [types.COMPANY_INVITED_SUPPLIERS](state, data) {
    if (data && Array.isArray(data)) {
      state.invitedSuppliers = data;
    }
  },
  [types.COMPANY_INVITED_SUPPLIERS_UPDATE_UPLOAD](state, progress) {
    state.invitedSuppliersUpload = Math.round((progress.loaded / progress.total) * 100);
  },
  [types.COMPANY_INVITED_SUPPLIERS_RESET_UPLOAD](state) {
    state.invitedSuppliersUpload = 0;
  },
};

const actions = {
  async fetchCompanyInvitedSuppliers({ commit }, companyId) {
    const response = await fetchCompanyInvitedSuppliers(companyId);
    if (response.status !== 200) return Promise.reject(response.data);
    return commit(types.COMPANY_INVITED_SUPPLIERS, response.data);
  },
  async addCompanyInvitedSuppliers({ commit }, { companyId, data }) {
    const response = await addCompanyInvitedSuppliers(companyId, data, {
      onUploadProgress: progress => commit(types.COMPANY_INVITED_SUPPLIERS_UPDATE_UPLOAD, progress),
    });
    if (response.status !== 201) return Promise.reject(response.data);
    return commit(types.COMPANY_INVITED_SUPPLIERS_ADD, response.data);
  },
  resetCompanyInvitedSuppliersUpload({ commit }) {
    commit(types.COMPANY_INVITED_SUPPLIERS_RESET_UPLOAD);
  },
};

const getters = {};

const state = {
  invitedSuppliers: [],
  invitedSuppliersUpload: 0,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
