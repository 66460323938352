import {
  getResponsaveis as dadosResponsaveis,
  criarResponsavel,
  deletarResponsavel,
} from '@/resources/ofertasCompra';

const RESPONSAVEL_OBJECT = 'RESPONSAVEL_OBJECT';
const RESPONSAVEL_COLLECTION = 'RESPONSAVEL_COLLECTION';
const RESPONSAVEL_CLEAR = 'RESPONSAVEL_CLEAR';
const RESPONSAVEL_ADICIONAR = 'RESPONSAVEL_ADICIONAR';
const RESPONSAVEL_REMOVER = 'RESPONSAVEL_REMOVER';

const mutations = {
  [RESPONSAVEL_OBJECT](state, data) {
    if (data && typeof data === 'object') {
      state.responsavel = data;
    }
  },
  [RESPONSAVEL_COLLECTION](state, data) {
    if (data && typeof data === 'object' && data instanceof Array) {
      state.responsaveis = data;
    }
  },
  [RESPONSAVEL_CLEAR](state) {
    state.responsavel = {
      id: 0,
      name: '',
    };
    state.responsaveis = [];
  },
  [RESPONSAVEL_ADICIONAR](state, data) {
    state.responsaveis.push(data);
  },
  [RESPONSAVEL_REMOVER](state, data) {
    const index = state.responsaveis.findIndex(item => item.id === data);
    if (index > -1) {
      state.responsaveis.splice(index, 1);
    }
  },
};

const actions = {
  async buscarOfertaCompraResponsaveis({ commit }, ofertaCompraId) {
    const response = await dadosResponsaveis(ofertaCompraId);
    if (response.status !== 200) return Promise.reject(response);
    return commit(RESPONSAVEL_COLLECTION, response.data.data);
  },
  async adicionarOfertaCompraResponsavel({ commit, rootState }, data) {
    const response = await criarResponsavel(rootState.ofertasCompra.ofertaCompra.id, data);
    if (response.status !== 201) return Promise.reject(response);
    return commit(RESPONSAVEL_ADICIONAR, response.data.data);
  },
  async removerOfertaCompraResponsavel({ commit, rootState }, responsavelId) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const responsavel = await deletarResponsavel(ofertaCompraId, responsavelId);
    if (responsavel.status === 204) commit(RESPONSAVEL_REMOVER, responsavelId);
  },
  validarOfertaCompraResponsaveis({ state }) {
    return state.responsaveis.length > 0;
  },
  limparResponsaveisPregao({ commit }) {
    commit(RESPONSAVEL_CLEAR);
  },
};

const getters = {
  getResponsavelObject(state) {
    return state.responsavel;
  },
  obterOfertaCompraResponsaveis(state) {
    return state.responsaveis;
  },
};

const state = {
  responsavel: {
    id: 0,
    name: '',
  },
  responsaveis: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
