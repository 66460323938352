<template>
  <div>
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container is-title-fullhd">
          <div class="level">
            <div class="level-left">
              <div>
                <h1 class="title">Editar Dispensa</h1>
              </div>
            </div>
            <div class="level-end has-text-centered tm1">
              <div>
                <a
                  v-if="situacao < 2"
                  class="action-buttons button is-primary is-inverted is-outlined"
                  :class="{ ' is-loading': loading.publicar }"
                  @click.prevent.stop="publicar()"
                  >Publicar</a
                >

                <a
                  v-if="situacao === 1"
                  class="action-buttons button is-danger is-outlined"
                  :class="{ ' is-loading': loading.excluir }"
                  @click.prevent.stop="excluir"
                  >Excluir</a
                >

                <a
                  v-if="exibirRevogarDispensa"
                  class="action-buttons button is-primary is-inverted is-outlined"
                  :class="{ ' is-loading': loading.revogar }"
                  @click.prevent.stop="abrirModal(dispensa.id)"
                  >Revogar Dispensa</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns column-direction-touch">
          <div class="column is-narrow">
            <MenuSecundario titulo="Dispensa" :itens="menu" />
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>

    <b-modal
      has-modal-card
      ref="justificationModal"
      :active.sync="justificativa"
      @justificate="enviar"
    >
      <AppJustification
        v-if="justificativa"
        :displayFailButton="exibirFalha"
        :displaySuccessButton="exibirSucesso"
        :failText="textoFalha"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppJustification from '@/components/AppJustification';
import MenuSecundario from '@/components/MenuSecundario';

export default {
  name: 'DispensaEditar',
  components: {
    AppJustification,
    MenuSecundario,
  },
  data() {
    const loading = {
      publicar: false,
      excluir: false,
      revogar: false,
    };

    return {
      desabilitarPublicar: false,
      dispensaId: 0,
      exibirFalha: false,
      exibirSucesso: false,
      justificativa: false,
      loading,
      textoFalha: '',
    };
  },
  beforeRouteLeave(to, from, next) {
    this.limparDispensa();
    this.limparAnexosDispensa();
    this.limparItensDispensa();
    return next();
  },
  computed: {
    ...mapGetters({
      dispensa: 'obterDispensa',
      anexos: 'obterAnexosDispensa',
      itens: 'obterTodosItensDispensa',
      emPublicacao: 'obterDispensaEmPublicacao',
    }),
    situacao() {
      return this.dispensa.situacao.id;
    },
    exibirRevogarDispensa() {
      return this.autoridade && [2, 4, 15].indexOf(this.situacao) > -1;
    },
    menu() {
      return [
        { titulo: 'Dados Cadastrais', rota: 'DispensaEditarDados' },
        { titulo: 'Anexos', rota: 'DispensaEditarAnexos' },
        { titulo: 'Itens', rota: 'DispensaEditarItens' },
      ];
    },
  },
  methods: {
    ...mapActions([
      'alternarEmPublicacaoDispensa',
      'buscarDispensa',
      'buscarAnexosDispensa',
      'buscarItensDispensa',
      'excluirDispensa',
      'limparDispensa',
      'limparAnexosDispensa',
      'limparItensDispensa',
      'publishDispensation',
      'validarDispensa',
      'validarAnexosDispensa',
      'validarItensDispensa',
      'revogarDispensa',
      'salvarDispensa',
    ]),
    async publicar() {
      this.alternarEmPublicacaoDispensa(true);
      const valida = await this.validar();

      if (valida) {
        const confirmation = await this.$confirmacao({
          message: `Você está publicando a Dispensa de Licitação <strong>${this.dispensa.numero_dispensa}</strong>. Deseja continuar?`,
          type: 'is-info',
        });
        if (!confirmation) {
          this.alternarEmPublicacaoDispensa(false);
          return;
        }

        this.loading.publicar = true;
        try {
          await this.publishDispensation();
          this.$alerta('Dispensa publicada', 'is-success');
          this.$router.push({ name: 'Dispensas' });
        } catch (error) {
          this.$alerta('Erro ao publicar dispensa', 'is-danger');
        }
        this.alternarEmPublicacaoDispensa(false);
      }
      this.loading.publicar = false;
    },
    abrirModal(dispensaId) {
      this.loading.revogar = true;
      this.dispensaId = dispensaId;
      this.textoFalha = 'Revogar';
      this.exibirSucesso = false;
      this.exibirFalha = true;
      this.justificativa = true;
    },
    async excluir() {
      const confirmacao = await this.$confirmacao({
        message: 'Tem certeza que deseja excluir a dispensa?',
      });
      if (confirmacao) {
        this.loading.excluir = true;
        try {
          await this.excluirDispensa(this.dispensa.id);
          this.$router.push({ name: 'Pregoes' });
        } catch (error) {
          this.$alerta('Erro ao excluir dispensa', 'is-danger');
        } finally {
          this.loading.excluir = false;
        }
      }
    },
    async enviar({ justification }) {
      await this.revogarDispensa({
        dispensaId: this.dispensaId,
        justificativa: justification,
      });
    },
    async revogarDispensa(dados) {
      try {
        await this.revogarDispensa(dados);
        this.$alerta('Dispensa revogada', 'is-success');
        this.$router.push({ name: 'Pregoes' });
      } catch (error) {
        this.$alerta('Erro ao revogar dispensa', 'is-danger');
      } finally {
        this.loading.revogar = false;
      }
      this.$refs.justificationModal.close();
    },
    async validar() {
      let valida = await this.validarDispensa();

      if (!valida && this.$route.name !== 'DispensaEditarDados') {
        await this.buscarDispensa(this.$route.params.id);
      }

      valida = await this.validarDispensa();

      if (!valida) {
        this.$alerta('Informe os dados da dispensa', 'is-danger');
        if (this.$route.name !== 'DispensaEditarDados') {
          await this.$router.push({ name: 'DispensaEditarDados' });
        }
        this.alternarEmPublicacaoDispensa(true);

        return valida;
      }

      if (
        !this.anexos.some(anexo => anexo.tipo === 'EDITAL') &&
        this.$route.name !== 'DispensaEditarAnexos'
      ) {
        await this.buscarAnexosDispensa(this.$route.params.id);
      }

      valida = await this.validarAnexosDispensa();
      if (!valida) {
        this.$alerta('Inserção de Edital é obrigatória', 'is-danger');
        if (this.$route.name !== 'DispensaEditarAnexos') {
          await this.$router.push({ name: 'DispensaEditarAnexos' });
        }

        return valida;
      }

      if (!this.itens.length && this.$route.name !== 'DispensaEditarItens') {
        await this.buscarItensDispensa(this.$route.params.id);
      }

      valida = await this.validarItensDispensa();
      if (!valida) {
        this.$alerta('Informe os itens da dispensa', 'is-danger');
        if (this.$route.name !== 'DispensaEditarItens') {
          await this.$router.push({ name: 'DispensaEditarItens' });
        }

        return valida;
      }

      return valida;
    },
  },
  beforeDestroy() {
    this.alternarEmPublicacaoDispensa(false);
  },
};
</script>

<style scoped>
.action-buttons {
  margin-right: 0.5rem;
}

.tm1 {
  margin-top: 1rem;
}
</style>
