import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/vuex/store';

import {
  adminNotAllowed,
  adminProtectedPage,
  companyValidationProtection,
  linkedCompanyPage,
  protectedPage,
  visitorPage,
} from '@/utils/session';

// Containers
import Admin from '@/containers/Admin';
import Default from '@/containers/Default';

// Views
import CompanyValidation from '@/views/Register/CompanyValidation/CompanyValidation';
import InterestedBuyerRegistration from '@/views/Register/PreRegistration/InterestedBuyerRegistration';
import LoginWrapper from '@/views/User/LoginWrapper';
import PreRegistration from '@/views/Register/PreRegistration/index';
import Register from '@/views/Register';
import Start from '@/views/Inicio';

// Routes
import AdminRoutes from './admin';
import AuctionRoutes from './auction';
import CompanyRoutes from './company';
import DispensationRoutes from './dispensation';
import ItemRoutes from './item';
import NotificationRoutes from './notification';
import ProfileRoutes from './profile';
import ReportRoutes from './report';

Vue.use(VueRouter);

let paths = [
  {
    path: '/pre-cadastro',
    name: 'PreRegistration',
    component: PreRegistration,
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      visitorPage(to, from, next);
    },
  },
  {
    path: '/pre-cadastro-orgao',
    name: 'InterestedBuyerRegistration',
    component: InterestedBuyerRegistration,
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      visitorPage(to, from, next);
    },
  },
  {
    path: '/cadastrar',
    alias: '/confirmar-email',
    name: 'Register',
    component: Register,
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
    },
  },
  {
    path: 'validacao-empresa/:id',
    name: 'CompanyValidation',
    component: CompanyValidation,
    beforeEnter: companyValidationProtection,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      linkedCompanyPage(to, from, next);
      protectedPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard'),
  },
  {
    path: '/esqueci-senha',
    name: 'ForgotPassword',
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      visitorPage(to, from, next);
    },
    component: () =>
      import(/* webpackChunkName: "forgot-password" */ '@/views/User/ForgotPassword'),
  },
  {
    path: '/redefinir-senha',
    name: 'ResetPassword',
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
      visitorPage(to, from, next);
    },
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/User/ResetPassword'),
  },
  {
    path: '/contrato-de-servico',
    name: 'MainContractPoints',
    beforeEnter: (to, from, next) => {
      adminNotAllowed(to, from, next);
    },
    component: () =>
      import(/* webpackChunkName: "main-contract-points" */ '@/views/MainContractPoints'),
  },
  {
    path: '/termos-de-uso',
    name: 'TermsOfUse',
    beforeEnter: adminNotAllowed,
    component: () => import(/* webpackChunkName: "terms-of-use" */ '@/views/Register/TermsOfUse'),
  },
  {
    path: '/manuais',
    name: 'Manuals',
    beforeEnter: adminNotAllowed,
    component: () => import(/* webpackChunkName: "manuals" */ '@/views/Manuals'),
  },
  {
    path: '/404',
    name: 'PaginaInexistente',
    beforeEnter: adminNotAllowed,
    component: () => import(/* webpackChunkName: "not-found-page" */ '@/views/PaginaInexistente'),
  },
  {
    path: '/comunicado',
    name: 'BlockedAccess',
    beforeEnter: adminNotAllowed,
    component: () => import(/* webpackChunkName: "blocked-access" */ '@/views/BlockedAccess'),
  },
  {
    path: '/sobre',
    name: 'SobreNos',
    beforeEnter: adminNotAllowed,
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Institucional'),
  },
];

let adminPaths = [
  {
    path: '/',
    name: 'Administrativo',
    component: () => import(/* webpackChunkName: "administrativo" */ '@/views/Admin/Inicio'),
    beforeEnter: adminProtectedPage,
  },
];

paths = paths.concat(
  AuctionRoutes,
  CompanyRoutes,
  DispensationRoutes,
  ItemRoutes,
  NotificationRoutes,
  ProfileRoutes,
  ReportRoutes,
);
adminPaths = adminPaths.concat(AdminRoutes);

const rotas = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'Start',
      component: Start,
    },
    {
      path: '/fatura/:uuid',
      name: 'Fatura',
      component: () => import(/* webpackChunkName: "Invoice" */ '@/views/Admin/Financial/Invoice'),
    },
    {
      path: '/',
      component: Default,
      children: paths,
    },
    {
      path: '/entrar',
      name: 'Login',
      component: LoginWrapper,
      beforeEnter: visitorPage,
    },
    {
      path: '/admin',
      redirect: { name: 'Administrativo' },
      component: Admin,
      children: adminPaths,
    },
  ],
});

rotas.beforeEach(async (to, from, next) => {
  const { company } = store.state.companies;
  const confirmedUserEmail = store.getters.getConfirmedUserEmail;
  const logged = store.getters.getLogged;

  const blockedCompany = company.status === 'BLOQUEADA';

  if (!logged) await store.dispatch('startSession');

  if (logged) {
    if (blockedCompany) {
      if (to.path !== '/comunicado') {
        return next({
          path: '/comunicado',
        });
      }
    }

    if (!confirmedUserEmail) {
      if (to.name !== 'Register') {
        return next({
          path: '/confirmar-email',
        });
      }
    }
  }

  return next();
});

export default rotas;
