import { adminProtectedPage } from '@/utils/session';

export default [
  {
    path: 'validacao',
    name: 'Validações',
    beforeEnter: adminProtectedPage,
    component: () =>
      import(
        /* webpackChunkName: "validateCompany" */ '@/views/Admin/ValidateCompany/ValidaEmpresa'
      ),
  },
  {
    path: 'faturas',
    name: 'Faturas',
    beforeEnter: adminProtectedPage,
    component: () => import(/* webpackChunkName: "Financial" */ '@/views/Admin/Financial/Invoices'),
  },
  {
    path: 'validacao/:validacao',
    redirect: { name: 'ViewCompanyData' },
    name: 'ReviewItem',
    beforeEnter: adminProtectedPage,
    component: () =>
      import(/* webpackChunkName: "validateCompany" */ '@/views/Admin/ValidateCompany/ReviewItem'),
    children: [
      {
        path: 'cadastro',
        name: 'ViewCompanyData',
        component: () =>
          import(
            /* webpackChunkName: "viewCompanyData" */ '@/views/Admin/ValidateCompany/ViewCompanyData'
          ),
      },
      {
        path: 'cartorio',
        name: 'ViewCompanyRegistry',
        component: () =>
          import(
            /* webpackChunkName: "viewCompanyRegistry" */ '@/views/Admin/ValidateCompany/ViewCompanyRegistry'
          ),
      },
      {
        path: 'tipos',
        name: 'ViewCompanyType',
        component: () =>
          import(
            /* webpackChunkName: "viewCompanyType" */ '@/views/Admin/ValidateCompany/ViewCompanyType'
          ),
      },
      {
        path: 'documentos',
        name: 'ViewCompanyDocuments',
        component: () =>
          import(
            /* webpackChunkName: "viewCompanyDocuments" */ '@/views/Admin/ValidateCompany/ViewCompanyDocuments'
          ),
      },
      {
        path: 'listausuarios',
        name: 'ViewCompanyUsers',
        component: () =>
          import(
            /* webpackChunkName: "viewCompanyUsers" */ '@/views/Admin/ValidateCompany/ViewCompanyUsers'
          ),
      },
    ],
  },
  {
    path: 'prefeituras',
    name: 'Cadastro',
    beforeEnter: adminProtectedPage,
    component: () =>
      import(/* webpackChunkName: "BuyerRegister" */ '@/views/Admin/Buyer/ListaPrefeituras'),
  },
  {
    path: 'prefeituras/:id',
    name: 'CadastroPrefeituras',
    redirect: { name: 'DadosCadastroPrefeitura' },
    component: () => import(/* webpackChunkName: "BuyerRegister" */ '@/views/Admin/Buyer/Cadastro'),
    children: [
      {
        path: 'dados',
        name: 'DadosCadastroPrefeitura',
        component: () =>
          import(/* webpackChunkName: "BuyerRegister" */ '@/views/Admin/Buyer/BuyerDataRegister'),
      },
      {
        path: 'usuarios',
        name: 'PrefeituraUsuarios',
        component: () =>
          import(/* webpackChunkName: "BuyerRegister" */ '@/views/Admin/Buyer/BuyerUsersRegister'),
      },
    ],
  },
  {
    path: 'relatorios',
    name: 'RelatorioVencedores',
    component: () =>
      import(
        /* webpackChunkName: "BuyerRegister" */ '@/views/Admin/Relatorios/RelatorioVencedores'
      ),
  },
  {
    path: 'eventos',
    name: 'Events',
    beforeEnter: adminProtectedPage,
    component: () => import(/* webpackChunkName: "Events" */ '@/views/Admin/Events'),
  },
];
