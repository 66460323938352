import {
  aceitarLanceNegociacao,
  enviarLanceNegociacao,
  recusarLanceNegociacao,
} from '@/resources/ofertasCompra';
import { negotiationItems } from '@/resources/electronicAuctions/auctionsNegotiations';

// const mutations = {};

const actions = {
  async aceitarLanceNegociacao({ rootState }, { itemId, justificativa }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await aceitarLanceNegociacao(ofertaCompraId, itemId, { justificativa });
    const {
      status,
      data: { type },
    } = response;
    return status === 200 && type === 'PropostaAceita';
  },
  async enviarLanceNegociacao({ rootState }, { id, valor }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await enviarLanceNegociacao(ofertaCompraId, id, { valor });
    const {
      status,
      data: { type },
    } = response;
    return status === 200 && type === 'PropostaEnviada';
  },
  async recusarLanceNegociacao({ rootState }, { itemId, justificativa }) {
    const ofertaCompraId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await recusarLanceNegociacao(ofertaCompraId, itemId, { justificativa });
    const {
      status,
      data: { type },
    } = response;
    return status === 200 && type === 'PropostaRecusada';
  },
  async negotiationItemsSelected({ rootState }, { items, accept, justification }) {
    const auctionId = rootState.ofertasCompra.ofertaCompra.id;
    const response = await negotiationItems(auctionId, { items, accept, justification });
    const {
      status,
      data: { type },
    } = response;
    return status === 200 && type === 'PropostasNegociadas';
  },
};

// const getters = {};

// const state = {};

export default {
  actions,
};
