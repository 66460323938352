import Vue from 'vue';
import Vuex from 'vuex';

// Módulos
import adminEvents from './modules/admin/adminEvents';
import app from './modules/app';
import anexos from './modules/anexos';
import auctionsClarifications from './modules/electronicAuctions/auctionsClarifications';
import auctionsDeclarations from './modules/electronicAuctions/auctionsDeclarations';
import auctionsDocuments from './modules/electronicAuctions/auctionsDocuments';
import auctionsImpugnations from './modules/electronicAuctions/auctionsImpugnations';
import auctionsOptions from './modules/electronicAuctions/auctionsOptions';
import auctionsQualifications from './modules/electronicAuctions/auctionsQualifications';
import auctionsBidders from './modules/electronicAuctions/auctionsBidders';
import dispensationsBidders from './modules/dispensations/dispensationsBidders';
import companies from './modules/companies';
import companiesConfigurations from './modules/companies/companiesConfigurations';
import companiesKeys from './modules/companies/companiesKeys';
import companiesDocuments from './modules/companies/companiesDocuments';
import companiesInvitedSuppliers from './modules/companies/companiesInvitedSuppliers';
import companiesUsers from './modules/companies/companiesUsers';
import chat from './modules/chat';
import dispensas from './modules/dispensas';
import dispensasAnexos from './modules/dispensasAnexos';
import dispensasItens from './modules/dispensasItens';
import dispensasLances from './modules/dispensasLances';
import dispensasPrazos from './modules/dispensasPrazos';
import dispensationsOptions from './modules/dispensations/dispensationsOptions';
import documentTypes from './modules/documentTypes';
import empresasPendentes from './modules/empresasPendentes';
import invoices from './modules/admin/faturas/invoices';
import itens from './modules/itens';
import lances from './modules/lances';
import negociacao from './modules/negociacao';
import notifications from './modules/users/notifications';
import ofertasCompra from './modules/ofertasCompra';
import ofertasCompraItens from './modules/ofertasCompraItens';
import prazos from './modules/prazos';
import prefectures from './modules/companies/prefectures';
import proposals from './modules/electronicAuctions/proposals';
import recursos from './modules/recursos';
import relatorios from './modules/relatorios';
import requiredDocuments from './modules/electronicAuctions/requiredDocuments';
import responsaveis from './modules/responsaveis';
import users from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    adminEvents,
    app,
    anexos,
    auctionsClarifications,
    auctionsDeclarations,
    auctionsDocuments,
    auctionsImpugnations,
    auctionsOptions,
    auctionsQualifications,
    companies,
    companiesConfigurations,
    companiesDocuments,
    companiesKeys,
    companiesInvitedSuppliers,
    companiesUsers,
    chat,
    dispensas,
    dispensasAnexos,
    dispensasItens,
    dispensasLances,
    dispensasPrazos,
    dispensationsOptions,
    documentTypes,
    empresasPendentes,
    invoices,
    itens,
    lances,
    negociacao,
    notifications,
    ofertasCompra,
    ofertasCompraItens,
    prazos,
    prefectures,
    proposals,
    responsaveis,
    recursos,
    relatorios,
    requiredDocuments,
    users,
    auctionsBidders,
    dispensationsBidders,
  },
  strict: process.env.NODE_ENV !== 'production',
});
