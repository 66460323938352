<template>
  <div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Documentos Enviados">
        <ListFiles
          :item="item"
          :qualificationCompany="qualificationCompany"
          :situation="situation"
        />
      </b-tab-item>

      <b-tab-item label="Documentos Requeridos">
        <ListRequiredDocuments />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ListFiles from './ListFiles';
import ListRequiredDocuments from './ListRequiredDocuments';

export default {
  name: 'Documents',
  props: {
    item: Object,
    qualificationCompany: Object,
    situation: Number,
  },
  components: {
    ListFiles,
    ListRequiredDocuments,
  },
};
</script>
