<template>
  <div class="brand">
    <b-field
      :type="campoInvalido('marca', 'lance') ? 'is-danger' : ''"
      :message="campoInvalido('marca', 'lance') ? 'Campo obrigatório' : ''"
    >
      <b-input
        custom-class="uppercase"
        placeholder="Marca/Modelo"
        v-model="lance.marca"
        :disabled="existeLanceValidoItem"
        :style="{ width: width + 'px' }"
        @input.native="event => atualizarMarca(event.target.value)"
        @blur="event => converterMaiusculo('marca', event.target.value, 'lance')"
        @keyup.enter.native="enviarLance"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
/* eslint-disable no-confusing-arrow */

import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

import campoMixin from '@/mixins/formulario';

export default {
  name: 'DispensaMarcaModelo',
  mixins: [campoMixin],
  props: {
    item: Object,
    width: { type: String, default: '' },
  },
  data() {
    const lance = {
      marca: '',
    };

    return {
      lance,
    };
  },
  validations: {
    lance: {
      marca: { required },
    },
  },
  computed: {
    ...mapGetters({
      lances: 'obterDispensaLances',
      lancesParaEnviar: 'obterLancesParaEnviar',
    }),
    existeLanceValidoItem() {
      return this.lances.find(
        elemento => elemento.dispensaItemId === this.item.id && elemento.status === 'VALIDO',
      );
    },
  },
  watch: {
    lances() {
      if (this.lances.length) {
        const indice = this.lancesParaEnviar.findIndex(
          lance => lance.dispensaItemId === this.item.id,
        );

        if (indice === -1 && !this.existeLanceValidoItem) {
          this.lance.marca = '';
        } else {
          this.carregarUltimoLance();
        }
      }
    },
    lancesParaEnviar() {
      const itemSemMarca = this.lancesParaEnviar.some(
        lance => lance.dispensaItemId === this.item.id && !lance.marca && lance.valor,
      );

      if (itemSemMarca) {
        this.$v.lance.$touch();
      } else {
        this.$v.lance.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['adicionarDispensaLance']),
    atualizarMarca(marca) {
      const dados = this.montarDados(marca);
      this.adicionarDispensaLance(dados);
    },
    montarDados(marca) {
      const dados = this.lancesParaEnviar.find(lance => lance.dispensaItemId === this.item.id);
      const importado = this.calcularImportado(dados);

      return {
        dispensaItemId: this.item.id,
        identificador: this.item.identificador,
        itemDescricao: this.item.item.descricao,
        itemUnidadeNome: this.item.unidade.nome,
        valor: dados ? dados.valor : 0,
        marca,
        importado,
        primeiroLance: !this.existeLanceValidoItem,
      };
    },
    calcularImportado(dados) {
      if (dados) return dados.importado;
      if (this.existeLanceValidoItem) {
        const ultimoLance = this.calcularUltimoLance();
        if (ultimoLance.fabricado === 'IMPORTADO') {
          return true;
        }
      }
      return false;
    },
    calcularUltimoLance() {
      return this.lances
        .filter(
          elemento => elemento.dispensaItemId === this.item.id && elemento.status === 'VALIDO',
        )
        .reduce((anterior, atual) => (anterior.id > atual.id ? anterior : atual));
    },
    carregarUltimoLance() {
      const existe = this.lances.some(
        lance => lance.dispensaItemId === this.item.id && lance.status === 'VALIDO',
      );

      if (existe) {
        const ultimoLance = this.lances
          .filter(
            elemento => elemento.dispensaItemId === this.item.id && elemento.status === 'VALIDO',
          )
          .reduce((anterior, atual) => (anterior.id > atual.id ? anterior : atual));

        this.lance.marca = ultimoLance.marca;
      }
    },
    enviarLance() {
      this.$emit('enter-lance');
    },
  },
  mounted() {
    this.carregarUltimoLance();
  },
};
</script>

<style lang="scss">
.brand {
  margin-bottom: 0.5rem;
}
</style>
