import { adminNotAllowed } from '@/utils/session';

export default [
  {
    path: '/relatorios/listagem-pregoes',
    name: 'ListagemPregoes',
    beforeEnter: adminNotAllowed,
    component: () =>
      import(/* webpackChunkName: "listagem-pregoes" */ '@/views/Reports/AuctionListing'),
  },
  {
    path: '/relatorios/listagem-dispensas',
    name: 'ListagemDispensas',
    beforeEnter: adminNotAllowed,
    component: () =>
      import(/* webpackChunkName: "listagem-dispensas" */ '@/views/Reports/DispensationListing'),
  },
];
